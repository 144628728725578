const calculateMean = (values) => {
    const total = values.reduce((acc, val) => acc + val, 0);
    return total / values.length;
};

// Функция для вычисления стандартного отклонения
const calculateStandardDeviation = (values, mean) => {
    const variance = values.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / values.length;
    return Math.sqrt(variance);
};

// Функция для определения границ на основе нормального распределения
export const determineNormalDistributionBounds = (values) => {
    const filteredValues = values.filter(value => value > 0);
    const mean = calculateMean(filteredValues);
    const stdDev = calculateStandardDeviation(filteredValues, mean);

    const level1 = Math.round(mean / 2)
    const level2 = Math.round(mean)
    const level3 = Math.round(mean + stdDev)
    return {level1, level2, level3};
};

export const createFilterText = (gradientData, dataIndex) => {
    return [
        {text: `Меньше ${gradientData[dataIndex]?.level1}`, value: '1'},
        {text: `От ${gradientData[dataIndex]?.level1} до ${gradientData[dataIndex]?.level2}`, value: '2'},
        {text: `От ${gradientData[dataIndex]?.level2} до ${gradientData[dataIndex]?.level3}`, value: '3'},
        {text: `Больше ${gradientData[dataIndex]?.level3}`, value: '4'},
    ]
}
export const createFilterFunction = (gradientData, dataIndex) => {
    return (value, record) => {
        switch (value) {
            case '1':
                return record[dataIndex] < gradientData[dataIndex].level1;
            case '2':
                return record[dataIndex] >= gradientData[dataIndex].level1 && record[dataIndex] <= gradientData[dataIndex].level2;
            case '3':
                return record[dataIndex] > gradientData[dataIndex].level2 && record[dataIndex] <= gradientData[dataIndex].level3;
            case '4':
                return record[dataIndex] > gradientData[dataIndex].level3;
            default:
                return true;
        }
    };
};


export const getCellStyle = (gradientData, dataIndex, value, reverse = false) => {
    if (gradientData[dataIndex]) {
        let background;
        if (!reverse) {
            if (value <= gradientData[dataIndex].level1) {
                background = 'linear-gradient(to right, #ffcccc, #ff9999)'; // Красный
            } else if (value <= gradientData[dataIndex].level2) {
                background = 'linear-gradient(to right, #ffcc99, #ff9966)'; // Оранжевый
            } else if (value <= gradientData[dataIndex].level3) {
                background = 'linear-gradient(to right, #ffffcc, #ffff99)'; // Желтый
            } else {
                background = 'linear-gradient(to right, #ccffcc, #99ff99)'; // Зеленый
            }
        } else {
            if (value > gradientData[dataIndex].level3) {
                background = 'linear-gradient(to right, #ffcccc, #ff9999)'; // Красный
            } else if (value >= gradientData[dataIndex].level2) {
                background = 'linear-gradient(to right, #ffcc99, #ff9966)'; // Оранжевый
            } else if (value >= gradientData[dataIndex].level1) {
                background = 'linear-gradient(to right, #ffffcc, #ffff99)'; // Желтый
            } else {
                background = 'linear-gradient(to right, #ccffcc, #99ff99)'; // Зеленый
            }
        }
        return {background};
    }
    return null
};