import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Card, Table, Tooltip, Input, Button, ConfigProvider, Modal, Select, notification, Typography, Row, Col, message, Tabs } from 'antd'
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { FaCopy } from 'react-icons/fa'

import { useAuth } from '../../core/hooks/useAuth'
import dayjs from 'dayjs'
import { apiAxios } from '../../core/api/api_axios'
import DatePickerRange from '../../components/simple/dateRange'
import { apiUserRemoveStaff } from '../../core/api/user/profile'
import TabPane from 'antd/es/tabs/TabPane'
import { formatCurrency } from '../../core/utils/formats'

const { Title, Text, Paragraph } = Typography

const TrafficAcc = () => {
    const { isCsrf, setIsCsrf, user } = useAuth()
    const { wbacc_id } = useParams()
    const [wbacc, setWBAcc] = useState({})
    const [period, setPeriod] = useState([dayjs().add(-30, 'd'), dayjs().add(-1, 'd')])
    const [orders, setOrders] = useState([])
    const [clicks, setClicks] = useState([])
    const [isModalOpen, setIsModal] = useState({
        track_new: false,
        track_remove: false
    })
    const setIsModalOpen = (name, value) => {
        setIsModal((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const [searchText, setSearchText] = useState('')
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [newTrack, setNewTrack1] = useState({
        name: '',
        source: '',
        nm_id: '',
        wbacc_id: wbacc_id
    })
    const setNewTrack = (name, value) => {
        setNewTrack1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const [currentTrack, setCurrentTrack1] = useState({
        track_id: ''
    })
    const setCurrentTrack = (name, value) => {
        setCurrentTrack1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedDataTrack = await apiAxios.post('track/get/', { wbacc_ids: [wbacc_id] })
            const fetchedDataStat = await apiAxios.post('track/stat/get/', { wbacc_ids: [wbacc_id], st: period[0].format('YYYY-MM-DD'), en: period[1].format('YYYY-MM-DD'), is_sum: true })
            const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: [wbacc_id] })

            setWBAcc(fetchedDataWbacc.data[wbacc_id])

            if (fetchedDataTrack.success) {
                const mergedArray1 = fetchedDataTrack.tracks.map((track) => {
                    const stat = fetchedDataStat.stat.find((stat) => stat.track_id === track.id)
                    return stat ? { ...track, ...stat } : track
                })
                console.log('mergedArray1', mergedArray1)
                setData(mergedArray1)
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const fetchData2 = async () => {
        try {
            setLoading(true)
            const fetchedDataTrack = await apiAxios.post('track/orders/get/', { wbacc_id: wbacc_id })
            const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: [wbacc_id] })
            setOrders(fetchedDataTrack.data)
            setWBAcc(fetchedDataWbacc.data[wbacc_id])
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }
    const fetchData3 = async () => {
        try {
            setLoading(true)
            const fetchedDataTrack = await apiAxios.post('track/clicks/get/', { wbacc_id: wbacc_id })
            const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: [wbacc_id] })
            setClicks(fetchedDataTrack.data)
            setWBAcc(fetchedDataWbacc.data[wbacc_id])
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const columnsClicks = [
        { title: 'ID', dataIndex: 'track_id', key: 'nm_id' },
        {
            title: 'Артикул',
            dataIndex: 'nm_id',
            key: 'nm_id',
            render: (text) => (
                <Tooltip title={text}>
                    <a href={`https://www.wildberries.ru/catalog/${text}/detail.aspx`} target='_blank' rel='noopener noreferrer'>
                        {text}{' '}
                    </a>
                </Tooltip>
            ),
            sorter: (a, b) => a.nm_id - b.nm_id
        },
        { title: 'Юзер агент', dataIndex: 'user_agent', key: 'user_agent' },
        { title: 'Страна', dataIndex: 'country', key: 'country' },
        { title: 'Область', dataIndex: 'oblast', key: 'oblast' },
        { title: 'Регион', dataIndex: 'region', key: 'region' },
        { title: 'Дата клика', dataIndex: 'click_datetime', key: 'click_datetime' }
        // {
        //     title: 'Действия', key: 'actions', render: (text, record) => (<div><DeleteOutlined style={{color: '#ff4d4f'}} onClick={() => {
        //         setCurrentTrack('track_id', record.id);
        //         setIsModalOpen('track_remove', true);
        //     }}/></div>),
        // }
    ]

    const columnsOrders = [
        {
            title: 'Артикул',
            dataIndex: 'nm_id',
            key: 'nm_id',
            render: (text) => (
                <Tooltip title={text}>
                    <a href={`https://www.wildberries.ru/catalog/${text}/detail.aspx`} target='_blank' rel='noopener noreferrer'>
                        {text}{' '}
                    </a>
                </Tooltip>
            ),
            sorter: (a, b) => a.nm_id - b.nm_id
        },
        { title: 'Цена', dataIndex: 'price', key: 'price', render: (text) => formatCurrency(text) },
        { title: 'Страна', dataIndex: 'country', key: 'country' },
        { title: 'Область', dataIndex: 'oblast', key: 'oblast' },
        { title: 'Регион', dataIndex: 'region', key: 'region' },
        { title: 'Дата заказа', dataIndex: 'order_date_time', key: 'order_date_time' }
        // {
        //     title: 'Действия', key: 'actions', render: (text, record) => (<div><DeleteOutlined style={{color: '#ff4d4f'}} onClick={() => {
        //         setCurrentTrack('track_id', record.id);
        //         setIsModalOpen('track_remove', true);
        //     }}/></div>),
        // }
    ]
    console.log(orders, 'orders!!')
    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData()
            fetchData2()
            fetchData3()
        }
    }, [user, period])

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            message.open({ type: 'success', content: 'Текст скопирован в буфер обмена!', style: { marginTop: '5vh' } })
        })
    }

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Link to={`/traffic/${record.wbacc_id}/${record.id}`} style={{ color: '#1890ff' }}>
                    {text}
                </Link>
            ),
            sorter: (a, b) => a.utm_campaign.localeCompare(b.utm_campaign)
        },
        { title: 'ID', dataIndex: 'id' },
        {
            title: 'Артикул',
            dataIndex: 'nm_id',
            key: 'nm_id',
            render: (text) => (
                <Tooltip title={text}>
                    <a href={`https://www.wildberries.ru/catalog/${text}/detail.aspx`} target='_blank' rel='noopener noreferrer'>
                        {text}{' '}
                    </a>
                </Tooltip>
            ),
            sorter: (a, b) => a.nm_id - b.nm_id
        },
        {
            title: 'Ссылка',
            dataIndex: 'url',
            key: 'url',
            render: (text, record) => (
                <Tooltip title={`https://r.bb-data.pro/t/${record.id}`}>
                    <p className='flex gap-1 items-center cursor-pointer' onClick={() => copyToClipboard(`https://r.bb-data.pro/t/${record.id}`)}>
                        <FaCopy />
                        {`https://r.bb-data.pro/t/${record.id}`}{' '}
                    </p>
                </Tooltip>
            ),
            sorter: (a, b) => a.url.localeCompare(b.url)
        },
        { title: 'Источник', dataIndex: 'utm_source', key: 'utm_source' },
        { title: 'Переходы', dataIndex: 'clicks', key: 'clicks', sorter: (a, b) => a.clicks - b.clicks },
        { title: 'Заказы', dataIndex: 'orders', key: 'orders', sorter: (a, b) => a.orders - b.orders },
        { title: 'Сумма заказов', dataIndex: 'orders_sum', key: 'orders_sum', sorter: (a, b) => a.orders_sum - b.orders_sum },
        {
            title: 'Действия',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <DeleteOutlined
                        style={{ color: '#ff4d4f' }}
                        onClick={() => {
                            setCurrentTrack('track_id', record.id)
                            setIsModalOpen('track_remove', true)
                        }}
                    />
                </div>
            )
        }
    ]

    const filteredData = data.filter((item) => {
        return item.utm_campaign.toLowerCase().includes(searchText.toLowerCase()) || item.nm_id.toString().includes(searchText)
    })
    return (
        <>
            <Card>
                <Typography.Title level={1}>Внешний трафик магазин {wbacc?.name}</Typography.Title>
                <Row type='flex' style={{ gap: 10 }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                </Row>
            </Card>

            {data.length ? (
                <Card>
                    <Tabs defaultActiveKey='1'>
                        <TabPane tab='Общее' key='1'>
                            <div className='flex gap-5 justify-end'>
                                <Input
                                    placeholder='Поиск по всем полям'
                                    value={searchText}
                                    className='max-w-[400px]'
                                    onChange={(e) => setSearchText(e.target.value)}
                                    style={{ marginBottom: '16px' }}
                                    prefix={<SearchOutlined />}
                                />
                                <ConfigProvider theme={{ token: { colorPrimaryHover: '#9333ea' } }}>
                                    <Button type='primary' onClick={() => setIsModalOpen('track_new', true)} className='bg-purple-600'>
                                        + Создать
                                    </Button>
                                </ConfigProvider>
                            </div>
                            <Table className='adverts-table' dataSource={filteredData} columns={columns} pagination={{ pageSize: 25 }} rowClassName='custom-row' loading={loading} />
                        </TabPane>
                        <TabPane tab='Заказы' key='2'>
                            <Table className='adverts-table' dataSource={orders} columns={columnsOrders} pagination={{ pageSize: 25 }} rowClassName='custom-row' loading={loading} />
                        </TabPane>
                        <TabPane tab='Клики' key='3'>
                            <Table className='adverts-table' dataSource={clicks} columns={columnsClicks} pagination={{ pageSize: 25 }} rowClassName='custom-row' loading={loading} />
                        </TabPane>
                    </Tabs>
                </Card>
            ) : (
                <p>Данные не найдены.</p>
            )}
            <ModalNewTrack isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} newTrack={newTrack} setNewTrack={setNewTrack} fetchData={fetchData} />
            <ModalRemoveTrack isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} currentTrack={currentTrack} setCurrentTrack={setCurrentTrack} fetchData={fetchData} />
        </>
    )
}

export default TrafficAcc

const ModalNewTrack = ({ isModalOpen, setIsModalOpen, newTrack, setNewTrack, fetchData }) => {
    const handleOk = async () => {
        try {
            const response = await apiAxios.post('track/add/', newTrack)
            setNewTrack('name', '')
            setNewTrack('source', '')
            setNewTrack('nm_id', '')
            if (response.success) {
                message.open({ type: 'success', content: 'Ссылка добавлена!', style: { marginTop: '5vh' } })
                setIsModalOpen('track_new', false)
                fetchData()
            } else {
                message.open({ type: 'error', content: 'Не удалось добавить ссылку.', style: { marginTop: '5vh' } })
            }
        } catch (error) {
            message.open({ type: 'error', content: 'Не удалось добавить ссылку.', style: { marginTop: '5vh' } })
        }
    }
    return (
        <Modal
            title='Добавить ссылку'
            open={isModalOpen.track_new}
            centered
            onCancel={() => setIsModalOpen('track_new', false)}
            footer={[
                <Button key='submit' onClick={handleOk}>
                    Добавить
                </Button>,
                <Button key='back' onClick={() => setIsModalOpen('track_new', false)}>
                    Отменить
                </Button>
            ]}
        >
            <div className='flex flex-col gap-5'>
                <label className='flex flex-col gap-2'>
                    <span className='font-semibold'>Название</span>
                    <Input placeholder='Введите название для ссылки(имя/лист)' className='max-w-[400px]' value={newTrack?.name} onChange={(e) => setNewTrack('name', e.target.value)} />
                </label>

                <label className='flex flex-col gap-2'>
                    <span className='font-semibold'>Артикул</span>
                    <Input placeholder='Введите артикул из карточки' className='max-w-[400px]' value={newTrack?.nm_id} onChange={(e) => setNewTrack('nm_id', e.target.value)} />
                </label>
                <label className='flex flex-col gap-2'>
                    <span className='font-semibold'>Источник рекламы</span>
                    <Select
                        style={{ width: '400px' }}
                        value={newTrack?.source}
                        onChange={(value) => setNewTrack('source', value)}
                        options={[
                            { value: 'vk', label: 'VK' },
                            { value: 'yandex', label: 'Яндекс' },
                            { value: 'another', label: 'Другое' }
                        ]}
                    />
                </label>
            </div>
        </Modal>
    )
}

const ModalRemoveTrack = ({ isModalOpen, setIsModalOpen, currentTrack, setCurrentTrack, fetchData }) => {
    const handleOk = async () => {
        try {
            const response = await apiAxios.post('track/remove/', { track_id: currentTrack.track_id })
            if (response.success) {
                message.open({ type: 'success', content: 'Ссылка удалена!', style: { marginTop: '5vh' } })

                fetchData()
            } else {
                message.open({ type: 'error', content: 'Не удалось удалить ссылку.', style: { marginTop: '5vh' } })
            }
        } catch (error) {
            message.open({ type: 'error', content: 'Не удалось удалить ссылку.', style: { marginTop: '5vh' } })
        }
        setCurrentTrack('track_id', '')
        setIsModalOpen('track_remove', false)
    }
    return (
        <Modal
            title='Удалить ссылку'
            open={isModalOpen.track_remove}
            centered
            onCancel={() => setIsModalOpen('track_remove', false)}
            footer={[
                <Button key='submit' danger onClick={handleOk}>
                    Удалить
                </Button>,
                <Button key='back' onClick={() => setIsModalOpen('track_remove', false)}>
                    Отменить
                </Button>
            ]}
        >
            <Paragraph className='small text-muted'>Удалить ссылку?</Paragraph>
        </Modal>
    )
}
