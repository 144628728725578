
import {apiAxios} from"../../utils/api_axios";

const updateLikeHide = (article, typ) => {
    const response = apiAxios.post('plan-fact/update/like-hide/', {'article': article, 'typ': typ});
    return response
};
export const defaultOnChangeLikeHide = (record, checked, typ, likeHideList, setLikeHideList) => {
    const ids = [...likeHideList[typ]];
    const index = ids.indexOf(record.article);
    if (checked && index === -1) {
        ids.push(record.article);
    } else if (!checked && index !== -1) {
        ids.splice(index, 1);
    }
    setLikeHideList((prevState) => ({...prevState, [typ]: ids}));
    updateLikeHide(record.article, typ);
};


export const setLikeHideOnLoadData = (fetchedData, setLikeHideList) => {
    let like = [];
    let hide = [];
    fetchedData['cards'].forEach((card, index) => {
        if (Boolean(card['is_like'])) {
            like.push(card['article']);
        }
        if (Boolean(card['is_hide'])) {
            hide.push(card['article']);
        }
    });
    setLikeHideList({like: like, hide: hide});
}