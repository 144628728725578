import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row, Tooltip, Typography } from 'antd'
import { SelectorSolo } from '../../components/Selectors'
import { useAuth } from '../../hooks/useAuth'
import dayjs from 'dayjs'
import DatePickerRange from '../../components/dateRange'
import TableAdverts from './components/adverts'
import { ButtonExel } from '../../components/Buttons'
import { useSidebar } from '../../Context/SideBarContext'
import { CiCircleQuestion } from 'react-icons/ci'
import Sidebar from '../home/sideBar/SideBar'
import SideBarAdverts from '../home/sideBar/SideBarAdverts'
import { apiAxios } from "../../utils/api_axios";

export default function Adverts() {
    const { user } = useAuth()
    const [filters_data, setFiltersData] = useState([])
    const [adverts, setAdverts] = useState([])
    const startDate = dayjs().date() == 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month')
    const [period, setPeriod] = useState([startDate, dayjs()])
    const [selectedWbaccs, setSelectedWbaccs] = useState([])
    const [selectedStatusType, setSelectedStatusType] = useState(1)
    const [selectedAdvertType, setSelectedAdvertType] = useState(1)
    const [loading, setLoading] = useState(true)
    const [optionsStatusType, setOptionsStatusType] = useState([])
    const [optionsAdvertType, setOptionsAdvertType] = useState([])

    const { openSidebar } = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarAdverts />)
    }, [openSidebar])

    const optionsAdvertTypeWB = [
        { label: 'Все', value: 1 },
        { label: 'Аукцион', value: 2 },
        { label: 'Автоматическая', value: 3 }
    ]
    const optionsStatusTypeWB = [
        { label: 'Все кроме архивных', value: 1 },
        { label: 'Готовые к запуску', value: 2 },
        { label: 'Активные', value: 3 },
        { label: 'Приостановленные', value: 4 },
        { label: 'Архивные', value: 5 }
    ]

    const optionsAdvertTypeOzon = [
        { label: 'Все', value: 'ALL' },
        { label: 'Трафареты', value: 'SKU' },
        { label: 'Баннер', value: 'BANNER' },
        { label: 'Поиск', value: 'SEARCH_PROMO' }
    ]

    const optionsStatusTypeOzon = [
        { label: 'Все кроме архивных', value: 1 },
        { label: 'Готовые к запуску', value: 2 },
        { label: 'Активные', value: 3 },
        { label: 'Приостановленные', value: 4 },
        { label: 'Архивные', value: 5 }
    ]
    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedData = await apiAxios.get('adverts/get/', {
                wbacc_ids: [selectedWbaccs],
                status_type: selectedStatusType,
                advert_type: selectedAdvertType,
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
            })

            setOptionsStatusType(selectedWbaccs > 0 ? optionsStatusTypeWB : optionsStatusTypeOzon)
            setOptionsAdvertType(selectedWbaccs > 0 ? optionsAdvertTypeWB : optionsAdvertTypeOzon)

            setFiltersData(fetchedData['filters'])
            setAdverts(fetchedData['adverts'])
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : [])
    }, [user.wbacc_ids])
    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData()
        }
    }, [selectedWbaccs, period, selectedStatusType, selectedAdvertType])
    return (
        <>
            <Card>
                <Typography.Title level={1}>Рекламные компании</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Поставщики</Typography.Title>
                        <SelectorSolo options={filters_data?.wbaccs} selected={selectedWbaccs} setSelected={setSelectedWbaccs} />
                    </Col>

                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Типы кампаний</Typography.Title>
                        <SelectorSolo options={optionsAdvertType} selected={selectedAdvertType} setSelected={setSelectedAdvertType} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Статус</Typography.Title>
                        <SelectorSolo options={optionsStatusType} selected={selectedStatusType} setSelected={setSelectedStatusType} />
                    </Col>
                    <ButtonExel data={adverts} />
                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                    </Tooltip>
                </Row>
            </Card>
            <Sidebar />
            <Card>
                <TableAdverts period={period} adverts={adverts} loading={loading} />
            </Card>
        </>
    )
}
