import {EyeOutlined, HeartOutlined} from "@ant-design/icons";
import {Checkbox, Table} from "antd";
import {formatCurrency, formatDays, formatPercent, formatQuantity} from "../../../../core/utils/formats";
import React from "react";
import {createFilterFunction, createFilterText, getCellStyle} from "../utils/scripts";


const getColumns = (gradientData, group_type, filters_data) => {
    console.log('gradientData', gradientData)
    let columns = [

        {title: 'Группа', dataIndex: 'group_type', width: 120, fixed: 'left', ellipsis: true,},
        {title: 'Остатки FBO', dataIndex: 'ost_quantity', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'ost_quantity', text)}>{formatQuantity(text)}</div>,
        filters: createFilterText(gradientData, 'ost_quantity'), onFilter: createFilterFunction(gradientData, 'ost_quantity'),},
        {title: 'В пути до', dataIndex: 'ost_way_to', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'ost_way_to', text)}>{formatQuantity(text)}</div>,
        filters: createFilterText(gradientData, 'ost_way_to'), onFilter: createFilterFunction(gradientData, 'ost_way_to'),},
        {title: 'В пути от', dataIndex: 'ost_way_from', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'ost_way_from', text)}>{formatQuantity(text)}</div>,
        filters: createFilterText(gradientData, 'ost_way_from'), onFilter: createFilterFunction(gradientData, 'ost_way_from'),},
        {title: 'Себестоимость', dataIndex: 'ost_sebes', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'ost_sebes', text)}>{formatCurrency(text)}</div>,
        filters: createFilterText(gradientData, 'ost_sebes'), onFilter: createFilterFunction(gradientData, 'ost_sebes'),},
        {title: 'Деньги в товаре', dataIndex: 'ost_sum', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'ost_sum', text)}>{formatCurrency(text)}</div>,
        filters: createFilterText(gradientData, 'ost_sum'), onFilter: createFilterFunction(gradientData, 'ost_sum'),},
        {title: 'Заказов в день', dataIndex: 'orders_per_day', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'orders_per_day', text)}>{text}</div>,
        filters: createFilterText(gradientData, 'orders_per_day'), onFilter: createFilterFunction(gradientData, 'orders_per_day'),},
        {title: 'Продажи в день', dataIndex: 'buyouts_per_day', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'buyouts_per_day', text)}>{text}</div>,
        filters: createFilterText(gradientData, 'buyouts_per_day'), onFilter: createFilterFunction(gradientData, 'buyouts_per_day'),},
        {title: 'Процент выкупа', dataIndex: 'buyout_percent', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'buyout_percent', text)}>{formatPercent(text)}</div>,
        filters: createFilterText(gradientData, 'buyout_percent'), onFilter: createFilterFunction(gradientData, 'buyout_percent'),},
        {title: 'Оборачиваемость', dataIndex: 'oborot_orders', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'oborot_orders', text, true)}>{formatDays(text)}</div>,
        filters: createFilterText(gradientData, 'oborot_orders'), onFilter: createFilterFunction(gradientData, 'oborot_orders'),},
        {title: 'Запас дней', dataIndex: 'oborot_buyouts', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'oborot_buyouts', text, true)}>{formatDays(text)}</div>,
        filters: createFilterText(gradientData, 'oborot_buyouts'), onFilter: createFilterFunction(gradientData, 'oborot_buyouts'),},
        {title: 'Наценка', dataIndex: 'nacenka_unit', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'nacenka_unit', text)}>{formatPercent(text)}</div>,
        filters: createFilterText(gradientData, 'nacenka_unit'), onFilter: createFilterFunction(gradientData, 'nacenka_unit'),},
        {title: 'Маржинальность', dataIndex: 'marzha_unit', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'marzha_unit', text)}>{formatPercent(text)}</div>,
        filters: createFilterText(gradientData, 'marzha_unit'), onFilter: createFilterFunction(gradientData, 'marzha_unit'),},
        {title: 'Цена со скидкой', dataIndex: 'price_with_discount', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'price_with_discount', text)}>{formatCurrency(text)}</div>,
        filters: createFilterText(gradientData, 'price_with_discount'), onFilter: createFilterFunction(gradientData, 'price_with_discount'),},
    ]
    if (group_type == 'nm_id') {
        columns.splice(1, 0, {title: 'Категория', dataIndex: 'category', width: 120, fixed: 'left', ellipsis: true,
        filters: filters_data['category'], filterSearch: true, onFilter: (value, record) => record.category == value,});
    }
    return gradientData ? columns : []
}


export default function OborotTable({data, loading, gradientData, group_type, filters_data}) {

    const columns = getColumns(gradientData, group_type, filters_data)

    let DataSource = data;
    return (
        <>
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                scroll={{
                    y: 800,
                }}
                loading={loading}
                pagination={false}
            />
        </>
    );
}