import React, { useEffect, useState, useCallback } from 'react'
import { Card, Col, Row, Select, Tooltip, Typography } from 'antd'
import { useAuth } from '../../hooks/useAuth'
import dayjs from 'dayjs'
import DatePickerRange from '../../components/dateRange'
import { apiAxios } from "../../utils/api_axios";
import FinDetailTable from './components/finDetailTable'
import { ButtonExel } from '../../components/Buttons'
import { CiCircleQuestion } from 'react-icons/ci'
import { useSidebar } from '../../Context/SideBarContext'
import SideBarPnlDetail from '../home/sideBar/SideBarPnlDetail'
import Sidebar from '../home/sideBar/SideBar'

export default function FinDetail() {
    const { user } = useAuth()
    const [data, setData] = useState([])
    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_ids: null,
        wbacc_options: [],
        group: 'nm_id',
        group_options: [
            { label: 'Без группировки', value: 'nm_id' },
            { label: 'Категория', value: 'category' },
            { label: 'Сезон', value: 'sezon' },
            { label: 'Модель', value: 'model' },
            { label: 'Материал', value: 'material' },
            { label: 'Статус', value: 'status' },
            { label: 'Цвет', value: 'color' }
        ],
        week: 1,
        week_options: [
            { label: 'По дням', value: 1 },
            { label: 'По неделям', value: 2 }
        ]
    })
    const [period, setPeriod] = useState([dayjs().add(-30, 'd'), dayjs().add(-1, 'd')])

    const { openSidebar } = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        console.log('Button clicked')
        openSidebar(<SideBarPnlDetail />)
    }, [openSidebar])

    const [loading, setLoading] = useState(true)
    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: user.wbacc_ids })
        let wbaccOptions = []
        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({ label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id })
        }
        // console.log(user.wbacc_ids)
        // console.log(wbaccOptions)
        setSelectorFilter('wbacc_ids', [user.wbacc_ids[0]])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const fetchData = async () => {
        setLoading(true)
        const fetchedData = await apiAxios.post('fin/detail/get/', {
            wbacc_ids: selectorFilter.wbacc_ids,
            st: period[0].format('YYYY-MM-DD'),
            en: period[1].format('YYYY-MM-DD'),
            is_week: selectorFilter.week === 2,
            group_type: selectorFilter.group
        })
        console.log('fetchedData', fetchedData)
        setData(fetchedData.data)
        setLoading(false)
    }
    useEffect(() => {
        if (!selectorFilter.wbacc_ids && user.wbacc_ids) {
            fetchData1()
        }
        if (selectorFilter.wbacc_ids) {
            fetchData()
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_ids, selectorFilter.group, selectorFilter.week, period])

    return (
        <>
            <Card>
                <Typography.Title level={1}>Прибыль и убытки детализация</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Магазины</Typography.Title>
                        <Select
                            mode='multiple'
                            maxTagCount={'responsive'}
                            style={{ width: '100%' }}
                            placeholder='Выберите магазин'
                            value={selectorFilter.wbacc_ids}
                            onChange={(value) => setSelectorFilter('wbacc_ids', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Группировка</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            value={selectorFilter.group}
                            onChange={(value) => {
                                setSelectorFilter('group', value)
                            }}
                            options={selectorFilter.group_options}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Группировка по дням</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            value={selectorFilter.week}
                            onChange={(value) => {
                                setSelectorFilter('week', value)
                            }}
                            options={selectorFilter.week_options}
                        />
                    </Col>
                    <ButtonExel data={data} />
                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                    </Tooltip>
                </Row>
            </Card>
            <Sidebar />
            <Card>
                <FinDetailTable data={data} loading={loading} />
            </Card>
        </>
    )
}
