import React, {useEffect, useState} from "react";
import {Table, Space, Typography, Modal, Button} from "antd";
import {apiAxios} from "../../../utils/api_axios";
import {formatCurrency, formatDate, formatQuantity} from '../../../utils/formats';
import {EyeOutlined} from '@ant-design/icons';

const {Text} = Typography;

export default function ProductSupply({selectorFilter}) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedSupply, setSelectedSupply] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('products/supplies/get/', {
                wbacc_ids: [selectorFilter.wbacc_id],
            });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching supply data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectorFilter.wbacc_id) {
            fetchData();
        }
    }, [selectorFilter.wbacc_id]);

    const showModal = (supply) => {
        setSelectedSupply(supply);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedSupply(null);
    };

    const detailColumns = [
        {title: 'Артикул WB', dataIndex: 'nm_id', width: 120},
        {title: 'Размер', dataIndex: 'size', width: 100},
        {title: 'Баркод', dataIndex: 'barcode', width: 150},
        {title: 'Количество', dataIndex: 'quantity', width: 100, align: 'right', render: (value) => formatQuantity(value)},
    ];

    const columns = [
        {title: '№ Поставки', dataIndex: 'id', width: 100, },
        {
            title: 'Количество',
            width: 150,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Товаров всего:</strong> {formatQuantity(record.quantity)}</Text>
                    <Text><strong>Артикулов:</strong> {formatQuantity(record.quantity_nm_ids)}</Text>
                    <Text><strong>Баркодов:</strong> {formatQuantity(record.quantity_barcodes)}</Text>
                </Space>
            )
        },
        {
            title: 'Информация',
            width: 150,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Склад:</strong> {record.warehouse_name}</Text>
                    <Text><strong>Статус:</strong> {record.status}</Text>
                    <Text><strong>Платная приемка:</strong> {formatCurrency(record.cost)}</Text>
                </Space>
            )
        },
        {
            title: 'Дата',
            width: 150,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Дата создания:</strong> {formatDate(record.date)}</Text>
                    <Text><strong>Дата закрытия:</strong> {formatDate(record.date_close)}</Text>
                </Space>
            )
        },
        {
            title: 'Действия',
            key: 'actions',
            width: 80,
            render: (_, record) => (
                <Button
                    type="link"
                    icon={<EyeOutlined />}
                    onClick={() => showModal(record)}
                >
                    Детали
                </Button>
            ),
        }
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                size="small"
                scroll={{ x: 1500 }}
                pagination={false}
            />
            <Modal
                title={`Детали поставки №${selectedSupply?.id}`}
                open={isModalVisible}
                onCancel={handleCancel}
                width={1200}
                footer={null}
            >
                {selectedSupply && (
                    <Table
                        columns={detailColumns}
                        dataSource={selectedSupply.items}
                        size="small"
                        pagination={false}
                        scroll={{ y: 400 }}
                    />
                )}
            </Modal>
        </>
    );
}
