import React, {useState, useCallback} from 'react'
import {Table, Typography, Space, Input, Form, Card, Menu, Dropdown, Button, Tag} from 'antd'
import {FilterOutlined, CloseOutlined, ReloadOutlined, DownloadOutlined, UploadOutlined} from '@ant-design/icons'
import {formatCurrency, formatPercent} from '../../../utils/formats';
import './PriceTable.css'
import {calculateRow} from "./calculate";
import {useFilterLogic, useFilteredCards} from '../../components/filterUtils'
import * as XLSX from "xlsx";

const {Text} = Typography

const FilterSearch = ({placeholder, onChange}) => (
    <div onClick={e => e.stopPropagation()} style={{padding: '8px'}}>
        <Input.Search
            placeholder={placeholder}
            onChange={onChange}
        />
    </div>
)

const PriceNewPrice = ({
                           cards,
                           setCards,
                           filters,
                           loading,
                           isOzon,
                           updatePrices,
                           loadPrices,
                           checkPrices
                       }) => {
    const isMobile = window.innerWidth <= 769
    const [expenses, setExpenses] = useState({
        sebes_ff: 88,
        sebes_supply: 100,
        sebes_store_days: 30,
        sebes_delivery_l: 54,
        sebes_delivery_l_dop: 12.6,
        sebes_store_l: 0.12,
        sebes_store_l_dop: 0.12,
        sebes_budget: 5,
        sebes_nalog: 6,
        sebes_other: 0,
    });

    const handleExpenseChange = (field, value) => {
        setExpenses(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const calculateLogisticsCost = useCallback((volume) => {
        // Если объем меньше литра, берем за литр
        const effectiveVolume = Math.max(1, volume);
        // Для первого литра берем полную стоимость
        const firstLiterCost = expenses.sebes_delivery_l;
        // Для последующих литров берем доп. стоимость
        const additionalLitersCost = Math.max(0, effectiveVolume - 1) * expenses.sebes_delivery_l_dop;
        return firstLiterCost + additionalLitersCost;
    }, [expenses]);

    const calculateStorageCost = useCallback((volume) => {
        // Если объем меньше литра, берем за литр
        const effectiveVolume = Math.max(1, volume);
        // Для первого литра берем полную стоимость
        const firstLiterCost = expenses.sebes_store_l;
        // Для последующих литров берем доп. стоимость
        const additionalLitersCost = Math.max(0, effectiveVolume - 1) * expenses.sebes_store_l_dop;
        // Умножаем на количество дней
        return (firstLiterCost + additionalLitersCost) * expenses.sebes_store_days;
    }, [expenses]);

    const calculatePlannedCost = useCallback((record) => {
        // Расчет стоимости логистики с учетом объема
        const logisticsCost = calculateLogisticsCost(record.size_value);

        // Добавляем стоимость логистики возвратов
        const returnLogisticsCost = logisticsCost * (100 - record.buyout_percent) / 100;

        // Расчет стоимости хранения с учетом объема
        const storageCost = calculateStorageCost(record.size_value);

        // Расчет дополнительных расходов (бюджет и налоги) от цены клиента
        const additionalCosts = (record.price_client * (expenses.sebes_budget + expenses.sebes_nalog)) / 100;

        // Расчет комиссии от цены со скидкой
        const commissionCost = (record.price_with_discount * record.sebes_commission_wb) / 100;

        return record.sebes +
            expenses.sebes_ff +
            expenses.sebes_supply +
            logisticsCost +
            returnLogisticsCost +
            storageCost +
            additionalCosts +
            commissionCost +
            expenses.sebes_other;
    }, [expenses, calculateLogisticsCost, calculateStorageCost]);

    const columns = () => {
        return [
            // Товар
            {
                title: 'Товар',
                key: 'product',
                width: 300,
                fixed: 'left',
                render: (_, record) => (
                    <Space direction="vertical" size={0}>
                        <Space align="start" size={12}>
                            <img src={record.img} style={{height: '60px', width: '60px', objectFit: 'contain'}} alt="Product"/>
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                                <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                                <Text><strong>Название:</strong> {record.name}</Text>
                                <Text><strong>Карточка:</strong> {record.imt_id}</Text>
                            </Space>
                        </Space>
                    </Space>
                )
            },
            // Информация о товаре
            {
                title: 'Информация о товаре',
                width: 250,
                render: (_, record) => (
                    <Space size={24} className="cost-info-cell" style={{width: '100%'}}>
                        <Space direction="vertical" size={0}>
                            <Text><strong>Модель:</strong> {record.material}</Text>
                            <Text><strong>Бренд:</strong> {record.brand}</Text>
                            <Text><strong>Категория:</strong> {record.category}</Text>
                        </Space>
                        <Space direction="vertical" size={0}>
                            <Text><strong>Статус:</strong> {record.status}</Text>
                            <Text><strong>Сезон:</strong> {record.sezon}</Text>
                            <Text><strong>Цвет:</strong> {record.color}</Text>
                            <Text><strong>Объем:</strong> {record.size_value} л</Text>
                        </Space>
                    </Space>
                )
            },
            // Текущая цена
            {
                title: 'Текущая цена',
                width: 200,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Цена:</strong> {formatCurrency(record.price)}</Text>
                        <Text><strong>Со скидкой:</strong> {formatCurrency(record.price_with_discount)}</Text>
                        <Text><strong>С СПП:</strong> {formatCurrency(record.price_client)}</Text>
                        <Text><strong>С ВБ кошельком:</strong> {formatCurrency(record.price_with_wb_discount)}</Text>
                    </Space>
                )
            },
            // Плановая себестоимость
            {
                title: 'Плановая себестоимость',
                width: 200,
                render: (_, record) => {
                    const logisticsCost = calculateLogisticsCost(record.size_value);
                    const returnLogisticsCost = logisticsCost * (100 - record.buyout_percent) / 100;
                    const storageCost = calculateStorageCost(record.size_value);
                    const additionalCosts = (record.price_client * (expenses.sebes_budget + expenses.sebes_nalog)) / 100;
                    const commissionCost = (record.price_with_discount * record.sebes_commission_wb) / 100;

                    return (
                        <Space direction="vertical" size={0} className="cost-info-cell">
                            <Text><strong>Себестоимость:</strong> {formatCurrency(record.sebes)}</Text>
                            <Text><strong>ФФ + Приемка:</strong> {formatCurrency(expenses.sebes_ff + expenses.sebes_supply)}</Text>
                            <Text><strong>Логистика:</strong> {formatCurrency(logisticsCost + returnLogisticsCost)}</Text>
                            <Text><strong>Хранение:</strong> {formatCurrency(storageCost)}</Text>
                            <Text><strong>Доп. расходы:</strong> {formatCurrency(additionalCosts + expenses.sebes_other)}</Text>
                            <Text><strong>Комиссия WB:</strong> {formatCurrency(commissionCost)} ({record.sebes_commission_wb}%)</Text>
                            <Text type="danger"><strong>Итого:</strong> {formatCurrency(record.sebes + expenses.sebes_ff + expenses.sebes_supply + logisticsCost + returnLogisticsCost + storageCost + additionalCosts + commissionCost + expenses.sebes_other)}</Text>
                        </Space>
                    );
                }
            },
            // Новая цена
            {
                title: 'Новая цена',
                width: 180,
                render: (_, record) => renderPriceInputs(record)
            },
            // Расчет прибыли
            {
                title: 'Расчет прибыли',
                width: 180,
                render: (_, record) => {
                    const plannedCost = calculatePlannedCost(record);
                    const profit = record.new_price_with_discount - plannedCost;
                    const margin = (profit / record.new_price_with_discount) * 100;
                    const markup = (profit / plannedCost) * 100;

                    return (
                        <Space direction="vertical" size={0} className="cost-info-cell">
                            <Text type={profit > 0 ? 'success' : 'danger'}><strong>Прибыль:</strong> {formatCurrency(profit)}</Text>
                            <Text type={markup > 0 ? 'success' : 'danger'}><strong>Наценка:</strong> {formatPercent(markup)}</Text>
                            <Text type={margin > 0 ? 'success' : 'danger'}><strong>Маржа:</strong> {formatPercent(margin)}</Text>
                        </Space>
                    );
                }
            }
        ]
    };
    const [activeFiltersState, setActiveFilters] = useState([])
    const [filterSearchTexts, setFilterSearchTexts] = useState({
        model: '',
        category: '',
        status: '',
        sezon: '',
        brand: '',
        color: ''
    })

    const handleSave = (row) => {
        console.log(row.new_price, row.discount, row.new_price_with_wb_discount)
        const newData = [...cards]
        let index = 0
        let item = {}
        if (row.is_ozon) {
            console.log('isozon', row.article)
            index = newData.findIndex((item) => row.nm_id === item.key)
            item = newData[index]
        } else {
            index = newData.findIndex((item) => row.key === item.key)
            item = newData[index]
        }
        row = calculateRow(row)
        console.log(row.new_price, row.discount, row.new_price_with_wb_discount)
        let new_item = {}
        if (row.is_ozon) {
            new_item = item
            for (let sku in item.by_sku) {
                console.log(sku, row.sku)
                if (sku == row.sku) {
                    console.log('find')
                    new_item.by_sku[sku] = row
                    break
                }
            }
        } else {
            new_item = row
        }
        newData.splice(index, 1, {...item, ...new_item})
        setCards(newData)
    }


    const handleAddFilter = (type, value) => {
        setActiveFilters(prev => {
            const filterExists = prev.some(
                filter => filter.type === type && filter.value.value === value.value
            )
            if (filterExists) return prev
            return [...prev, {type, value}]
        })
    }

    const handleRemoveFilter = (index) => {
        setActiveFilters(prev => prev.filter((_, i) => i !== index))
    }

    const menuItems = useFilterLogic(
        filters,
        filterSearchTexts,
        setFilterSearchTexts,
        handleAddFilter
    )

    const filteredCards = useFilteredCards(cards, activeFiltersState)

    const filterMenu = <Menu items={menuItems}/>

    const renderPriceInputs = (record) => (
        <Space direction="vertical" size={0} className="cost-info-cell">
            <Text><strong>До скидки:</strong> {formatCurrency(record.new_price)}</Text>
            <Space>
                <strong>Скидка:</strong>
                <Input
                    size="small"
                    style={{width: 100}}
                    value={record.new_discount}
                    onChange={(e) => {
                        record.new_discount = e.target.value
                        handleSave(record)
                    }}
                    suffix="%"
                />
            </Space>
            <Text type="success"><strong>После скидки:</strong> {formatCurrency(record.new_price_with_discount)}</Text>
            <Text type="success"><strong>После спп:</strong> {formatCurrency(record.new_price_client)}</Text>
            <Space>
                <strong>После кошелька:</strong>
                <Input
                    size="small"
                    style={{width: 100}}
                    value={record.new_price_with_wb_discount}
                    onChange={(e) => {
                        record.new_price_with_wb_discount = e.target.value
                        handleSave(record)
                    }}
                    suffix="₽"
                />
            </Space>
        </Space>
    )

    const downloadExcel = () => {
        const excelData = cards.map(card => {
            const logisticsCost = calculateLogisticsCost(card.size_value);
            const returnLogisticsCost = logisticsCost * (100 - card.buyout_percent) / 100;
            const storageCost = calculateStorageCost(card.size_value);
            const additionalCosts = (card.price_client * (expenses.sebes_budget + expenses.sebes_nalog)) / 100;
            const commissionCost = (card.price_with_discount * card.sebes_commission_wb) / 100;
            const totalCost = card.sebes + expenses.sebes_ff + expenses.sebes_supply + logisticsCost + returnLogisticsCost + storageCost + additionalCosts + expenses.sebes_other + commissionCost;

            return {
                'Артикул': card.article,
                'Артикул WB': card.nm_id,
                'Название': card.name,
                'Карточка': card.imt_id,
                'Бренд': card.brand,
                'Категория': card.category,
                'Модель': card.model,
                'Материал': card.material,
                'Статус': card.status,
                'Сезон': card.material,
                'Цвет': card.color,
                'Объем': card.size_value,

                // Текущие цены
                'Цена': card.price,
                'Цена со скидкой': card.price_with_discount,
                'Цена с спп': card.price_client,
                'Цена с кошельком': card.price_with_wb_discount,

                // Плановая себестоимость
                'Себестоимость': card.sebes,
                'ФФ + Приемка': expenses.sebes_ff + expenses.sebes_supply,
                'Логистика': logisticsCost + returnLogisticsCost,
                'Хранение': storageCost,
                'Доп. расходы': additionalCosts + expenses.sebes_other,
                'Комиссия WB': commissionCost,
                'Комиссия %': card.sebes_commission_wb,
                'Итого себестоимость': totalCost,

                // Показатели
                'Текущая маржа': card.marzha,
                'Текущая наценка': card.nacenka,

                // Новая цена
                'Новая цена до скидки': card.new_price,
                'Скидка': card.new_discount,
                'После скидки': card.new_price_with_discount,
                'Спп': card.spp,
                'После спп': card.new_price_client,
                'Кошелек': card.wb_discount,
                'После кошелька': card.new_price_with_wb_discount,
            };
        });

        // Создаем книгу Excel
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Настраиваем ширину столбцов
        const maxWidth = 20;
        const colWidths = {};
        Object.keys(excelData[0] || {}).forEach(key => {
            colWidths[key] = Math.min(
                maxWidth,
                Math.max(
                    key.length,
                    ...excelData.map(row => String(row[key] || '').length)
                )
            );
        });
        ws['!cols'] = Object.values(colWidths).map(width => ({wch: width}));

        // Добавляем лист в книгу
        XLSX.utils.book_append_sheet(wb, ws, "Новая цена");

        // Скачиваем файл
        XLSX.writeFile(wb, `Новая_цена_${new Date().toLocaleDateString()}.xlsx`);
    };

    return (
        <div className="price-table-container">
            <Card style={{marginBottom: 16}}>
                <Form layout="vertical">
                    <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '16px'}}>
                        <div style={{border: '1px solid #f0f0f0', padding: '16px', borderRadius: '8px'}}>
                            <div style={{marginBottom: '8px', fontWeight: 'bold'}}>Базовые расходы</div>
                            <Form.Item label="ФФ" style={{marginBottom: '8px'}}>
                                <Input
                                    type="number"
                                    value={expenses.sebes_ff}
                                    onChange={e => handleExpenseChange('sebes_ff', parseFloat(e.target.value) || 0)}
                                    suffix="₽"
                                />
                            </Form.Item>
                            <Form.Item label="Приемка" style={{marginBottom: '8px'}}>
                                <Input
                                    type="number"
                                    value={expenses.sebes_supply}
                                    onChange={e => handleExpenseChange('sebes_supply', parseFloat(e.target.value) || 0)}
                                    suffix="₽"
                                />
                            </Form.Item>
                        </div>

                        <div style={{border: '1px solid #f0f0f0', padding: '16px', borderRadius: '8px'}}>
                            <div style={{marginBottom: '8px', fontWeight: 'bold'}}>Логистика</div>
                            <Form.Item label="Стоимость" style={{marginBottom: '8px'}}>
                                <Input
                                    type="number"
                                    value={expenses.sebes_delivery_l}
                                    onChange={e => handleExpenseChange('sebes_delivery_l', parseFloat(e.target.value) || 0)}
                                    suffix="₽/л"
                                />
                            </Form.Item>
                            <Form.Item label="Доп. Стоимость" style={{marginBottom: '8px'}}>
                                <Input
                                    type="number"
                                    value={expenses.sebes_delivery_l_dop}
                                    onChange={e => handleExpenseChange('sebes_delivery_l_dop', parseFloat(e.target.value) || 0)}
                                    suffix="₽/л"
                                />
                            </Form.Item>
                        </div>

                        <div style={{border: '1px solid #f0f0f0', padding: '16px', borderRadius: '8px'}}>
                            <div style={{marginBottom: '8px', fontWeight: 'bold'}}>Хранение</div>
                            <Form.Item label="Дни хранения" style={{marginBottom: '8px'}}>
                                <Input
                                    type="number"
                                    value={expenses.sebes_store_days}
                                    onChange={e => handleExpenseChange('sebes_store_days', parseInt(e.target.value) || 0)}
                                    suffix="дн."
                                />
                            </Form.Item>
                            <Form.Item label="Стоимость" style={{marginBottom: '8px'}}>
                                <Input
                                    type="number"
                                    value={expenses.sebes_store_l}
                                    onChange={e => handleExpenseChange('sebes_store_l', parseFloat(e.target.value) || 0)}
                                    suffix="₽/л"
                                />
                            </Form.Item>
                            <Form.Item label="Доп. стоимость" style={{marginBottom: '8px'}}>
                                <Input
                                    type="number"
                                    value={expenses.sebes_store_l_dop}
                                    onChange={e => handleExpenseChange('sebes_store_l_dop', parseFloat(e.target.value) || 0)}
                                    suffix="₽/л"
                                />
                            </Form.Item>
                        </div>

                        <div style={{border: '1px solid #f0f0f0', padding: '16px', borderRadius: '8px'}}>
                            <div style={{marginBottom: '8px', fontWeight: 'bold'}}>Дополнительные расходы</div>
                            <Form.Item label="Рекламный бюджет" style={{marginBottom: '8px'}}>
                                <Input
                                    type="number"
                                    value={expenses.sebes_budget}
                                    onChange={e => handleExpenseChange('sebes_budget', parseFloat(e.target.value) || 0)}
                                    suffix="%"
                                />
                            </Form.Item>
                            <Form.Item label="Налог" style={{marginBottom: '8px'}}>
                                <Input
                                    type="number"
                                    value={expenses.sebes_nalog}
                                    onChange={e => handleExpenseChange('sebes_nalog', parseFloat(e.target.value) || 0)}
                                    suffix="%"
                                />
                            </Form.Item>
                            <Form.Item label="Прочие расходы" style={{marginBottom: '8px'}}>
                                <Input
                                    type="number"
                                    value={expenses.sebes_other}
                                    onChange={e => handleExpenseChange('sebes_other', parseFloat(e.target.value) || 0)}
                                    suffix="₽"
                                />
                            </Form.Item>
                        </div>

                    </div>
                </Form>
            </Card>
            <div className="table-header">
                <Space size={8} wrap>
                    <Dropdown
                        overlay={filterMenu}
                        trigger={['click']}
                        overlayClassName="filter-dropdown"
                    >
                        <Button icon={<FilterOutlined/>}>
                            Добавить фильтр
                        </Button>
                    </Dropdown>
                    <Button
                        icon={<ReloadOutlined/>}
                        onClick={updatePrices}
                    >
                        Обновить
                    </Button>
                    <Button
                        icon={<UploadOutlined/>}
                        onClick={loadPrices}
                    >
                        Загрузить
                    </Button>
                      <Button
                        type="primary"
                        icon={<DownloadOutlined/>}
                        style={{backgroundColor: '#4CAF50', borderColor: '#4CAF50'}}
                        onClick={() => downloadExcel(filteredCards)}
                    >
                        Экспорт в Excel
                    </Button>
                    {activeFiltersState.map((filter, index) => (
                        <Tag
                            key={index}
                            closable
                            onClose={() => handleRemoveFilter(index)}
                            closeIcon={<CloseOutlined/>}
                        >
                            {`${filter.type}: ${filter.value.label}`}
                        </Tag>
                    ))}
                </Space>
            </div>
            <Table
                dataSource={filteredCards}
                columns={columns()}
                loading={loading}
                pagination={{
                    defaultPageSize: 10,
                    showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`
                }}
                scroll={{x: true}}
                size={isMobile ? 'small' : 'middle'}
            />
        </div>
    );
};

export default PriceNewPrice;
