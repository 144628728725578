import React from 'react';
import { Input, Menu } from 'antd';

const MENU_HEIGHT = 400; // Maximum height for the menu
const SEARCH_HEIGHT = 56; // Height of search input with padding

export const FilterSearch = ({placeholder, onChange}) => (
    <div onClick={e => e.stopPropagation()} style={{padding: '8px', position: 'sticky', top: 0, background: 'white', zIndex: 1}}>
        <Input
            placeholder={placeholder}
            onChange={e => onChange(e.target.value)}
            autoFocus
        />
    </div>
);

export const useFilterLogic = (filters, filterSearchTexts, setFilterSearchTexts, handleAddFilter) => {
    return React.useMemo(() => {
        const createSubMenu = (type, title) => {
            const options = filters?.[type] || [];
            const searchText = filterSearchTexts[type] || '';

            const filteredOptions = options.filter(option =>
                option?.label?.toLowerCase?.()?.includes?.(searchText?.toLowerCase?.() || '') || false
            );

            const maxHeight = MENU_HEIGHT - SEARCH_HEIGHT;
            
            return {
                key: type,
                label: title,
                children: [
                    {
                        key: `${type}-search`,
                        label: (
                            <FilterSearch
                                placeholder={`Поиск по ${title.toLowerCase()}`}
                                onChange={(value) => {
                                    setFilterSearchTexts(prev => ({
                                        ...prev,
                                        [type]: value
                                    }));
                                }}
                            />
                        )
                    },
                    {
                        key: `${type}-options`,
                        type: 'group',
                        label: null,
                        style: { 
                            maxHeight: `${maxHeight}px`,
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        },
                        children: filteredOptions.map(option => ({
                            key: `${type}-${option?.value}`,
                            label: option?.label || '',
                            onClick: () => handleAddFilter(type, {
                                label: option?.value || '',
                                value: option?.value || ''
                            })
                        }))
                    }
                ],
                popupClassName: 'scrollable-filter-menu'
            };
        };

        return [
            createSubMenu('article', 'Артикул'),
            createSubMenu('material', 'Модель'),
            createSubMenu('category', 'Категория'),
            createSubMenu('status', 'Статус'),
            createSubMenu('sezon', 'Сезон'),
            createSubMenu('brand', 'Бренд'),
            createSubMenu('color', 'Цвет'),
        ];
    }, [filters, filterSearchTexts, handleAddFilter, setFilterSearchTexts]);
};

export const useFilteredCards = (cards, activeFiltersState) => {
    return React.useMemo(() => {
        if (!activeFiltersState?.length) return cards || [];

        return (cards || []).filter(card => {
            return activeFiltersState.every(filter => {
                if (!card || !filter?.type || !filter?.value?.value) return false;
                const cardValue = (card[filter.type] || '').toString().toLowerCase();
                const filterValue = filter.value.value.toString().toLowerCase();
                return cardValue === filterValue;
            });
        });
    }, [cards, activeFiltersState]);
};
