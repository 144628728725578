import { formatCurrency, formatQuantity } from '../../../utils/formats';
import { EyeOutlined, HeartOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Switch, Table } from 'antd'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { monthsRU } from '../../../utils'

const getColumns = (filters_data, likeHideList, onChangeLikeHide, isHideColumns) => {
    const isMobile = window.innerWidth <= 769
    return [
        {
            title: <HeartOutlined />,
            width: 21,
            render: (text, record) => <Checkbox checked={likeHideList['like'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'like')} />,
            hidden: isHideColumns
        },
        {
            title: <EyeOutlined />,
            width: 21,
            render: (text, record) => <Checkbox checked={likeHideList['hide'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'hide')} />,
            hidden: isHideColumns
        },
        { title: '', dataIndex: 'img', width: 20, fixed: isMobile ? '' : 'left', render: (text) => <img src={text} style={{ height: '20px' }}></img> },
        {
            title: 'Артикул продавца',
            dataIndex: 'article',
            ellipsis: true,
            width: 100,
            render: (text) => <a href={`product/${text}`}>{text}</a>,
            hidden: isHideColumns,
            filters: filters_data?.article,
            filterSearch: true,
            onFilter: (value, record) => record.article == value || record.is_fix
        },
        { title: 'Артикул WB', dataIndex: 'nm_id', width: 70, ellipsis: true, fixed: isMobile ? '' : 'left' },
        {
            title: 'Карточка',
            dataIndex: 'imt_id',
            width: 80,
            ellipsis: true,
            filters: filters_data['imt_ids'],
            filterSearch: true,
            onFilter: (value, record) => record.imt_id == value || record.is_fix
        },
        {
            title: 'Категория',
            dataIndex: 'category',
            key: 'category',
            ellipsis: true,
            width: 100,
            fixed: isMobile ? '' : 'left',
            filters: filters_data?.category,
            filterSearch: true,
            onFilter: (value, record) => record.category == value || record.is_fix
        },
        {
            title: 'Модель',
            dataIndex: 'material',
            width: 100,
            ellipsis: true,
            filters: filters_data?.material,
            fixed: isMobile ? '' : 'left',
            filterSearch: true,
            onFilter: (value, record) => record.material?.indexOf(value) === 0 || record.is_fix
        },
        { title: 'Цвет', dataIndex: 'color', width: 100, ellipsis: true, filters: filters_data?.color, filterSearch: true, onFilter: (value, record) => record.color?.indexOf(value) === 0 },
        { title: 'Остатки', dataIndex: 'ost', width: 70, ellipsis: true, fixed: isMobile ? '' : 'left', className: 'red-color', render: (text) => formatQuantity(text) },
        { title: 'Рейтинг', dataIndex: 'rating', width: 50, hidden: isHideColumns },
        { title: 'Деньги в товаре', dataIndex: 'ost_sum', width: 90, sorter: (a, b) => a.ost_sum - b.ost_sum, render: (text) => formatCurrency(text), className: 'blue-color' },
        {
            title: 'ABC',
            dataIndex: 'abc',
            width: 50,
            ellipsis: true,
            filters: filters_data?.abc,
            filterSearch: true,
            onFilter: (value, record) => record.abc?.indexOf(value) === 0,
            hidden: isHideColumns
        },
        { title: 'Сезон', dataIndex: 'sezon', width: 60, ellipsis: true, filters: filters_data?.sezon, filterSearch: true, onFilter: (value, record) => record.sezon?.indexOf(value) === 0 },
        { title: 'Цена', dataIndex: 'price_client', width: 70, className: 'green-color', render: (text) => formatCurrency(text) }
    ]
}

const getDaysForEachMonth = (showedMonths, setShowedMonths) => {
    const currentYear = dayjs().year()
    const result = []

    for (let month = 0; month < 12; month++) {
        let daysInMonth = {
            title: <ExpandButton title={monthsRU[month]} showedMonths={showedMonths} setShowedMonths={setShowedMonths} />,
            dataIndex: '',
            children: [],
            className: 'border_left'
        }
        for (let day = 1; day <= dayjs().set('month', month).daysInMonth(); day++) {
            const formattedDate = dayjs().year(currentYear).month(month).date(day).format('YYYY-MM-DD')
            const item = { title: formattedDate, dataIndex: formattedDate, width: 35, hidden: !showedMonths.includes(monthsRU[month]) }
            daysInMonth.children.push(item)
        }
        daysInMonth.children[0].className = 'border_left'
        const item = { title: 'Итого', dataIndex: `month${month + 1}`, width: 70, render: (text) => formatQuantity(text) }
        daysInMonth.children.push(item)
        result.push(daysInMonth)
    }
    return result
}

const expandData = (cards) => {
    return cards.map((card) => {
        card = { ...card, ...card.orders_day }
        card = { ...card, ...card.orders_month }
        return card
    })
}

const ExpandButton = ({ title, showedMonths, setShowedMonths }) => {
    return (
        <div style={{ display: 'flex', 'align-items': 'center' }}>
            <Button size={'small'} onClick={() => toggleDay(title, showedMonths, setShowedMonths)}>
                {showedMonths.includes(title) ? <MinusOutlined /> : <PlusOutlined />}
            </Button>
            {title}
        </div>
    )
}

const toggleDay = (day, showedMonths, setShowedMonths) => {
    const index = showedMonths.indexOf(day)
    if (index === -1) {
        // Если элемента нет в массиве, добавляем его.
        setShowedMonths([...showedMonths, day])
    } else {
        // Если элемент уже есть, создаем новый массив без этого элемента.
        setShowedMonths(showedMonths.filter((_day) => _day !== day))
    }
}

export default function TableOrdersYear(args) {
    const [isHideColumns, setHideColumns] = useState(false)
    let columns = getColumns(args.filters, args.likeHideList, args.onChangeLikeHide, isHideColumns)
    let newColumns = getDaysForEachMonth(args.showedMonths, args.setShowedMonths)
    columns = [...columns, ...newColumns]
    let modifiedDataSource = args.cards
    let fixedRow = {}
    if (args.cards) {
        const DataSource = expandData(args.cards)
        fixedRow = args.itog
        fixedRow.is_fix = true
        fixedRow.nm_id = 'Итого'

        fixedRow = expandData([fixedRow])
        modifiedDataSource = [...fixedRow, ...DataSource]
    }

    return (
        <>
            <Switch checkedChildren='Скрыть' unCheckedChildren='Скрыть' checked={isHideColumns} onChange={() => setHideColumns(!isHideColumns)} />
            <Table
                columns={columns}
                dataSource={modifiedDataSource}
                className='adverts-table2'
                bordered
                size={'small'}
                scroll={{
                    y: 800
                }}
                rowClassName={(record, index) => `my-custom-row`}
                loading={args.loading}
                pagination={{ defaultPageSize: 30, showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов` }}
            />
        </>
    )
}
