import { Form, Input, Table, Image} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";

const {TextArea} = Input;

const getColumns = (savePositions) => {
    const save = (e) => {
        savePositions(e.target.id, e.target.value);
    };
    return [
        {title: 'Артикул WB', dataIndex: 'nm_id', width: 100, className: 'width21', render: (text, record) => <div className='flex gap-1'>

                <div className='w-5 h-6'><Image src={record.img} alt="product"  className='object-contain rounded-md' /></div>
                {text}
            </div>,},
        {title: 'Артикул продавца', dataIndex: 'article', width: 100},
        {title: 'Карточка', dataIndex: 'imt_id', width: 80,},
        {title: 'Категория', dataIndex: 'category', key: 'category', width: 100},
        {title: 'Модель', dataIndex: 'model', width: 100, editable: true},
        {title: 'Материал', dataIndex: 'material', width: 100, editable: true},
        {title: 'Цвет', dataIndex: 'color', width: 100, editable: true,},
        {title: 'ABC', dataIndex: 'abc', width: 100},
        {title: 'Сезон', dataIndex: 'sezon', width: 100, editable: true,},
        {title: 'Статус', dataIndex: 'status', width: 100, editable: true,},
        {title: 'Себестоимость', dataIndex: 'sebes', width: 100, editable: true,},
        {
            title: 'Фразы для отслеживания позиций', dataIndex: 'positions', width: 200,  render: (text, record, index) => {
                let options = []
                let selected = []
                if (record?.params?.positions) {
                    record.params.positions.forEach((query) => {
                        options.push({label: query, value: query, nm_id: record.nm_id});
                        selected.push(query)
                    })

                }
                return <TextArea onPressEnter={save} onBlur={save} placeholder="Введите фразы через запятую" defaultValue={selected} id={record.nm_id}/>
            }
        },

    ]
}


const EditableContext = React.createContext(null);
const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({title, editable, children, dataIndex, record, handleSave, ...restProps}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const validationRules = {
        plan_orders: [
            {
                transform: (value) => Number(value),
                type: 'number',
                message: `Нужно ввести число`,
            },
        ],
        plan_orders_sum: [
            {
                transform: (value) => Number(value),
                type: 'number',
                message: `Нужно ввести число`,
            },
        ],
        sebes: [
            {
                transform: (value) => Number(value),
                type: 'number',
                message: `Нужно ввести число`,
            },
        ]
    };
    const specificRules = validationRules[dataIndex] || [];

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={specificRules}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div
                className='editable-cell-value-wrap'
                style={{
                    paddingInlineEnd: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

export default function TableEditCards({cards, loading, setCards,onEditCard, savePositions}) {

    let defaultColumns = getColumns(savePositions);



    const handleSave = (row) => {
        const newData = [...cards];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];


        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        onEditCard(row);
        setCards(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    function enhanceColumn(col, handleSave) {
        if (col.children) {
            col.children = col.children.map((childCol) =>
                enhanceColumn(childCol, handleSave)
            );
        }
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    }

    const columns = defaultColumns.map((col) => enhanceColumn(col, handleSave));


    return (
        <>

            <Table
                components={components}
                columns={columns}
                dataSource={cards}
                bordered
                size={'small'}
                className='adverts-table2'
                scroll={{
                    y: 800
                }}
                rowClassName={(record, index) => `editable-row `}
                loading={loading}
                pagination={{ defaultPageSize: 30, showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов` }}
            />
        </>
    )
}