import {EyeOutlined, HeartOutlined} from "@ant-design/icons";
import {Checkbox, Form, Input, Table} from "antd";
import {formatCurrency, formatDays, formatPercent, formatQuantity} from "../../../../core/utils/formats";
import React, { useContext, useEffect, useRef, useState } from "react";
import {createFilterFunction, createFilterText, getCellStyle} from "../utils/scripts";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';



const getColumns = (gradientData, group_type, filters_data, updateData) => {
    console.log('gradientData', gradientData)
    
    let columns = [
      {
            title: 'Заказы за год',
            dataIndex: 'orders_year',
            width: 150,
            render: (orders) => {
                const data = Object.keys(orders).map((key) => ({
                    month: key.replace('month', ''), 
                    value: orders[key]
                }));
                
                return (
                    <ResponsiveContainer width="100%" height={60}>
                        <BarChart data={data}>
                            <XAxis dataKey="month" hide />
                            <YAxis hide />
                            <Tooltip formatter={(value) => value} labelFormatter={(label) => `Месяц ${label}`} />
                            <Bar dataKey="value" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                );
            }
        },
    {
            title: 'Средний чек',
            dataIndex: 'check',
            width: 150,
            render: (orders) => {
                // Преобразование объекта `orders` в массив данных для Recharts
                const data = Object.keys(orders).map((key) => ({
                    month: key.replace('month', ''), 
                    value: orders[key]
                }));
                
                return (
                    <ResponsiveContainer width="100%" height={60}>
                        <BarChart data={data}>
                            <XAxis dataKey="month" hide />
                            <YAxis hide />
                            <Tooltip formatter={(value) => value} labelFormatter={(label) => `Месяц ${label}`} />
                            <Bar dataKey="value" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                );
            }
        },
    {
            title: 'Переходов в карточку',
            dataIndex: 'open_card',
            width: 150,
            render: (orders) => {
                const data = Object.keys(orders).map((key) => ({
                    month: key.replace('month', ''), 
                    value: orders[key]
                }));
                
                return (
                    <ResponsiveContainer width="100%" height={60}>
                        <BarChart data={data}>
                            <XAxis dataKey="month" hide />
                            <YAxis hide />
                            <Tooltip formatter={(value) => value} labelFormatter={(label) => `Месяц ${label}`} />
                            <Bar dataKey="value" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                );
            }
        },
    {
        title: 'Заказов',
        dataIndex: 'plan_orders',
        width: 80,
        editable: true,  // Make this column editable
    },
    {
        title: 'Сумма заказов',
        dataIndex: 'plan_orders_sum',
        width: 80,
        editable: true,  // Make this column editable
    },
    {
        title: 'Себестоимость',
        dataIndex: 'sebes',
        width: 80,
        editable: true,  render: (text) => <div>{formatCurrency(text)}</div>
    },
        {title: 'Продажи в день', dataIndex: 'buyouts_per_day', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'buyouts_per_day', text)}>{text}</div>,
        filters: createFilterText(gradientData, 'buyouts_per_day'), onFilter: createFilterFunction(gradientData, 'buyouts_per_day'),},
        {title: 'Процент выкупа', dataIndex: 'buyout_percent', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'buyout_percent', text)}>{formatPercent(text)}</div>,
        filters: createFilterText(gradientData, 'buyout_percent'), onFilter: createFilterFunction(gradientData, 'buyout_percent'),},
        {title: 'Оборачиваемость', dataIndex: 'oborot_orders', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'oborot_orders', text, true)}>{formatDays(text)}</div>,
        filters: createFilterText(gradientData, 'oborot_orders'), onFilter: createFilterFunction(gradientData, 'oborot_orders'),},
        {title: 'Запас дней', dataIndex: 'oborot_buyouts', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'oborot_buyouts', text, true)}>{formatDays(text)}</div>,
        filters: createFilterText(gradientData, 'oborot_buyouts'), onFilter: createFilterFunction(gradientData, 'oborot_buyouts'),},
        {title: 'Наценка', dataIndex: 'nacenka_unit', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'nacenka_unit', text)}>{formatPercent(text)}</div>,
        filters: createFilterText(gradientData, 'nacenka_unit'), onFilter: createFilterFunction(gradientData, 'nacenka_unit'),},
        {title: 'Маржинальность', dataIndex: 'marzha_unit', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'marzha_unit', text)}>{formatPercent(text)}</div>,
        filters: createFilterText(gradientData, 'marzha_unit'), onFilter: createFilterFunction(gradientData, 'marzha_unit'),},
        {title: 'Цена со скидкой', dataIndex: 'price_with_discount', width: 80, render: (text) => <div style={getCellStyle(gradientData, 'price_with_discount', text)}>{formatCurrency(text)}</div>,
        filters: createFilterText(gradientData, 'price_with_discount'), onFilter: createFilterFunction(gradientData, 'price_with_discount'),},
    ];

    if (group_type == 'nm_id') {
        columns.splice(0, 0, {
            title: 'Категория',
            dataIndex: 'category',
            width: 120,
            fixed: 'left',
            ellipsis: true,
            filters: filters_data['category'],
            filterSearch: true,
            onFilter: (value, record) => record.category == value,
        });
    }
    return gradientData ? columns : [];
};

const EditableContext = React.createContext(null);
const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item style={{ margin: 0 }} name={dataIndex} rules={[{ required: true, message: `${title} - это обязательное поле.` }]}>
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div className='editable-cell-value-wrap' style={{ paddingInlineEnd: 24 }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }
    
    return <td {...restProps}>{childNode}</td>;
};



export default function SalesPlanTable({ data, loading, gradientData, group_type,handleSave, filters_data , updateData }) {
    const components = { body: { row: EditableRow, cell: EditableCell } };
    const columns = getColumns(gradientData, group_type, filters_data, updateData);

    return (
        <Table
            components={components}
            bordered
            size={'small'}
            rowClassName={() => 'editable-row my-custom-row'}
            pagination={{
                defaultPageSize: 30,
                showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`,
            }}
            scroll={{
                y: 800,
            }}
            loading={loading}
            dataSource={data}
            columns={columns.map(col => ({
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                }),
            }))}
        />
    );
}
