import { apiAxios } from "../api_axios";

export const apiUserUpdate = async(data) => {
    const res = await apiAxios.post('user/update/', data)
    return res.data
};

export const apiUserUpdateStaff = async(data) => {
    const res = await apiAxios.post('user/staff/update/', data)
    return res.data
};

export const apiUserRemoveStaff = async(data) => {
    const res = await apiAxios.post('user/staff/remove/', data)
    return res.data
};

export const apiUserUpdateWBAcc = async(data) => {
    const res = await apiAxios.post('user/wbacc/update/', data)
    return res.data
};


export const apiUserRemove = async() => {
    const res = await apiAxios.post('user/remove/')
    return res.data
};