import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

const { Text } = Typography
const SideBarPlanFact = () => {
    const metrics = [
        {
            label: 'ДРР (Доля Расходов на Рекламу)',
            description: 'процент расходов на рекламу по отношению к общему доходу от продаж, отражает эффективность рекламных затрат.'
        },
        {
            label: 'СТР (Средний Товарооборот Рекламный)',
            description: 'показатель, который может указывать на средний процент или долю, измеряющую частоту продаж товара после рекламного контакта.'
        },
        {
            label: 'МРК (Маркетинговые исследования и коммуникации)',
            description: 'комплексный подход, который включает в себя сбор и анализ данных о рынке и потребителях, а также разработку стратегий для эффективного взаимодействия с целевой аудиторией.'
        },
        {
            label: 'Оборачиваемость',
            description: 'указывает на количество дней, необходимых для полной продажи запасов, что помогает отслеживать, как быстро продаются товары.'
        },
        {
            label: 'Выкуп',
            description: 'процент товаров, выкупленных после заказа, что может свидетельствовать о популярности товара и уровне удовлетворенности клиентов.'
        }
    ]

    return (
        <div>
            <Title level={2}>Описание страницы "План Факт"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                Страница <span className='text-black'>"План Факт"</span> предназначена для отображения и анализа ключевых данных о товарных остатках, продажах и других показателях товаров. В таблице
                содержатся данные о наличии товаров, статусах, продажах и других метриках, позволяя пользователям отслеживать, сравнивать и оценивать фактические результаты с запланированными
                показателями. Это помогает лучше контролировать запасы, оперативно реагировать на изменения и принимать более обоснованные решения по управлению товарными запасами.
            </Text>
            <Title level={3}>Расшифровка ключевых показателей:</Title>
            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {metrics.map((metric, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold'>{metric.label}</span> — {metric.description}
                    </li>
                ))}
            </div>
            <Text className='font-bold text-xs'>Эти данные помогают в стратегическом планировании, оптимизации запасов и оценке рекламной эффективности для достижения бизнес-целей.</Text>
        </div>
    )
}

export default SideBarPlanFact
