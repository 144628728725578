import React, { useState } from 'react';
import { Table, Button, Space, Image, Typography } from 'antd';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Cell, Tooltip } from 'recharts';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

const { Text } = Typography;

const metricMapping = {
    'Выкупы': 'buyouts',
    'Сумма выкупов': 'buyouts_sum',
    'Комиссия': 'commission',
    'Доставка': 'delivery',
    'ДРР': 'drr',
    'Маржинальность': 'margin_percent',
    'Заказы': 'orders',
    'Прибыль': 'profit',
    'Рентабельность': 'ren',
    'ROI': 'roi',
    'Себестоимость': 'sebes',
    'Хранение': 'store'
};

const additionalMetrics = [
    { key: 'buyouts', label: 'Выкупы' },
    { key: 'buyouts_sum', label: 'Сумма выкупов' },
    { key: 'commission', label: 'Комиссия' },
    { key: 'delivery', label: 'Доставка' },
    { key: 'drr', label: 'ДРР' },
    { key: 'margin_percent', label: 'Маржинальность' },
    { key: 'orders', label: 'Заказы' },
    { key: 'profit', label: 'Прибыль' },
    { key: 'ren', label: 'Рентабельность' },
    { key: 'roi', label: 'ROI' },
    { key: 'sebes', label: 'Себестоимость' },
    { key: 'store', label: 'Хранение' }
];

const formatCurrency = (value) => {
    return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value);
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ 
                backgroundColor: 'white', 
                padding: '10px', 
                border: '1px solid #ccc',
                borderRadius: '5px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                position: 'fixed',  
                zIndex: 1000,       
                transform: 'translateX(-50%)',  
                left: '50%'          
            }}>
                <p><strong>Дата:</strong> {label}</p>
                <p>
                    <strong>Значение:</strong> {
                        payload[0].value > 0 
                            ? formatCurrency(payload[0].value)
                            : payload[0].value
                    }
                </p>
            </div>
        );
    }
    return null;
};

const CustomSparkBar = ({ data }) => {
    // Ensure data is sorted chronologically
    const sortedData = [...data].sort((a, b) => {
        const dateA = new Date(a.date.split(' - ')[0]?.split('.').reverse().join('-') || a.date);
        const dateB = new Date(b.date.split(' - ')[0]?.split('.').reverse().join('-') || b.date);
        return dateA - dateB;
    });

    // Determine max absolute value for consistent scaling
    const maxAbsValue = Math.max(...sortedData.map(d => Math.abs(d.value)));

    return (
        <ResponsiveContainer width="100%" height={40}>
            <BarChart 
                data={sortedData} 
                margin={{ 
                    top: 5, 
                    right: 5, 
                    left: 5, 
                    bottom: 5 
                }}
            >
                <XAxis 
                    dataKey="date" 
                    hide={true} 
                />
                <YAxis 
                    hide={true} 
                    domain={['auto', 'auto']}
                />
                <Tooltip 
                    content={<CustomTooltip />}
                    cursor={{ fill: 'rgba(0,0,0,0.1)' }}
                />
                <Bar 
                    dataKey="value" 
                    maxBarSize={15}
                    barSize={15}
                    radius={[3, 3, 0, 0]}
                >
                    {sortedData.map((entry, index) => {
                        const color = entry.value > 0 
                            ? '#52c41a'   // Green for positive
                            : entry.value < 0 
                                ? '#ff4d4f'   // Red for negative
                                : '#666666';  // Gray for zero

                        return (
                            <Cell 
                                key={`cell-${index}`} 
                                fill={color}
                            />
                        );
                    })}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

const DynamicsTable = ({ data, loading, metric = 'Прибыль', selectorFilter, activeFilters }) => {
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 20;

    const getMetricValue = (record, period, currentMetric) => {
        // Check if periods is an array or object
        const periods = Array.isArray(record.periods) 
            ? record.periods 
            : Object.values(record.periods || {});

        const periodData = periods.find(p => p.period === period);
        if (!periodData) return null;

        const metricKey = record.isMetricRow 
            ? record.metricKey 
            : metricMapping[currentMetric];

        const value = periodData[metricKey];

        // Special formatting for different metric types
        switch (metricKey) {
            case 'buyouts_sum':
            case 'profit':
                return formatCurrency(value);
            case 'drr':
            case 'margin_percent':
            case 'ren':
            case 'roi':
                return `${value}%`;
            default:
                return value;
        }
    };

    const preparePeriodData = (periods, metricKey) => {
        // Handle different possible input types
        let periodArray = [];
        
        if (Array.isArray(periods)) {
            periodArray = periods;
        } else if (typeof periods === 'object' && periods !== null) {
            periodArray = Object.values(periods);
        } else {
            return [];
        }

        // Enhanced date parsing function
        const parseDate = (dateStr) => {
            // Handle different date formats
            // First, try splitting by ' - ' for range dates
            const baseDateStr = dateStr.split(' - ')[0];
            
            // Try parsing different date formats
            const parsedDate = new Date(
                baseDateStr.split('.').reverse().join('-') || // DD.MM.YYYY to YYYY-MM-DD
                baseDateStr
            );

            return parsedDate;
        };

        // Sort periods chronologically with more robust sorting
        const sortedPeriods = periodArray.sort((a, b) => {
            const dateA = parseDate(a.period);
            const dateB = parseDate(b.period);
            
            // If dates are the same, maintain original order
            return dateA - dateB || 
                   periodArray.indexOf(a) - periodArray.indexOf(b);
        });

        // Ensure each period is represented
        return sortedPeriods.map(periodData => ({
            date: periodData.period,
            value: periodData[metricKey] || 0  // Default to 0 if no value
        }));
    };

    const getPeriodColumns = () => {
        if (!data?.[0]?.periods) return [];
        
        // Ensure periods is an array
        const periods = Array.isArray(data[0].periods) 
            ? data[0].periods 
            : Object.values(data[0].periods);

        return periods.map(periodObj => ({
            title: periodObj.period,
            key: periodObj.period,
            width: 100,
            render: (_, record) => {
                const value = getMetricValue(record, periodObj.period, metric);
                return value !== null 
                    ? <span style={{ 
                        color: value > 0 ? '#52c41a' : value < 0 ? '#ff4d4f' : '#666666'
                    }}>
                        {value}
                    </span>
                    : '-';
            },
            sorter: (a, b) => {
                const aValue = getMetricValue(a, periodObj.period, metric) || 0;
                const bValue = getMetricValue(b, periodObj.period, metric) || 0;
                return aValue - bValue;
            },
        }));
    };

    const processDataSource = () => {
        // If no data, return empty array
        if (!data || data.length === 0) return [];

        // Apply filters
        let filteredData = data;
        if (activeFilters && activeFilters.length > 0) {
            filteredData = data.filter(item => 
                activeFilters.every(filter => {
                    const { type, value } = filter;
                    
                    // Handle different filter types
                    switch(type) {
                        case 'brand':
                            return item.brand === value.value;
                        case 'category':
                            return item.category === value.value;
                        case 'status':
                            return item.status === value.value;
                        case 'color':
                            return item.color === value.value;
                        case 'sezon':
                            return item.sezon === value.value;
                        default:
                            return true;
                    }
                })
            );
        }

        let fullDataSource = [];
        
        filteredData.forEach(item => {
            const groupKey = selectorFilter.group === 'nm_id' ? item.nm_id : item[selectorFilter.group];
            const processedItem = {
                ...item,
                key: groupKey,
                metric_value: item[metricMapping[metric]]
            };
            
            fullDataSource.push(processedItem);
            
            if (expandedRows.has(groupKey)) {
                additionalMetrics
                    .filter(m => m.key !== metricMapping[metric])
                    .forEach(m => {
                        fullDataSource.push({
                            key: `${groupKey}-${m.key}`,
                            [selectorFilter.group]: m.label,
                            isMetricRow: true,
                            metricKey: m.key,
                            periods: item.periods,
                            parentId: groupKey,
                            brand: item.brand,
                            article: item.article
                        });
                    });
            }
        });

        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        
        return fullDataSource.slice(startIndex, endIndex);
    };

    const columns = [
        {
            title: '',
            key: 'expand',
            width: 50,
            fixed: 'left',
            render: (_, record) => {
                if (record.isMetricRow) return null;
                return (
                    <Button 
                        type="text"
                        icon={expandedRows.has(record.nm_id) ? <DownOutlined /> : <RightOutlined />}
                        onClick={(e) => {
                            e.stopPropagation();
                            const newExpandedRows = new Set(expandedRows);
                            if (expandedRows.has(record.nm_id)) {
                                newExpandedRows.delete(record.nm_id);
                            } else {
                                newExpandedRows.add(record.nm_id);
                            }
                            setExpandedRows(newExpandedRows);
                        }}
                    />
                );
            }
        },
        {
            title: selectorFilter.group === 'nm_id' ? 'Товар' : 'Группа',
            dataIndex: selectorFilter.group === 'nm_id' ? 'nm_id' : selectorFilter.group,
            key: selectorFilter.group,
            width: 300,
            fixed: 'left',
            render: (text, record) => {
                if (record.isMetricRow) return null;
                
                if (selectorFilter.group === 'nm_id') {
                    return  <Space direction="vertical" size={0}>
                    <Space align="start" size={12}>
                        <img src={record.img} className='min-w-14 max-w-14 mt-2 h-14 object-contain' alt="Product"/>
                        <Space direction="vertical" size={0} className="cost-info-cell">
                            <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                            <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                            <Text><strong>Название:</strong> {record.name}</Text>
                        </Space>
                    </Space>
                </Space>
                }
                return text;
            }
        },
        {
            title: 'Информация о товаре',
            dataIndex: 'details',
            key: 'details',
            width: 350,
            render: (_, record) => (
                <Space size={24} className="cost-info-cell">
                <div className='w-[200px]'>
                    <Text><strong>Модель:</strong> {record.material}</Text>
                    <Text><strong>Бренд:</strong> {record.brand}</Text>
                    <Text><strong>Категория:</strong> {record.category}</Text>
                </div>
                <div>
                    <Text><strong>Статус:</strong> {record.status}</Text>
                    <Text><strong>Сезон:</strong> {record.sezon}</Text>
                    <Text><strong>Цвет:</strong> {record.color}</Text>
                </div>
            </Space>
            )
        },
        {
            title: 'Метрика',
            key: 'metric',
            width: 140,
            render: (_, record) => {
                if (record.isMetricRow) {
                    return (
                        <div style={{ fontWeight: 'bold', color: '#666666' }}>
                            {additionalMetrics.find(m => m.key === record.metricKey)?.label || 'Метрика'}
                        </div>
                    );
                }
                return (
                    <div style={{ fontWeight: 'bold' }}>
                        {metric}
                    </div>
                );
            }
        },
        {
            title: 'Динамика',
            dataIndex: 'periods',
            key: 'dynamics',
            width: 250,
            render: (periods, record) => {
                // Use the specific metric key for each row
                const metricToRender = record.isMetricRow 
                    ? record.metricKey 
                    : metricMapping[metric];
                
                const chartData = preparePeriodData(periods, metricToRender);

                return (
                    <div style={{ 
                        width: '100%', 
                        height: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white'
                    }}>
                        <CustomSparkBar data={chartData} />
                    </div>
                );
            }
        },
        ...getPeriodColumns()
    ];

    return (
        <div>
            <Table
                columns={columns}
                dataSource={processDataSource()}
                loading={loading}
                rowKey={(record) => record.isMetricRow ? record.key : record.nm_id}
                scroll={{ x: 'max-content' }}
                size="small"
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    onChange: (page) => {
                        setCurrentPage(page);
                        setExpandedRows(new Set()); // Сбрасываем раскрытые строки при смене страницы
                    },
                    showSizeChanger: true,
                    showTotal: (total) => `Всего ${total} записей`,
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
            />
        </div>
    );
};

export default DynamicsTable;
