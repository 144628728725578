import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row, Tooltip, Typography } from 'antd'
import { SelectorSolo } from '../../components/Selectors'
import { useAuth } from '../../hooks/useAuth'
import { options_hide, options_like } from '../../utils'
import TableOrdersYear from './components/ordersYear'
import { defaultOnChangeLikeHide, setLikeHideOnLoadData } from '../components/LikeHide'
import { ButtonExel } from '../../components/Buttons'
import { useSidebar } from '../../Context/SideBarContext'
import { CiCircleQuestion } from 'react-icons/ci'
import Sidebar from '../home/sideBar/SideBar'
import SideBarOrdersYear from '../home/sideBar/SideBarOrdersYear'
import { apiAxios } from "../../utils/api_axios";

function OrdersYear() {
    const { user, isCsrf } = useAuth()
    const [filters_data, setFiltersData] = useState([])
    const [cards, setCards] = useState([])
    const [itog, setItog] = useState([])

    const [selectedWbaccs, setSelectedWbaccs] = useState([])
    const [selected_hide, setSelectedHide] = useState(1)
    const [selected_like, setSelectedLike] = useState(1)
    const [likeHideList, setLikeHideList] = useState({ like: [], hide: [] })
    const [showedMonths, setShowedMonths] = useState([])
    const [loading, setLoading] = useState(true)

    const { openSidebar } = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        console.log('Button clicked')
        openSidebar(<SideBarOrdersYear />)
    }, [openSidebar])

    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedData = await await apiAxios.get('orders-year/get/', {
                'wbacc_ids': [selectedWbaccs],
                'is_like': selected_like,
                'is_hide': selected_hide
            })
            console.log('fetchedData', fetchedData)
            setFiltersData(fetchedData['filters'])
            setCards(fetchedData['cards'])
            setItog(fetchedData['itog'])
            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : [])
    }, [user.wbacc_ids])
    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData()
        }
    }, [selected_hide, selected_like, selectedWbaccs])

    return (
        <>
            <Card>
                <Typography.Title level={1}>Продажи за год</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Поставщики</Typography.Title>
                        <SelectorSolo options={filters_data?.wbaccs} selected={selectedWbaccs} setSelected={setSelectedWbaccs} />
                    </Col>

                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Избранное</Typography.Title>
                        <SelectorSolo options={options_like} selected={selected_like} setSelected={setSelectedLike} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Скрытые товары</Typography.Title>
                        <SelectorSolo options={options_hide} selected={selected_hide} setSelected={setSelectedHide} />
                    </Col>
                    <ButtonExel data={cards} />
                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                    </Tooltip>
                </Row>
            </Card>
            <Sidebar />
            <Card>
                <TableOrdersYear
                    cards={cards}
                    filters={filters_data}
                    likeHideList={likeHideList}
                    onChangeLikeHide={onChangeLikeHide}
                    loading={loading}
                    itog={itog}
                    showedMonths={showedMonths}
                    setShowedMonths={setShowedMonths}
                />
            </Card>
        </>
    )
}

export default OrdersYear
