import {Button, Card, Checkbox, Col, ConfigProvider, Divider, Flex, Form, Image, Input, List, Modal, Row, Select, Space, Table, Tabs, Typography} from 'antd'
import React, {useEffect, useMemo, useState} from 'react'
import {getUserInfo} from '../../core/api/user/auth'
import {useAuth} from '../../core/hooks/useAuth'
import {SelectorMulti, SelectorSolo, SelectorTags} from '../../components/simple/selects'
import {TabWBAccs} from './profileTabWBAccs'
import {TabStaff} from './profileTabStaff'
import {TabSub} from './profileTabSub'
import {TabProfile} from './profileTabProfile'
import {ProfileTabPartner} from './profileTabPartner'
import { useLocation } from 'react-router-dom'

const TAB_NAMES = ['Информация', 'Магазины', 'Сотрудники', 'Подписка', 'Партнерская программа']

export const Profile = () => {
    const {user, setUser, setIsAuth} = useAuth()
      const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialTab = queryParams.get('tab') === 'stores' ? 'Магазины' : TAB_NAMES[0];

    const [activeTab, setActiveTab] = useState(initialTab);
    const [loading, setLoading] = useState(true)
    const [activePeriod, setActivePeriod] = useState('1') // Устанавливаем активный период
    const [activeButton, setActiveButton] = useState(null)
    const [tabProfileState, setTabProfile] = useState({like: [], hide: []})
    const [tabWBAccState, setTabWBAcc] = useState({selected_wbacc: {}})
    const [tabStaffState, setTabStaff] = useState({email: '', wbaccs: [], allowed_wbaccs: [], wbacc_ids: []})
    const setTabProfileState = (name, value) => {
        setTabProfile((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const setTabWBAccState = (name, value) => {
        setTabWBAcc((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const setTabStaffState = (name, value) => {
        setTabStaff((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await getUserInfo();
            setUser(fetchedData)
            if (fetchedData.params) {
                if (fetchedData.params.hide_articles) {
                    setTabProfile((prev) => ({...prev, hide: fetchedData.params.hide_articles}))
                }
                if (fetchedData.params.like_articles) {
                    setTabProfile((prev) => ({...prev, like: fetchedData.params.like_articles}))
                }
            }

            console.log('fetchedData', fetchedData)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const [isModalOpen, setIsModal] = useState({
        account_remove: false,
        staff: false,
        staff_edit: false,
        staff_remove: false,
        wbacc: false,
        wbacc_update: false,
        wbacc_remove: false,
        ozonacc: false,
        ozonacc_update: false,
        nalog: false
    })
    const setIsModalOpen = (name, value) => {
        setIsModal((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    useEffect(() => {
        fetchData()

    }, [])
    const renderTabContent = () => {
        switch (activeTab) {
            case 'Информация':
                return TabProfile(user, setIsAuth, tabProfileState, setTabProfileState, setActiveTab, isModalOpen, setIsModalOpen)
            case 'Магазины':
                return TabWBAccs(user,  isModalOpen, setIsModalOpen, tabWBAccState, setTabWBAccState)
            case 'Сотрудники':
                return TabStaff(user, isModalOpen, setIsModalOpen, tabStaffState, setTabStaffState)
            case 'Подписка':
                return <TabSub activeButton={activeButton} setActiveButton={setActiveButton}/>
            case 'Партнерская программа':
                return <ProfileTabPartner/>
            default:
                return null
        }
    }

    return (
        <Card loading={loading}>
            <Typography.Title level={1}>Профиль</Typography.Title>
            <Tabs defaultActiveKey={TAB_NAMES[0]} activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
                {TAB_NAMES.map((name) => (
                    <Tabs.TabPane tab={name} key={name}>
                        {renderTabContent()}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </Card>
    )
}
