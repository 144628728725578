import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Divider, InputNumber, Row, Select, Typography} from 'antd';
import {useAuth} from '../../core/hooks/useAuth';
import {getSupply} from '../../core/api/analStock/supplies';
import TableSupply from "../../components/smart/tables/analStock/supply";
import { ButtonExel } from '../../components/simple/buttons/Buttons';

export default function Supply() {
    const {user, isCsrf, setIsCsrf} = useAuth();
    const [selectedData, setSelectedData] = useState({
        wbacc_id: 0,
        store_id: '',
        articles: [1],
        need_days: 30,
        days_calculate: 14,
    });
    const [options, setOptions] = useState({
        wbacc_id: [],
        store_id: [],
        articles: [],
    });
    const [filtersData, setFiltersData] = useState([]);
    const [cards, setCards] = useState([]);
    const [msStoreName, setMsStoreName] = useState('Выберите склад в параметрах поставки')
    const [storeNames, setStoreNames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hidden, setHidden] = useState([]);

    // {title: 'Запас в днях', dataIndex: `${name}oborot_buyouts`, className: 'border_left', width: 70, render: (text) => formatDays(text),},
    //           {title: 'Оборачиваемость', dataIndex: `${name}oborot`, width: 70, render: (text) => formatDays(text),},
    //           {title: 'Остатки', dataIndex: `${name}ost`, width: 70, render: (text) => formatQuantity(text),},
    //           {title: 'В пути к клиенту', dataIndex: `${name}way_to`, width: 70, render: (text) => formatQuantity(text),},
    //           {title: 'В пути от клиента', dataIndex: `${name}way_from`, width: 70, render: (text) => formatQuantity(text),},
    //           {title: 'Продажи в день', dataIndex: `${name}orders_per_day`, width: 70, render: (text) => formatQuantity(text),},
    //           {title: 'Выкупы в день', dataIndex: `${name}buyouts_per_day`, width: 70, render: (text) => formatQuantity(text),},
    //           {title: 'Потребность', dataIndex: `${name}need`, width: 70, render: (text) => formatQuantity(text), className: 'blue-color',},
    //           {title: 'Поставка', dataIndex: `${name}supply_allocated`, width: 70, className: 'blue2-color',

    const optionsHidden = [
        {value: 'oborot_buyouts', label: 'Запас в днях'},
        {value: 'oborot', label: 'Оборачиваемость'},
        {value: 'ost', label: 'Остатки'},
        {value: 'way_to', label: 'В пути к клиенту'},
        {value: 'way_from', label: 'В пути от клиента'},
        {value: 'orders_per_day', label: 'Продажи в день'},
        {value: 'buyouts_per_day', label: 'Выкупы в день'},
        {value: 'need', label: 'Потребность'},
    ]

    const handleChangeSelect = (name) => (value) => {
        setSelectedData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name == 'store_id') {
            options.store_id.forEach((item) => {
                if (item.value == value) {
                    setMsStoreName(item.label)
                }
            })

        }
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await getSupply(selectedData.wbacc_id, selectedData.store_id, selectedData.articles, selectedData.need_days, selectedData.days_calculate)
            setOptions({
                wbacc_id: fetchedData?.selectors?.wbaccs,
                store_id: fetchedData?.selectors?.stores,
                articles: fetchedData?.selectors?.articles,
            })
            setFiltersData(fetchedData['filters']);
            setCards(fetchedData['cards']);
            setStoreNames(fetchedData['store_names']);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        setSelectedData((prevState) => ({
            ...prevState,
            wbacc_id: user.wbacc_ids ? user.wbacc_ids[0] : [],
        }));
    }, [user.wbacc_ids]);

    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData();
            setSelectedData((prevState) => ({
                ...prevState,
                articles: [],
            }));
        }
    }, [selectedData.wbacc_id]);

    return (
        <>
            <Card title={'Выберите параметры для поставки'}>
                <Row type='flex' style={{gap:10, alignItems:'end'}}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5} style={{marginTop: 0}}>Поставщики</Typography.Title>
                        <Select

                            style={{width: '100%'}}
                            placeholder="Выберите поставщика"
                            options={options.wbacc_id}
                            value={selectedData.wbacc_id}
                            onChange={handleChangeSelect('wbacc_id')}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5} style={{marginTop: 0}}>Склад МС</Typography.Title>
                        <Select

                            style={{width: '100%'}}
                            placeholder="Выберите склад"
                            options={options.store_id}
                            value={selectedData.store_id}
                            onChange={handleChangeSelect('store_id')}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5} style={{marginTop: 0}}>Артикулы WB</Typography.Title>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{width: '100%'}}
                            placeholder="Выберите артикулы"
                            options={options.articles}
                            value={selectedData.articles}
                            onChange={handleChangeSelect('articles')}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5} style={{marginTop: 0}}>Период анализа, дней</Typography.Title>
                        <InputNumber min={7} max={30} defaultValue={selectedData.days_calculate} onChange={handleChangeSelect('days_calculate')} style={{width: '100%'}}/>
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5} style={{marginTop: 0}}>Должно хватить на дней</Typography.Title>
                        <InputNumber min={20} defaultValue={selectedData.need_days} onChange={handleChangeSelect('need_days')} style={{width: '100%'}}/>
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5} style={{marginTop: 0}}>Скрыть столбцы</Typography.Title>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{width: '100%'}}
                            placeholder="Скрыть столбцы"
                            options={optionsHidden}
                            value={hidden}
                            onChange={(value) => setHidden(value)}
                        />
                    </Col>
<ButtonExel data={cards} />
                </Row>
                <Row>
                    <Col lg={3} xs={24}>
                        <Divider/>
                        <Button type="primary" size={'large'} onClick={fetchData}>Рассчитать поставку</Button>
                    </Col>
                </Row>
            </Card>
            <Card>
                <TableSupply
                    cards={cards}
                    setCards={setCards}
                    filters={filtersData}
                    loading={loading}
                    storeNames={storeNames}
                    msStoreName={msStoreName}
                    hidden={hidden}
                />
            </Card>

        </>
    );
}


