import {Button, Checkbox, Col, Divider, Flex, Form, Input, message, Row, theme, Typography} from 'antd';
import {FacebookFilled, GoogleOutlined, TwitterOutlined} from '@ant-design/icons';

import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Logo} from "../../components/simple/Logo";
import {useAuth} from "../../core/hooks/useAuth";
import {apiAxios} from "../../core/api/api_axios";

const {Title, Text, Link} = Typography;


export const SignInPage = () => {
    const navigate = useNavigate();
    const {isAuth, setIsAuth} = useAuth()

    if (isAuth) {
        navigate('/');
    }

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const apiLogin = async () => {
        const data = {login: login, password: password}
        const res = await apiAxios.post("user/login/", data)
        if (res.success == true) {
            setIsAuth(true)
            navigate('/');
            message.open({
                type: 'success',
                content: 'Успешная авторизация',
                style: {
                    marginTop: '5vh',
                },
            });
        } else {
            message.open({
                type: 'error',
                content: 'Неверные данные',
                style: {
                    marginTop: '5vh',
                },
            });
        }

    }

    async function submitForm(e) {
        e.preventDefault()
        await apiLogin()
    }

    const {
        token: {colorPrimary},
    } = theme.useToken();
    const isMobile = window.innerWidth <= 769;

    return (
        <Row style={{minHeight: isMobile ? 'auto' : '100vh', overflow: 'hidden'}}>
            <Col xs={24} lg={12}>
                <Flex vertical
                      align="center"
                      justify="center"
                      className="text-center"
                      style={{background: colorPrimary, height: '100%', padding: '1rem'}}>
                    <Logo color="white"/>
                    <Title level={2} className="text-white">
                        Добро пожаловать
                    </Title>
                    <Text className="text-white" style={{fontSize: 18}}>

                    </Text>
                </Flex>
            </Col>
            <Col xs={24} lg={12}>
                <Flex vertical
                      align={isMobile ? 'center' : 'flex-start'}
                      justify="center"
                      gap="middle"
                      style={{height: '100%', padding: '2rem'}}>
                    <Title className="m-0">Войти</Title>
                    <Flex gap={4}>
                        <Text>Нет аккаунта?</Text>
                        <Link href='/signup'>Создать аккаунт</Link>
                    </Flex>
                    <Form name="sign-up-form"
                          layout="vertical"
                          labelCol={{span: 24}}
                          wrapperCol={{span: 24}}
                          initialValues={{
                              remember: true,
                          }}
                          autoComplete="on"
                          requiredMark={false}>
                        <Row gutter={[8, 0]}>
                            <Col xs={24}>
                                <Form.Item label="Имя пользователя"
                                           name="username"
                                           onChange={(e) => setLogin(e.target.value)}
                                           rules={[
                                               {required: true, message: 'Please input your username'},
                                           ]}
                                >
                                    <Input autocomplete="username"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item label="Пароль"
                                           name="password"
                                           onChange={(e) => setPassword(e.target.value)}
                                           rules={[
                                               {required: true, message: 'Please input your password!'},
                                           ]}
                                >
                                    <Input.Password autocomplete="current-password"/>
                                </Form.Item>
                            </Col>
                            {/*<Col xs={24}>*/}
                            {/*    <Form.Item name="remember" valuePropName="checked">*/}
                            {/*        <Checkbox>Remember me</Checkbox>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                        </Row>
                        <Form.Item>
                            <Flex align="center" justify="space-between">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="middle"
                                    onClick={submitForm}
                                >
                                    Войти
                                </Button>
                                <Link href='/app-front/public'>Забыли пароль?</Link>
                            </Flex>
                        </Form.Item>
                    </Form>

                </Flex>
            </Col>
        </Row>
    );
};
