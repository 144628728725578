import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

const { Text } = Typography

const SideBarMarketing = () => {
    const sections = [
        {
            label: 'Фильтры для выбора данных',
            description: [
                'В верхней части страницы можно выбрать период данных, что позволяет просматривать отчеты за определенный интервал времени.',
                'Доступен фильтр по поставщикам, где можно выбрать одного или нескольких поставщиков для анализа.',
                'Кнопка "Экспорт в Excel" позволяет скачать данные в виде таблицы для последующей обработки и анализа.'
            ]
        },
        {
            label: 'Карточки с ключевыми показателями',
            description: [
                'Расходы: общие расходы, ROMI (возврат на маркетинговые инвестиции), и ДРР (доля рекламных расходов). Здесь также отображаются изменения по сравнению с предыдущим периодом.',
                'Показы: общее количество показов рекламы, количество рекламных кампаний и количество товаров в рекламе.',
                'Клики: общее количество кликов, средняя стоимость клика (CPC), и коэффициент CTR (отношение кликов к показам), с показом изменений в процентном соотношении.',
                'Корзина: количество добавлений в корзину, стоимость корзины и процент добавлений.',
                'Заказы: общее количество заказов, средняя стоимость заказа и общая выручка, что помогает оценить прибыльность.'
            ]
        },
        {
            label: 'График изменений',
            description: [
                'Под карточками находится график с линиями, показывающими динамику различных показателей за указанный период. Каждый параметр (расходы, показы, клики, корзина, заказы и т.д.) выделен своим цветом, что позволяет легко отслеживать изменения.',
                'Легенда графика позволяет выбирать или исключать определенные метрики для более точного анализа данных на графике.'
            ]
        },
        {
            label: 'Таблица с детализацией по дням',
            description: [
                'В нижней части страницы представлена таблица, где данные по основным показателям разделены по дням. Это помогает более точно отслеживать ежедневные изменения расходов и продаж, чтобы выявить тенденции.'
            ]
        }
    ]

    return (
        <div>
            <Title level={2}>Описание страницы "Общий отчет"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                На странице <span className='text-black'>"Общий отчет"</span> представлена сводная информация о показателях, связанных с рекламными кампаниями и продажами за выбранный период. Она
                включает различные ключевые метрики и график, которые помогают оценить эффективность затрат и доходов.
            </Text>

            <Title level={3}>Основные разделы страницы:</Title>

            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {sections.map((section, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold'>{section.label}</span>:
                        <ul>
                            {section.description.map((desc, subIndex) => (
                                <li key={subIndex} className='text-base text-gray-600'>
                                    {desc}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </div>

            <Text className='font-bold text-xs'>
                Эта страница предоставляет пользователю удобный способ для визуализации ключевых метрик, анализа тенденций, а также возможность экспорта данных для более глубокого анализа и
                отчетности.
            </Text>
        </div>
    )
}

export default SideBarMarketing
