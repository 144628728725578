import React from 'react'
import { formatCurrency, formatPercent, formatQuantity, formatViews } from '../../../../core/utils/formats'
import { FallOutlined, RiseOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import '../../../../App.css'

export const GeneralLongCard = ({ title, content, Format, percent }) => {
    const textColor = percent > 0 ? 'text-green-500' : 'text-red-500'

    return (
        <Card  className='CardSmall CardLong extra:max-w-[220px] w-full max-h-[80px]'>
            <span className='text-gray-500'>{title}</span>
            <div className='flex justify-between items-center'>
                <span className='text-xl font-bold text-black'>
                    {Format === 'percent'
                        ? formatPercent(content?.toFixed(2))
                        : Format === 'rubels'
                        ? formatCurrency(content)
                        : Format === 'sht'
                        ? formatQuantity(content)
                        : Format === 'prs'
                        ? formatViews(content)
                        : content}
                </span>
                <p className={`flex text-base items-center gap-1 ${textColor}`}>
                    {percent > 0 ? <RiseOutlined className='text-xl' /> : <FallOutlined classID='text-xl' />} {formatPercent(percent)}
                </p>
            </div>
        </Card>
    )
}
