import {Card, Col, Row, Table, Tooltip, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useAuth} from "../../core/hooks/useAuth";
import dayjs from "dayjs";
import {apiAxios} from "../../core/api/api_axios";
import DatePickerRange from "../../components/simple/dateRange";
import {FaCopy} from "react-icons/fa";
import {DeleteOutlined} from "@ant-design/icons";


export const TrafficTrack = () => {

    const {wbacc_id, track_id} = useParams()
    const {user} = useAuth();
    const [wbacc, setWBAcc] = useState({});
    const [track, setTrack] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [period, setPeriod] = useState([dayjs().add(-30, 'd'), dayjs().add(-1, 'd')]);
    const blocks = [
        {title: 'Создан', key: 'created'},
        {title: 'Аккаунт', key: 'wbacc_name'},
        {title: 'Название', key: 'name'},
        {title: 'Ссылка', key: 'id'},
        {title: 'Ссылка маркетплейса', key: 'url'},
        {title: 'Откуда', key: 'utm_source'},
        {title: 'Переходов', key: 'clicks'},
        {title: 'Заказов', key: 'orders'},
        {title: 'Сумма заказов', key: 'orders_sum'},
    ]
    const fetchData = async () => {
        try {
            let fetchedDataTrack = await apiAxios.post('track/get/', {wbacc_ids: [wbacc_id]});
            let fetchedDataStat = await apiAxios.post('track/stat/get/', {wbacc_ids: [wbacc_id], st: period[0].format('YYYY-MM-DD'), en: period[1].format('YYYY-MM-DD'), is_sum: false});
            const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: [wbacc_id]});
            fetchedDataStat = fetchedDataStat.stat.filter(track => track.track_id === track_id);
            fetchedDataTrack = fetchedDataTrack.tracks.filter(track => track.id === track_id)
            let summedData = {buyouts: 0, cancels: 0, clicks: 0, orders: 0, orders_sum: 0, returns: 0}
            fetchedDataStat.forEach((item) => {
                for (let i in summedData) {
                    summedData[i] += item[i]
                }
            })
            setTrack({...fetchedDataTrack[0], ...summedData})
            setWBAcc(fetchedDataWbacc.data[wbacc_id])
            setData(fetchedDataStat)
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData();
        }
    }, [user]);
    console.log('track', track)
    const columns = [
        {title: 'Дата', dataIndex: 'date',},
        {title: 'Переходы', dataIndex: 'clicks', sorter: (a, b) => a.clicks - b.clicks},
        {title: 'Заказы', dataIndex: 'orders', sorter: (a, b) => a.orders - b.orders},
        {title: 'Сумма заказов', dataIndex: 'orders_sum', sorter: (a, b) => a.orders_sum - b.orders_sum},

    ];
    return <>
        {track ? (
            <>
                <Card>
                    <Typography.Title level={1}>Внешний трафик ссылка {track_id}</Typography.Title>
                    <Row type='flex' style={{gap: 10}}>
                        <Col lg={3} xs={24}>
                            <Typography.Title level={5}>
                                Период данных
                            </Typography.Title>
                            <DatePickerRange
                                selected={period}
                                setSelected={setPeriod}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card>
                    {blocks.map((item, index) => <div className={`${index % 2 === 0 ? 'bg-[#FAFAFD]' : 'bg-[#FFFFFF]'} flex items-center gap-4`} key={index}>
                        <p className='font-semibold text-lg'>{item.title}:</p>
                        <span className={`text-lg`}>{track[item.key]}</span>
                    </div>)}
                    <Table dataSource={data} columns={columns} pagination={{pageSize: 25}} loading={loading}/>
                </Card>
            </>
        ) : (
            <p>Данные не найдены.</p>
        )}
    </>
}
