import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

const { Text } = Typography

const SideBarDetails = () => {
    const sections = [
        {
            label: 'Фильтры',
            description: ['В верхней части страницы пользователю доступны фильтры для выбора периода данных и поставщиков.', 'Также есть кнопка "Экспорт в Excel" для выгрузки данных в таблицу Excel.']
        },
        {
            label: 'Дерево данных',
            description: [
                'Таблица организована в виде дерева, где можно раскрыть и свернуть строки.',
                'В верхнем уровне отображаются агрегированные данные для каждого типа (например, артикул или реклама). При раскрытии строки видно ежедневную разбивку данных.'
            ]
        },
        {
            label: 'Колонки таблицы',
            description: [
                'Тип – обозначает категорию строки (артикул или реклама).',
                'Id и Название – идентификатор и название товара или кампании.',
                'Расход – сумма, потраченная на маркетинг.',
                'Показы и Клики – количество показов и кликов.',
                'Корзина и Заказы – количество добавлений в корзину и оформленных заказов.',
                'Сумма заказов – общая сумма всех заказов.',
                'Процент в корзине – процент добавлений в корзину.',
                'Стоимость корзины и Стоимость заказа – средняя стоимость одного добавления в корзину и одного заказа.',
                'Ставка – ставка за клик или за показ.',
                'CTR – показатель кликабельности.',
                'CPC – стоимость за клик.',
                'DRR – показатель дохода на затраты.',
                'ROMI – возврат на инвестиции в маркетинг.'
            ]
        }
    ]

    return (
        <div>
            <Title level={2}>Описание страницы "Маркетинг (детализация)"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                На странице <span className='text-black'>"Маркетинг (детализация)"</span> представлена таблица с детализированной информацией о маркетинговых показателях для каждого товара и рекламной
                кампании.
            </Text>

            <Title level={3}>Основные элементы страницы:</Title>

            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {sections.map((section, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold'>{section.label}</span>:
                        <ul>
                            {section.description.map((desc, subIndex) => (
                                <li key={subIndex} className='text-base text-gray-600'>
                                    {desc}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </div>

            <Text className='font-bold text-xs'>
                Эта страница позволяет пользователям глубоко анализировать маркетинговые затраты и их влияние на продажи для каждого товара и рекламной кампании, что упрощает контроль за
                эффективностью маркетинговых действий.
            </Text>
        </div>
    )
}

export default SideBarDetails
