// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableDetails .ant-table-cell {
    border-radius: 0px !important;
}

.TableDetails .ant-table {
    margin: -8px -10px !important;
}

.TableDetails2 .ant-table {
    margin: -8px -8px -8px 38px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/marketing/components/index.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".TableDetails .ant-table-cell {\r\n    border-radius: 0px !important;\r\n}\r\n\r\n.TableDetails .ant-table {\r\n    margin: -8px -10px !important;\r\n}\r\n\r\n.TableDetails2 .ant-table {\r\n    margin: -8px -8px -8px 38px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
