import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row, Select, Tooltip, Typography, Tabs, Space } from 'antd'
import { useAuth } from '../../hooks/useAuth'
import { apiAxios } from "../../utils/api_axios";
import { useSidebar } from '../../Context/SideBarContext'
import Sidebar from '../home/sideBar/SideBar'
import SideBarPrices from '../home/sideBar/SideBarPrices'
import { ShopOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import ProductStock from "./components/ProductStock";
import ProductSupply from "./components/ProductSupply";
import ProductsSebes from "./components/ProductsSebes";

const { TabPane } = Tabs

export default function MyProducts() {
    const { user } = useAuth()
    const [activeTab, setActiveTab] = useState('1')
    const { openSidebar } = useSidebar()
    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_id: null,
        wbacc_options: [],
    })

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarPrices />)
    }, [openSidebar])

    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: user.wbacc_ids })
        let wbaccOptions = []

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({ label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id })
        }

        setSelectorFilter('wbacc_id', user.wbacc_ids[0])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1()
        }

    }, [user.wbacc_ids])


    return (
        <div className="pricing-container">
            {/* Header Section */}
            <Card className="mb-4">
                <Row gutter={[16, 16]} align="middle">
                    <Col xs={24} lg={12}>
                        <Space align="center">
                            <Typography.Title level={2}>Мои товары</Typography.Title>
                            <Tooltip title="Информация">
                                <QuestionCircleOutlined
                                    onClick={handleOpenSidebar}
                                    style={{
                                        fontSize: '24px',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s',
                                    }}
                                    className="hover:rotate-180 active:scale-90"
                                />
                            </Tooltip>
                        </Space>
                    </Col>

                </Row>

                {/* Filters Section */}
                <Row gutter={[16, 16]} className="mt-4">
                    <Col xs={24} lg={6}>
                        <Typography.Title level={5}>
                            <ShopOutlined /> Магазины
                        </Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Выберите магазин"
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                </Row>
            </Card>

            {/* Main Content */}
            <Card>
                <Tabs activeKey={activeTab} onChange={setActiveTab}>
                    <TabPane tab="Склад" key="1">
                        <ProductStock
                            selectorFilter={selectorFilter}
                        />
                    </TabPane>
                    <TabPane tab="Поставки" key="2"><ProductSupply selectorFilter={selectorFilter}/></TabPane>
                    <TabPane tab="Себестоимость" key="3"><ProductsSebes selectorFilter={selectorFilter}/></TabPane>
                </Tabs>
            </Card>

            <Sidebar />
        </div>
    )
}

