// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-tabs .ant-tabs-nav {
    margin-bottom: 24px;
}

.orders-tabs .ant-tabs-nav::before {
    border: none;
}

.orders-tabs .ant-tabs-tab {
    padding: 8px 16px;
    font-size: 14px;
    border: none !important;
    background: none !important;
}

.orders-tabs .ant-tabs-tab-active {
    background: none !important;
}

.orders-tabs .ant-tabs-ink-bar {
    background: #1890ff;
    height: 2px;
}

.orders-tabs .ant-tabs-tab:hover {
    color: #1890ff;
}

.orders-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
    font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/pages/sales/components/OrdersTabs.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".orders-tabs .ant-tabs-nav {\r\n    margin-bottom: 24px;\r\n}\r\n\r\n.orders-tabs .ant-tabs-nav::before {\r\n    border: none;\r\n}\r\n\r\n.orders-tabs .ant-tabs-tab {\r\n    padding: 8px 16px;\r\n    font-size: 14px;\r\n    border: none !important;\r\n    background: none !important;\r\n}\r\n\r\n.orders-tabs .ant-tabs-tab-active {\r\n    background: none !important;\r\n}\r\n\r\n.orders-tabs .ant-tabs-ink-bar {\r\n    background: #1890ff;\r\n    height: 2px;\r\n}\r\n\r\n.orders-tabs .ant-tabs-tab:hover {\r\n    color: #1890ff;\r\n}\r\n\r\n.orders-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\r\n    color: #1890ff;\r\n    font-weight: 500;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
