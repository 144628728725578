import React from 'react';
import { Card, Row, Col } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const OrdersCharts = ({ chartData }) => {
    // Transform data for daily chart
    const dailyData = chartData?.by_day?.map(item => ({
        day: item.day,
        count: item.count
    })) || [];

    // Transform data for weekly chart
    const weekdayNames = {
        'пн': 'Понедельник',
        'вт': 'Вторник',
        'ср': 'Среда',
        'чт': 'Четверг',
        'пт': 'Пятница',
        'сб': 'Суббота',
        'вс': 'Воскресенье'
    };

    const weeklyData = chartData?.by_weekday?.map(item => ({
        day: weekdayNames[item.day],
        count: item.count
    })) || [];

    // Transform data for hourly chart
    const hourlyData = chartData?.by_hour?.map(item => ({
        hour: item.hour,
        count: item.count
    })) || [];

    const chartStyle = {
        height: 300,
    };

    return (
        <Row gutter={[16, 16]}>
            <Col span={8}>
                <Card title="По числам месяца" bordered={false}>
                    <div style={chartStyle}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={dailyData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="day" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="count" fill="#ff6b84" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </Col>
            <Col span={8}>
                <Card title="По дням недели" bordered={false}>
                    <div style={chartStyle}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={weeklyData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="day" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="count" fill="#4096ff" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </Col>
            <Col span={8}>
                <Card title="По часам" bordered={false}>
                    <div style={chartStyle}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={hourlyData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="hour" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="count" fill="#95de64" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </Col>
        </Row>
    );
};

export default OrdersCharts;
