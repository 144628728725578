import { apiAxios } from '../api_axios';

export const getAnalByWeeks = async(wbacc_id, is_like, is_hide, period) => {
    try {
        return await apiAxios.get('anal-by-weeks/get/', { wbacc_ids: [wbacc_id], is_like: is_like, is_hide: is_hide, st: period[0].format('YYYY-MM-DD'), en: period[1].format('YYYY-MM-DD') })
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};