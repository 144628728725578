import React from 'react'
import { formatCurrency, formatPercent, formatQuantity, formatViews } from '../../../utils/formats';
import { FallOutlined, RiseOutlined } from '@ant-design/icons'

export const GeneralFin = ({ inf }) => {
    const fin = [
        {
            title: 'ДРР',
            number: inf?.drr,
            percent: inf?.diff?.drr,
            format: 'percent'
        },
        {
            title: 'CTR',
            number: inf?.ctr,
            percent: inf?.diff?.ctr,
            format: 'percent'
        },
        {
            title: 'CPC',
            number: inf?.cpc,
            percent: inf?.diff?.cpc,
            format: 'percent'
        },
        // {
        //     title: 'CPO',
        //     number: inf?.cpo,
        //     percent: inf?.diff.cpo,
        //     format: 'percent'
        // },
        { title: 'Заказы', number: inf?.orders, percent: inf?.diff?.orders, format: 'sht' },
        { title: 'Бюджет', number: inf?.budget, percent: inf?.diff?.budget, format: 'rub' },
        { title: 'Клики', number: inf?.clicks, percent: inf?.diff?.clicks },
        { title: 'Просмотры', number: inf?.views, percent: inf?.diff?.views }
    ]

    return (
        <>
            <h2 className='text-3xl font-bold text-black'>Реклама</h2>
            <div className='flex flex-col gap-2'>
                {fin.map((item, index) => (
                    <div key={index} className={`${index !== 0 && 'border-t-[1px] border-gray-300'} flex pt-2 justify-between items-center`}>
                        <p className='md:text-xl font-medium'>{item.title}</p>
                        <div className='flex items-center gap-2'>
                            <span className='md:text-2xl font-bold'>
                                {item.format === 'percent'
                                    ? formatPercent(item.number)
                                    : item.format === 'views'
                                    ? formatViews(item.number)
                                    : item.format === 'rub'
                                    ? formatCurrency(item.number)
                                    : item.format === 'sht'
                                    ? formatQuantity(item.number)
                                    : item.number}
                            </span>
                            <div className={`${item.percent > 0 ? 'text-green-500' : 'text-red-500'} text-xs md:text-base gap-1 flex items-center`}>
                                {item.percent > 0 ? <RiseOutlined /> : <FallOutlined />} {formatPercent(item.percent)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
