import React, { createContext, useContext, useState, useCallback, useMemo } from 'react'

const SidebarContext = createContext()

export const useSidebar = () => useContext(SidebarContext)

export const SidebarProvider = ({ children }) => {
    const [isSidebarVisible, setSidebarVisible] = useState(false)
    const [sidebarContent, setSidebarContent] = useState(null)

    const openSidebar = useCallback((content) => {
        console.log('Opening sidebar') // <-- Add this
        setSidebarContent(content)
        setSidebarVisible(true)
    }, [])

    const closeSidebar = useCallback(() => {
        console.log('Closing sidebar') // <-- Add this
        setSidebarVisible(false)
        setSidebarContent(null)
    }, [])

    const value = useMemo(
        () => ({
            isSidebarVisible,
            sidebarContent,
            openSidebar,
            closeSidebar
        }),
        [isSidebarVisible, sidebarContent, openSidebar, closeSidebar]
    )

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
}
