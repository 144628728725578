import React, { useCallback, useEffect, useState } from 'react'
import {Button, Card, Col, Dropdown, Input, Menu, Row, Select, Space, Tag, Tooltip, Typography} from 'antd'
import { useAuth } from '../../hooks/useAuth'
import { apiAxios } from "../../utils/api_axios";
import SalesPlanTable from './components/SalesPlanTable'
import { useSidebar } from '../../Context/SideBarContext'
import { CiCircleQuestion } from 'react-icons/ci'
import Sidebar from '../home/sideBar/SideBar'
import {useFilteredCards, useFilterLogic} from "../components/filterUtils";
import {FilterOutlined, ReloadOutlined} from "@ant-design/icons";

export default function SalesPlan() {
    const { user } = useAuth()
    const [data, setData] = useState([])
     const [previousData, setPreviousData] = useState([]);
    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_id: null,
        wbacc_options: [],
    })
    const [loading, setLoading] = useState(true)
    const { openSidebar } = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        openSidebar('fd')
    }, [])
    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const [filters_data, setFiltersData] = useState({});
    const [activeFilters, setActiveFilters] = useState([]);
    const [filterSearchTexts, setFilterSearchTexts] = useState({
        article: '',
        material: '',
        category: '',
        status: '',
        sezon: '',
        brand: '',
        color: ''
    });

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: user.wbacc_ids })
        let wbaccOptions = []

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({ label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id })
        }

        setSelectorFilter('wbacc_id', user.wbacc_ids[0])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const fetchData = async () => {
        setLoading(true)
        const response = await apiAxios.post('sales/plan/get', { wbacc_ids: [selectorFilter.wbacc_id] })
        console.log('fetchedData', response)
        setData(response.data)
        setFiltersData(response.filters || {});
        setLoading(false)
    }
     useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1()
        }

    }, [user.wbacc_ids])
    useEffect(() => {
        if (selectorFilter.wbacc_id) {
            fetchData();
        }
    }, [selectorFilter.wbacc_id]);
    const handleAddFilter = (type, value) => {
        setActiveFilters(prev => {
            const filterExists = prev.some(
                filter => filter.type === type && filter.value.value === value.value
            );
            if (filterExists) return prev;
            return [...prev, {type, value}];
        });
    };

    const handleRemoveFilter = (index) => {
        setActiveFilters(prev => prev.filter((_, i) => i !== index));
    };

    const menuItems = useFilterLogic(
        filters_data,
        filterSearchTexts,
        setFilterSearchTexts,
        handleAddFilter
    );

    const filterMenu = <Menu items={menuItems}/>;
    const [quickFilters, setQuickFilters] = useState({
        showNegativeMargin: false,
        showNoSales: false,
        showLowStock: false,
        showHighStock: false,
        showLongTurnover: false,
        showLowBuyout: false,
        showNoPlan: false,
    });

    const quickFilterButtons = [
        {key: 'showNegativeMargin', label: 'Отрицательная маржа', filter: item => item.marzha_unit < 0},
        {key: 'showNoSales', label: 'Нет заказов', filter: item => item.orders_per_day < 0.2 && item.ost_quantity > 10},
        {key: 'showLowStock', label: 'Низкий остаток', filter: item => item.oborot_orders < 30 && item.orders_per_day > 0.2},
        {key: 'showHighStock', label: 'Высокий остаток', filter: item => item.oborot_orders > 90 && item.orders_per_day > 0.2},
        {key: 'showLongTurnover', label: 'Долгая оборачиваемость', filter: item => item.oborot_orders > 60},
        {key: 'showLowBuyout', label: 'Низкий выкуп', filter: item => item.buyout_percent < 30 && item.buyout_percent > 0},
        {key: 'showNoPlan', label: 'Не указан план', filter: item => item.plan_orders === 0 || item.plan_orders_sum === 0},
    ];

    const handleQuickFilter = (filterKey) => {
        if (quickFilters[filterKey]) {
            setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
                ...acc,
                [key]: false
            }), {}));
        } else {
            setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
                ...acc,
                [key]: key === filterKey
            }), {}));
        }
    };

    const applyQuickFilters = (data) => {
        const activeFilter = Object.entries(quickFilters).find(([_, value]) => value);
        if (!activeFilter) return data;

        const filterConfig = quickFilterButtons.find(btn => btn.key === activeFilter[0]);
        if (!filterConfig) return data;
        const filteredData = data.filter(filterConfig.filter);
        return filteredData;
    };
    let filteredData = useFilteredCards(data, activeFilters);
    filteredData = applyQuickFilters(filteredData);
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 1000); // Задержка 1 секунда

        // Очистка таймера при изменении searchText
        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);
    filteredData = filteredData.filter(record =>
        record.article?.toLowerCase().includes(debouncedSearchText.toLowerCase()) || record.nm_id?.toString().toLowerCase().includes(debouncedSearchText.toLowerCase())
    );

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    const onEditCard = (card) => {
        console.log('edit', card)
        apiAxios.post('sales/plan/set', card)
    }

    return (
        <>
            <Card>
                <Typography.Title level={1}>План продаж</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Магазины</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='Выберите магазин'
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                    </Tooltip>
                </Row>

            <Sidebar />
                </Card>
            <Card>
            <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16, marginTop: 16}}>
                <Space wrap>
                    <Input
                        placeholder="Поиск по артикулу"
                        value={searchText}
                        onChange={handleSearch}
                    />
                    <Dropdown overlay={filterMenu} trigger={['click']}>
                        <Button icon={<FilterOutlined/>}>
                            Добавить фильтр
                        </Button>
                    </Dropdown>
                    <Button
                        icon={<ReloadOutlined/>}
                        onClick={fetchData}
                    >
                        Обновить
                    </Button>


                    {activeFilters.map((filter, index) => (
                        <Tag
                            key={index}
                            closable
                            onClose={() => handleRemoveFilter(index)}
                        >
                            {`${filter.type}: ${filter.value.label}`}
                        </Tag>
                    ))}
                </Space>
                <Space wrap>
                    {quickFilterButtons.map(button => (
                        <Button
                            key={button.key}
                            type={quickFilters[button.key] ? 'primary' : 'default'}
                            onClick={() => handleQuickFilter(button.key)}
                        >
                            {button.label}
                        </Button>
                    ))}
                </Space>
            </Space>

                <SalesPlanTable
                    cards={filteredData}
                    setCards={setData}
                    loading={loading}
                    onEditCard={onEditCard}
                />
            </Card>
        </>
    )
}
