import React, {useState, useRef} from 'react'
import {Table, Typography, Space, Button, Dropdown, Tag, Menu, Input} from 'antd'
import { FilterOutlined, CloseOutlined, ReloadOutlined, PlusOutlined, MinusOutlined} from '@ant-design/icons'
import {formatCurrency, formatDays2, formatPercent, formatPercent2, formatQuantity} from '../../../utils/formats';
import '../../products/components/PriceTable.css'
import {useFilterLogic, useFilteredCards} from '../../components/filterUtils'
import * as XLSX from "xlsx";

const {Text} = Typography

const ExpandButton = ({title, showedDays, setShowedDays}) => {
    const isExpanded = showedDays.includes(title)

    const toggleDay = (day) => {
        if (showedDays.includes(day)) {
            setShowedDays(prev => prev.filter(d => d !== day))
        } else {
            setShowedDays(prev => [...prev, day])
        }
    }

    return (
        <Button
            type="text"
            icon={isExpanded ? <MinusOutlined/> : <PlusOutlined/>}
            onClick={() => toggleDay(title)}
            style={{padding: '4px'}}
        >
            {title}
        </Button>
    )
}


function getDayColumns(dayStat, gradientData) {
    if (!dayStat || dayStat.length === 0) return [];
    let resultColumns = []

    dayStat.forEach((day, indexDay) => {
        const newColumn = {
            title: day['date_show'],
            className: 'border_left',
            width: 180,
            render: (record) => {
                return (
                    <Space direction="vertical" size={0}>
                        <Space align="start" size={12}>
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Штук:</strong> {formatQuantity(record.by_days[day.date].orders)}</Text>
                                <Text><strong>Рублей:</strong> {formatCurrency(record.by_days[day.date].orders_sum)}</Text>
                                <Text><strong>Выполнение:</strong> {formatPercent(record.by_days[day.date].otkl)}</Text>
                                <Text><strong>МРК:</strong> {formatCurrency(record.by_days[day.date].budget)}</Text>
                                <Text><strong>ДРР:</strong> {formatPercent(record.by_days[day.date].drr)}</Text>
                                <Text><strong>CTR:</strong> {formatPercent(record.by_days[day.date].ctr)}</Text>
                                <Text><strong>Оборачиваемость:</strong> {formatDays2(record.by_days[day.date].oborot_orders)}</Text>
                                <Text><strong>Выкуп:</strong> {formatPercent(record.by_days[day.date].buyout_percent)}</Text>
                                <Text><strong>Цена:</strong> {formatCurrency(record.by_days[day.date].price_with_discount)}</Text>
                            </Space>
                        </Space>
                    </Space>

                );
            }
        }
        resultColumns.push(newColumn)
    })

    return resultColumns
}

const expandDaysStat = (cards) => {
    if (!cards || cards.length === 0 || !cards[0].days_stat) return [];

    const expandedCards = cards.map(card => {
        const newCard = {...card}
        card.days_stat.forEach((day, index) => {
            newCard[`col${index}_plan`] = day.plan
            newCard[`col${index}_fact`] = day.fact
            newCard[`col${index}_completion`] = (day.fact / day.plan) * 100
            newCard[`col${index}_revenue`] = day.revenue
            newCard[`col${index}_profit`] = day.profit
        })
        return newCard
    })
    return expandedCards
}

export const PlanFactTable3 = ({
                                   cards,
                                   setCards,
                                   filters,
                                   loading,
                                   isOzon,
                                   gradientData
                               }) => {
    const isMobile = window.innerWidth <= 769
    const updateTimeoutRef = useRef(null);
    const [activeFiltersState, setActiveFilters] = useState([])
    const [filterSearchTexts, setFilterSearchTexts] = useState({
        model: '',
        category: '',
        status: '',
        sezon: '',
        brand: '',
        color: ''
    })

    const handleAddFilter = (type, value) => {
        setActiveFilters(prev => {
            const filterExists = prev.some(
                filter => filter.type === type && filter.value.value === value.value
            )
            if (filterExists) return prev
            return [...prev, {type, value}]
        })
    }

    const handleRemoveFilter = (index) => {
        setActiveFilters(prev => prev.filter((_, i) => i !== index))
    }

    const menuItems = useFilterLogic(
        filters,
        filterSearchTexts,
        setFilterSearchTexts,
        handleAddFilter
    )

    const filteredCards = useFilteredCards(cards, activeFiltersState)
    const expandedCards = expandDaysStat(filteredCards)

    const filterMenu = <Menu items={menuItems}/>

    const baseColumns = [
        // Товар
        {
            title: 'Товар',
            key: 'product',
            width: 300,
            fixed: isMobile ? '' : 'left',
            render: (_, record) => (
                <Space direction="vertical" size={0}>
                    <Space align="start" size={12}>
                        <img src={record.img || 'assets/images/logo.svg'} style={{height: '60px', width: '60px', objectFit: 'contain'}} alt="Product"/>
                        <Space direction="vertical" size={0} className="cost-info-cell">
                            <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                            <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                            <Text><strong>Название:</strong> {record.name}</Text>
                            <Text><strong>Карточка:</strong> {record.imt_id}</Text>
                        </Space>
                    </Space>
                </Space>
            )
        },
        // Информация о товаре
        {
            title: 'Информация о товаре',
            width: 250,
            render: (_, record) => (
                <Space size={24} className="cost-info-cell" style={{width: '100%',}}>
                    <Space direction="vertical" size={0}>
                        <Text><strong>Модель:</strong> {record.material}</Text>
                        <Text><strong>Бренд:</strong> {record.brand}</Text>
                        <Text><strong>Категория:</strong> {record.category}</Text>
                        <Text><strong>Статус:</strong> {record.status}</Text>
                        <Text><strong>Сезон:</strong> {record.sezon}</Text>
                        <Text><strong>Цвет:</strong> {record.color}</Text>
                    </Space>
                </Space>
            )
        },

        // Складские остатки
        {
            title: 'Остатки',
            width: 200,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Всего:</strong> {formatQuantity(record.ost + (record.way_to || 0))}</Text>
                    <Text><strong>FBO:</strong> {formatQuantity(record.ost)}</Text>
                    <Text><strong>В пути от:</strong> {formatQuantity(record.way_from || 0)}</Text>
                    <Text><strong>В пути до:</strong> {formatQuantity(record.way_to || 0)}</Text>
                    <Text><strong>Себестоимость:</strong> {formatCurrency(record.sebes * record.full)}</Text>
                    <Text><strong>Деньги в товаре:</strong> {formatCurrency(record.ost_sum)}</Text>
                </Space>
            )
        },
        // Складские остатки
        {
            title: 'Другие остатки',
            width: 150,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Остаток МС:</strong> {formatQuantity(record.ms_ost || 0)}</Text>
                    <Text><strong>Транзит:</strong> {formatQuantity(record.ms_transfer || 0)}</Text>
                    <Text><strong>Шьется:</strong> {formatQuantity(record.ms_production || 0)}</Text>
                </Space>
            )
        },
        // Аналитика
        {
            title: 'Показатели',
            width: 170,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Цена:</strong> {formatCurrency(record.price_with_wb_discount)}</Text>
                    <Text><strong>Заказов:</strong> {formatQuantity(record.orders)}</Text>
                    <Text><strong>Сумма заказов:</strong> {formatCurrency(record.orders_sum)}</Text>
                    <Text><strong>Сумма выкупов:</strong> {formatCurrency(record.buyouts_sum)}</Text>
                    <Text><strong>Заказов в день:</strong> {formatQuantity(record.orders_per_day)}</Text>
                    <Text><strong>Маржинальность:</strong> {formatPercent(record.marzha)}</Text>
                    <Text><strong>Оборачиваемость:</strong> {formatDays2(record.oborot_orders)}</Text>
                    <Text><strong>ABC:</strong> {record.abc}</Text>
                </Space>
            )
        },
        // План
        {
            title: 'План',
            width: 200,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Шт:</strong> {formatQuantity(record.plan_orders)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
                        ({formatPercent2(record.plan_orders_percent || (record.orders / record.plan_orders * 100))})
                    </span></Text>
                    <Text><strong>Руб:</strong> {formatCurrency(record.plan_orders_sum)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
                        ({formatPercent2(record.plan_orders_sum_percent || (record.orders_sum / record.plan_orders_sum * 100))})
                    </span></Text>
                    <Text><strong>Выкуп руб:</strong> {formatCurrency(record.plan_buyouts_sum)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
                        ({formatPercent2(record.plan_buyouts_sum_percent || (record.buyouts_sum / record.plan_buyouts_sum * 100))})
                    </span></Text>
                    <Text><strong>Шт в день:</strong> {formatQuantity(record.plan_orders_day)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
                        ({formatPercent2(record.plan_orders_day_percent || (record.orders_per_day / record.plan_orders_day * 100))})
                    </span></Text>
                    <Text><strong>Руб в день:</strong> {formatCurrency(record.plan_orders_sum_day)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
                        ({formatPercent2(record.plan_orders_sum_day_percent || (record.orders_per_day * record.price_with_wb_discount / record.plan_orders_sum_day * 100))})
                    </span></Text>
                </Space>
            )
        },
    ]
    console.log('cards', cards)
    const dayColumns = getDayColumns(cards[0]?.days_stat || [], gradientData)
    const allColumns = [...baseColumns, ...dayColumns]

    // Функция для подсчета суммарных значений
    const calculateSummary = (data) => {
        if (!data || data.length === 0 || !cards[0]?.days_stat) return null;

        const summary = {
            article: 'Итого',
            by_days: {},
            // Добавляем поля для отображения в колонке товара
            material: 'Суммарная информация',
            brand: `Всего товаров: ${data.length}`,
            category: '',
            status: '',
            sezon: '',
            color: '',
            // Добавляем суммарные значения для остатков
            ost: 0,
            way_to: 0,
            way_from: 0,
            sebes: 0,
            full: 0,
            ost_sum: 0,
            // Другие остатки
            ms_ost: 0,
            ms_transfer: 0,
            ms_production: 0,
            // Добавляем суммарные значения для показателей
            price_with_wb_discount: 0,
            orders: 0,
            orders_sum: 0,
            buyouts_sum: 0,
            orders_per_day: 0,
            marzha: 0,
            oborot_orders: 0,
            abc: '',
            // Добавляем суммарные значения для плана
            plan_orders: 0,
            plan_orders_sum: 0,
            plan_buyouts_sum: 0,
            plan_orders_day: 0,
            plan_orders_sum_day: 0,
            fact_orders: 0,
            fact_orders_day: 0,
            fact_orders_sum: 0,
            fact_buyouts_sum: 0,
            plan_orders_percent: 0,
            plan_orders_sum_percent: 0,
            plan_orders_day_percent: 0,
            plan_orders_sum_day_percent: 0,
            plan_buyouts_sum_percent: 0,
        };

        let totalOrders = 0;

        // Инициализируем структуру для каждого дня
        let totalDays = 0
        cards[0].days_stat.forEach(day => {
            totalDays += 1
            return summary.by_days[day.date] = {
                orders: 0,
                orders_sum: 0,
                budget: 0,
            };
        });

        // Суммируем значения по дням и общие показатели
        data.forEach(record => {
            // Суммируем остатки
            summary.ost += record.ost || 0;
            summary.way_to += record.way_to || 0;
            summary.way_from += record.way_from || 0;
            summary.ost_sum += record.ost_sum || 0;
            if (record.sebes && record.full) {
                summary.sebes += record.sebes * record.full;
            }

            // Другие остатки
            summary.ms_ost += record.ms_ost || 0;
            summary.ms_transfer += record.ms_transfer || 0;
            summary.ms_production += record.ms_production || 0;

            // Суммируем показатели
            summary.orders += record.orders || 0;
            summary.orders_sum += record.orders_sum || 0;
            summary.buyouts_sum += record.buyouts_sum || 0;
            summary.orders_per_day += record.orders_per_day || 0;

            // Считаем взвешенные средние для процентных показателей
            if (record.orders_per_day > 0) {
                summary.marzha += (record.marzha || 0) * record.orders_per_day;
                summary.oborot_orders += (record.oborot_orders || 0) * record.orders_per_day;
                summary.price_with_wb_discount += (record.price_with_wb_discount || 0) * record.orders_per_day;
                totalOrders += record.orders_per_day;
            }

            // Суммируем план
            summary.plan_orders += record.plan_orders || 0;
            summary.plan_orders_sum += record.plan_orders_sum || 0;
            summary.plan_buyouts_sum += record.plan_buyouts_sum || 0;
            summary.plan_orders_day += record.plan_orders_day || 0;
            summary.plan_orders_sum_day += record.plan_orders_sum_day || 0;
            if (record.plan_orders > 0) {
                summary.fact_orders_day += record.orders_per_day;
            }
            // Суммируем значения по дням
            if (record.by_days) {
                Object.keys(record.by_days).forEach(date => {
                    const dayData = record.by_days[date];
                    if (dayData) {
                        summary.by_days[date].orders += dayData.orders || 0;
                        summary.by_days[date].orders_sum += dayData.orders_sum || 0;
                        summary.by_days[date].budget += dayData.budget || 0;
                        if (record.plan_orders > 0) {
                            summary.fact_orders += dayData.orders || 0;
                            summary.fact_orders_sum += dayData.orders_sum || 0;
                            summary.fact_buyouts_sum += dayData.buyouts_sum || 0;
                        }
                        // Суммируем значения для взвешенного среднего
                        if (dayData.orders_per_day > 0) {
                            if (!summary.by_days[date].weighted) {
                                summary.by_days[date].weighted = {
                                    drr: 0,
                                    ctr: 0,
                                    buyout_percent: 0,
                                    oborot_orders: 0,
                                    price_with_discount: 0,
                                    totalOrders: 0
                                };
                            }
                            summary.by_days[date].weighted.drr += (dayData.drr || 0) * dayData.orders_per_day;
                            summary.by_days[date].weighted.ctr += (dayData.ctr || 0) * dayData.orders_per_day;
                            summary.by_days[date].weighted.buyout_percent += (dayData.buyout_percent || 0) * dayData.orders_per_day;
                            summary.by_days[date].weighted.oborot_orders += (dayData.oborot_orders || 0) * dayData.orders_per_day;
                            summary.by_days[date].weighted.price_with_discount += (dayData.price_with_discount || 0) * dayData.orders_per_day;
                            summary.by_days[date].weighted.totalOrders += dayData.orders_per_day;
                        }
                    }
                });
            }
        });

        // Вычисляем средние значения для процентных показателей
        if (totalOrders > 0) {
            summary.marzha = summary.marzha / totalOrders;
            summary.oborot_orders = summary.oborot_orders / totalOrders;
            summary.price_with_wb_discount = summary.price_with_wb_discount / totalOrders;
        }

        // Вычисляем проценты для плана
        if (summary.plan_orders > 0) {
            summary.plan_orders_percent = (summary.fact_orders / summary.plan_orders) * 100;
            summary.plan_orders_sum_percent = (summary.fact_orders_sum / summary.plan_orders_sum) * 100;
            summary.plan_buyouts_sum_percent = (summary.fact_buyouts_sum / summary.plan_buyouts_sum) * 100;
            summary.plan_orders_day_percent = (summary.fact_orders_day / summary.plan_orders_day) * 100;
            summary.plan_orders_sum_day_percent = (summary.fact_orders_sum / summary.plan_orders_sum_day / totalDays) * 100;

        }

        // Вычисляем средние значения для каждого дня
        Object.keys(summary.by_days).forEach(date => {
            const dayData = summary.by_days[date];
            if (dayData.weighted && dayData.weighted.totalOrders > 0) {
                dayData.drr = dayData.weighted.drr / dayData.weighted.totalOrders;
                dayData.ctr = dayData.weighted.ctr / dayData.weighted.totalOrders;
                dayData.buyout_percent = dayData.weighted.buyout_percent / dayData.weighted.totalOrders;
                dayData.oborot_orders = dayData.weighted.oborot_orders / dayData.weighted.totalOrders;
                dayData.price_with_discount = dayData.weighted.price_with_discount / dayData.weighted.totalOrders;
            }
            delete dayData.weighted; // Удаляем промежуточные данные
        });
        console.log('summary', summary)
        return summary;
    };

    // Добавляем суммарную строку как первый элемент
    const summaryRow = calculateSummary(expandedCards);
    const dataWithSummary = summaryRow ? [summaryRow, ...expandedCards] : expandedCards;

    const downloadExcel = () => {
        // Получаем все уникальные даты
        const dates = new Set();
        cards.forEach(card => {
            if (card.by_days) {
                Object.keys(card.by_days).forEach(date => dates.add(date));
            }
        });

        // Создаем заголовок с датами
        const dateHeaders = {};
        const fieldsHeader = {};
        dates.forEach(date => {
            // Дата
            dateHeaders[`${date} Штук`] = date;
            dateHeaders[`${date} Рублей`] = date;
            dateHeaders[`${date} МРК`] = date;
            dateHeaders[`${date} ДРР`] = date;
            dateHeaders[`${date} CTR`] = date;
            dateHeaders[`${date} Оборачиваемость`] = date;
            dateHeaders[`${date} Выкуп`] = date;
            dateHeaders[`${date} Цена`] = date;

            // Названия полей
            fieldsHeader[`${date} Штук`] = 'Штук';
            fieldsHeader[`${date} Рублей`] = 'Рублей';
            fieldsHeader[`${date} МРК`] = 'МРК';
            fieldsHeader[`${date} ДРР`] = 'ДРР';
            fieldsHeader[`${date} CTR`] = 'CTR';
            fieldsHeader[`${date} Оборачиваемость`] = 'Обор.';
            fieldsHeader[`${date} Выкуп`] = 'Выкуп';
            fieldsHeader[`${date} Цена`] = 'Цена';
        });

        const excelData = cards.map(card => {
            const baseData = {
                // Товар
                'Артикул': card.article,
                'Артикул WB': card.nm_id,
                'Название': card.name,
                'Карточка': card.imt_id,

                // Информация о товаре
                'Модель': card.material,
                'Бренд': card.brand,
                'Категория': card.category,
                'Статус': card.status,
                'Сезон': card.sezon,
                'Цвет': card.color,

                // Складские остатки
                'Всего остатков': card.ost + (card.way_to || 0),
                'FBO': card.ost,
                'В пути от': card.way_from || 0,
                'В пути до': card.way_to || 0,
                'Себестоимость остатков': card.sebes * card.full,
                'Деньги в товаре': card.ost_sum,

                // Другие остатки
                'Остаток МС': card.ms_ost || 0,
                'Транзит': card.ms_transfer || 0,
                'Шьется': card.ms_production || 0,
            };

            // Добавляем статистику по дням
            if (card.by_days) {
                Object.entries(card.by_days).forEach(([date, dayData]) => {
                    baseData[`${date} Штук`] = dayData.orders;
                    baseData[`${date} Рублей`] = dayData.orders_sum;
                    baseData[`${date} МРК`] = dayData.budget;
                    baseData[`${date} ДРР`] = dayData.drr;
                    baseData[`${date} CTR`] = dayData.ctr;
                    baseData[`${date} Оборачиваемость`] = dayData.oborot_orders;
                    baseData[`${date} Выкуп`] = dayData.buyout_percent;
                    baseData[`${date} Цена`] = dayData.price_with_discount;
                });
            }

            return baseData;
        });

        // Добавляем строки с датами и названиями полей в начало массива
        excelData.unshift(
            // Строка с названиями полей
            {
                'Артикул': 'Поле',
                'Артикул WB': '',
                'Название': '',
                'Карточка': '',
                'Модель': '',
                'Бренд': '',
                'Категория': '',
                'Статус': '',
                'Сезон': '',
                'Цвет': '',
                'Всего остатков': '',
                'FBO': '',
                'В пути от': '',
                'В пути до': '',
                'Себестоимость остатков': '',
                'Деньги в товаре': '',
                'Остаток МС': '',
                'Транзит': '',
                'Шьется': '',
                ...fieldsHeader
            },
            // Строка с датами
            {
                'Артикул': 'Дата',
                'Артикул WB': '',
                'Название': '',
                'Карточка': '',
                'Модель': '',
                'Бренд': '',
                'Категория': '',
                'Статус': '',
                'Сезон': '',
                'Цвет': '',
                'Всего остатков': '',
                'FBO': '',
                'В пути от': '',
                'В пути до': '',
                'Себестоимость остатков': '',
                'Деньги в товаре': '',
                'Остаток МС': '',
                'Транзит': '',
                'Шьется': '',
                ...dateHeaders
            }
        );

        // Создаем книгу Excel
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Настраиваем ширину столбцов
        const maxWidth = 20;
        const colWidths = {};
        Object.keys(excelData[0] || {}).forEach(key => {
            colWidths[key] = Math.min(
                maxWidth,
                Math.max(
                    key.length,
                    ...excelData.map(row => String(row[key] || '').length)
                )
            );
        });
        ws['!cols'] = Object.values(colWidths).map(width => ({wch: width}));

        // Добавляем форматирование для заголовков с датами
        const range = XLSX.utils.decode_range(ws['!ref']);
        const dateColumns = new Set();

        // Находим колонки с датами
        Object.keys(dateHeaders).forEach(key => {
            const col = Object.keys(excelData[0]).indexOf(key);
            if (col !== -1) {
                dateColumns.add(col);
            }
        });

        // Создаем стили для ячеек
        if (!ws['!merges']) ws['!merges'] = [];
        if (!ws['!styles']) ws['!styles'] = [];

        // Применяем жирный стиль к колонкам с датами
        for (let col = 0; col <= range.e.c; col++) {
            if (dateColumns.has(col)) {
                const cellRef = XLSX.utils.encode_cell({r: 0, c: col}); // Строка с названиями полей
                const cellRef2 = XLSX.utils.encode_cell({r: 1, c: col}); // Строка с датами

                if (!ws['!styles']) ws['!styles'] = {};
                ws['!styles'][cellRef] = {font: {bold: true}, fill: {fgColor: {rgb: "EFEFEF"}}};
                ws['!styles'][cellRef2] = {font: {bold: true}, fill: {fgColor: {rgb: "EFEFEF"}}};
            }
        }

        // Добавляем лист в книгу
        XLSX.utils.book_append_sheet(wb, ws, "План-факт");

        // Скачиваем файл
        XLSX.writeFile(wb, `План_факт_${new Date().toLocaleDateString()}.xlsx`);
    };

    return (
        <div className="price-table-container">
            <div className="table-header">
                <Space size={8} wrap>
                    <Dropdown
                        overlay={filterMenu}
                        trigger={['click']}
                        overlayClassName="filter-dropdown"
                    >
                        <Button icon={<FilterOutlined/>}>
                            Добавить фильтр
                        </Button>
                    </Dropdown>
                    <Button
                        icon={<ReloadOutlined/>}
                    >
                        Обновить
                    </Button>
                    <Button
                        type="primary"
                        style={{backgroundColor: '#4CAF50', borderColor: '#4CAF50'}}
                        onClick={downloadExcel}
                    >
                        Экспорт в Excel
                    </Button>
                    {activeFiltersState.map((filter, index) => (
                        <Tag
                            key={index}
                            closable
                            onClose={() => handleRemoveFilter(index)}
                            closeIcon={<CloseOutlined/>}
                        >
                            {`${filter.type}: ${filter.value.label}`}
                        </Tag>
                    ))}
                </Space>
            </div>
            <Table
                dataSource={dataWithSummary}
                columns={allColumns}
                size="small"
                scroll={{x: 1500}}
                loading={loading}
                rowKey="article"
                pagination={{
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    showTotal: (total) => `Всего ${total - 1} товаров`
                }}
                className="plan-fact-table"
            />
        </div>
    );
};
