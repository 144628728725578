import {EyeOutlined, HeartOutlined} from "@ant-design/icons";
import {Checkbox, Table} from "antd";
import {formatCurrency, formatDays, formatPercent, formatQuantity} from "../../../../core/utils/formats";
import React from "react";


const getColumns = () => {

    return [

        {title: 'Группа', dataIndex: 'group_type', width: 120, fixed: 'left', ellipsis: true,},
        {title: 'Дата', dataIndex: 'date', width: 100, fixed: 'left',},
        {title: 'Сумма выкупов', dataIndex: 'buyouts_sum', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Сумма возвратов', dataIndex: 'returns_sum', width: 120, render: (text) => formatCurrency(text)},
        {title: 'К перечислению', dataIndex: 'for_pay', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Комиссия', dataIndex: 'commission', width: 100, render: (text) => formatCurrency(text)},
        {title: 'Логистика', dataIndex: 'delivery', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Хранение', dataIndex: 'store', width: 100, render: (text) => formatCurrency(text)},
        {title: 'Приемка', dataIndex: 'supply', width: 100, render: (text) => formatCurrency(text)},
        {title: 'Штрафы', dataIndex: 'penalty', width: 100, render: (text) => formatCurrency(text)},
        {title: 'МРК', dataIndex: 'budget', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Другие расходы', dataIndex: 'other_external', width: 80, render: (text) => formatCurrency(text)},
        {title: 'Другие выплаты', dataIndex: 'additional_payment', width: 80, render: (text) => formatCurrency(text)},
        {title: 'Итого к выводу', dataIndex: 'itog', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Себестоимость', dataIndex: 'sebes', width: 120, render: (text) => formatCurrency(text)},
        {title: 'Налог', dataIndex: 'nalog', width: 100, render: (text) => formatCurrency(text)},
        {title: 'МРК Внешний', dataIndex: 'budget_external', width: 80, render: (text) => formatCurrency(text)},
        {title: 'Другие расходы', dataIndex: 'other_services', width: 100, render: (text) => formatCurrency(text)},
        {title: 'Чистая прибыль', dataIndex: 'profit', width: 120, render: (text) => formatCurrency(text)},


    ]
}

const expandData = (cards) => {
    cards.forEach((card) => {
        card['children'] = []
        card.key = card.group_type
        card.date = 'Итого'
        card['children'] = card.by_period
        for (let key in card.summed) {
            card[key] = card.summed[key]
        }
    })
    return cards

};

export default function FinDetailTable({data, loading}) {

    const columns = getColumns()

    let DataSource = data;
    if (data) {
        DataSource = expandData(data);
    }
    return (
        <>
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                scroll={{
                    y: 800,
                }}
                loading={loading}
                pagination={false}
            />
        </>
    );
}