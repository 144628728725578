import { apiAxios } from '../api_axios';
export const getSalesReport = async(wbacc_ids, month) => {
    try {
        return await apiAxios.get('sales-report/get/', { 'wbacc_ids': wbacc_ids, 'month': month })
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};