import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Select, Typography} from 'antd';
import {useAuth} from '../../core/hooks/useAuth';
import {apiAxios} from "../../core/api/api_axios";
import {ButtonExel} from '../../components/simple/buttons/Buttons';
import OborotTable from '../../components/smart/tables/finances/oborotTable';
import {determineNormalDistributionBounds} from "../../components/smart/tables/utils/scripts";

export default function Oborot() {
    const {user} = useAuth();
    const [data, setData] = useState([]);
    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_ids: null,
        wbacc_options: [],
        group: 'nm_id',
        group_options: [{label: 'Без группировки', value: 'nm_id'}, {label: 'Категория', value: 'category'}, {label: 'Сезон', value: 'sezon'},
            {label: 'Модель', value: 'model'}, {label: 'Материал', value: 'material'}, {label: 'Статус', value: 'status'}, {label: 'Цвет', value: 'color'}],
        week: 1,
        week_options: [{label: 'По дням', value: 1}, {label: 'По неделям', value: 2}],
    });

    const [loading, setLoading] = useState(true);
    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const [gradientData, setGradientData1] = useState({})
    const setGradientData = (name, value) => {
        setGradientData1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const [filters_data, setFiltersData] = useState([]);
    const filter_keys = ['orders_per_day', 'buyouts_per_day', 'oborot_orders', 'oborot_buyouts', 'nacenka_unit', 'marzha_unit', 'price_with_discount',
        'ost_quantity', 'ost_way_to', 'ost_way_from', 'ost_sum', 'ost_sebes', 'buyout_percent']

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids});
        let wbaccOptions = []
        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id,})
        }
        setSelectorFilter('wbacc_ids', [user.wbacc_ids[0]]);
        setSelectorFilter('wbacc_options', wbaccOptions);
    }

    const fetchData = async () => {
        setLoading(true);
        console.log('selectorFilter', selectorFilter);
        const fetchedData = await apiAxios.post('sales/oborot/get', {wbacc_ids: selectorFilter.wbacc_ids, group_type: selectorFilter.group});
        console.log('fetchedData', fetchedData);
        setData(fetchedData.data);
        setLoading(false);
        filter_keys.forEach((key) => {
            const allValues = fetchedData.data.map(item => item[key]);

            // Определяем границы на основе нормального распределения
            let bounds = determineNormalDistributionBounds(allValues);
            console.log('bounds', bounds)
            setGradientData(key, bounds)
        })
        setFiltersData(fetchedData.filters);
    };
    useEffect(() => {
        if (!selectorFilter.wbacc_ids && user.wbacc_ids) {
            fetchData1();
        }
        if (selectorFilter.wbacc_ids) {
            fetchData();
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_ids, selectorFilter.group, selectorFilter.week]);

    return (
        <>
            <Card>
                <Typography.Title level={1}>Оборачиваемость</Typography.Title>
                <Row type='flex' style={{gap: 10, alignItems: 'end'}}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Магазины
                        </Typography.Title>
                        <Select
                            style={{width: '100%'}}
                            placeholder="Выберите магазин"
                            value={selectorFilter.wbacc_ids}
                            onChange={(value) => setSelectorFilter('wbacc_ids', [value])}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Группировка
                        </Typography.Title>
                        <Select
                            style={{width: '100%'}}
                            value={selectorFilter.group}
                            onChange={(value) => {
                                console.log('value', value)
                                setSelectorFilter('group', value);
                            }}
                            options={selectorFilter.group_options}
                        />
                    </Col>
                    <ButtonExel data={data}/>
                </Row>
            </Card>
            <Card>
                <OborotTable data={data} loading={loading} gradientData={gradientData} group_type={selectorFilter.group} filters_data={filters_data}/>
            </Card>
        </>
    );
}
