import { apiAxios } from '../api_axios';

export const getEditCards = async(wbacc_id, is_like, is_hide) => {
    try {
        return await apiAxios.get('edit-cards/get/', { 'wbacc_ids': [wbacc_id], 'is_like': is_like, 'is_hide': is_hide, });
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return [];
    }
};

export const updateEditCards = (card) => {
    try {
        const data = {
            'card': card,
        }
        const response = apiAxios.post('edit-cards/update/', data)
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return [];
    }
};

export const updatePositions = (nm_id, positions) => {

    try {
        const data = {
            'nm_id': nm_id,
            'positions': positions,
        }
        const response = apiAxios.post("edit-cards/update/positions/", data);
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return [];
    }
};