import React, {useEffect, useState} from 'react';
import {Card, Col, message, Row, Select, Typography} from 'antd';
import {SelectorSolo} from '../../components/simple/selects';
import {useAuth} from '../../core/hooks/useAuth';
import {getPrices} from '../../core/api/analCards/prices';
import TablePrices from '../../components/smart/tables/analCards/prices';
import axios from 'axios';
import {options_hide, options_like, serverUrl} from '../../core/constants';
import {defaultOnChangeLikeHide, setLikeHideOnLoadData} from "./utils";
import {apiAxios} from '../../core/api/api_axios';
import {ButtonExel, exportToExcel} from '../../components/simple/buttons/Buttons';
import * as XLSX from 'xlsx';


function Prices() {
    const {user, isCsrf} = useAuth();
    const [filters_data, setFiltersData] = useState([]);
    const [cards, setCards] = useState([]);
    const [selectedWbaccs, setSelectedWbaccs] = useState([]);
    const [selected_hide, setSelectedHide] = useState(1);
    const [selected_like, setSelectedLike] = useState(1);
    const [likeHideList, setLikeHideList] = useState({like: [], hide: []});
    const [isOzon, setIsOzon] = useState(true);

    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_id: null,
        wbacc_options: [],
        group: 1,
        group_options: [{ label: 'По дням', value: 1 }, { label: 'По неделям', value: 2 }]
    });
    const [loading, setLoading] = useState(true);

    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids});
        let wbaccOptions = [];
        
        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({ label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id });
        }

        setSelectorFilter('wbacc_id', user.wbacc_ids[0]);
        setSelectorFilter('wbacc_options', wbaccOptions);
    };
    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await getPrices(
                selectorFilter.wbacc_id,
                selected_like,
                selected_hide
            );
            console.log('fetchedData', fetchedData);
            setFiltersData(fetchedData['filters']);
            setCards(fetchedData['cards']);

            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const checkPrices = () => {
        setCards(cards.map((card) => {
            card.load_price = card.new_price;
            let load_discount = 0;
            if (Number(card.load_discount) == 0) {
                load_discount = card.discount;
            } else {
                load_discount = Number(card.load_discount);
            }
            card.load_price_client = Math.floor(card.new_price * (100 - load_discount) / 100)
            return card
        }))
    }

    const loadPrices = async () => {
        try {
            const data = {
                cards: cards.map((card) => {
                    return {
                        nm_id: card.nm_id,
                        price: card.load_price,
                        discount: card.load_discount,
                    };
                }),
                wbacc_id: cards[0].wbacc_id,
            };

            const response = await apiAxios.post('prices/load/', data);

            message.open({
                type: 'success',
                content: 'Цены загружены!',
                style: {
                    marginTop: '5vh',
                },
            });
            return response;
        } catch (err) {
            console.log('err', err);

            message.open({
                type: 'error',
                content: 'Не удалось загрузить цены',
                style: {
                    marginTop: '5vh',
                },
            });

            return [];
        }
    };


    const updatePrices = () => {
        setLoading(true);
        const data = {wbacc_id: cards[0].wbacc_id};
        const response = apiAxios.post('prices/update/', data)
            .then((res) => {
                fetchData();
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    console.log(err.response.data.error);
                } else {
                    console.error('An unexpected error happened:', err);
                }
            });
        return response.data;
    };

    // useEffect(() => {
    //     getCSRF(setIsCsrf);
    //     fetchUser(setSelectedWbaccs);
    //
    // }, []);

    useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1();
        }
        
        if (selectorFilter.wbacc_id) {
            console.log('selectorFilter.wbacc_id', selectorFilter.wbacc_id)
            selectorFilter.wbacc_id > 0 ? setIsOzon(false): setIsOzon(true)
            fetchData();
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_id, selected_hide, selected_like]);
    return (
        <>
            <Card>
                <Typography.Title level={1}>Ценообразование</Typography.Title>
                <Row type='flex' style={{gap: 10, alignItems: 'end'}}>
                    {/* <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Поставщики
                        </Typography.Title>
                        <SelectorSolo
                            options={filters_data.wbaccs}
                            selected={selectedWbaccs}
                            setSelected={setSelectedWbaccs}
                        />
                    </Col> */}
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Магазины</Typography.Title>
                        <Select
                            style={{ width:'100%' }}
                            placeholder="Выберите магазин"
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>

                            Избранное
                        </Typography.Title>
                        <SelectorSolo
                            options={options_like}
                            selected={selected_like}
                            setSelected={setSelectedLike}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Скрытые товары
                        </Typography.Title>
                        <SelectorSolo
                            options={options_hide}
                            selected={selected_hide}
                            setSelected={setSelectedHide}
                        />
                    </Col>
                    <ButtonExel data={cards}/>
                </Row>
            </Card>
            <Card>
                <TablePrices
                    cards={cards}
                    setCards={setCards}
                    filters={filters_data}
                    likeHideList={likeHideList}
                    onChangeLikeHide={onChangeLikeHide}
                    loading={loading}
                    checkPrices={checkPrices}
                    loadPrices={loadPrices}
                    updatePrices={updatePrices}
                    isOzon={isOzon}
                />
            </Card>
        </>
    );
}

export default Prices;
