import React, {useEffect, useState} from "react";
import {Card, Col, Row, Typography} from "antd";
import {SelectorSolo} from "../../components/simple/selects";
import {useAuth} from "../../core/hooks/useAuth";
import {options_hide, options_like} from "../../core/constants";
import {getEditCards, updateEditCards, updatePositions} from "../../core/api/settings/edit_cards";
import TableEditCards from "../../components/smart/tables/settings/editCards";
import {defaultOnChangeLikeHide, setLikeHideOnLoadData} from "../analCards/utils";


export default function EditCards() {

    const {user, isCsrf} = useAuth()
    const [filters_data, setFiltersData] = useState([])
    const [cards, setCards] = useState([])
    const [selectedWbaccs, setSelectedWbaccs] = useState([])
    const [selected_hide, setSelectedHide] = useState(1)
    const [selected_like, setSelectedLike] = useState(1)
    const [likeHideList, setLikeHideList] = useState({like: [], hide: []});
    const [isAdmin, setIsAdmin] = useState(false)
    const [loading, setLoading] = useState(true);
    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const onEditCard = (card) => {
        updateEditCards(card, isCsrf);
    }

    const savePositions = (nm_id, positions) => {
        updatePositions(nm_id, positions, isCsrf);
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await getEditCards(selectedWbaccs, selected_like, selected_hide);
            console.log('fetchedData', fetchedData);
            setFiltersData(fetchedData['filters']);
            setCards(fetchedData['cards']);
            setIsAdmin(fetchedData['is_admin']);
            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : [])
    }, [user.wbacc_ids]);

    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData();
        }
    }, [selected_hide, selected_like, selectedWbaccs]);


    return (
        <>
            <Card>
                <Row type="flex" style={{gap:'10px'}}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Поставщики</Typography.Title>
                        <SelectorSolo options={filters_data?.wbaccs} selected={selectedWbaccs} setSelected={setSelectedWbaccs}/>
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Избранное</Typography.Title>
                        <SelectorSolo options={options_like} selected={selected_like} setSelected={setSelectedLike}/>
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Скрытые товары</Typography.Title>
                        <SelectorSolo options={options_hide} selected={selected_hide} setSelected={setSelectedHide}/>
                    </Col>
                </Row>
            </Card>
            <Card>
                <TableEditCards 
                    cards={cards} filters={filters_data} likeHideList={likeHideList} onChangeLikeHide={onChangeLikeHide} 
                    loading={loading} setCards={setCards} onEditCard={onEditCard} savePositions={savePositions} isAdmin={isAdmin}
                />
            </Card>
        </>
    );
}
