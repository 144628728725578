import {formatCurrency, formatDays, formatPercent, formatQuantity} from "../../../../core/utils/formats";
import {EyeOutlined, HeartOutlined} from "@ant-design/icons";
import {Checkbox, Switch, Table} from "antd";
import React, {useState} from "react";


const getColumns = (filters_data, likeHideList, onChangeLikeHide, isHideColumns) => {
    const isMobile = window.innerWidth <= 769;
    return [
        {title: '', dataIndex: '', width: 20, fixed: isMobile ? '': 'left',},
        // {title: <HeartOutlined/>, width: 21, render: (text, record) => (<Checkbox checked={likeHideList['like'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'like')}/>), hidden: isHideColumns,},
        // {title: <EyeOutlined/>, width: 21, render: (text, record) => (<Checkbox checked={likeHideList['hide'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'hide')}/>), hidden: isHideColumns,},
        {title: '', dataIndex: 'img', width: 20, fixed: isMobile ? '': 'left', render: (text) => <img src={text} style={{'height': '20px'}}></img>,},
        {title: ' ', dataIndex: 'type', width: 70, fixed: isMobile ? '': 'left', ellipsis: true,},
        {title: 'Кабинет', dataIndex: 'wbacc_name', fixed: isMobile ? '': 'left', width: 100, ellipsis: true, hidden: isHideColumns,},
        {
            title: 'Артикул продавца', dataIndex: 'article', fixed: isMobile ? '': 'left', width: 80, render: (text) => <a href={`product/${text}`}>{text}</a>, hidden: isHideColumns,
            filters: filters_data?.article, filterSearch: true, onFilter: (value, record) => record.article == value || record.is_fix,
        },
        {title: 'Артикул', dataIndex: 'nm_id', width: 70, ellipsis: true},
        //{title: 'Карточка', dataIndex: 'imt_id', width: 80, filters: filters_data['imt_ids'], filterSearch: true, onFilter: (value, record) => record.imt_id == value || record.is_fix,},
        {title: 'Категория', dataIndex: 'category', key: 'category', width: 70, ellipsis: true, fixed: isMobile ? '': 'left', filters: filters_data?.category, filterSearch: true, onFilter: (value, record) => record.category == value || record.is_fix,},
        {title: 'Модель', dataIndex: 'material', width: 70, filters: filters_data?.material, ellipsis: true, fixed: isMobile ? '': 'left', filterSearch: true, onFilter: (value, record) => record.material?.indexOf(value) === 0 || record.is_fix,},
        {title: 'Цвет', dataIndex: 'color', width: 70, ellipsis: true, filters: filters_data?.color, filterSearch: true, onFilter: (value, record) => record.color?.indexOf(value) === 0},
        {title: 'Размер', dataIndex: 'size_name', width: 40, fixed: isMobile ? '': 'left',},
        //{title: 'Баркод', dataIndex: 'barcode', width: 70,},
        {title: 'Рейтинг', dataIndex: 'rating', width: 30,},
        {title: 'Деньги в товаре', dataIndex: 'ost_sum', width: 100, sorter: (a, b) => a.ost_sum - b.ost_sum, render: (text) => formatCurrency(text), className: 'blue-color',},
        {title: 'ABC', dataIndex: 'abc', width: 50, filters: filters_data?.abc, filterSearch: true, onFilter: (value, record) => record.abc?.indexOf(value) === 0,},
        {title: 'Сезон', dataIndex: 'sezon', width: 60, ellipsis: true, filters: filters_data?.sezon, filterSearch: true, onFilter: (value, record) => record.sezon?.indexOf(value) === 0,},
        {title: 'Статус', dataIndex: 'status', width: 60, ellipsis: true, filters: filters_data?.status, filterSearch: true, onFilter: (value, record) => record.status?.indexOf(value) === 0,},
        {
            title: 'План', className: 'yellow-color',
            children: [
                {title: 'Шт', dataIndex: 'plan_orders', width: 50, className: 'yellow-color', render: (text) => formatQuantity(text),},
                {title: 'Шт в день', dataIndex: 'plan_orders_per_day', width: 50, className: 'yellow-color', render: (text) => formatQuantity(text),},
            ]
        },
        {
            title: 'Показатели', className: 'yellow-color',
            children: [
                {title: 'Заказы в день', dataIndex: 'orders_per_day', width: 60, className: 'yellow-color', render: (text) => formatQuantity(text),},
                {title: 'Выкупы в день', dataIndex: 'buyouts_per_day', width: 60, className: 'yellow-color', render: (text) => formatQuantity(text),},
                {title: 'Выкуп', dataIndex: 'buyout_percent', width: 50, className: 'yellow-color', render: (text) => formatPercent(text)},
                {title: 'Оборачиваемость', dataIndex: 'oborot_orders', width: 60, className: 'yellow-color', render: (text) => formatDays(text),},
                {title: 'Запас дней', dataIndex: 'oborot_buyouts', width: 60, className: 'yellow-color', render: (text) => formatDays(text),},
                {title: 'Обор. себес', dataIndex: 'oborot_sebes', width: 60, className: 'yellow-color', render: (text) => formatDays(text),},

            ]
        },
        {
            title: 'Цена', className: 'green-color',
            children: [
                {title: 'Цена', dataIndex: 'price_with_discount', width: 50, className: 'green-color', render: (text) => formatCurrency(text)},
                // {title: 'Скидка', dataIndex: 'discount', width: 30, className: 'green-color', render: (text) => formatPercent(text)},
                // {title: 'Цена со скидкой', dataIndex: 'price_with_discount', width: 50, className: 'green-color', render: (text) => formatCurrency(text)},
                // {title: 'Спп', dataIndex: 'spp', width: 45, className: 'green-color', render: (text) => formatPercent(text)},
                // {title: 'Цена с спп', dataIndex: 'price_client', width: 50, className: 'green-color', render: (text) => formatCurrency(text)},
                // {title: 'Цена с кошельком', dataIndex: 'price_with_wb_discount', width: 50, className: 'green-color', render: (text) => formatCurrency(text)},
                {title: 'Наценка', dataIndex: 'nacenka_unit', width: 50, className: 'green-color', render: (text) => formatPercent(text)},
                {title: 'Маржа', dataIndex: 'marzha_unit', width: 50, className: 'green-color', render: (text) => formatPercent(text)},
                {title: 'Прибыль', dataIndex: 'profit_unit', width: 50, className: 'green-color', render: (text) => formatCurrency(text)},
            ]
        },
        {
            title: 'Себестоимость',
            className: 'red-color',
            children: [
                {title: 'Себес', dataIndex: 'sebes', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                {title: 'Комиссия %', dataIndex: 'commission', width: 50, className: 'red-color', render: (text) => formatPercent(text),},
                {title: 'Хранение', dataIndex: 'store_cost_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                {title: 'Логистика', dataIndex: 'delivery_cost_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                {title: 'Приемка', dataIndex: 'supply_cost_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                {title: 'Маркетинг', dataIndex: 'budget_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                {title: 'Налог', dataIndex: 'nalog_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                {title: 'Другие расходы', dataIndex: 'other_services_cost_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                {title: 'Себ-ть ИТОГО', dataIndex: 'sebes_itog', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                {title: 'Расходы с комиссией', dataIndex: 'sebes_itog_with_commission', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
            ],
        },

        {
            title: 'Реклама', className: 'pink-color',
            children: [
                {title: 'Бюджет', dataIndex: 'budget', width: 60, className: 'pink-color', render: (text) => formatCurrency(text)},
                {title: 'Просмотры', dataIndex: 'views', width: 40, className: 'pink-color'},
                {title: 'Клики', dataIndex: 'clicks', width: 40, className: 'pink-color'},
                {title: 'Корзина', dataIndex: 'add_to_cart', width: 40, className: 'pink-color'},
                {title: 'Заказы', dataIndex: 'adv_orders', width: 40, className: 'pink-color'},
                {title: 'CTR', dataIndex: 'ctr', width: 45, className: 'pink-color', render: (text) => formatPercent(text)},
                {title: 'CPC', dataIndex: 'cpc', width: 45, className: 'pink-color', render: (text) => formatCurrency(text)},
                {title: 'ДРР', dataIndex: 'drr', width: 45, className: 'pink-color', render: (text) => formatPercent(text)},
            ]
        },


        {
            title: 'Остатки МС', className: 'green-color',
            children: [
                {title: 'Москва', dataIndex: 'ms_ost_msk', width: 70, className: 'green-color', render: (text) => formatQuantity(text),},
                {title: 'Бишкек', dataIndex: 'ms_ost_bishkek', width: 70, className: 'green-color', render: (text) => formatQuantity(text),},
                {title: 'Транзит', dataIndex: 'ms_transfer', width: 70, className: 'green-color', render: (text) => formatQuantity(text),},
                {title: 'Шьется', dataIndex: 'ms_production', width: 70, className: 'green-color', render: (text) => formatQuantity(text),},
            ]
        },

        {
            title: 'Остатки FBO', className: 'pink-color',
            children: [
                {title: 'Остатки', dataIndex: 'ost', width: 55, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'В пути до клиента', dataIndex: 'way_to', width: 55, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'В пути от клиента', dataIndex: 'way_from', width: 55, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'Остатки итого', dataIndex: 'full', width: 55, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'Продажи 14 дней средние', dataIndex: 'orders_per_day', width: 60, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'Оборачиваемость', dataIndex: 'oborot_orders', width: 60, className: 'pink-color', render: (text) => formatDays(text),},
                {title: 'Запас дней', dataIndex: 'oborot_buyouts', width: 60, className: 'pink-color', render: (text) => formatDays(text),},
                {title: 'Обор. себес', dataIndex: 'oborot_sebes', width: 60, className: 'pink-color', render: (text) => formatDays(text),},
            ]
        },
    ]
}


const expandData = (cards) => {
    return cards.map((card) => {
        let by_cabinet = {
            type: 'По кабинетам', key: card.article + '_1', children: [],
            category: card.category,
            material: card.material,
            color: card.color,
            abc: card.abc,
            sezon: card.sezon,
            status: card.status,
            article: card.article,
        }
        let by_size = {
            type: 'По размерам', key: card.article + '_2', children: [],
            category: card.category,
            material: card.material,
            color: card.color,
            abc: card.abc,
            sezon: card.sezon,
            status: card.status,
            article: card.article,
        }
        card.by_cabinet.forEach((item) => {
            item.category = card.category;
            item.material = card.material;
            item.color = card.color;
            item.abc = card.abc;
            item.sezon = card.sezon;
            item.status = card.status;
            by_cabinet['children'].push(item)
        })
        card.by_variants.forEach((item) => {
            item.category = card.category;
            item.material = card.material;
            item.color = card.color;
            item.abc = card.abc;
            item.sezon = card.sezon;
            item.status = card.status;
            by_size['children'].push(item);
        })
        card['children'] = [by_cabinet, by_size]
        return card
    });
};


export default function TableStatGeneral(args) {
    const [isHideColumns, setHideColumns] = useState(false)
    let columns = getColumns(args.filters, args.likeHideList, args.onChangeLikeHide, isHideColumns);

    let DataSource = args.cards
    if (args.cards) {
        DataSource = expandData(args.cards)
    }

    return <>

        <Table
            columns={columns}
            dataSource={DataSource}
            bordered
            size={'small'}
            scroll={{
                y: 800,
            }}
            rowClassName={(record, index) => `my-custom-row ${record.is_ms ? 'border_top' : ''}`}
            loading={args.loading}
            pagination={{'defaultPageSize': 30, 'showTotal': (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`}}

        />
    </>
}