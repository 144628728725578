import React, {useEffect, useState} from 'react'
import {Card, Col, Row, Select, Tooltip, Typography, Space} from 'antd'
import {useAuth} from '../../hooks/useAuth'
import {apiAxios} from "../../utils/api_axios";
import { useSidebar } from '../../Context/SideBarContext'
import Sidebar from '../home/sideBar/SideBar'
import {ShopOutlined, QuestionCircleOutlined} from '@ant-design/icons'
import SideBarPlanFact from "../home/sideBar/SideBarPlanFact";
import dayjs from "dayjs";
import DatePickerRange from "../../components/dateRange";
import {PlanFactTable3} from "./components/PlanFactTableV3";
import {determineNormalDistributionBounds} from "../../components/Gradient";


export default function PlanFactV3() {
    const {user} = useAuth()
    const [filters_data, setFiltersData] = useState([])
    const [cards, setCards] = useState([])
    const [sum_stat, setSumStat] = useState([])
    const [selected_hide, setSelectedHide] = useState(2)
    const [selected_like, setSelectedLike] = useState(1)
    const [likeHideList, setLikeHideList] = useState({like: [], hide: []})
    const startDate = dayjs().date() == 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month')
    const [period, setPeriod] = useState([startDate, dayjs().add(-1, 'd')])
    const [isOzon, setIsOzon] = useState(true)
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('1')

    const [expenses, setExpenses] = useState([])

    const {openSidebar} = useSidebar()

    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_id: null,
        wbacc_options: [],
    })

    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const [gradientData, setGradientData1] = useState({})
    const setGradientData = (name, value) => {
        setGradientData1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const filter_keys = ['ost', 'way_to', 'way_from', 'full', 'ms_ost', 'orders_sum', 'orders_per_day', 'orders', 'oborot_orders', 'marzha']
    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids})
        let wbaccOptions = []

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id})
        }

        setSelectorFilter('wbacc_id', user.wbacc_ids[0])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const handleOpenSidebar = () => {
        openSidebar(<SideBarPlanFact/>);
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await apiAxios.get('plan-fact/get/', {
            'wbacc_ids': [selectorFilter.wbacc_id],
            'is_like': 1,
            'is_hide': 2,
            'st': period[0].format('YYYY-MM-DD'),
            'en': period[1].format('YYYY-MM-DD'),
        });
            setFiltersData(fetchedData['filters']);
            setCards(fetchedData['cards']);
            filter_keys.forEach((key) => {
                const allValues = fetchedData['cards'].map((item) => item[key])

                // Определяем границы на основе нормального распределения
                let bounds = determineNormalDistributionBounds(allValues)
                setGradientData(key, bounds)
            })
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1()
        }

        if (selectorFilter.wbacc_id) {
            selectorFilter.wbacc_id > 0 ? setIsOzon(false) : setIsOzon(true)
            fetchData()
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_id, selected_hide, selected_like, period])


    return (
        <div className="pricing-container">
            {/* Header Section */}
            <Card className="mb-4">
                <Row gutter={[16, 16]} align="middle">
                    <Col xs={24} lg={12}>
                        <Space align="center">
                            <Typography.Title level={2}>План-факт</Typography.Title>
                            <Tooltip title="Информация">
                                <QuestionCircleOutlined
                                    onClick={handleOpenSidebar}
                                    style={{
                                        fontSize: '24px',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s',
                                    }}
                                    className="hover:rotate-180 active:scale-90"
                                />
                            </Tooltip>
                        </Space>
                    </Col>

                </Row>

                {/* Filters Section */}
                <Row gutter={[16, 16]} className="mt-4">

                    <Col xs={24} lg={3}>
                        <Typography.Title level={5}>
                            <ShopOutlined/> Магазины
                        </Typography.Title>
                        <Select
                            style={{width: '100%'}}
                            placeholder="Выберите магазин"
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Col xs={24} lg={3}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                </Row>
            </Card>

            <Card>
                <PlanFactTable3
                    cards={cards}
                    setCards={setCards}
                    filters={filters_data}
                    likeHideList={likeHideList}
                    loading={loading}
                    isOzon={isOzon}
                    gradientData={gradientData}
                />
            </Card>

            <Sidebar/>
        </div>
    )
}
