import axios from "axios";
import { useAuth } from "../../hooks/useAuth";
import { serverUrl } from "../../constants";
import { apiAxios } from "../api_axios";

axios.defaults.xsrfCookieName = 'csrftoken'; // Имя куки, где хранится CSRF-токен
axios.defaults.xsrfHeaderName = 'X-CSRFToken'; // Имя заголовка, в который будет помещен токен

const isResponseOk = (res) => {
    if (!(res.status >= 200 && res.status <= 299)) {
        throw Error(res.statusText);
    }
}
export const getCSRF = (setIsCsrf) => {
    axios.get(serverUrl + 'user/csrf/', { withCredentials: true })
        .then((res) => {
            isResponseOk(res)
            const csrfToken = res.headers.get('X-CSRFToken')
            console.log(csrfToken)
            setIsCsrf(csrfToken)
        })
        .catch((err) => console.error(err))
}
export const getCSRFasync = async(setIsCsrf) => {
    const res = await axios.get(serverUrl + 'user/csrf/', { withCredentials: true })
        .then((res) => {
            isResponseOk(res)
            const csrfToken = res.headers.get('X-CSRFToken')
            console.log(csrfToken)
            setIsCsrf(csrfToken)
        })
        .catch((err) => console.error(err))
    return res
}

export const getUserInfo = async() => {
    return await apiAxios.get('user/user-info/', {})

}