import React, { useEffect, useState, useCallback } from 'react'
import { Card, Col, Row, Typography } from 'antd'
import { SelectorMulti, SelectorSolo } from '../../components/Selectors'
import { useAuth } from '../../hooks/useAuth'
import { options_hide, options_like } from '../../utils'
import { defaultOnChangeLikeHide } from '../components/LikeHide'
import CatalogTable from './components/catalogTable'
import { ButtonExel } from '../../components/Buttons'
import { apiAxios } from "../../utils/api_axios";

export const Catalog = () => {
    const { user, isCsrf } = useAuth()
    const [filters_data, setFiltersData] = useState([])
    const [cards, setCards] = useState([])
    const [selectedWbaccs, setSelectedWbaccs] = useState([])
    const [selected_hide, setSelectedHide] = useState(1)
    const [optionsWbaccs, setOptionsWbaccs] = useState([])
    const [selected_like, setSelectedLike] = useState(1)
    const [likeHideList, setLikeHideList] = useState({ like: [], hide: [] })
    const [loading, setLoading] = useState(true)

    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const fetchData = useCallback(async () => {
        try {
            setLoading(true)
            const fetchedData = await await apiAxios.get('settings/catalog/get/', {
                'wbacc_ids': selectedWbaccs,
                is_like: selected_like,
                is_hide: selected_hide,
            })
            setFiltersData(fetchedData['filters'])
            setOptionsWbaccs(fetchedData['selectors']?.wbaccs)
            setCards(fetchedData['data'])
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }, [selectedWbaccs, selected_like, selected_hide])

    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids)
    }, [user.wbacc_ids])

    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData()
        }
    }, [fetchData])

    return (
        <>
            <Card>
                <Typography.Title level={1}>Справочник</Typography.Title>
                <Row type='flex' style={{ gap: 10 }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Поставщики</Typography.Title>
                        <SelectorMulti options={optionsWbaccs} selected={selectedWbaccs} setSelected={setSelectedWbaccs} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Избранное</Typography.Title>
                        <SelectorSolo options={options_like} selected={selected_like} setSelected={setSelectedLike} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Скрытые товары</Typography.Title>
                        <SelectorSolo options={options_hide} selected={selected_hide} setSelected={setSelectedHide} />
                    </Col>
                    <div className='pt-7'>
                        <ButtonExel data={cards} />
                    </div>
                </Row>
            </Card>
            <Card>
                <CatalogTable cards={cards} filters={filters_data} likeHideList={likeHideList} onChangeLikeHide={onChangeLikeHide} loading={loading} />
            </Card>
        </>
    )
}
