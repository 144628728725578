import React, {useState} from 'react';
import {Table, Spin, Switch} from 'antd';
import './index.css'
import { formatCurrency, formatPercent } from '../../../../core/utils/formats';
export default function TableDetails({cards, loading, filters, likeHideList, onChangeLikeHide, period, dateColumns}) {
    const expandedRowRender = (record) => {
        const byAdverts = record.marketing.by_adverts;

        const byAdvertsColumns = [
            {title: 'Тип',className:'yellow-color', render:() => {
                return <div>Реклама</div>
            }},
            {title: 'Id', dataIndex: 'id', key: 'id', ellipsis:true},
            {title: 'Название', dataIndex: 'name', key: 'name', ellipsis: true},
            {title: 'Расход', dataIndex:  'budget', key: 'budget', render: (text) => formatCurrency(text)},
            {title: 'Показы', dataIndex:  'views', key: 'views'},
            {title: 'Клики', dataIndex:  'clicks', key: 'clicks'},
            {title: 'Корзина', dataIndex:  'add_to_cart', key: 'add_to_cart'},
            {title: 'Заказы', dataIndex:  'orders', key: 'orders'},            
            {title: 'Сумма заказов', dataIndex:  'orders_sum', key: 'orders_sum', render: (text) => formatCurrency(text)},
            {title: 'Процент в корзину', dataIndex:  'add_to_cart_percent', key: 'add_to_cart_percent',ellipsis: true, render: (text) => formatPercent(text)},
            {title: 'Стоимость корзины', dataIndex:  'add_to_cart_cost', key: 'add_to_cart_cost',ellipsis: true, render: (text) => formatCurrency(text)}, 
            {title: 'Ставка', dataIndex:  'cpm', key: 'cpm', render: (text) => formatCurrency(text)},
            {title: 'Стоимость заказа', dataIndex:  'cpo', key: 'cpo',ellipsis: true, render: (text) => formatCurrency(text)},
            {title: 'CTR', dataIndex:  'ctr', key: 'ctr', render: (text) => formatPercent(text)},
            {title: 'CPC', dataIndex:  'cpc', key: 'cpc', render: (text) => formatCurrency(text)},
            {title: 'ДРР', dataIndex:  'drr', key: 'drr', render: (text) => formatPercent(text)},
            {title: 'ROMI', dataIndex:  'romi', key: 'romi'},
        ];

        const byAdvertsData = Object.values(byAdverts || {}).map(advert => ({
            key: advert.id,
            id: advert.id,
            cpc: advert.cpc,
            cpm: advert.cpm,
            cpo: advert.cpo,
            orders_sum: advert.orders_sum,
            budget: advert.budget,
            clicks: advert.clicks,
            ctr: advert.ctr,
            drr: advert.drr,
            romi: advert.romi,
            views: advert.views,
            add_to_cart:advert.add_to_cart,
            orders:advert.orders,
            add_to_cart_percent:advert.add_to_cart_percent,
            add_to_cart_cost:advert.add_to_cart_cost,
        }));

        // Получаем уникальные даты из by_days
        const byDays = record.marketing.by_days || {};
        const uniqueDates = Object.keys(byDays);

        return (
            <Table
                className='TableDetails'
                columns={byAdvertsColumns}
                size={'small'}
                dataSource={byAdvertsData}
                pagination={false}
                showHeader={false}
                bordered
                loading={loading}
                rowClassName={(record, index) =>
                    `my-custom-row `
                }
                expandable={{
    expandedRowRender: () => {
        // First, define the dates as rows
        const byDaysRows = uniqueDates.map(date => {
            const dayData = byDays[date] || {};
            return {
                key: date,
                date: date,
                budget: dayData['budget'] !== undefined ? dayData['budget'] : '-',
                views: dayData['views'] !== undefined ? dayData['views'] : '-',
                clicks: dayData['clicks'] !== undefined ? dayData['clicks'] : '-',
                add_to_cart: dayData['add_to_cart'] !== undefined ? dayData['add_to_cart'] : '-',
                orders: dayData['orders'] !== undefined ? dayData['orders'] : '-',
                orders_sum: dayData['orders_sum'] !== undefined ? dayData['orders_sum'] : '-',
                add_to_cart_percent: dayData['add_to_cart_percent'] !== undefined ? dayData['add_to_cart_percent'] : '-',
                add_to_cart_cost: dayData['add_to_cart_cost'] !== undefined ? dayData['add_to_cart_cost'] : '-',
                cpm: dayData['cpm'] !== undefined ? dayData['cpm'] : '-',
                cpo: dayData['cpo'] !== undefined ? dayData['cpo'] : '-',
                ctr: dayData['ctr'] !== undefined ? dayData['ctr'] : '-',
                cpc: dayData['cpc'] !== undefined ? dayData['cpc'] : '-',
                drr: dayData['drr'] !== undefined ? dayData['drr'] : '-',
                romi: dayData['romi'] !== undefined ? dayData['romi'] : '-',
            };
        });

        // Now, define the columns for each metric
        const byMetricsColumns = [
            { title: 'Дата', dataIndex: 'date', key: 'date', width: 0, fixed: 'left' },
            { title: 'Расход', dataIndex: 'budget', key: 'budget', render: (text) => formatCurrency(text) },
            { title: 'Показы', dataIndex: 'views', key: 'views' },
            { title: 'Клики', dataIndex: 'clicks', key: 'clicks' },
            { title: 'Корзина', dataIndex: 'add_to_cart', key: 'add_to_cart' },
            { title: 'Заказы', dataIndex: 'orders', key: 'orders' },
            { title: 'Сумма заказов', dataIndex: 'orders_sum', key: 'orders_sum', render: (text) => formatCurrency(text) },
            { title: 'Процент в корзину', dataIndex: 'add_to_cart_percent', key: 'add_to_cart_percent', render: (text) => formatPercent(text) },
            { title: 'Стоимость корзины', dataIndex: 'add_to_cart_cost', key: 'add_to_cart_cost', render: (text) => formatCurrency(text) },
            { title: 'Ставка', dataIndex: 'cpm', key: 'cpm', render: (text) => formatCurrency(text) },
            { title: 'Стоимость заказа', dataIndex: 'cpo', key: 'cpo', render: (text) => formatCurrency(text) },
            { title: 'CTR', dataIndex: 'ctr', key: 'ctr', render: (text) => formatPercent(text) },
            { title: 'CPC', dataIndex: 'cpc', key: 'cpc', render: (text) => formatCurrency(text) },
            { title: 'ДРР', dataIndex: 'drr', key: 'drr', render: (text) => formatPercent(text) },
            { title: 'ROMI', dataIndex: 'romi', key: 'romi' },
        ];

        return (
            <Table
                className='TableDetails2'
                size={'small'}
                scroll={{ y: 800 }}
                columns={byMetricsColumns}
                dataSource={byDaysRows}
                pagination={false}
                rowClassName={(record, index) => `my-custom-row`}
            />
        );
    }}
                }
            />
        );
    };


    const getColumns = (
        filters_data,
        likeHideList,
        onChangeLikeHide,
        period,
        dateColumns
    ) => {
        const columns = [
            {title: 'Тип', render:() => {
                return <div>Артикул</div>
            }},
            {title: 'Id', dataIndex: 'nm_id', key: 'nm_id'},
            {title: 'Название', dataIndex: 'name', key: 'name', ellipsis: true, filters: filters_data?.table_subjects, filterSearch: true, onFilter: (value, record) => record.subject_id == value || record.is_fix,},
            {title: 'Расход', dataIndex: ['marketing', 'budget'], key: 'budget', render: (text) => formatCurrency(text)},
            {title: 'Показы', dataIndex: ['marketing', 'views'], key: 'views'},
            {title: 'Клики', dataIndex: ['marketing', 'clicks'], key: 'clicks'},
            {title: 'Корзина', dataIndex: ['marketing', 'add_to_cart'], key: 'add_to_cart'},
            {title: 'Заказы', dataIndex: ['marketing', 'orders'], key: 'orders'},            
            {title: 'Сумма заказов', dataIndex: ['marketing', 'orders_sum'], key: 'orders_sum', render: (text) => formatCurrency(text)},
            {title: 'Процент в корзину', dataIndex: ['marketing', 'add_to_cart_percent'], key: 'add_to_cart_percent',ellipsis: true, render: (text) => formatPercent(text)},
            {title: 'Стоимость корзины', dataIndex: ['marketing', 'add_to_cart_cost'], key: 'add_to_cart_cost',ellipsis: true, render: (text) => formatCurrency(text)}, 
            {title: 'Ставка', dataIndex: ['marketing', 'cpm'], key: 'cpm', render: (text) => formatCurrency(text)},
            {title: 'Стоимость заказа', dataIndex: ['marketing', 'cpo'], key: 'cpo',ellipsis: true, render: (text) => formatCurrency(text)},
            {title: 'CTR', dataIndex: ['marketing', 'ctr'], key: 'ctr', render: (text) => formatPercent(text)},
            {title: 'CPC', dataIndex: ['marketing', 'cpc'], key: 'cpc', render: (text) => formatCurrency(text)},
            {title: 'ДРР', dataIndex: ['marketing', 'drr'], key: 'drr', render: (text) => formatPercent(text)},
            {title: 'ROMI', dataIndex: ['marketing', 'romi'], key: 'romi'},

        ];
        return columns
    }

    const [isHideColumns, setHideColumns] = useState(false);

    let columns = getColumns(
        filters,
        likeHideList,
        onChangeLikeHide,
        period,
        dateColumns,
        isHideColumns
    );

    // Render loading state or table
    if (loading) {
        return <Spin/>;
    }

    // Handle case when there are no cards
    if (!cards || cards.length === 0) {
        return <div>No data available</div>;
    }

    return (
        <>
            <Switch
                style={{marginBottom: '5px'}}
                checkedChildren='Скрыть'
                unCheckedChildren='Скрыть'
                checked={isHideColumns}
                onChange={() => setHideColumns(!isHideColumns)}/>
            <Table
                columns={columns}
                expandable={{expandedRowRender, defaultExpandedRowKeys: []}}
                sticky
                size={'small'}
                scroll={{y: 800}}
                dataSource={cards}
                rowKey="nm_id"
                pagination={{defaultPageSize: 30, showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`}}
                rowClassName={(record, index) =>
                    `my-custom-row `
                }/>

        </>
    );
}
