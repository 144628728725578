import {EyeOutlined, HeartOutlined} from "@ant-design/icons";
import {Checkbox, Table} from "antd";
import {formatCurrency, formatDays, formatPercent, formatQuantity} from "../../../../core/utils/formats";
import React from "react";


const getColumns = () => {

    return [

        {title: 'Поставщик', dataIndex: 'wbacc_name', width: 120, fixed: 'left', ellipsis: true,},
        {title: 'Дата', dataIndex: 'date', width: 100, fixed: 'left',},

        {
            title: 'Остатки',
            children: [
                {title: 'Остатки', dataIndex: 'ost', width: 80, render: (text) => formatQuantity(text),},
                {title: 'В пути до клиента', dataIndex: 'way_to', width: 80, render: (text) => formatQuantity(text),},
                {title: 'В пути от клиента', dataIndex: 'way_from', width: 80, render: (text) => formatQuantity(text),},
                {title: 'Остатки итого', dataIndex: 'full', width: 80, render: (text) => formatQuantity(text),},
                {title: 'Деньги в товаре', dataIndex: 'ost_sum', width: 100, render: (text) => formatCurrency(text),},
                {title: 'Оборачиваемость', dataIndex: 'oborot_orders', width: 60, render: (text) => formatDays(text),},
                {title: 'Запас дней', dataIndex: 'oborot_buyouts', width: 60, render: (text) => formatDays(text),},
                {title: 'Оборачиваемость по себестоимости', dataIndex: 'oborot_sebes', width: 80, render: (text) => formatDays(text),},


            ]
        },
        {
            title: 'Финансы',
            children: [
                {title: 'Сумма заказов', dataIndex: 'orders_sum', width: 100, render: (text) => formatCurrency(text)},
                {title: 'Сумма выкупов', dataIndex: 'buyouts_sum', width: 100, render: (text) => formatCurrency(text)},
                {title: 'Сумма возвратов', dataIndex: 'returns_sum', width: 80, render: (text) => formatCurrency(text)},
                {title: 'МРК', dataIndex: 'budget', width: 80, render: (text) => formatCurrency(text)},
                {title: 'Себестоимость выкупов', dataIndex: 'sebes_buyouts', width: 100, render: (text) => formatCurrency(text)},
                {title: 'Хранение', dataIndex: 'store_cost', width: 80, render: (text) => formatCurrency(text)},
                {title: 'Приемка', dataIndex: 'supply_cost', width: 100, render: (text) => formatCurrency(text)},
                {title: 'ФФ', dataIndex: 'ff_cost', width: 80, render: (text) => formatCurrency(text)},
                {title: 'Логистика', dataIndex: 'delivery_cost', width: 80, render: (text) => formatCurrency(text)},
                {title: 'Налог', dataIndex: 'nalog', width: 80, render: (text) => formatCurrency(text)},
                {title: 'Штрафы', dataIndex: 'penalty', width: 80, render: (text) => formatCurrency(text)},
                {title: 'Другие расходы', dataIndex: 'other_services_cost', width: 80, render: (text) => formatCurrency(text)},
                {title: 'Чистая прибыль', dataIndex: 'profit', width: 100, render: (text) => formatCurrency(text)},

            ],
        },
        {
            title: 'Показатели',
            children: [

                {title: 'Заказы', dataIndex: 'orders', width: 80, render: (text) => formatQuantity(text),},
                {title: 'Выкупы', dataIndex: 'buyouts', width: 80, render: (text) => formatQuantity(text),},
                {title: 'Отмены', dataIndex: 'cancels', width: 80, render: (text) => formatQuantity(text),},
                {title: 'Возвраты', dataIndex: 'returns', width: 80, render: (text) => formatQuantity(text),},

                {title: 'Заказы в день', dataIndex: 'orders_per_day', width: 80, render: (text) => formatQuantity(text),},
                {title: 'Выкупы в день', dataIndex: 'buyouts_per_day', width: 80, render: (text) => formatQuantity(text),},
                {title: 'Выкуп', dataIndex: 'buyout_percent', width: 80, render: (text) => formatPercent(text)},
                {title: 'Маржинальность', dataIndex: 'marzha_unit', width: 80, render: (text) => formatPercent(text),},
                {title: 'Наценка', dataIndex: 'nacenka_unit', width: 80, render: (text) => formatPercent(text),},
            ]
        },

        {
            title: 'Реклама',
            children: [
                {title: 'Бюджет', dataIndex: 'budget', width: 100, render: (text) => formatCurrency(text)},
                {title: 'Просмотры', dataIndex: 'views', width: 80,},
                {title: 'Клики', dataIndex: 'clicks', width: 80,},
                {title: 'Корзина', dataIndex: 'add_to_cart', width: 60,},
                {title: 'Заказы', dataIndex: 'adv_orders', width: 60,},
                {title: 'Сумма заказов', dataIndex: 'adv_orders_sum', width: 100, render: (text) => formatCurrency(text)},
                {title: 'CTR', dataIndex: 'ctr', width: 80, render: (text) => formatPercent(text)},
                {title: 'CPC', dataIndex: 'cpc', width: 80, render: (text) => formatPercent(text)},
                {title: 'ДРР', dataIndex: 'drr', width: 80, render: (text) => formatPercent(text)},


            ]
        },

    ]
}

const expandData = (cards, groupType) => {
    const key = groupType === 1 ? 'by_days' : 'by_weeks'
    cards.forEach((card) => {
        card['children'] = []

        card.key = card.wbacc_name
        card['children'] = card[key].map((item) => {

            return item
        })
        const firstItem = card['children'].shift();
        for (let key in firstItem) {
            card[key] = firstItem[key]
        }
    })
    return cards

};
// const expandData = (cards, groupType) => {
//     const key = groupType === 1 ? 'by_days' : 'by_weeks';
//
//     cards.forEach((card) => {
//         card['children'] = [];
//         card.key = card.wbacc_name;
//
//         // Извлекаем первый элемент
//         const firstItem = card[key].shift(); // Удаляем первый элемент из массива и сохраняем его
//
//         // Если первый элемент существует, добавляем его в родительский объект
//         if (firstItem) {
//             firstItem.key = card.wbacc_name; // Устанавливаем ключ для первого элемента
//             firstItem.wbacc_name = card.wbacc_name;
//             card = { ...card, ...firstItem }; // Копируем свойства первого элемента в родительский объект
//         }
//
//         // Добавляем оставшиеся элементы в children
//         card[key].forEach((item) => {
//             item.key = card.wbacc_name;
//             card['children'].push(item);
//         });
//     });
//
//     return cards;
// };

export default function TableStatDay2({cards, groupType, loading}) {

    const columns = getColumns()

    let DataSource = cards;

    if (cards) {
        DataSource = expandData(cards, groupType);
    }
    console.log('DataSource', DataSource)
    return (
        <>
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                scroll={{
                    y: 800,
                }}


                loading={loading}
                pagination={false}
            />
        </>
    );
}