import {
    formatCurrency,
    formatDays,
    formatPercent,
    formatQuantity,
} from '../../../../core/utils/formats';
import {EyeOutlined, HeartOutlined} from '@ant-design/icons';
import {Checkbox, Form, Input, Switch, Table} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';

const getColumns = (filters_data, likeHideList, onChangeLikeHide, isHideColumns, msStoreName) => {
    const isMobile = window.innerWidth <= 769;
    return [
        {title: '', dataIndex: 'img', width: 20, fixed: isMobile ? '': 'left', render: (text) => <img src={text} style={{height: '20px'}}></img>,},
        {title: 'Артикул продавца', dataIndex: 'article', ellipsis: true, width: 100, fixed: isMobile ? '': 'left', render: (text) => <a href={`product/${text}`}>{text}</a>, hidden: isHideColumns,},
        {title: 'Артикул', dataIndex: 'nm_id', width: 100, ellipsis: true, fixed: isMobile ? '': 'left',},
        {title: 'Карточка', dataIndex: 'imt_id', width: 80, ellipsis: true, filters: filters_data['imt_ids'], filterSearch: true, onFilter: (value, record) => record.imt_id == value || record.is_fix,},
        {title: 'Категория', dataIndex: 'category', key: 'category', ellipsis: true, width: 100, fixed: isMobile ? '': 'left', filters: filters_data?.category, filterSearch: true, onFilter: (value, record) => record.category == value || record.is_fix,},
        {title: 'Модель', dataIndex: 'material', width: 100, ellipsis: true, filters: filters_data?.material, fixed: isMobile ? '': 'left', filterSearch: true, onFilter: (value, record) => record.material?.indexOf(value) === 0 || record.is_fix,},
        {title: 'Цвет', dataIndex: 'color', width: 100, ellipsis: true, filters: filters_data?.color, filterSearch: true, onFilter: (value, record) => record.color?.indexOf(value) === 0,},
        {title: 'Размер', dataIndex: 'size', width: 70, ellipsis: true, fixed: isMobile ? '': 'left'},
        {title: 'Баркод', dataIndex: 'barcode', ellipsis: true, width: 100},
        {title: 'ABC', dataIndex: 'abc', width: 50, ellipsis: true, filters: filters_data?.abc, filterSearch: true, onFilter: (value, record) => record.abc?.indexOf(value) === 0,},
        {title: 'Сезон', dataIndex: 'sezon', width: 60, ellipsis: true, filters: filters_data?.sezon, filterSearch: true, onFilter: (value, record) => record.sezon?.indexOf(value) === 0,},
        {title: 'Статус', dataIndex: 'status', width: 70, ellipsis: true, filters: filters_data?.status, filterSearch: true, onFilter: (value, record) => record.status?.indexOf(value) === 0,},
        {title: 'Логистика', dataIndex: 'delivery_cost_unit_mid', width: 70, className: 'blue-color', render: (text) => formatCurrency(text),},
        {title: 'Выкуп', dataIndex: 'buyout_percent', width: 70, fixed: isMobile ? '': 'left', className: 'blue-color', render: (text) => formatPercent(text),},


        {
            title: 'Склады',
            className: 'pink-color',
            children: [
                {title: '11. Москва (2-й проезд)', dataIndex: 'ms_ost_3fa0afcf-b9e1-11ee-0a80-067c000f6ed3', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: '1. Москва (включая FBS)', dataIndex: 'ms_ost_68a20fcf-61b2-11ed-0a80-0635002527be', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'ФФ Мытищи', dataIndex: 'ms_ost_7c49ab1b-5220-11ee-0a80-037a00214693', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'ФФ Чертаново - Егорка', dataIndex: 'ms_ost_56d05d54-cb41-11ee-0a80-037b0013f8cc', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'ФФ Алматы', dataIndex: 'ms_ost_01bf8396-4f11-11ef-0a80-147000088111', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'для ОТК', dataIndex: 'ms_ost_be477717-9839-11ed-0a80-07ad0001fb0c', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'после ОТК', dataIndex: 'ms_ost_c8527fa1-9839-11ed-0a80-101b000256f7', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'Бишкек', dataIndex: 'ms_ost_06f657d6-8b8f-11ee-0a80-00e10058c042', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
            ],
        },
        {title: 'Шьется', dataIndex: 'ms_production', width: 70, className: 'green-color', render: (text) => formatQuantity(text),},
        {title: 'Транзит', dataIndex: 'ms_transfer', width: 70, className: 'green-color', render: (text) => formatQuantity(text),},
        {
            title: 'ЗАПАС В ДНЯХ ВСЕ СКЛАДЫ WB',
            className: 'pink-color',
            children: [
                {title: 'Остатки на складах', dataIndex: 'ost_wb', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'В пути до клиента', dataIndex: 'ost_wb_way_to', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'В пути от клиента', dataIndex: 'ost_wb_way_from', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'Транзит', dataIndex: 'ms_transfer', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'Остатки итого', dataIndex: 'ost_wb_full', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'Продажи средние в день', dataIndex: 'orders_per_day', width: 70, className: 'pink-color',},
                {title: 'Выкупы средние в день', dataIndex: 'buyouts_per_day', width: 70, className: 'pink-color',},
                {title: 'Оборачиваемость', dataIndex: 'oborot', width: 70, className: 'pink-color', render: (text) => formatDays(text),},
                {title: 'Запас дней', dataIndex: 'oborot_buyouts', width: 70, className: 'pink-color', render: (text) => formatDays(text),},
                {title: 'Потребность БЕЗ УЧЕТА ТРАНЗИТА', dataIndex: 'need', width: 70, className: 'blue-color', render: (text) => formatQuantity(text),},
            ],
        },
        {
            title: msStoreName,
            className: 'blue2-color',
            children: [
                {title: 'Для распределения', dataIndex: 'supply_initial', width: 70, fixed: isMobile ? '': 'left', className: 'light-yellow-color', editable: true,},
                {title: 'Распределено', dataIndex: 'supply_allocated', width: 70, fixed: isMobile ? '': 'left', className: 'blue2-color',},
                {title: 'Осталось распределить', dataIndex: 'supply_remaining', width: 70, fixed: isMobile ? '': 'left', className: 'blue2-color',},
            ],
        },
    ];
};


const getStoreColumns = (storeNames, hidden) => {
    let columns = [];
    storeNames.forEach((name) => {
        let item = {
            title: name,
            className: 'border_left',
            children: [
                {title: 'Запас в днях', dataIndex: `${name}oborot_buyouts`, className: 'border_left', width: 70, render: (text) => formatDays(text), hidden: hidden.includes('oborot_buyouts'),},
                {title: 'Оборачиваемость', dataIndex: `${name}oborot`, width: 70, render: (text) => formatDays(text), hidden: hidden.includes('oborot'),},
                {title: 'Остатки', dataIndex: `${name}ost`, width: 70, render: (text) => formatQuantity(text), hidden: hidden.includes('ost'),},
                {title: 'В пути к клиенту', dataIndex: `${name}way_to`, width: 70, render: (text) => formatQuantity(text), hidden: hidden.includes('way_to'),},
                {title: 'В пути от клиента', dataIndex: `${name}way_from`, width: 70, render: (text) => formatQuantity(text), hidden: hidden.includes('way_from'),},
                {title: 'Продажи в день', dataIndex: `${name}orders_per_day`, width: 70, render: (text) => formatQuantity(text), hidden: hidden.includes('orders_per_day'),},
                {title: 'Выкупы в день', dataIndex: `${name}buyouts_per_day`, width: 70, render: (text) => formatQuantity(text), hidden: hidden.includes('buyouts_per_day'),},
                {title: 'Потребность', dataIndex: `${name}need`, width: 70, render: (text) => formatQuantity(text), className: 'blue-color', hidden: hidden.includes('need'),},
                {title: 'Поставка', dataIndex: `${name}supply_allocated`, width: 70, className: 'light-yellow-color', editable: true,},
            ],
        };
        columns.push(item);
    });
    return columns;
};

const expandData = (cards) => {
    let new_cards = []
    cards.forEach((card) => {
        for (let storeName in card.by_stores) {
            card[`${storeName}oborot_buyouts`] =
                card.by_stores[storeName].oborot_buyouts;
            card[`${storeName}oborot`] = card.by_stores[storeName].oborot;
            card[`${storeName}ost`] = card.by_stores[storeName].ost;
            card[`${storeName}way_to`] = card.by_stores[storeName].way_to;
            card[`${storeName}way_from`] = card.by_stores[storeName].way_from;
            card[`${storeName}wb_transfer`] = card.by_stores[storeName].transfer;
            card[`${storeName}orders_per_day`] = card.by_stores[storeName].orders_per_day;
            card[`${storeName}buyouts_per_day`] = card.by_stores[storeName].buyouts_per_day;
            card[`${storeName}need`] = card.by_stores[storeName].need;
        }
        new_cards.push(card)
        //card['children'] = [];
        for (let barcode in card.by_barcode) {
            let item = card.by_barcode[barcode];
            item.subject_name = card.subject_name;
            item.subject_id = card.subject_id;
            item.material = card.material;
            item.color = card.color;
            item.abc = card.abc;
            item.sezon = card.sezon;

            for (let storeName in item.by_stores) {
                item[`${storeName}oborot_buyouts`] = item.by_stores[storeName].oborot_buyouts;
                item[`${storeName}oborot`] = item.by_stores[storeName].oborot;
                item[`${storeName}ost`] = item.by_stores[storeName].ost;
                item[`${storeName}way_to`] = item.by_stores[storeName].way_to;
                item[`${storeName}way_from`] = item.by_stores[storeName].way_from;
                item[`${storeName}wb_transfer`] = item.by_stores[storeName].transfer;
                item[`${storeName}orders_per_day`] = item.by_stores[storeName].orders_per_day;
                item[`${storeName}buyouts_per_day`] = item.by_stores[storeName].buyouts_per_day;
                item[`${storeName}need`] = item.by_stores[storeName].need;
            }

            //card['children'].push(item);
            new_cards.push(item)
        }

    });
    return new_cards
};

const calculateSupply = (item, storeNames) => {
    let supply_initial = item.supply_initial
    let supply_allocated = 0
    storeNames.forEach((storeName) => {
        if (item[`${storeName}need`] <= supply_initial) {
            supply_initial -= item[`${storeName}need`]
            supply_allocated += item[`${storeName}need`]
            item[`${storeName}supply_allocated`] = item[`${storeName}need`]
        } else {
            item[`${storeName}supply_allocated`] = supply_initial
            supply_allocated += supply_initial
            supply_initial = 0
        }
    })
    item.supply_allocated = supply_allocated
    item.supply_remaining = supply_initial
    return item
}


const calculateRemaining = (item, storeNames) => {
    let supply_initial = item.supply_initial
    let supply_allocated = 0
    storeNames.forEach((storeName) => {
        supply_allocated += Number(item[`${storeName}supply_allocated`])

    })
    item.supply_allocated = supply_allocated
    item.supply_remaining = supply_initial - supply_allocated
    return item
}

const EditableContext = React.createContext(null);

const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({title, editable, children, dataIndex, record, handleSave, ...restProps}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({[dataIndex]: record[dataIndex],});
    };
    const validationRules = {
        load_price: [{transform: (value) => Number(value), type: 'number', message: `Нужно ввести число`,}],
        load_discount: [{transform: (value) => Number(value), type: 'number', message: `Нужно ввести число`,}],
        new_price_with_wb_discount: [{transform: (value) => Number(value), type: 'number', message: `Нужно ввести число`,}],
    };
    const specificRules = validationRules[dataIndex] || [];

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({...record, ...values,});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item style={{margin: 0}} name={dataIndex} rules={specificRules}><Input ref={inputRef} onPressEnter={save} onBlur={save}/></Form.Item>
        ) : (
            <div className='editable-cell-value-wrap' style={{paddingInlineEnd: 24,}} onClick={toggleEdit}>{children}</div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

export default function TableSupply(args) {
    const [isHideColumns, setHideColumns] = useState(false);
    let columns = getColumns(
        args.filters,
        args.likeHideList,
        args.onChangeLikeHide,
        isHideColumns,
        args.msStoreName,

    );
    if (args.storeNames) {
        const newColumns = getStoreColumns(args.storeNames, args.hidden);
        columns = [...columns, ...newColumns];
    }
    // let DataSource = args.cards;
    // if (args.cards) {
    //     DataSource = expandData(args.cards);
    // }

    const handleSave = (row) => {
        const newData = [...args.cards];
        if (!row.barcode) {
            return
        }
        const index = newData.findIndex((item) => row.barcode === item.barcode);
        const item = newData[index];
        if (item.supply_initial != row.supply_initial) {
            row = calculateSupply(row, args.storeNames)
        } else {
            row = calculateRemaining(row, args.storeNames)
        }
        row.supply_initial = Number(row.supply_initial)
        newData.splice(index, 1, {...row,});
        args.setCards(newData);
    };

    const components = {body: {row: EditableRow, cell: EditableCell,},};

    function enhanceColumn(col, handleSave) {
        if (col.children) {
            col.children = col.children.map((childCol) => enhanceColumn(childCol, handleSave));
        }
        if (!col.editable) {
            return col;
        }
        return {...col, onCell: (record) => ({record, editable: col.editable, dataIndex: col.dataIndex, title: col.title, handleSave,}),};
    }

    columns = columns.map((col) => enhanceColumn(col, handleSave));
    return (
        <>

            <Table
                columns={columns}
                dataSource={args.cards}
                components={components}
                bordered
                size={'small'}
                scroll={{
                    y: 800,
                }}
                rowClassName={(record, index) =>
                    `my-custom-row ${record.article ? 'border_top' : ''}`
                }
                loading={args.loading}
                pagination={false}
            />
        </>
    );
}
