// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-link {
  text-decoration: none;
}

.logo-link:hover,
.logo-link:focus {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/components/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;;EAEE,0BAA0B;AAC5B","sourcesContent":[".logo-link {\r\n  text-decoration: none;\r\n}\r\n\r\n.logo-link:hover,\r\n.logo-link:focus {\r\n  text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
