import React from 'react'
import { formatCurrency, formatPercent, formatQuantity } from '../../../../core/utils/formats'
import { BsGraphUpArrow } from 'react-icons/bs'
import { BsGraphDownArrow } from 'react-icons/bs'
import { Card } from 'antd'

export const GeneralSmallCard = ({ title, classNameDiv, NameIcon, Rubels, byDay, Number, byDayNumber, Format, FormatSmall, percent }) => {
    // Determine text color based on percent
    const textColor = percent > 0 ? 'text-green-500' : 'text-red-500'

    return (
        <Card className='CardSmall xx:max-w-[300px] h-[179px]'>
            <span className='text-gray-500'>{title}</span>
            <div className='flex flex-col gap-4'>
                <div className='flex flex-col'>
                    <h2 className={`font-bold text-xl text-black`}>
                        {Format === 'percent' ? formatPercent(Rubels) : Format === 'rubels' ? formatCurrency(Rubels) : Format === 'sht' ? formatQuantity(Rubels) : Rubels}
                    </h2>
                    <div className='flex items-center gap-2'>
                        <span>0%</span>
                        <p className='text-gray-500'>В день ~ 0 ₽</p>
                    </div>
                </div>
                <div className='flex flex-col'>
                    <h2 className={`font-bold text-xl`}>
                        {FormatSmall === 'percent' ? formatPercent(Number) : FormatSmall === 'rubels' ? formatCurrency(Number) : FormatSmall === 'sht' ? formatQuantity(Number) : Number}
                    </h2>
                    <div className='flex items-center gap-2'>
                        <span className={`flex items-center gap-1 ${textColor}`}>
                            {percent > 0 ? <BsGraphUpArrow className={`text-xl`} /> : <BsGraphDownArrow className={`text-xl`} />} {formatPercent(percent)}
                        </span>
                        <p className={`text-gray-500`}>
                            В день ~{' '}
                            {FormatSmall === 'percent'
                                ? formatPercent(byDayNumber)
                                : FormatSmall === 'rubels'
                                ? formatCurrency(byDayNumber)
                                : FormatSmall === 'sht'
                                ? formatQuantity(byDayNumber)
                                : byDayNumber}
                        </p>
                    </div>
                </div>
            </div>
            <div className={classNameDiv}>{NameIcon}</div>
        </Card>
    )
}
