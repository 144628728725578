import React, { useEffect, useState } from 'react'
import { Card, Table, Tooltip, Input, Button, ConfigProvider, notification, Typography, Col, Row } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useAuth } from '../../core/hooks/useAuth'
import dayjs from 'dayjs'
import { apiAxios } from '../../core/api/api_axios'
import DatePickerRange from '../../components/simple/dateRange'

export const Traffic = () => {
    const { user } = useAuth()
    console.log('user', user)
    const [searchText, setSearchText] = useState('')
    const [accs, setAccs] = useState([])
    const [period, setPeriod] = useState([dayjs().add(-30, 'd'), dayjs().add(-1, 'd')])
    const fetchData = async () => {
        const fetchedData = await apiAxios.post('track/stat/get/', { wbacc_ids: user.wbacc_ids, st: period[0].format('YYYY-MM-DD'), en: period[1].format('YYYY-MM-DD'), is_wbacc: true, is_sum: true })
        setAccs(fetchedData['stat'])
    }
    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData()
        }
    }, [user, period])

    const columns = [
        {
            title: 'Название аккаунта',
            dataIndex: 'wbacc_name',
            key: 'name',
            render: (text, record) => (
                <Link to={`/traffic/${record.wbacc_id}`} style={{ color: '#1890ff' }}>
                    {text}
                </Link>
            )
        },
        //{title: 'Количество ссылок', dataIndex: 'links_quantity', key: 'links', sorter: (a, b) => a.links - b.links},
        { title: 'Переходы', dataIndex: 'clicks', key: 'clicks', sorter: (a, b) => a.clicks - b.clicks },
        { title: 'Заказы', dataIndex: 'orders', key: 'orders', sorter: (a, b) => a.orders - b.orders },
        { title: 'Сумма заказов', dataIndex: 'orders_sum', key: 'orders_sum', sorter: (a, b) => a.conv - b.conv }
    ]

    const filteredData = accs?.filter((item) => item.wbacc_name.toLowerCase().includes(searchText.toLowerCase()))

    return (
        <>
            <Card>
                <Typography.Title level={1}>Внешний трафик</Typography.Title>
                <Row type='flex' style={{ gap: 10 }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                </Row>
            </Card>
            <Card>
                <Table className='adverts-table' dataSource={filteredData} columns={columns} pagination={false} rowClassName='custom-row' />
            </Card>
        </>
    )
}
