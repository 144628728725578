import './styles.css'
import {Divider, Switch, Table} from "antd";
import React from "react";
import {formatCurrency, formatDays, formatPercent, formatQuantity} from "../../../../core/utils/formats";

function transformData(data) {
    let table = []
    let arr = {
        fact_orders_sum: {stat: [], name: 'Заказано, Р'},
        fact_orders: {stat: [], name: 'Заказанно, шт'},
        fact_receipt: {stat: [], name: 'Средний чек'},
        fact_buyouts_sum: {stat: [], name: 'Выкупили, Р'},
        fact_buyouts: {stat: [], name: 'Выкупили, шт'},
        itog_orders_sum: {stat: [], name: 'Итого заказано'},
        itog_buyouts_sum: {stat: [], name: 'Итого выкуплено'},
        itog_trend_orders_sum: {stat: [], name: 'Тренд Заказано'},
        itog_trend_buyouts_sum: {stat: [], name: 'Тренд Выкуплено'},
        itog_plan_orders_sum_percent: {stat: [], name: '% план заказано'},
        itog_plan_buyouts_sum_percent: {stat: [], name: '% плана выкуп'},

    }
    data.stat.forEach((stat) => {
        for (let key_stat in arr) {
            arr[key_stat].stat.push({value: stat[key_stat], index: stat.date, cellKey: key_stat})
        }
    })
    for (let key_stat in arr) {
        let new_item = {name: arr[key_stat].name}
        arr[key_stat].stat.forEach((item_stat) => {
            new_item[item_stat.index] = item_stat.value
        })
        table.push(new_item)
    }
    return table
}


function formatCell(text, name) {
    console.log(text, name);
    if (['Заказано, Р', 'Средний чек', 'Итого заказано', 'Итого выкуплено', 'Тренд Заказано', 'Тренд Выкуплено'].includes(name)) {
        return formatCurrency(text)
    }
    if (['Заказанно, шт', 'Выкупили, шт',].includes(name)) {
        return formatQuantity(text)
    }
    if (['% план заказано', '% плана выкуп'].includes(name)) {
        return formatPercent(text)
    }
    return text
}
export default function TableSalesReport(args) {
    let days_columns = []
    for (let key in args.data) {
        days_columns = args.data[key].stat.map((item) => {
            return {
                title: item.date,
                dataIndex: item.date,
                render: (text, record) => formatCell(text, record.name),
            }
        })
        break
    }
    const isMobile = window.innerWidth <= 769;
    const columns = [{title: '', dataIndex: 'name', width: 110, ellipsis: true, fixed: isMobile ? '': 'left'}].concat(days_columns)

    let tables = []
    for (let wbacc_id in args.data) {
        let table = transformData(args.data[wbacc_id])
        table.name = args.data[wbacc_id].wbacc_name
        tables.push(table)
    }

    return <>
        {tables.map((table) => {
            return <>
                <Divider>{table.name}</Divider>
                <Table
                    columns={columns}
                    dataSource={table}
                    bordered
                    size={'small'}
                    rowClassName={(record, index) => `my-custom-row sale_report_row${index}`}
                    loading={args.loading}
                    pagination={false}

                />
            </>
        })}

    </>
}