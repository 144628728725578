import React from 'react'
import {Button} from 'antd';
import {useNavigate} from 'react-router-dom';
import * as XLSX from "xlsx";

export const ButtonExel = ({data}) => {
    const handleExport = () => {
        exportToExcel(data);
    };
    return (
        <button class='relative flex items-center px-6 py-3 max-h-[32px] overflow-hidden font-medium transition-all bg-[#21A65B] rounded-md group' onClick={handleExport}>
            <span class='absolute top-0 right-0 inline-block w-4 h-4 transition-all duration-1000 ease-in-out bg-[#1a8247] rounded group-hover:-mr-4 group-hover:-mt-4'>
                <span class='absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white'></span>
            </span>
            <span class='absolute bottom-0 rotate-180 left-0 inline-block w-4 h-4 transition-all duration-1000 ease-in-out bg-[#1a8247] rounded group-hover:-ml-4 group-hover:-mb-4'>
                <span class='absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white'></span>
            </span>
            <span class='absolute bottom-0 left-0 w-full h-full transition-all duration-1000 ease-in-out delay-200 -translate-x-full bg-[#1d904f] rounded-md group-hover:translate-x-0'></span>
            <span class='relative w-full text-left text-white transition-colors duration-1000 ease-in-out group-hover:text-white'>Экспорт в Exel</span>
        </button>
    )
}
// export const ButtonExel = ({data}) => {
//     return (
//         <button class='' onClick={exportToExcel({data: data})}></button>
//             )
// }
export const exportToExcel = async (data) => {
    try {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFile(workbook, 'table_data.xlsx');
    } catch (error) {
        console.error('Ошибка при экспорте данных:', error);
    }
}


export const NavigateButton = ({title, link}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(link);
    };

    return (
        <Button type="primary" className='max-w-[200px]' onClick={handleClick}>
            {title}
        </Button>
    );
};

