import { apiAxios } from '../api_axios';

export const getCatalog = async(wbacc_ids, is_like, is_hide) => {
    try {
        return await apiAxios.get('settings/catalog/get/', {
            'wbacc_ids': wbacc_ids,
            is_like: is_like,
            is_hide: is_hide,
        })

    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};