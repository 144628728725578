import React from "react";
import {Button, Col, Flex, Row, Select, Typography} from "antd";
import {CalendarOutlined} from "@ant-design/icons";
import dayjs from "dayjs";


const SelectorMulti = (props) => {
    const { options, selected, setSelected } = props;
    return (

        <Select
            mode="multiple"
            style={{width: '100%'}}
            placeholder="Please select"
            value={selected}
            onChange={(value) => setSelected(value)}
            options={options}
        />

    );
};

const SelectorSolo = (props) => {
    const { options, selected, setSelected } = props;

    return (

        <Select
            style={{width: '100%'}}
            placeholder="Please select"
            value={selected}
            onChange={(value) => setSelected(value)}
            options={options}
        />

    );
};
const SelectorTags = (props) => {
    const { options, selected, setSelected } = props;
    return (

        <Select
            mode="tags"
            maxTagCount={'responsive'}
            allowClear
            style={{width: '100%'}}
            placeholder="Please select"
            value={selected}
            onChange={(value) => setSelected(value)}
            options={options}
        />

    );
};

export {SelectorMulti, SelectorSolo, SelectorTags};