import {formatAdvertStatus, formatAdvertType, formatCurrency, formatDays, formatNumber, formatPercent, formatQuantity} from "../../../../core/utils/formats";
import {EyeOutlined, HeartOutlined} from "@ant-design/icons";
import {Checkbox, ConfigProvider, Switch, Table} from "antd";
import React, {useState} from "react";
import {Link} from "react-router-dom";


const getColumns = (isHideColumns) => {

    return [
        {title: 'ID', dataIndex: 'id', ellipsis: true, width: 50, },
        {title: 'Название, Тип', dataIndex: 'name', width: 200, ellipsis: true, render: (text,record) => <div className="flex flex-col gap-1">
        <Link className="text-blue-400" to={`/advert/${record.wbacc_id}/${record.id}`}>{text}</Link>
            <p className="text-sm text-gray-400">{formatAdvertType(record.advert_type)}</p>
        </div>},
        {title: 'Артикулы WB', dataIndex: 'nm_ids', width: 80, ellipsis: true, render: (text, record, index) => `${record?.nm_ids}`,},
        {title: 'Статус', dataIndex: 'status', width: 55, ellipsis: true, render: (text) => <div className={`${text === 9 ? 'text-[#007E00] bg-[#DEF1DD]' : text === 11 ? 'text-[#7C2AD0] bg-[#ECDAFF]' : text === 8 ? 'text-[#d02a2a] bg-[#ffdada]' : ''} text-sm font-bold rounded-lg  flex justify-center items-center px-[6px] py-1`}>{formatAdvertStatus(text)}</div>,},
        {title: 'Расход', dataIndex: 'budget', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'Показы', dataIndex: 'views', width: 50, ellipsis: true, render: (text) => formatNumber(text),},
        {title: 'Клики', dataIndex: 'clicks', width: 50, ellipsis: true, render: (text) => formatNumber(text),},
        {title: 'Корзина', dataIndex: 'add_to_cart', width: 50, ellipsis: true, render: (text) => formatNumber(text),},
        {title: 'Заказы', dataIndex: 'orders', width: 50, ellipsis: true, render: (text) => formatQuantity(text),},
        {title: 'Сумма заказов', dataIndex: 'orders_sum', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'CTR', dataIndex: 'ctr', width: 50, ellipsis: true, render: (text) => formatPercent(text),},
        {title: 'CPC', dataIndex: 'cpc', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'Процент в корзину', dataIndex: 'add_to_cart_percent', width: 50, ellipsis: true, render: (text) => formatPercent(text),},
        {title: 'Стоимость корзины', dataIndex: 'add_to_cart_cost', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'Стоимость заказа', dataIndex: 'cpo', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'Ставка', dataIndex: 'cpm', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'ДРР', dataIndex: 'drr', width: 50, ellipsis: true, render: (text) => formatPercent(text),},
        {title: 'ROMI', dataIndex: 'romi', width: 50, ellipsis: true},
        {title: 'Период', dataIndex: 'period', width: 70, ellipsis: true, render: (text, record, index) => `${record?.period?.st}-${record?.period?.en}`,},

    ]
}


export default function TableAdverts(args) {
    const [isHideColumns, setHideColumns] = useState(false)
    let columns = getColumns(isHideColumns);

    return <>

      <Table
            columns={columns}
            dataSource={args.adverts}
            size={'small'}
            scroll={{
                y: 800,
            }}
            className="adverts-table"
            loading={args.loading}
            pagination={{'defaultPageSize': 30, 'showTotal': (total, range) => `${range[0]}-${range[1]} из ${total} кампаний`}}
        />
    
    </>
}