import React, {useEffect, useState, useCallback, useMemo} from 'react'
import {Card, Col, Row, Tooltip, Typography} from 'antd'
import {SelectorSolo} from '../../components/Selectors'
import dayjs from 'dayjs'
import DatePickerRange from '../../components/dateRange'
import TableStatDay2 from './components/generalStatDays2'
import {ButtonExel} from '../../components/Buttons'
import { useSidebar } from '../../Context/SideBarContext'
import {CiCircleQuestion} from 'react-icons/ci'
import Sidebar from '../home/sideBar/SideBar'
import SideBarGeneral from '../home/sideBar/SideBarGeneral'
import {apiAxios} from "../../utils/api_axios";
import { 
    ComposedChart, 
    Bar, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip as RechartsTooltip, 
    Legend, 
    ResponsiveContainer, 
    Line 
} from 'recharts';
import { formatCurrency } from '../../utils/formats';

export default function GeneralStatDay() {
    const [filters_data, setFiltersData] = useState([])
    const [cards, setCards] = useState([])
    const [cards1, setCards1] = useState([])
    const [period, setPeriod] = useState([dayjs().add(-30, 'd'), dayjs().add(-1, 'd')])
    const [groupType, setGroupType] = useState(1)
    const [loading, setLoading] = useState(true)
    const [visibleItems, setVisibleItems] = useState({
        orders: true,
        buyouts: true,
        orders_sum: true,
        buyouts_sum: true
    });
    const [selectedWbacc, setSelectedWbacc] = useState(null);

    const {openSidebar} = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarGeneral/>)
    }, [openSidebar])

    const fetchData = useCallback(async () => {
        try {
            setLoading(true)
            const fetchedData = await apiAxios.post('general/stat_by_period/get/', {
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
            })

            setFiltersData(fetchedData['filters'])
            setCards(fetchedData['data'])
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }, [period])

    const fetchData1 = useCallback(async () => {
        try {
            setLoading(true)
            const fetchedData = await apiAxios.post('general/stat_by_period/get/', {
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
                group_day: 'week',
            })
            setFiltersData(fetchedData['filters'])
            setCards1(fetchedData['data'])
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }, [period])

    useEffect(() => {
        fetchData()
        fetchData1()
    }, [period])

    const prepareChartData = (data) => {
        // Фильтруем данные по выбранному магазину, если выбран
        const filteredData = selectedWbacc 
            ? data.filter(wbAccount => wbAccount.wbacc_name === selectedWbacc)
            : data;

        // Агрегируем данные
        const aggregatedData = filteredData.reduce((acc, wbAccount) => {
            wbAccount.by_period.forEach(period => {
                const existingPeriod = acc.find(item => item.name === period.date);
                
                if (existingPeriod) {
                    // Суммируем значения для существующей даты
                    existingPeriod.orders += period.orders || 0;
                    existingPeriod.buyouts += period.buyouts || 0;
                    existingPeriod.orders_sum += period.orders_sum || 0;
                    existingPeriod.buyouts_sum += period.buyouts_sum || 0;
                } else {
                    // Добавляем новую дату
                    acc.push({
                        name: period.date,
                        orders: period.orders || 0,
                        buyouts: period.buyouts || 0,
                        orders_sum: period.orders_sum || 0,
                        buyouts_sum: period.buyouts_sum || 0
                    });
                }
            });
            return acc;
        }, []);

        // Сортируем данные по дате
        return aggregatedData.sort((a, b) => {
            const parseDate = (dateStr) => {
                const parts = dateStr.split('-')[0].split('.');
                return new Date(parts[2], parts[1] - 1, parts[0]);
            };
            return parseDate(a.name) - parseDate(b.name);
        });
    };

    const wbaccOptions = useMemo(() => {
        const allWbaccs = groupType === 1 ? cards : cards1;
        return [
            { label: 'Все магазины', value: null },
            ...allWbaccs.map(wbacc => ({
                label: wbacc.wbacc_name, 
                value: wbacc.wbacc_name
            }))
        ];
    }, [cards, cards1, groupType]);

    const toggleVisibility = (key) => {
        setVisibleItems({ ...visibleItems, [key]: !visibleItems[key] });
    };

    const checkboxes = [
        { id: 'checkBox1', label: 'Заказы, шт', key: 'orders', accentColor: '#E66ED4' },
        { id: 'checkBox2', label: 'Продажи, шт', key: 'buyouts', accentColor: '#427EFE' },
        { id: 'checkBox3', label: 'Сумма заказов, руб', key: 'orders_sum', accentColor: '#E66ED4' },
        { id: 'checkBox4', label: 'Сумма продаж, руб', key: 'buyouts_sum', accentColor: '#427EFE' }
    ];

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className='custom-tooltip' style={{ 
                    backgroundColor: '#fff', 
                    border: '1px solid #ccc', 
                    padding: '10px', 
                    borderRadius: '5px' 
                }}>
                    <p>{label}</p>
                    {payload[0] && (
                        <p className='text-xs md:text-base' style={{ color: '#E66ED4' }}>
                            ■ Заказы, шт: {payload[0].value.toLocaleString()}
                        </p>
                    )}
                    {payload[1] && (
                        <p className='text-xs md:text-base' style={{ color: '#427EFE' }}>
                            ■ Продажи, шт: {payload[1].value.toLocaleString()}
                        </p>
                    )}
                    {payload[2] && (
                        <p className='text-xs md:text-base' style={{ color: '#E66ED4' }}>
                            ● Сумма заказов: {formatCurrency(payload[2].value)}
                        </p>
                    )}
                    {payload[3] && (
                        <p className='text-xs md:text-base' style={{ color: '#427EFE' }}>
                            ● Сумма продаж:  {formatCurrency(payload[3].value)}
                        </p>
                    )}
                </div>
            );
        }

        return null;
    };

    const renderBarChart = () => {
        const chartData = prepareChartData(groupType === 1 ? cards : cards1);
        
        return (
            <>
                <Row type='flex' style={{gap: 10, alignItems: 'end', marginBottom: '16px'}}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Магазин</Typography.Title>
                        <SelectorSolo 
                            options={wbaccOptions} 
                            selected={selectedWbacc} 
                            setSelected={setSelectedWbacc}
                        />
                    </Col>
                    <div className='flex justify-end mb-2 flex-wrap gap-4 pr-2'>
                        {checkboxes.map(({ id, label, key, accentColor }) => (
                            <label key={id} className='flex items-center text-xs gap-1'>
                                <input 
                                    id={id} 
                                    type='checkbox' 
                                    style={{ accentColor }} 
                                    checked={visibleItems[key]} 
                                    onChange={() => toggleVisibility(key)} 
                                />
                                {label}
                            </label>
                        ))}
                    </div>
                </Row>
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart data={chartData}>
                        <defs>
                            <linearGradient id='colorOrders' x1='0' y1='0' x2='0' y2='1'>
                                <stop offset='0%' stopColor='#E66ED4' stopOpacity={1} />
                                <stop offset='50%' stopColor='#D268DC' stopOpacity={1} />
                                <stop offset='100%' stopColor='#C565E3' stopOpacity={0.3} />
                            </linearGradient>
                            <linearGradient id='colorSales' x1='0' y1='0' x2='0' y2='1'>
                                <stop offset='0%' stopColor='#427EFE' stopOpacity={1} />
                                <stop offset='50%' stopColor='#6F6FF9' stopOpacity={1} />
                                <stop offset='100%' stopColor='#9263F4' stopOpacity={0.5} />
                            </linearGradient>
                            <linearGradient id='colorOrdersSum' x1='0' y1='0' x2='0' y2='1'>
                                <stop offset='0%' stopColor='#E66ED4' stopOpacity={1} />
                                <stop offset='50%' stopColor='#D268DC' stopOpacity={1} />
                                <stop offset='100%' stopColor='#C565E3' stopOpacity={0.3} />
                            </linearGradient>
                            <linearGradient id='colorBuyoutsSum' x1='0' y1='0' x2='0' y2='1'>
                                <stop offset='0%' stopColor='#427EFE' stopOpacity={1} />
                                <stop offset='50%' stopColor='#6F6FF9' stopOpacity={1} />
                                <stop offset='100%' stopColor='#9263F4' stopOpacity={0.5} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid  stroke='#f5f5f5' />
                        <XAxis 
                            dataKey="name" 
                            angle={-45} 
                            textAnchor="end" 
                            height={100}
                            interval={0}
                        />
                        <YAxis 
                            yAxisId="left" 
                            orientation="left" 
                            label={{ value: 'Количество', angle: -90, position: 'insideLeft' }}
                        />
                        <YAxis 
                            yAxisId="right" 
                            orientation="right" 
                            label={{ value: 'Сумма, руб', angle: 90, position: 'insideRight' }}
                        />
                        <RechartsTooltip content={<CustomTooltip />} />
                        <Legend />

                        {visibleItems.orders && 
                            <Bar 
                                yAxisId="left" 
                                dataKey="orders" 
                                barSize={20} 
                                fill='url(#colorOrders)' 
                                name="Заказы, шт" 
                            />
                        }
                        {visibleItems.buyouts && 
                            <Bar 
                                yAxisId="left" 
                                dataKey="buyouts" 
                                barSize={20} 
                                fill='url(#colorSales)' 
                                name="Продажи, шт" 
                            />
                        }
                        {visibleItems.orders_sum && 
                            <Line 
                                yAxisId="right" 
                                type="monotone" 
                                dataKey="orders_sum" 
                                stroke="url(#colorOrdersSum)"
                                strokeWidth={3}
                                dot={false}
                                name="Сумма заказов, руб" 
                            />
                        }
                        {visibleItems.buyouts_sum && 
                            <Line 
                                yAxisId="right" 
                                type="monotone" 
                                dataKey="buyouts_sum" 
                                stroke="url(#colorBuyoutsSum)"
                                strokeWidth={3}
                                dot={false}
                                name="Сумма продаж, руб" 
                            />
                        }
                    </ComposedChart>
                </ResponsiveContainer>
            </>
        );
    };

    const groupOptions = [
        {label: 'По дням', value: 1},
        {label: 'По неделям', value: 2}
    ]

    return (
        <>
            <Card>
                <Typography.Title level={1}>Аналитика по дням</Typography.Title>
                <Row type='flex' style={{gap: 10, alignItems: 'end'}}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod}/>
                    </Col>

                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Группировка</Typography.Title>
                        <SelectorSolo options={groupOptions} selected={groupType} setSelected={setGroupType}/>
                    </Col>
                    <ButtonExel data={groupType === 1 ? cards : cards1}/>
                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold'/>
                    </Tooltip>
                </Row>
            </Card>
            <Sidebar/>
            <Card>
                {!loading && renderBarChart()}
            </Card>
            <Card>
                <TableStatDay2 cards={groupType === 1 ? cards : cards1} loading={loading}/>
            </Card>
        </>
    )
}
