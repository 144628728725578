import React, {useEffect, useState} from "react";
import {Pie} from '@ant-design/plots';
import {Col, Row} from "antd";

const PlotMaps = {};

export function GeneralPieBlocks(data) {
    //const [data, setData] = useState({});
    data = data.data

    console.log('GeneralPieBlocks', data)
    if (!data) {
        return null
    }
    let dataSource = data
    if (dataSource) {
        dataSource = {
            "pie1": [
                {"area": "Болотчиева", "value": data?.sebes['1']},
                {"area": "Бегменов", "value": data['sebes']['2']},
                {"area": "Интрейд", "value": data['sebes']['3']},
                {"area": "Ворокова", "value": data['sebes']['4']},
                {"area": "Оразова", "value": data['sebes']['9']},
                {"area": "Осоо Лимитед", "value": data['sebes']['15']},
                {"area": "Озон Интрейд", "value": data['sebes']['-2']},
                {"area": "Озон Ворокова", "value": data['sebes']['-1']},
            ],
            "pie2": [
                {"area": "Болотчиева", "value": data['ost_sum']['1']},
                {"area": "Бегменов", "value": data['ost_sum']['2']},
                {"area": "Интрейд", "value": data['ost_sum']['3']},
                {"area": "Ворокова", "value": data['ost_sum']['4']},
                {"area": "Оразова", "value": data['ost_sum']['9']},
                {"area": "Осоо Лимитед", "value": data['ost_sum']['15']},
                {"area": "Озон Интрейд", "value": data['ost_sum']['-2']},
                {"area": "Озон Ворокова", "value": data['ost_sum']['-1']},
            ],
            "pie3": [
                {"area": "Болотчиева", "value": data['orders_sum']['1']},
                {"area": "Бегменов", "value": data['orders_sum']['2']},
                {"area": "Интрейд", "value": data['orders_sum']['3']},
                {"area": "Ворокова", "value": data['orders_sum']['4']},
                {"area": "Оразова", "value": data['orders_sum']['9']},
                {"area": "Осоо Лимитед", "value": data['orders_sum']['15']},
                {"area": "Озон Интрейд", "value": data['orders_sum']['-2']},
                {"area": "Озон Ворокова", "value": data['orders_sum']['-1']},
            ],
            "pie4": [
                {"area": "Болотчиева", "value": data['buyouts_sum']['1']},
                {"area": "Бегменов", "value": data['buyouts_sum']['2']},
                {"area": "Интрейд", "value": data['buyouts_sum']['3']},
                {"area": "Ворокова", "value": data['buyouts_sum']['4']},
                {"area": "Оразова", "value": data['buyouts_sum']['9']},
                {"area": "Осоо Лимитед", "value": data['buyouts_sum']['15']},
                {"area": "Озон Интрейд", "value": data['buyouts_sum']['-2']},
                {"area": "Озон Ворокова", "value": data['buyouts_sum']['-1']},
            ],
            "pie5": [
                {"area": "Болотчиева", "value": data['profit']['1']},
                {"area": "Бегменов", "value": data['profit']['2']},
                {"area": "Интрейд", "value": data['profit']['3']},
                {"area": "Ворокова", "value": data['profit']['4']},
                {"area": "Оразова", "value": data['profit']['9']},
                {"area": "Осоо Лимитед", "value": data['profit']['15']},
                {"area": "Озон Интрейд", "value": data['profit']['-2']},
                {"area": "Озон Ворокова", "value": data['profit']['-1']},
            ]
        }
    } else {
        dataSource = {}
    }

    console.log(dataSource);
    const showTooltip = (evt, pie) => {
        Object.keys(PlotMaps).forEach((plot) => {
            if (plot !== pie) {
                PlotMaps[plot].chart.emit('tooltip:show', {
                    data: {data: {area: evt.data.data.area}},
                });
                PlotMaps[plot].chart.emit('element:highlight', {
                    data: {data: {area: evt.data.data.area}},
                });
            }
        });
    };

    const hideTooltip = (evt, pie) => {
        Object.keys(PlotMaps).forEach((plot) => {
            if (plot !== pie) {
                PlotMaps[plot].chart.emit('tooltip:hide', {
                    data: {data: {area: evt.data.data.area}},
                });
                PlotMaps[plot].chart.emit('element:unhighlight', {
                    data: {data: {area: evt.data.data.area}},
                });
            }
        });
    };

    const configPie1 = {
        angleField: 'value', colorField: 'area', data: dataSource.pie1, label: {text: 'value', position: 'outside',},
        legend: false, innerRadius: 0.6, tooltip: {title: 'area',}, interaction: {elementHighlight: true,}, state: {inactive: {opacity: 0.5},},
        annotations: [{type: 'text', style: {text: 'Остатки\nсебес', x: '50%', y: '50%', textAlign: 'center', fontSize: 30, fontStyle: 'bold',},},],
        scale: {color: {palette: 'cool',},},
    };
    const configPie2 = {
        angleField: 'value', colorField: 'area', data: dataSource.pie2, label: {text: 'value', position: 'outside',},
        legend: false, innerRadius: 0.6, tooltip: {title: 'area',}, interaction: {elementHighlight: true,}, state: {inactive: {opacity: 0.5},},
        annotations: [{type: 'text', style: {text: 'Деньги\nв товаре', x: '50%', y: '50%', textAlign: 'center', fontSize: 30, fontStyle: 'bold',},},],
        scale: {color: {palette: 'cool',},},
    };
    const configPie3 = {
        angleField: 'value', colorField: 'area', data: dataSource.pie3, label: {text: 'value', position: 'outside',},
        legend: false, innerRadius: 0.6, tooltip: {title: 'area',}, interaction: {elementHighlight: true,}, state: {inactive: {opacity: 0.5},},
        annotations: [{type: 'text', style: {text: 'Сумма\nзаказов', x: '50%', y: '50%', textAlign: 'center', fontSize: 30, fontStyle: 'bold',},},],
        scale: {color: {palette: 'cool',},},
    };
    const configPie4 = {
        angleField: 'value', colorField: 'area', data: dataSource.pie4, label: {text: 'value', position: 'outside',},
        legend: false, innerRadius: 0.6, tooltip: {title: 'area',}, interaction: {elementHighlight: true,}, state: {inactive: {opacity: 0.5},},
        annotations: [{type: 'text', style: {text: 'Сумма\nвыкупов', x: '50%', y: '50%', textAlign: 'center', fontSize: 30, fontStyle: 'bold',},},],
        scale: {color: {palette: 'cool',},},
    };
    const configPie5 = {
        angleField: 'value', colorField: 'area', data: dataSource.pie5, label: {text: 'value', position: 'outside',},
        legend: false, innerRadius: 0.6, tooltip: {title: 'area',}, interaction: {elementHighlight: true,}, state: {inactive: {opacity: 0.5},},
        annotations: [{type: 'text', style: {text: 'Чистая\nприбыль', x: '50%', y: '50%', textAlign: 'center', fontSize: 30, fontStyle: 'bold',},},],
        scale: {color: {palette: 'cool',},},
    };
    const pieConfigs = [
        configPie1,
        configPie2,
        configPie3,
        configPie4,
        configPie5,
    ];
    return (

        <Row justify="space-between">
            {pieConfigs.map((config, index) => (
                <Col key={index} xs={24} sm={12} md={8} lg={4} style={{ height: '320px' }}>
                    <Pie
                        style={{ width: '100%', height: '350px' }}
                        {...config}
                        onReady={(plot) => {
                            PlotMaps[`pie${index + 1}`] = plot;
                            plot.chart.on('interval:pointerover', (evt) => {
                                showTooltip(evt, `pie${index + 1}`);
                            });
                            plot.chart.on('interval:pointerout', (evt) => {
                                hideTooltip(evt, `pie${index + 1}`);
                            });
                        }}
                    />
                </Col>
            ))}
        </Row>

    );
}

