import React, {useEffect, useState} from 'react'
import { Typography, Space, Button, Dropdown, Tag, Menu, Select, Row, Col, Table, Input} from 'antd'
import {FilterOutlined, ReloadOutlined} from '@ant-design/icons'
import {formatCurrency, formatCurrency2, formatDays, formatPercent, formatQuantity} from '../../../utils/formats';
import {apiAxios} from "../../../utils/api_axios";
import {useFilterLogic, useFilteredCards} from '../../components/filterUtils'


const {Text, Title} = Typography;

export default function ProductStock({selectorFilter}) {
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [columns, setColumns] = useState([]);

    const [loading, setLoading] = useState(true);
    const [activeFilters, setActiveFilters] = useState([]);
    const [filterSearchTexts, setFilterSearchTexts] = useState({
        article: '',
        material: '',
        category: '',
        status: '',
        sezon: '',
        brand: '',
        color: ''
    });

    const [selectorFilter2, setSelectorFilter1] = useState({
        group: 'nm_id',
        group_options: [
            {label: 'Артикул', value: 'nm_id'},
            {label: 'Артикул по складам', value: 'nm_id_store'},
            {label: 'Артикул по регионам', value: 'nm_id_reg'},
            {label: 'Размеры', value: 'bar'},
            {label: 'Размеры по складам', value: 'bar_store'},
            {label: 'Размеры по регионам', value: 'bar_reg'},
        ],
        need_days: 30,
        days_calculate: 14,
    });

    const setSelectorFilter2 = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const [filters_data, setFiltersData] = useState({});

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('supplies/get/', {
                wbacc_ids: [selectorFilter.wbacc_id],
                need_days: selectorFilter2.need_days,
                days_calculate: selectorFilter2.days_calculate,
            });
            setData(response.cards);
            console.log(response.cards)
            setFiltersData(response.filters || {});
        } catch (error) {
            console.error('Error fetching oborot data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectorFilter.wbacc_id) {
            fetchData();
        }
    }, [selectorFilter.wbacc_id]);

    const handleAddFilter = (type, value) => {
        setActiveFilters(prev => {
            const filterExists = prev.some(
                filter => filter.type === type && filter.value.value === value.value
            );
            if (filterExists) return prev;
            return [...prev, {type, value}];
        });
    };

    const handleRemoveFilter = (index) => {
        setActiveFilters(prev => prev.filter((_, i) => i !== index));
    };

    const menuItems = useFilterLogic(
        filters_data,
        filterSearchTexts,
        setFilterSearchTexts,
        handleAddFilter
    );

    const filterMenu = <Menu items={menuItems}/>;

    const getColumns = () => {
        const baseColumns = [
            {
                title: 'Товар',
                key: 'product',
                width: 300,
                fixed: 'left',
                render: (_, record) => (
                    <Space direction="vertical" size={0}>
                        <Space align="start" size={12}>
                            <img src={record.img} style={{height: '60px', width: '60px', objectFit: 'contain'}} alt="Product"/>
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                                <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                                <Text><strong>Название:</strong> {record.name}</Text>
                                <Text><strong>Карточка:</strong> {record.imt_id}</Text>

                            </Space>
                        </Space>
                    </Space>
                )
            },
            {
                title: 'Информация о товаре',
                width: 300,
                render: (_, record) => (
                    <Space size={24} className="cost-info-cell">
                        <Space direction="vertical" size={0}>
                            <Text><strong>Модель:</strong> {record.material}</Text>
                            <Text><strong>Бренд:</strong> {record.brand}</Text>
                            <Text><strong>Категория:</strong> {record.category}</Text>
                        </Space>
                        <Space direction="vertical" size={0}>
                            <Text><strong>Статус:</strong> {record.status}</Text>
                            <Text><strong>Сезон:</strong> {record.material}</Text>
                            <Text><strong>Цвет:</strong> {record.color}</Text>
                        </Space>
                    </Space>
                )
            }
        ]
        const sizeColumn = {
            title: 'Размер',
            width: 150,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Размер:</strong> {record.size}</Text>
                    <Text><strong>Баркод:</strong> {record.barcode}</Text>
                </Space>
            )
        }
        const storeColumn = {
            title: 'Склад',
            width: 150,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Склад:</strong> {record.store_name}</Text>
                </Space>
            )
        }
        const regionColumn = {
            title: 'Регион',
            width: 150,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                    <Text><strong>Регион:</strong> {record.store_name}</Text>
                </Space>
            )
        }

        const mainColumns = [
            {
                title: 'Остатки',
                width: 100,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Всего:</strong> {formatQuantity(record.ost_full)}</Text>
                        <Text><strong>FBO:</strong> {formatQuantity(record.ost)}</Text>
                        <Text><strong>В пути от:</strong> {formatQuantity(record.way_from || 0)}</Text>
                        <Text><strong>В пути до:</strong> {formatQuantity(record.way_to || 0)}</Text>
                    </Space>
                )
            },
            {
                title: 'Финансы',
                width: 200,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Себестоимость:</strong> {formatCurrency(record.sebes * record.ost_full)}</Text>
                        <Text><strong>В товаре:</strong> {formatCurrency(record.price_client * record.ost_full)}</Text>
                        <Text><strong>Цена со скидкой:</strong> {formatCurrency(record.price_client)}</Text>
                    </Space>
                )
            },
            {
                title: 'Продажи',
                width: 150,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Заказов в день:</strong> {formatQuantity(record.orders_per_day)}</Text>
                        <Text><strong>Продаж в день:</strong> {formatQuantity(record.buyouts_per_day)}</Text>
                        <Text><strong>Выкуп:</strong> {formatPercent(record.buyout_percent)}</Text>

                    </Space>
                )
            },
            {
                title: 'Оборачиваемость',
                width: 150,
                render: (_, record) => (
                    <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text type={record.oborot_orders <= 60 ? 'success' : 'danger'}>
                            <strong>Оборачиваемость:</strong> {formatDays(record.oborot_orders)}
                        </Text>
                        <Text type={record.oborot_buyouts <= 60 ? 'success' : 'danger'}>
                            <strong>Запас дней:</strong> {formatDays(record.oborot_buyouts)}
                        </Text>
                        <Text type={record.need <= 5 ? null : record.need <= 20 ? 'success' : 'danger'}>
                            <strong>Потребность:</strong> {formatQuantity(record.need)}
                        </Text>
                        <Text><strong>Хранение 30 дней:</strong> {formatCurrency2(record.store_cost)}</Text>

                    </Space>
                )
            },
        ]
        let columns = baseColumns

        if (selectorFilter2.group.includes('bar')) {
            columns = [
                ...columns,
                sizeColumn,
            ]
        }
        if (selectorFilter2.group.includes('store')) {
            columns = [
                ...columns,
                storeColumn,
            ]
        }
        if (selectorFilter2.group.includes('reg')) {
            columns = [
                ...columns,
                regionColumn,
            ]
        }
        columns = [
            ...columns,
            ...mainColumns,
        ]

        return columns
    };

    const [quickFilters, setQuickFilters] = useState({
        showNegativeMargin: false,
        showNoSales: false,
        showLowStock: false,
        showHighStock: false,
        showLongTurnover: false,
        showLowBuyout: false,
        showNeed: false,
        showHighNeed: false,
    });

    const quickFilterButtons = [
        {
            key: 'showNoSales',
            label: 'Нет заказов',
            filter: item => item.orders_per_day < 0.2 && item.ost > 10
        },
        {
            key: 'showLowStock',
            label: 'Низкий остаток',
            filter: item => item.oborot_orders < 30 && item.orders_per_day > 0.2
        },
        {
            key: 'showHighStock',
            label: 'Высокий остаток',
            filter: item => item.oborot_orders > 90 && item.orders_per_day > 0.2
        },
        {
            key: 'showLongTurnover',
            label: 'Долгая оборачиваемость',
            filter: item => item.oborot_orders > 60
        },
        {
            key: 'showLowBuyout',
            label: 'Низкий выкуп',
            filter: item => item.buyout_percent < 30 && item.buyout_percent > 0
        },
        {
            key: 'showNeed',
            label: 'Есть потребность',
            filter: item => item.need > 0
        },
        {
            key: 'showHighNeed',
            label: 'Высокая потребность',
            filter: item => item.need > 10
        }
    ];

    const handleQuickFilter = (filterKey) => {
        if (quickFilters[filterKey]) {
            setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
                ...acc,
                [key]: false
            }), {}));
        } else {
            setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
                ...acc,
                [key]: key === filterKey
            }), {}));
        }
    };

    const applyQuickFilters = (data) => {
        const activeFilter = Object.entries(quickFilters).find(([_, value]) => value);
        if (!activeFilter) return data;

        const filterConfig = quickFilterButtons.find(btn => btn.key === activeFilter[0]);
        if (!filterConfig) return data;
        const filteredData = data.filter(filterConfig.filter);
        return filteredData;
    };

    const expandData = (data) => {
        const newData = [];
        switch (selectorFilter2.group) {
            case 'nm_id':
                if (data.ost_full > 0 || data.need > 0) {
                    return [data].sort((a, b) => b.ost_full - a.ost_full);
                }
                return [];
            case 'nm_id_store':
                Object.entries(data.by_stores || {}).forEach(([store, storeData]) => {
                    if (storeData.ost_full > 0 || storeData.need > 0) {
                        newData.push({
                            ...data,
                            key: `${data.nm_id}-${store}`,
                            store_name: store,
                            ost: storeData.ost,
                            ost_full: storeData.ost_full,
                            way_to: storeData.way_to,
                            way_from: storeData.way_from,
                            orders_per_day: storeData.orders_per_day,
                            buyouts_per_day: storeData.buyouts_per_day,
                            need: storeData.need,
                            oborot_orders: storeData.oborot_orders,
                            oborot_buyouts: storeData.oborot_buyouts,
                            store_cost: storeData.store_cost,
                        });
                    }
                });
                return newData.sort((a, b) => b.ost_full - a.ost_full);
            case 'nm_id_reg':
                Object.entries(data.by_regions || {}).forEach(([region, regionData]) => {
                    if (regionData.ost_full > 0 || regionData.need > 0) {
                        newData.push({
                            ...data,
                            key: `${data.nm_id}-${region}`,
                            store_name: region,
                            ost: regionData.ost,
                            ost_full: regionData.ost_full,
                            way_to: regionData.way_to,
                            way_from: regionData.way_from,
                            orders_per_day: regionData.orders_per_day,
                            buyouts_per_day: regionData.buyouts_per_day,
                            need: regionData.need,
                            oborot_orders: regionData.oborot_orders,
                            oborot_buyouts: regionData.oborot_buyouts,
                            store_cost: regionData?.store_cost,
                        });
                    }
                });
                return newData.sort((a, b) => b.ost_full - a.ost_full);
            case 'bar':
                Object.entries(data.by_barcode || {}).forEach(([barcode, barcodeData]) => {
                    if (barcodeData.ost_wb_full > 0 || barcodeData.need > 0) {
                        const sizeInfo = data.sizes.find(size => size.barcode === barcode);
                        newData.push({
                            ...data,
                            key: `${data.nm_id}-${barcode}`,
                            barcode,
                            buyout_percent: barcodeData.buyout_percent,
                            size: sizeInfo?.size_name || '',
                            techSize: sizeInfo?.techSize || '',
                            wbSize: sizeInfo?.wbSize || '',
                            ost: barcodeData.ost_wb,
                            ost_full: barcodeData.ost_wb_full,
                            way_to: barcodeData.ost_wb_way_to,
                            way_from: barcodeData.ost_wb_way_from,
                            orders_per_day: barcodeData.orders_per_day,
                            buyouts_per_day: barcodeData.buyouts_per_day,
                            need: barcodeData.need,
                            oborot_orders: barcodeData.oborot_orders,
                            oborot_buyouts: barcodeData.oborot_buyouts,
                            store_cost: barcodeData?.store_cost,
                        });
                    }
                });
                return newData.sort((a, b) => b.ost_full - a.ost_full);
            case 'bar_store':
                Object.entries(data.by_barcode || {}).forEach(([barcode, barcodeData]) => {
                    const sizeInfo = data.sizes.find(size => size.barcode === barcode);
                    Object.entries(barcodeData.by_stores || {}).forEach(([store, storeData]) => {
                        if (storeData.ost_full > 0 || storeData.need > 0) {
                            newData.push({
                                ...data,
                                key: `${data.nm_id}-${barcode}-${store}`,
                                barcode,
                                buyout_percent: barcodeData.buyout_percent,
                                store_name: store,
                                size: sizeInfo?.size_name || '',
                                techSize: sizeInfo?.techSize || '',
                                wbSize: sizeInfo?.wbSize || '',
                                ost: storeData.ost,
                                ost_full: storeData.ost_full,
                                way_to: storeData.way_to,
                                way_from: storeData.way_from,
                                orders_per_day: storeData.orders_per_day,
                                buyouts_per_day: storeData.buyouts_per_day,
                                need: storeData.need,
                                oborot_orders: storeData.oborot_orders,
                                oborot_buyouts: storeData.oborot_buyouts,
                                store_cost: storeData?.store_cost,
                            });
                        }
                    });
                });
                return newData.sort((a, b) => b.ost_full - a.ost_full);
            case 'bar_reg':
                Object.entries(data.by_barcode || {}).forEach(([barcode, barcodeData]) => {
                    const sizeInfo = data.sizes.find(size => size.barcode === barcode);
                    Object.entries(barcodeData.by_regions || {}).forEach(([region, regionData]) => {
                        if (regionData.ost_full > 0 || regionData.need > 0) {
                            newData.push({
                                ...data,
                                key: `${data.nm_id}-${barcode}-${region}`,
                                barcode,
                                buyout_percent: barcodeData.buyout_percent,
                                store_name: region,
                                size: sizeInfo?.size_name || '',
                                techSize: sizeInfo?.techSize || '',
                                wbSize: sizeInfo?.wbSize || '',
                                ost: regionData.ost,
                                ost_full: regionData.ost_full,
                                way_to: regionData.way_to,
                                way_from: regionData.way_from,
                                orders_per_day: regionData.orders_per_day,
                                buyouts_per_day: regionData.buyouts_per_day,
                                need: regionData.need,
                                oborot_orders: regionData.oborot_orders,
                                oborot_buyouts: regionData.oborot_buyouts,
                                store_cost: regionData?.store_cost,
                            });
                        }
                    });
                });
                return newData.sort((a, b) => b.ost_full - a.ost_full);
            default:
                return [data];
        }
    };

    const filteredData = useFilteredCards(data, activeFilters);
    const [searchText, setSearchText] = useState('');
    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    useEffect(() => {
        // First expand the data
        const columns2 = getColumns()
        setColumns(columns2)
        let tableData = filteredData.flatMap(item => expandData(item));
        // Then apply quick filters to the expanded data
        tableData = applyQuickFilters(tableData);
        tableData = tableData.filter(record =>
            record.article?.toLowerCase().includes(searchText.toLowerCase()) || record.nm_id?.toString().toLowerCase().includes(searchText.toLowerCase())
        );
        tableData.sort((a, b) => b.ost_full - a.ost_full);
        setNewData(tableData);
    }, [activeFilters, selectorFilter2.group, quickFilters, data, searchText]);

    return (
        <>

            <Space direction="vertical" size={16} style={{width: '100%'}}>


                <Row gutter={[16, 16]} align="middle">

                    <Col xs={24} sm={12} md={6} lg={4}>
                        <Text strong>Группировка</Text>
                        <Select
                            style={{width: '100%'}}
                            value={selectorFilter2.group}
                            onChange={value => setSelectorFilter2('group', value)}
                            options={selectorFilter2.group_options}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={4}>
                        <Text strong>Дней для анализа</Text>
                        <Input
                            type="number"
                            value={selectorFilter2.days_calculate}
                            onChange={e => setSelectorFilter2('days_calculate', parseFloat(e.target.value) || 0)}
                            suffix="дней"
                        />
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={4}>
                        <Text strong>На сколько дней должно хватить</Text>
                        <Input
                            type="number"
                            value={selectorFilter2.need_days}
                            onChange={e => setSelectorFilter2('need_days', parseFloat(e.target.value) || 0)}
                            suffix="дней"
                        />
                    </Col>

                </Row>
            </Space>

            <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16, marginTop: 16}}>
                <Space wrap>
                    <Input
                        placeholder="Поиск по артикулу"
                        value={searchText}
                        onChange={handleSearch}
                    />
                    <Dropdown overlay={filterMenu} trigger={['click']}>
                        <Button icon={<FilterOutlined/>}>
                            Добавить фильтр
                        </Button>
                    </Dropdown>
                    <Button
                        icon={<ReloadOutlined/>}
                        onClick={fetchData}
                    >
                        Обновить
                    </Button>

                    {activeFilters.map((filter, index) => (
                        <Tag
                            key={index}
                            closable
                            onClose={() => handleRemoveFilter(index)}
                        >
                            {`${filter.type}: ${filter.value.label}`}
                        </Tag>
                    ))}
                </Space>
                <Space wrap>
                    {quickFilterButtons.map(button => (
                        <Button
                            key={button.key}
                            type={quickFilters[button.key] ? 'primary' : 'default'}
                            onClick={() => handleQuickFilter(button.key)}
                        >
                            {button.label}
                        </Button>
                    ))}
                </Space>
            </Space>

            <Table
                dataSource={newData}
                columns={columns}
                loading={loading}
                size="small"
                scroll={{x: 1500}}
                pagination={{
                    defaultPageSize: 50,
                    showSizeChanger: true,
                    showTotal: (total) => `Всего ${total - 1} товаров`
                }}
            />

        </>
    );
}
