import { EyeOutlined, HeartOutlined } from '@ant-design/icons';
import { Checkbox, Table } from 'antd';
import React from 'react';
import '../../products/components/styles.css';
import {
    formatCurrency,
    formatPercent,
    formatQuantity,
} from '../../../utils/formats';

const metrics = [
    { key: 'quantity', label: 'Остатки' },
    { key: 'orders', label: 'Заказов' },
    { key: 'orders_sum', label: 'Сумма заказов' },
    { key: 'marzha_unit', label: 'Маржинальность' },
    { key: 'oborot_buyouts', label: 'Оборачиваемость' },
    { key: 'price', label: 'Цена' },
    { key: 'buyout_percent', label: 'Выкуп' },
    { key: 'budget', label: 'Бюджет РК' },
    { key: 'drr', label: 'ДРР' },
    { key: 'views', label: 'Просмотров' },
    { key: 'clicks', label: 'Кликов' },
    { key: 'CTR', label: 'CTR' },
    { key: 'CPC', label: 'CPC' },
    { key: 'rating', label: 'Рейтинг' },
];

const getColumns = (
    filters_data,
    likeHideList,
    onChangeLikeHide,
    period,
    weekColumns
) => {
    const isMobile = window.innerWidth <= 769;
    const columns = [
        {
            title:'Артикул',
            dataIndex:'article',
            filters: filters_data?.article, filterSearch: true, onFilter: (value, record) => record.article?.indexOf(value) === 0,
            width:50,
        },
        {
            title: 'Информация',
            dataIndex: 'info',
            fixed: isMobile ? '': 'left',
            render: (text, record) => (
                <div style={{ height: '301px' }}>
                    <div style={{ borderBottom: '0.5px solid gray' }}> Артикул WB </div>
                    <div style={{ borderBottom: '0.5px solid gray' }}> {record.nm_id} </div>
                    <div style={{ borderBottom: '0.5px solid gray' }}> Артикул продавца </div>
                    <div style={{ borderBottom: '0.5px solid gray' }}> {record.article} </div>
                    <div style={{ borderBottom: '0.5px solid gray' }}> Название </div>
                    <div style={{ borderBottom: '0.5px solid gray' }}> {record.name} </div>
                    <div style={{ borderBottom: '0.5px solid gray' }}> Себестоимость </div>
                    <div style={{ borderBottom: '0.5px solid gray' }}> {record.sebes} </div>
                    <div style={{ borderBottom: '0.5px solid gray' }}>Фото</div>
                    <img src={record.img} alt='icon' style={{ minHeight: '120px !important', height: '127px', }} />
                </div>
            ),
            width: 125,
            ellipsis: true,
        },
        {
            title: 'Показатели',
            dataIndex: 'metrics',
            fixed: isMobile ? '': 'left',
            render: () => [
                <div style={{ height: '301px' }}>
                    {metrics.map((item, key) => (
                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key={item.key}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>,
            ],

            width: 125,
        },
    ];

    weekColumns.forEach(week => {
        columns.push({
            title: week,
            dataIndex: week,
            render: (text, record) => {
                const positionData = record.by_period[week] || {};
                return [
                    <div style={{ height: '301px' }}>
                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='quantity'
                        >
                            {formatQuantity(positionData.wb_ost.quantity) ||
                                '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='orders'
                        >
                            {formatQuantity(positionData.orders) || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='orders_sum'
                        >
                            {formatCurrency(positionData.orders_sum) || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='marzha_unit'
                        >
                            {formatPercent(positionData.marzha_unit) || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='oborot_buyouts'
                        >
                            {positionData.oborot_buyouts || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='price'
                        >
                            {formatCurrency(positionData.price_client) || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='buyout_percent'
                        >
                            {formatPercent(positionData.buyout_percent) || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='budget'
                        >
                            {positionData.budget || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='drr'
                        >
                            {positionData.drr || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='views'
                        >
                            {positionData.views || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='clicks'
                        >
                            {positionData.clicks || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='cpo'
                        >
                            {positionData.ctr || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='ctr'
                        >
                            {positionData.cpc || '-'}
                        </div>

                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key='rating'
                        >
                            {positionData.rating || '-'}
                        </div>
                    </div>,
                ];
            },
            width: 150,
        });
    });

    return columns;
};

const expandData = (cards) => {
    return cards.map((card) => {
        card['children'] = [];
        return {
            ...card,
        };
    });
};

export default function TableAnalPeriod({
    filters,
    likeHideList,
    onChangeLikeHide,
    loading,
    cards,
    period,
}) {

    const weekColumns = Object.keys(cards[0]?.by_period || {}).sort((a, b) => new Date(b) - new Date(a));

    let columns = getColumns(filters, likeHideList, onChangeLikeHide, period, weekColumns);

    let DataSource = cards;
    if (cards) {
        DataSource = expandData(cards);
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                scroll={{
                    y: 800,
                }}
                className='DaysTable'
                rowClassName={(record, index) => `my-custom-row`}
                loading={loading}
                pagination={{
                    defaultPageSize: 10,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} из ${total} артикулов`,
                }}
            />
        </>
    );
}

