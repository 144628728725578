import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {Card, Col, Flex, Progress, Row, Select, Typography} from 'antd';
import {SelectorSolo} from '../../components/Selectors';
import TableSalesReport from './components/salesReport';
import {DownOutlined, UpOutlined} from '@ant-design/icons';
import {ButtonExel} from '../../components/Buttons';
import {useAuth} from "../../hooks/useAuth";
import {apiAxios} from "../../utils/api_axios";
import {formatCurrency, formatCurrency1000} from "../../utils/formats";


const {Title} = Typography;

const ProgressCard = (item) => {
    return (
        <Card>
            <Title level={5} style={{margin: '0px'}}>
                {item.name}
            </Title>

            <Flex justify='space-between'>
                <span>% план заказано ({formatCurrency1000(item.orders_sum)} / {formatCurrency1000(item.plan_orders_sum)})</span>
                <div>
                    <span>{item.orders_percent}%</span>
                    <span
                        style={{fontSize: '10px', color: item.orders_prev_color}}
                    >
                        {item.orders_prev_dif}%<UpOutlined/>
                    </span>
                </div>
            </Flex>

            <Progress
                percent={item.orders_percent}
                style={{height: '0px'}}
                strokeColor={item.orders_color}
                showInfo={false}
            />

            <Flex justify='space-between'>
                <span>% план выкуп ({formatCurrency1000(item.buyouts_sum)} / {formatCurrency1000(item.plan_buyouts_sum)})</span>
                <div>
                    <span>{item.buyouts_percent}%</span>
                    <span style={{fontSize: '10px', color: item.buyouts_prev_color}}>
                        {item.buyouts_prev_dif}%<DownOutlined/>
                    </span>
                </div>
            </Flex>
            <Progress
                percent={item.buyouts_percent}
                style={{height: '0px'}}
                strokeColor={item.buyouts_color}
                showInfo={false}
            />
        </Card>
    );
};

function SalesReport() {
    const {user} = useAuth();
    const [data, setData] = useState();
    const [miniBlocks, setMiniBlocks] = useState([]);
    const [optionsWbaccs, setOptionsWbaccs] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(dayjs().month() + 1);
    const [optionsMonth, setOptionsMonth] = useState([]);

    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_ids: null,
        wbacc_options: [],
        group: 1,
        group_options: [{label: 'По дням', value: 1}, {label: 'По неделям', value: 2}]
    });

    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids});
        let wbaccOptions = [];

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id});
        }

        setSelectorFilter('wbacc_ids', user.wbacc_ids);
        setSelectorFilter('wbacc_options', wbaccOptions);
    };

    const fetchData = async () => {
        const data =await apiAxios.get('sales-report/get/', { 'wbacc_ids': selectorFilter.wbacc_ids, 'month': selectedMonth })
        console.log('data', data);
        setData(data['data']);
        setOptionsWbaccs(data['filter']?.wbaccs);
        setOptionsMonth(data['filter']?.months);
        setMiniBlocks(data['miniblocks']);
    };


    useEffect(() => {
        if (!selectorFilter.wbacc_ids && user.wbacc_ids) {
            fetchData1();
        }

        if (selectorFilter.wbacc_ids) {
            fetchData();
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_ids, selectorFilter.group, selectedMonth]);
    return (
        <>
            <Card>
                <Typography.Title level={1}>Отчет по продажам</Typography.Title>
                <Row type='flex' style={{gap: 10}}>
                    <Col lg={4} xs={24}>
                        <Typography.Title level={5}>Магазины</Typography.Title>
                        <Select
                            mode="multiple"
                            maxTagCount={'responsive'}
                            style={{width: '100%'}}
                            placeholder="Выберите магазин"
                            value={selectorFilter.wbacc_ids}
                            onChange={(value) => setSelectorFilter('wbacc_ids', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>

                    <Col lg={4} xs={24} style={{margin: '7px'}}>
                        <Title level={5} style={{margin: '0px'}}>
                            Месяц
                        </Title>
                        <SelectorSolo
                            options={optionsMonth}
                            selected={selectedMonth}
                            setSelected={setSelectedMonth}
                        />
                    </Col>
                    <div className='mt-8'><ButtonExel data={data}/></div>
                </Row>
            </Card>

            <Row>
                {miniBlocks?.map((item, index) => {
                    return (
                        <Col key={index} lg={6} md={12} xs={24}>
                            {ProgressCard(item)}
                        </Col>
                    );
                })}
            </Row>

            <Card>
                <TableSalesReport data={data}/>
            </Card>
        </>
    );
}

export default SalesReport;
