import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Card, Col, Row, Tooltip, Typography } from 'antd'
import { SelectorSolo } from '../../components/Selectors'
import { useAuth } from '../../hooks/useAuth'
import dayjs from 'dayjs'
import DatePickerRange from '../../components/dateRange'
import TableDetails from './components/marketingDetails'
import { defaultOnChangeLikeHide, setLikeHideOnLoadData } from '../components/LikeHide'
import { ButtonExel } from '../../components/Buttons'
import { useSidebar } from '../../Context/SideBarContext'
import { CiCircleQuestion } from 'react-icons/ci'
import SideBarDetails from '../home/sideBar/SideBarDetails'
import Sidebar from '../home/sideBar/SideBar'
import { apiAxios } from "../../utils/api_axios";

export default function Details() {
    const { user, isCsrf } = useAuth()
    const startDate = dayjs().date() === 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month')

    const [filters_data, setFiltersData] = useState([])
    const [period, setPeriod] = useState([startDate, dayjs()])
    const [selectedWbaccs, setSelectedWbaccs] = useState([])
    const [selected_hide, setSelectedHide] = useState(1)
    const [selected_like, setSelectedLike] = useState(1)
    const [likeHideList, setLikeHideList] = useState({ like: [], hide: [] })
    const [loading, setLoading] = useState(true)
    const [cards, setCards] = useState([])

    const { openSidebar } = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarDetails />)
    }, [openSidebar])

    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const fetchData = useCallback(async () => {
        try {
            setLoading(true)
            const fetchedData = await await apiAxios.get('marketing-detail/get/', {
                'wbacc_ids': [selectedWbaccs],
                'is_like': selected_like,
                'is_hide': selected_hide,
                'st': period[0].format('YYYY-MM-DD'),
                'en': period[1].format('YYYY-MM-DD'),
            })
            console.log('Fetched Data:', fetchedData)
            setFiltersData(fetchedData['filters'])
            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            if (fetchedData['cards']) {
                setCards(fetchedData['cards'])
            } else {
                console.warn('No data found in the fetched data')
                setCards([])
            }

            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
            setLoading(false)
        }
    }, [selectedWbaccs, selected_like, selected_hide, period])

    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : [])
    }, [user.wbacc_ids])

    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData()
        }
    }, [fetchData])

    const dateColumns = useMemo(() => {
        const today = new Date()
        return Object.keys(cards[0]?.by_days || {})
            .filter((date) => new Date(date) <= today)
            .sort((a, b) => new Date(b) - new Date(a))
    }, [cards])

    return (
        <>
            <Card>
                <Typography.Title level={1}>Маркетинг (детализация)</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Поставщики</Typography.Title>
                        <SelectorSolo options={filters_data?.wbaccs} selected={selectedWbaccs} setSelected={setSelectedWbaccs} />
                    </Col>
                    <ButtonExel data={cards} />
                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                    </Tooltip>
                </Row>
            </Card>
            <Sidebar />
            <Card>
                <TableDetails cards={cards} filters={filters_data} likeHideList={likeHideList} onChangeLikeHide={onChangeLikeHide} loading={loading} dateColumns={dateColumns} />
            </Card>
        </>
    )
}
