import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row, Select, Typography, DatePicker} from 'antd'
import { useAuth } from'../../hooks/useAuth'
import dayjs from 'dayjs'
import { apiAxios } from "../../utils/api_axios";
import PnlComponent from './components/pnlTable'
import { CalendarOutlined } from '@ant-design/icons'
import { XAxis, YAxis, CartesianGrid, Legend, Line, Tooltip, ComposedChart, ResponsiveContainer, Brush } from 'recharts'
import { ButtonExel } from '../../components/Buttons'
import { CiCircleQuestion } from 'react-icons/ci'
import { useSidebar } from '../../Context/SideBarContext'
import Sidebar from '../home/sideBar/SideBar'
import SideBarPnl from '../home/sideBar/SideBarPnl'

const { RangePicker } = DatePicker

const rangePresets = [
    {
        label: 'Последние 7 дней',
        value: [dayjs().add(-7, 'd'), dayjs()]
    },
    {
        label: 'Последние 14 дней',
        value: [dayjs().add(-14, 'd'), dayjs()]
    },
    {
        label: 'Последние 30 дней',
        value: [dayjs().add(-30, 'd'), dayjs()]
    },
    {
        label: 'Последние 2 месяца',
        value: [dayjs().add(-2, 'month'), dayjs()]
    },
    {
        label: 'Текущий месяц',
        value: [dayjs().startOf('month'), dayjs()]
    }
]

const DatePickerRange = (props) => {
    const { selected, setSelected } = props
    return <RangePicker allowClear={false} suffixIcon={<CalendarOutlined />} presets={rangePresets} defaultValue={selected} onChange={(value) => setSelected(value)} />
}

export default function PnL() {
    const { user } = useAuth()
    const [data, setData] = useState([])
    const [card, setCards] = useState([])
    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_ids: null,
        wbacc_options: [],
        group: 1,
        group_options: [
            { label: 'По дням', value: 1 },
            { label: 'По неделям', value: 2 }
        ]
    })
    const [period, setPeriod] = useState([dayjs().add(-30, 'd'), dayjs()])
    const [loading, setLoading] = useState(true)
    const [visibleLines, setVisibleLines] = useState([])

    const { openSidebar } = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        console.log('Button clicked')
        openSidebar(<SideBarPnl />)
    }, [openSidebar])

    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: user.wbacc_ids })
        let wbaccOptions = []

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({ label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id })
        }
        setSelectorFilter('wbacc_ids', user.wbacc_ids)
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const fetchData = async () => {
        setLoading(true)

        const fetchedData = await apiAxios.post('fin/stat/get/', {
            wbacc_ids: selectorFilter.wbacc_ids,
            st: period[0].format('YYYY-MM-DD'),
            en: period[1].format('YYYY-MM-DD'),
            is_week: selectorFilter.group === 2
        })
        console.log('fetchedData', fetchedData)
        const transformedData = fetchedData.data[0].by_period.map((item) => ({
            date: item.date,
            orders_sum: Number(item.orders_sum),
            profit: Number(item.profit),
            itog: Number(item.itog),
            sebes: Number(item.sebes),
            budget: Number(item.budget),
            supply: Number(item.supply),
            store: Number(item.store),
            penalty: Number(item.penalty),
            percent_budget: item.percent_budget,
            percent_commission: item.percent_commission,
            percent_delivery: item.percent_delivery,
            percent_nalog: item.percent_nalog,
            percent_penalty: item.percent_penalty,
            percent_profit: item.percent_profit,
            percent_sebes: item.percent_sebes,
            percent_store: item.percent_store,
            percent_supply: item.percent_supply,
            percent_other_services: item.percent_other_services
        }))

        setCards(transformedData)
        setData(fetchedData)
        setLoading(false)
    }

    const barKeys = [
        { key: 'percent_budget', fill: '#00008B', name: 'МРК' },
        { key: 'percent_commission', fill: '#413ea0', name: 'Комиссия' },
        { key: 'percent_delivery', fill: '#FF00FF', name: 'Логистика' },
        { key: 'percent_nalog', fill: '#FF1493', name: 'Налог' },
        { key: 'percent_penalty', fill: '#FFD700', name: 'Штраф' },
        { key: 'percent_profit', fill: '#ADFF2F', name: 'Чистая прибыль' },
        { key: 'percent_sebes', fill: '#FFA500', name: 'Себестоимость' },
        { key: 'percent_store', fill: '#8A2BE2', name: 'Хранение' },
        { key: 'percent_supply', fill: '#7FFF00', name: 'Приемка' },
        { key: 'percent_other_services', fill: '#FF4500', name: 'Другие расходы' }
    ]

    const lineKeys = [
        { key: 'profit', stroke: '#82ca9d', name: 'Чистая прибыль' },
        { key: 'itog', stroke: '#ff7300', name: 'Итого' },
        { key: 'sebes', stroke: '#413ea0', name: 'Себестоимость' },
        { key: 'budget', stroke: '#FF1493', name: 'МРК' },
        { key: 'supply', stroke: '#FFD700', name: 'Приемка' },
        { key: 'store', stroke: '#ADFF2F', name: 'Хранение' },
        { key: 'penalty', stroke: '#FF0000', name: 'Штраф' }
    ]

    useEffect(() => {
        if (!selectorFilter.wbacc_ids && user.wbacc_ids) {
            fetchData1()
        }

        if (selectorFilter.wbacc_ids) {
            fetchData()
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_ids, selectorFilter.group, period])
    return (
        <>
            <Card>
                <Typography.Title level={1}>Прибыль и убытки</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Магазины</Typography.Title>
                        <Select
                            mode='multiple'
                            maxTagCount={'responsive'}
                            style={{ width: '100%' }}
                            placeholder='Выберите магазин'
                            allowClear
                            value={selectorFilter.wbacc_ids}
                            onChange={(value) => setSelectorFilter('wbacc_ids', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Группировка</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            value={selectorFilter.group}
                            onChange={(value) => {
                                setSelectorFilter('group', value)
                            }}
                            options={selectorFilter.group_options}
                        />
                    </Col>
                    <ButtonExel data={data} />
                    <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                </Row>
            </Card>
            <Sidebar />
            <Card>
                {/*<Flex gap={50}>*/}
                    {/*<div className='flex flex-col gap-2 w-full max-w-[300px]'>*/}
                    {/*    <Typography.Title level={5}>Скрыть бары:</Typography.Title>*/}
                    {/*    <Select*/}
                    {/*        mode='multiple'*/}
                    {/*        style={{ maxWidth: '300px', width: '100%' }}*/}
                    {/*        placeholder='Выберите бары для скрытия'*/}
                    {/*        value={visibleBars}*/}
                    {/*        maxTagCount={'responsive'}*/}
                    {/*        onChange={setVisibleBars}*/}
                    {/*        options={[*/}
                    {/*            { label: 'МРК', value: 'percent_budget' },*/}
                    {/*            { label: 'Комиссия', value: 'percent_commission' },*/}
                    {/*            { label: 'Логистика', value: 'percent_delivery' },*/}
                    {/*            { label: 'Налог', value: 'percent_nalog' },*/}
                    {/*            { label: 'Штраф', value: 'percent_penalty' },*/}
                    {/*            { label: 'Чистая прибыль', value: 'percent_profit' },*/}
                    {/*            { label: 'Себестоимость', value: 'percent_sebes' },*/}
                    {/*            { label: 'Хранение', value: 'percent_store' },*/}
                    {/*            { label: 'Приемка', value: 'percent_supply' },*/}
                    {/*            { label: 'Другие расходы', value: 'percent_other_services' }*/}
                    {/*        ]}*/}
                    {/*    />*/}
                    {/*</div>*/}
                {/*    <div className='flex flex-col gap-2 w-full max-w-[300px]'>*/}
                {/*        <Typography.Title level={5}>Скрыть линии:</Typography.Title>*/}
                {/*        <Select*/}
                {/*            mode='multiple'*/}
                {/*            style={{ maxWidth: '300px', width: '100%' }}*/}
                {/*            placeholder='Выберите линии для скрытия'*/}
                {/*            maxTagCount={'responsive'}*/}
                {/*            value={visibleLines}*/}
                {/*            onChange={setVisibleLines}*/}
                {/*            options={[*/}
                {/*                { label: 'Чистая прибыль', value: 'profit' },*/}
                {/*                { label: 'Итого', value: 'itog' },*/}
                {/*                { label: 'Себестоимость', value: 'sebes' },*/}
                {/*                { label: 'МРК', value: 'budget' },*/}
                {/*                { label: 'Приемка', value: 'supply' },*/}
                {/*                { label: 'Хранение', value: 'store' },*/}
                {/*                { label: 'Штраф', value: 'penalty' }*/}
                {/*            ]}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</Flex>*/}
                {/*<ResponsiveContainer width='100%' height={500}>*/}
                {/*    <ComposedChart data={card} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>*/}
                {/*        <CartesianGrid strokeDasharray='3 3' />*/}
                {/*        <XAxis dataKey='date' />*/}
                {/*        <YAxis yAxisId='left' orientation='left' domain={[0, 160]} />*/}
                {/*        <Tooltip*/}
                {/*            formatter={(value, name, props) => {*/}
                {/*                const percentageKeys = [*/}
                {/*                    'percent_budget',*/}
                {/*                    'percent_commission',*/}
                {/*                    'percent_delivery',*/}
                {/*                    'percent_nalog',*/}
                {/*                    'percent_penalty',*/}
                {/*                    'percent_profit',*/}
                {/*                    'percent_sebes',*/}
                {/*                    'percent_store',*/}
                {/*                    'percent_supply',*/}
                {/*                    'percent_other_services'*/}
                {/*                ]*/}
                {/*                const isPercentageKey = percentageKeys.includes(props.dataKey)*/}
                {/*                const formattedValue = isPercentageKey ? `${value.toFixed(2)}%` : `${value.toLocaleString()} ₽`*/}
                {/*                return [formattedValue, name]*/}
                {/*            }}*/}
                {/*            contentStyle={{ whiteSpace: 'pre-wrap', fontSize: '12px', wordWrap: 'break-word', maxWidth: '230px' }}*/}
                {/*            labelFormatter={(label) => `Дата: ${label}`}*/}
                {/*        />*/}
                {/*        <Legend />*/}
                {/*        <Brush dataKey='date' height={30} stroke='#8884d8' />*/}
                {/*        {barKeys.map(({ key, fill, name }) => !visibleBars.includes(key) && <Bar key={key} yAxisId='left' dataKey={key} stackId='a' fill={fill} maxBarSize={30} name={name} />)}*/}
                {/*    </ComposedChart>*/}
                {/*</ResponsiveContainer>*/}
                <ResponsiveContainer width='100%' height={500}>
                    <ComposedChart data={card} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='date' />
                        <YAxis yAxisId='left' orientation='left' domain={[0, 'dataMax']} />
                        <Tooltip
                            formatter={(value, name, props) => {
                                const formattedValue = `${value.toLocaleString()} ₽`
                                return [formattedValue, name]
                            }}
                            contentStyle={{ whiteSpace: 'pre-wrap', fontSize: '12px', wordWrap: 'break-word', maxWidth: '230px' }}
                            labelFormatter={(label) => `Дата: ${label}`}
                        />
                        <Legend />
                        <Brush dataKey='date' height={30} stroke='#8884d8' />
                        {lineKeys.map(({ key, stroke, name }) => !visibleLines.includes(key) && <Line key={key} yAxisId='left' type='monotone' dot={false} dataKey={key} stroke={stroke} name={name} />)}
                    </ComposedChart>
                </ResponsiveContainer>
            </Card>
            <Card>
                <PnlComponent data={data.data} />
            </Card>
        </>
    )
}
