import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

const { Text } = Typography

const SideBarAnalPeriod = () => {
    return (
        <div>
            <Title level={2}>Описание страницы "Аналитика за период"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                Страница <span className='text-black'>"Аналитика за период"</span> предоставляет пользователю возможность детально анализировать данные за выбранный временной промежуток. Пользователи
                могут установить отображение данных по дням или по неделям, что позволяет гибко оценивать изменения в ключевых показателях на разных уровнях детализации.
            </Text>
            <Title level={3}>Описание данных:</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                Страница отображает статистику, включающую информацию о продажах, расходах, доходах, возвратах, выкупах и других показателях, которые помогают пользователю отслеживать эффективность
                бизнеса в течение выбранного периода. Этот анализ позволяет выявить тенденции, сезонные колебания, а также быстро реагировать на изменения в показателях для улучшения результатов и
                достижения стратегических целей.
            </Text>
        </div>
    )
}

export default SideBarAnalPeriod
