import {Button, Checkbox, Col, Divider, Flex, Form, Input, message, Row, theme, Typography} from 'antd';
import {FacebookFilled, GoogleOutlined, TwitterOutlined} from '@ant-design/icons';

import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {useAuth} from "../../core/hooks/useAuth";
import {Logo} from "../../components/simple/Logo";
import {apiAxios} from '../../core/api/api_axios';


const {Title, Text, Link} = Typography;


export const SignUpPage = () => {
    const {setIsAuth} = useAuth()
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const onFinish = async (values) => {
        console.log('Received values of form: ', values);
        const res = await apiAxios.post("user/signup/", values)
        if (res.success == true) {
            setIsAuth(true);
            message.open({
                type: 'success',
                content: 'Успешная регистрация',
                style: {
                    marginTop: '5vh',
                },
            });
            navigate('/');
        } else {
            const error_list = []
            for (let key in res.error) {
                error_list.push(res.error[key])
            }
            console.log(error_list);
            message.open({
                type: 'error',
                content: error_list.map((item) => item),
                style: {
                    marginTop: '5vh',
                },
            });
        }
    }

    const {
        token: {colorPrimary},
    } = theme.useToken();
    const isMobile = window.innerWidth <= 769;


    return (
        <Row style={{minHeight: isMobile ? 'auto' : '100vh', overflow: 'hidden'}}>
            <Col xs={24} lg={12}>
                <Flex
                    vertical
                    align="center"
                    justify="center"
                    className="text-center"
                    style={{background: colorPrimary, height: '100%', padding: '1rem'}}
                >
                    <Logo color="white"/>
                    <Title level={2} className="text-white">
                        Добро пожаловать
                    </Title>
                    <Text className="text-white" style={{fontSize: 18}}>

                    </Text>
                </Flex>
            </Col>
            <Col xs={24} lg={12}>
                <Flex vertical
                      align={isMobile ? 'center' : 'flex-start'}
                      justify="center"
                      gap="middle"
                      style={{height: '100%', padding: '2rem'}}>
                    <Title className="m-0">Создать аккаунт</Title>
                    <Flex gap={4}>
                        <Text>Уже есть аккаунт?</Text>
                        <Link href='/login'>Войти</Link>
                    </Flex>

                    <Form
                        name="register"
                        layout="vertical"
                        form={form}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 24}}
                        initialValues={{remember: true}}
                        autoComplete="off"
                        requiredMark={false}
                        onFinish={onFinish}
                        scrollToFirstError
                    >
                        <Row gutter={[8, 0]}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Имя"
                                    name="first_name"
                                    hasFeedback

                                    rules={[
                                        {required: true, message: 'Пожалуйста, введите имя'},
                                        {pattern: /^[^\s]+$/, message: 'Поле не должно содержать пробелов',},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Фамилия"
                                    name="last_name"
                                    hasFeedback

                                    rules={[
                                        {required: true, message: 'Пожалуйста, введите фамилию'},
                                        {pattern: /^[^\s]+$/, message: 'Поле не должно содержать пробелов',},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Никнейм(может использоваться для авторизации)"
                                    name="username"
                                    hasFeedback

                                    rules={[
                                        {required: true, message: 'Пожалуйста, введите username'},
                                        {pattern: /^[^\s]+$/, message: 'Поле не должно содержать пробелов',},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Почта"
                                    name="email"
                                    hasFeedback

                                    rules={[
                                        {type: 'email', message: 'Пожалуйста, введите корректную почту',},
                                        {required: true, message: 'Пожалуйста, введите почту',},
                                        {pattern: /^[^\s]+$/, message: 'Поле не должно содержать пробелов',},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Пароль"
                                    name="password"
                                    hasFeedback

                                    rules={[
                                        {required: true, message: 'Пожалуйста, введите пароль'},
                                        {pattern: /^[^\s]+$/, message: 'Поле не должно содержать пробелов',},
                                    ]}
                                >
                                    <Input.Password/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Подтвердите пароль"
                                    name="confirm"
                                    dependencies={['password']}
                                    hasFeedback

                                    rules={[
                                        {required: true, message: 'Пожалуйста, подтвердите пароль',},
                                        {pattern: /^[^\s]+$/, message: 'Поле не должно содержать пробелов',},
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Пароли не совпадают!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password/>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Form.Item>

                            <Button type="primary" htmlType="submit" size="middle">
                                Зарегистрироваться
                            </Button>
                        </Form.Item>
                        <Form.Item name="terms" valuePropName="checked">
                            <Flex>
                                        <span>
                                            Регистрируясь, вы соглашаетесь с
                                            <Link href="/terms"> правилами сервиса </Link>
                                             и даете согласие на
                                            <Link href="/terms"> обработку ваших персональных данных </Link>
                                             в соответствии с нашей
                                            <Link href="/terms"> политикой конфиденциальности.</Link>
                                            </span>
                            </Flex>
                        </Form.Item>
                    </Form>
                </Flex>
            </Col>
        </Row>
    );
};
