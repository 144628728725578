import { EyeOutlined, HeartOutlined } from '@ant-design/icons';
import { Checkbox, Table } from 'antd';
import React from 'react';
import './styles.css';
import {
    formatCurrency,
    formatPercent,
    formatQuantity,
} from '../../../../core/utils/formats';

const metrics = [
    { key: 'quantity', label: 'Остатки' },
    { key: 'orders', label: 'Заказов' },
    { key: 'orders_sum', label: 'Сумма заказов' },
    { key: 'marzha_unit', label: 'Маржинальность' },
    { key: 'oborot_buyouts', label: 'Оборачиваемость' },
    { key: 'price', label: 'Цена' },
    { key: 'buyout_percent', label: 'Выкуп' },
    { key: 'budget', label: 'Бюджет РК' },
    { key: 'drr', label: 'ДРР' },
    { key: 'views', label: 'Просмотров' },
    { key: 'clicks', label: 'Кликов' },
    { key: 'CTR', label: 'CTR' },
    { key: 'CPC', label: 'CPC' },
    { key: 'rating', label: 'Рейтинг' },
];

// Функция получения колонок
const getColumns = (
    filters_data,
    likeHideList,
    onChangeLikeHide,
    period,
    columns, // Данные по дням или неделям
    viewMode // Режим просмотра: 'days' или 'weeks'
) => {
    const isMobile = window.innerWidth <= 769;
    const columnData = viewMode === 'days' ? columns.by_days : columns.by_weeks;

    // Основные колонки
    const baseColumns = [
        {
            title: 'Артикул',
            dataIndex: 'article',
            filters: filters_data?.article,
            filterSearch: true,
            onFilter: (value, record) => record.article?.indexOf(value) === 0,
            width: 80,
        },
        {
            title: 'Информация',
            dataIndex: 'info',
            fixed: isMobile ? '' : 'left',
            render: (text, record) => (
                <div style={{ height: '301px' }}>
                    <div style={{ borderBottom: '0.5px solid gray' }}>Артикул WB</div>
                    <div style={{ borderBottom: '0.5px solid gray' }}>{record.nm_id}</div>
                    <div style={{ borderBottom: '0.5px solid gray' }}>Артикул продавца</div>
                    <div style={{ borderBottom: '0.5px solid gray' }}>{record.article}</div>
                    <div style={{ borderBottom: '0.5px solid gray' }}>Название</div>
                    <div style={{ borderBottom: '0.5px solid gray' }}>{record.name}</div>
                    <div style={{ borderBottom: '0.5px solid gray' }}>Себестоимость</div>
                    <div style={{ borderBottom: '0.5px solid gray' }}>{record.sebes}</div>
                    <div style={{ borderBottom: '0.5px solid gray' }}>Фото</div>
                    <img
                        src={record.img}
                        alt='icon'
                        style={{ minHeight: '120px !important', height: '127px' }}
                    />
                </div>
            ),
            width: 125,
            ellipsis: true,
        },
        {
            title: 'Показатели',
            dataIndex: 'metrics',
            fixed: isMobile ? '' : 'left',
            render: () => (
                <div style={{ height: '301px' }}>
                    {metrics.map((item) => (
                        <div style={{ borderBottom: '0.5px solid gray' }} key={item.key}>
                            {item.label}
                        </div>
                    ))}
                </div>
            ),
            width: 125,
        },
    ];

    // Добавляем динамические колонки для дней или недель
    if (columnData) {
        columnData.forEach((date) => {
            baseColumns.push({
                title: date,
                dataIndex: date,
                render: (text, record) => {
                    const positionData = viewMode === 'days' ? record.by_days[date] : record.by_weeks[date] || {};
                    return (
                        <div style={{ height: '301px' }}>
                            {metrics.map((item) => (
                                <div style={{ borderBottom: '0.5px solid gray' }} key={item.key}>
                                    {positionData[item.key] ? formatQuantity(positionData[item.key]) : '-'}
                                </div>
                            ))}
                        </div>
                    );
                },
                width: 150,
            });
        });
    }

    return baseColumns;
};

const expandData = (cards) => {
    return cards.map((card) => {
        card['children'] = [];
        return {
            ...card,
        };
    });
};

// Основной компонент таблицы
export default function TableAnalDays({
    filters,
    likeHideList,
    onChangeLikeHide,
    loading,
    cards,
    period,
    viewMode = 'days', // Добавляем параметр для режима просмотра
}) {
    // Определяем колонки по дням или неделям
    const weekColumns = Object.keys(cards[0]?.by_weeks || {}).sort((a, b) => new Date(b) - new Date(a));
    const dayColumns = Object.keys(cards[0]?.by_days || {}).sort((a, b) => new Date(b) - new Date(a));

    // Передаем колонки в зависимости от режима
    let columns = getColumns(filters, likeHideList, onChangeLikeHide, period, { by_days: dayColumns, by_weeks: weekColumns }, viewMode);

    let DataSource = cards;
    if (cards) {
        DataSource = expandData(cards);
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                scroll={{
                    y: 800,
                }}
                className='DaysTable'
                rowClassName={(record, index) => `my-custom-row`}
                loading={loading}
                pagination={{
                    defaultPageSize: 10,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} из ${total} артикулов`,
                }}
            />
        </>
    );
}
