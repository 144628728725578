import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

const { Text } = Typography
const SideBarPrices = () => {
    const metrics = [
        {
            label: 'Цена до скидки',
            description: 'изначальная цена товара до применения каких-либо скидок. Это базовая цена, на основе которой рассчитываются последующие показатели.'
        },
        {
            label: 'Скидка',
            description: 'процент или сумма скидки, применяемая к изначальной цене товара. Отображает снижение цены для покупателя.'
        },
        {
            label: 'Цена после скидки',
            description: 'цена товара после вычета скидки. Это стоимость, по которой покупатель может приобрести товар с учетом скидки.'
        },
        {
            label: 'Цена после СПП',
            description: 'цена товара после применения скидок, рассчитанных с учетом специальных программ (СПП), например, привилегий для постоянных клиентов.'
        },
        {
            label: 'Цена после кошелька',
            description:
                'финальная цена товара после применения дополнительных скидок, включая "скидку из кошелька" или бонусы, предоставляемые платформой. Поле редактируемое, позволяя вручную корректировать эту цену.'
        },
        {
            label: 'Прибыль',
            description: 'ожидаемая прибыль с продажи товара после применения всех скидок и вычета себестоимости.'
        },
        {
            label: 'Наценка',
            description: 'процентная наценка, показывающая разницу между себестоимостью и продажной ценой после всех скидок. Позволяет увидеть, насколько цена превышает себестоимость.'
        },
        {
            label: 'Маржинальность',
            description:
                'показатель маржинальности, выраженный в процентах, который показывает, какую долю от продажной цены составляет прибыль. Высокий процент маржинальности означает более высокую доходность.'
        }
    ]

    return (
        <div>
            <Title level={3}>Описание страницы "Ценообразование"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                Страница <span className='text-black'>"Ценообразование"</span> предназначена для анализа структуры цен на товары с учетом всех затрат, связанных с их реализацией. Таблица на этой
                странице отображает детализированную информацию о себестоимости товаров, различных расходах и других показателях, влияющих на конечную цену продукта. Это позволяет пользователям
                оценивать фактическую маржинальность товаров и помогает принимать обоснованные решения по корректировке цен и управлению доходностью.
            </Text>
            <Title level={4}>Калькулятор новой цены</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                <span className='text-black'>"Калькулятор новой цены"</span> предназначен для интерактивного расчета новой цены товара с учетом скидок, наценок и ожидаемой прибыли. Это позволяет
                пользователям быстро изменять параметры цены и видеть, как они повлияют на доходность товара. Калькулятор помогает выбрать оптимальную стратегию ценообразования для увеличения продаж и
                повышения маржинальности.
            </Text>
            <Title level={4}>Описание полей калькулятора новой цены:</Title>
            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {metrics.map((metric, index) => (
                    <li key={index} className='text-base text-gray-600'>
                        <span className='font-bold text-black'>{metric.label}</span> — {metric.description}
                    </li>
                ))}
            </div>
        </div>
    )
}

export default SideBarPrices
