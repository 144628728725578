import React, { useState } from 'react'
import { Button, Drawer } from 'antd'
import LeftMenu from './LeftMenu'
import RightMenu from './RightMenu'
import { MenuOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

function Navbar() {
    const [visible, setVisible] = useState(false)
    const showDrawer = () => {
        setVisible(!visible)
    }

    return (
        <nav className='navbar' style={{ marginBottom: 0, zIndex: 50 }}>
            <div className='nav-header'>
                <Link to='/' className='logo'>
                    <img className='w-[194px]' src='assets/images/logo.svg' />
                </Link>
                <div className='navbar-menu'>
                    <div className='leftMenu'>
                        <LeftMenu mode={'horizontal'} />
                    </div>
                    <Button className='menuButton' type='text' onClick={showDrawer}>
                        <MenuOutlined />
                    </Button>
                    <div className='rightMenu'>
                        <RightMenu mode={'horizontal'} />
                    </div>

                    <Drawer title={'BB Data'} placement='right' closable={true} onClose={showDrawer} visible={visible} style={{ zIndex: 99999 }}>
                        <LeftMenu mode={'inline'} showDrawer={showDrawer} />
                        <RightMenu mode={'inline'} showDrawer={showDrawer} />
                    </Drawer>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
