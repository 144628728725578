import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Typography, Tag, Tooltip, Select, Input } from 'antd';
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Text } = Typography;

const SalesTable = ({ data, loading, onRefresh }) => {
    const [filteredData, setFilteredData] = useState(data);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [searchArticle, setSearchArticle] = useState('');

    // Extract unique regions and warehouses
    const regions = [...new Set(data?.filter(item => item.region).map(item => item.region))];
    const warehouses = [...new Set(data?.filter(item => item.warehouse_name).map(item => item.warehouse_name))];

    useEffect(() => {
        let result = data || [];

        if (selectedRegion) {
            result = result.filter(item => item.region === selectedRegion);
        }

        if (selectedWarehouse) {
            result = result.filter(item => item.warehouse_name === selectedWarehouse);
        }

        if (searchArticle) {
            result = result.filter(item => 
                item.nm_id?.toString().includes(searchArticle)
            );
        }

        setFilteredData(result);
    }, [data, selectedRegion, selectedWarehouse, searchArticle]);

    const handleRefresh = () => {
        setSelectedRegion(null);
        setSelectedWarehouse(null);
        setSearchArticle('');
        onRefresh?.();
    };

    const columns = [
        {
            title: 'Дата продажи',
            dataIndex: 'sale_date',
            key: 'sale_date',
            render: (text) => dayjs(text).format('YYYY-MM-DD'),
            sorter: (a, b) => dayjs(a.sale_date).unix() - dayjs(b.sale_date).unix(),
        },
        {
            title: 'Время продажи',
            dataIndex: 'sale_date_time',
            key: 'sale_date_time',
            render: (text) => dayjs(text).format('HH:mm:ss'),
        },
        {
            title: 'Артикул WB',
            dataIndex: 'nm_id',
            key: 'nm_id',
        },
        {
            title: 'Артикул продавца',
            dataIndex: 'article',
            key: 'article',
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            render: (text) => (
                <Tooltip title={text}>
                    <div style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {text}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: 'Штрихкод',
            dataIndex: 'barcode',
            key: 'barcode',
        },
        {
            title: 'Размер',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            render: (price) => price ? `${price} ₽` : '-',
            sorter: (a, b) => (a.price || 0) - (b.price || 0),
        },
        {
            title: 'Склад',
            dataIndex: 'warehouse_name',
            key: 'warehouse_name',
        },
        {
            title: 'Регион',
            dataIndex: 'region',
            key: 'region',
        },
    ];

    return (
        <div>
            <Space style={{ marginBottom: 16, marginTop: 16 }} size="middle">
                <Space>
                    <Select
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Фильтр по региону"
                        onChange={setSelectedRegion}
                        value={selectedRegion}
                        options={regions.map(region => ({ label: region, value: region }))}
                    />
                    <Select
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Фильтр по складу"
                        onChange={setSelectedWarehouse}
                        value={selectedWarehouse}
                        options={warehouses.map(warehouse => ({ label: warehouse, value: warehouse }))}
                    />
                    <Input.Search
                        placeholder="Поиск по артикулу WB"
                        style={{ width: 200 }}
                        value={searchArticle}
                        onChange={(e) => setSearchArticle(e.target.value)}
                        allowClear
                    />
                </Space>
                <Button icon={<DownloadOutlined />}>СКАЧАТЬ</Button>
                <Button 
                    icon={<ReloadOutlined />} 
                    onClick={handleRefresh}
                    loading={loading}
                >
                    ОБНОВИТЬ
                </Button>
            </Space>
            <Table
                columns={columns}
                dataSource={filteredData}
                loading={loading}
                rowKey={(record) => record.rid + record.sale_date_time}
                pagination={{
                    showSizeChanger: true,
                    showTotal: (total) => `Всего ${total} записей`,
                    defaultPageSize: 20,
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                size="small"
                scroll={{ x: 'max-content' }}
            />
        </div>
    );
};

export default SalesTable;
