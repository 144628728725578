import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from "./serviceWorker";
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./core/config/Context";
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <ConfigProvider
  theme={{
    components: {
      Table: {
        rowHoverBg:'#c9dffd8b'
      },
    },
  }}
>
     <AuthProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </AuthProvider>
</ConfigProvider>
    </>
);

reportWebVitals();