import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './Context/Context'
import { ConfigProvider } from 'antd'
import { SidebarProvider } from './Context/SideBarContext'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <>
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        rowHoverBg: '#c9dffd'
                    }
                }
            }}
        >
             <QueryClientProvider client={queryClient}><SidebarProvider>
                <AuthProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </AuthProvider>
            </SidebarProvider></QueryClientProvider>
        </ConfigProvider>
    </>
)

reportWebVitals()
