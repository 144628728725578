import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Button,
    Checkbox,
    Divider,
    Flex,
    Form,
    Input,
    Switch,
    Table,
} from 'antd';
import {
    PlusOutlined,
    MinusOutlined,
    HeartOutlined,
    EyeOutlined,
    UploadOutlined,
    ReloadOutlined, CheckOutlined,
} from '@ant-design/icons';
import {
    formatCurrency,
    formatDays,
    formatPercent,
    formatQuantity,
} from '../../../../core/utils/formats';

import './styles.css';

const getColumns = (filters_data, likeHideList, onChangeLikeHide, isHideColumns, sales_info, isOzon) => {
    sales_info = sales_info ? sales_info : [];
    const isMobile = window.innerWidth <= 769;
    let columns = [
        {title: '', dataIndex: '', width: 20, fixed: isMobile ? '': 'left',},
        {title: <HeartOutlined/>, width: 21, render: (text, record) => (<Checkbox checked={likeHideList['like'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'like')}/>), hidden: isHideColumns,},
        {title: <EyeOutlined/>, width: 21, render: (text, record) => (<Checkbox checked={likeHideList['hide'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'hide')}/>), hidden: isHideColumns,},
        {title: '', dataIndex: 'img', width: 20, ellipsis: true, fixed: isMobile ? '': 'left', render: (text) => <img src={text} style={{height: '20px'}}></img>,},
        {title: 'Артикул продавца', dataIndex: 'article', width: 100, ellipsis: true, fixed: isMobile ? '': 'left', render: (text) => <a href={`product/${text}`}>{text}</a>, hidden: isHideColumns,
        filters: filters_data?.article, filterSearch: true, onFilter: (value, record) => record.article == value || record.is_fix,},
        {title: 'Артикул', dataIndex: isOzon ? 'offer_id' : 'nm_id', width: 100, ellipsis: true, fixed: isMobile ? '': 'left',},
        {title: 'Карточка', dataIndex: 'imt_id', width: 80, ellipsis: true, filters: filters_data['imt_ids'], filterSearch: true, hidden: isOzon, onFilter: (value, record) => record.imt_id == value || record.is_fix,},
        {title: 'Категория', dataIndex: 'category', key: 'category', width: 100, ellipsis: true, fixed: isMobile ? '': 'left', filters: filters_data?.category, filterSearch: true, onFilter: (value, record) => record.category == value || record.is_fix,},
        {title: 'Модель', dataIndex: 'material', width: 100, ellipsis: true, filters: filters_data['material'], fixed: isMobile ? '': 'left', filterSearch: true, onFilter: (value, record) => record.material?.indexOf(value) === 0 || record.is_fix,},
        {title: 'X', dataIndex: 'coef', width: 30, ellipsis: true},
        {
            title: 'Остатки FBO', dataIndex: 'ost', width: 70, ellipsis: true, fixed: isMobile ? '': 'left', className: 'red-color', render: (text) => formatQuantity(text),
            sorter: (a, b, sortOrder) => {
                if (a.is_fix) {
                    return sortOrder == 'ascend' ? 1 : -1;
                }
                if (b.is_fix) {
                    return sortOrder == 'ascend' ? 1 : -1;
                }
                return a.ost - b.ost;
            },

        },
        {title: 'В пути от', dataIndex: 'way_to', width: 70, ellipsis: true, sorter: (a, b) => a.way_to - b.way_to, hidden: isHideColumns || isOzon, className: 'red-color', render: (text) => formatQuantity(text),},
        {title: 'В пути до', dataIndex: 'way_from', width: 70, ellipsis: true, sorter: (a, b) => a.way_from - b.way_from, hidden: isHideColumns || isOzon, className: 'red-color', render: (text) => formatQuantity(text),},
        {title: 'Ост. МС', dataIndex: 'ms_ost', width: 70, ellipsis: true, hidden: isHideColumns, className: 'red-color', render: (text) => formatQuantity(text),},
        {title: 'Транзит', dataIndex: 'ms_transfer', width: 70, ellipsis: true, sorter: (a, b) => a.transit - b.transit, hidden: isHideColumns, className: 'red-color', render: (text) => formatQuantity(text),},
        {title: 'Шьется', dataIndex: 'ms_production', width: 70, ellipsis: true, hidden: isHideColumns, className: 'red-color', render: (text) => formatQuantity(text),},
        {title: 'Цвет', dataIndex: 'color', width: 100, ellipsis: true, filters: filters_data['color'], filterSearch: true, onFilter: (value, record) => record.color?.indexOf(value) === 0, hidden: isHideColumns,},
        {title: 'Рейтинг', dataIndex: 'rating', width: 50, ellipsis: true, hidden: isHideColumns,},
        {title: 'ABC', dataIndex: 'abc', width: 50, ellipsis: true, filters: filters_data['abc'], filterSearch: true, onFilter: (value, record) => record.abc?.indexOf(value) === 0, hidden: isHideColumns,},
        {title: 'Сезон', dataIndex: 'sezon', width: 60, ellipsis: true, filters: filters_data['sezon'], filterSearch: true, onFilter: (value, record) => record.sezon?.indexOf(value) === 0, hidden: isHideColumns,},
        {title: 'Статус', dataIndex: 'status', width: 60, ellipsis: true, filters: filters_data['status'], filterSearch: true, onFilter: (value, record) => record.status?.indexOf(value) === 0, hidden: isHideColumns,},
        {title: 'Ср. продажи', dataIndex: 'speed14', width: 70, ellipsis: true, hidden: isHideColumns, render: (text) => formatQuantity(text),},
        {title: 'Обор.', dataIndex: 'oborot', width: 50, ellipsis: true, hidden: isHideColumns, render: (text) => formatDays(text),},
        {title: 'Объем', dataIndex: 'size_value', width: 50, ellipsis: true, hidden: isHideColumns,},
        {title: 'Выкуп', dataIndex: 'buyout_percent', width: 50, ellipsis: true, fixed: isMobile ? '': 'left', hidden: isHideColumns, render: (text) => formatPercent(text),},
        {
            title: 'Калькулятор новой цены',
            className: 'yellow-color',
            children: [
                {title: 'Цена до скидки', dataIndex: 'new_price', width: 60, className: 'yellow-color', render: (text) => formatCurrency(text),},
                {title: 'Скидка', dataIndex: 'new_discount', width: 50, className: 'yellow-color', render: (text) => formatPercent(text),},
                {title: 'Цена после скидки', dataIndex: 'new_price_with_discount', width: 50, className: 'yellow-color', render: (text) => formatCurrency(text),},
                {title: 'Цена после СПП', dataIndex: 'new_price_client', width: 50, className: 'yellow-color', render: (text) => formatCurrency(text),},
                {title: 'Цена после кошелька', dataIndex: 'new_price_with_wb_discount', width: 50, editable: true, ellipsis: true, className: 'light-yellow-color',},
                {title: 'Прибыль', dataIndex: 'new_profit', width: 50, className: 'yellow-color', render: (text) => formatCurrency(text),},
                {title: 'Наценка', dataIndex: 'new_nacenka', width: 50, className: 'yellow-color', render: (text) => formatPercent(text),},
                {title: 'Маржинальность', dataIndex: 'new_marzha', width: 50, className: 'yellow-color', render: (text) => formatPercent(text),},
            ],
        },
        {
            title: 'ЗАГРУЗКА ЦЕНЫ',
            className: 'red-color',
            children: [
                {title: 'Цена до скидки', dataIndex: 'load_price', width: 50, editable: true, ellipsis: true, className: 'light-yellow-color',},
                {title: 'Скидка', dataIndex: 'load_discount', width: 50, editable: true, ellipsis: true, className: 'light-yellow-color',},
                {title: 'Цена после Скидки', dataIndex: 'load_price_client', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
            ],
        },
        {
            title: 'Текущая цена',
            className: 'blue-color',
            children: [

                {title: 'Цена до скидки', dataIndex: 'price', width: 60, className: 'blue-color', render: (text) => formatCurrency(text),},
                {title: 'Скидка', dataIndex: 'discount', width: 50, className: 'blue-color', render: (text) => formatPercent(text),},
                {title: 'Лимит цены', dataIndex: 'limit', width: 50, className: 'blue-color', render: (text) => formatCurrency(text),},
                {title: 'Цена после скидки', dataIndex: 'price_with_discount', width: 50, className: 'blue-color', render: (text) => formatCurrency(text),},
                {title: 'СПП, %', dataIndex: 'spp', width: 50, className: 'blue-color', render: (text) => formatPercent(text),},
                {title: 'Цена после СПП', dataIndex: 'price_client', width: 50, className: 'blue-color', render: (text) => formatCurrency(text),},
                {title: 'Кошелек', dataIndex: 'wb_discount', width: 50, className: 'blue-color', render: (text) => formatPercent(text),},
                {title: 'ЦЕНА С КОШЕЛЬКОМ', dataIndex: 'price_with_wb_discount', width: 50, className: 'blue-color', render: (text) => formatCurrency(text),},
                {title: 'Прибыль', dataIndex: 'profit', width: 50, className: 'blue-color', render: (text) => formatCurrency(text),},
                {title: 'Наценка', dataIndex: 'nacenka', width: 50, className: 'blue-color', render: (text) => formatPercent(text),},
                {title: 'Маржинальность', dataIndex: 'marzha', width: 50, className: 'blue-color', render: (text) => formatPercent(text),},
            ],
        },

        {
            title: 'Себестоимость',
            className: 'green-color',
            children: [
                {title: 'Себес', dataIndex: 'sebes', width: 50, className: 'green-color', render: (text) => formatCurrency(text),},
                {title: 'Комиссия %', dataIndex: 'sebes_commission_wb', width: 50, className: 'green-color', render: (text) => formatPercent(text),},
                {title: 'Хранение', dataIndex: 'sebes_store_cost', width: 50, className: 'green-color', render: (text) => formatCurrency(text),},
                {title: 'ФФ', dataIndex: 'sebes_ff', width: 50, className: 'green-color', render: (text) => formatCurrency(text),},
                {title: 'Логистика', dataIndex: 'sebes_delivery', width: 50, className: 'green-color', render: (text) => formatCurrency(text),},
                {title: 'Приемка', dataIndex: 'sebes_supply', width: 50, className: 'green-color', render: (text) => formatCurrency(text),},
                {title: 'Маркетинг', dataIndex: 'sebes_marketing', width: 50, className: 'green-color', render: (text) => formatCurrency(text),},
                {title: 'Налог', dataIndex: 'sebes_nalog', width: 50, className: 'green-color', render: (text) => formatCurrency(text),},
                {title: 'Другие расходы', dataIndex: 'sebes_other_services', width: 50, className: 'green-color', render: (text) => formatCurrency(text),},
                {title: 'Себ-ть ИТОГО', dataIndex: 'sebes_itog', width: 50, className: 'green-color', render: (text) => formatCurrency(text),},
                {title: 'Расходы с комиссией', dataIndex: 'sebes_itog_with_commission', width: 50, className: 'green-color', render: (text) => formatCurrency(text),},
            ],
        },

        {
            title: 'Акция',
            children: [
                {title: 'Цена до скидки', dataIndex: 'sale_price', width: 60, render: (text) => formatCurrency(text),},
                {title: 'Скидка', dataIndex: 'sale_discount', width: 50, render: (text) => formatPercent(text),},
                {title: 'Цена для участия', dataIndex: 'sale_price_with_discount', width: 50, editable: true, ellipsis: true, className: 'light-yellow-color',},
                {title: 'Цена после СПП', dataIndex: 'sale_price_client', width: 50, render: (text) => formatCurrency(text),},
                {title: 'Цена после WB', dataIndex: 'sale_price_with_wb_discount', width: 50, render: (text) => formatCurrency(text),},
                {title: 'Прибыль', dataIndex: 'sale_profit', width: 50, render: (text) => formatCurrency(text),},
                {title: 'Наценка', dataIndex: 'sale_nacenka', width: 50, render: (text) => formatPercent(text),},
                {title: 'Маржинальность', dataIndex: 'sale_marzha', width: 50, render: (text) => formatPercent(text),},
            ],
        },
    ];
    sales_info.forEach((item, index) => {
        columns.push({
            title: item.name,
            ellipsis: true,
            children: [{title: `${item.start}-${item.end}`, dataIndex: `sale${index}`, width: 75, render: (text) => formatCurrency(text),}],
        });
    });
    return columns;
};


const expandData = (cards) => {
    return cards.map((card) => {

        if (!card.by_sku) {
            return card
        }
        card['children'] = [];
        for (let sku in card.by_sku) {
            let item = card.by_sku[sku];
            item.subject_name = card.subject_name;
            item.subject_id = card.subject_id;
            item.material = card.material;
            item.color = card.color;
            item.abc = card.abc;
            item.sezon = card.sezon;
            item.status = card.status;

            card['children'].push(item);
        }
        return card;
    });
};
const EditableContext = React.createContext(null);
const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({title, editable, children, dataIndex, record, handleSave, ...restProps}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({[dataIndex]: record[dataIndex],});
    };
    const validationRules = {
        load_price: [{transform: (value) => Number(value), type: 'number', message: `Нужно ввести число`,}],
        load_discount: [{transform: (value) => Number(value), type: 'number', message: `Нужно ввести число`,}],
        new_price_with_wb_discount: [{transform: (value) => Number(value), type: 'number', message: `Нужно ввести число`,}],
    };
    const specificRules = validationRules[dataIndex] || [];

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({...record, ...values,});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item style={{margin: 0}} name={dataIndex} rules={specificRules}><Input ref={inputRef} onPressEnter={save} onBlur={save}/></Form.Item>
        ) : (
            <div className='editable-cell-value-wrap' style={{paddingInlineEnd: 24,}} onClick={toggleEdit}>{children}</div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

function calculateStartPrices(priceWithWBDisc, wBDisc, spp, discount, isOzon) {
    priceWithWBDisc = Number(priceWithWBDisc);
    wBDisc = Number(wBDisc);
    spp = Number(spp);
    discount = Number(discount);

    let price = (((((priceWithWBDisc / (100 - wBDisc)) * 100) / (100 - spp)) * 100) / (100 - discount)) * 100;
    price = Math.round(price);
    for (let i = 50; i > 0; i--) {
        let tryPrice = price + i;
        let calcPrice = tryPrice * ((100 - discount) / 100);

        let tryPriceWithDiscount = isOzon ? Math.round(calcPrice) : Math.floor(calcPrice);
        let tryPriceWithSPP = isOzon ? Math.round(calcPrice * ((100 - spp) / 100)) : Math.floor(calcPrice * ((100 - spp) / 100));
        let tryPriceWithWBDiscount = isOzon ? Math.round(tryPriceWithSPP * ((100 - wBDisc) / 100)) : Math.floor(tryPriceWithSPP * ((100 - wBDisc) / 100)); // 100%
        if (tryPriceWithWBDiscount === priceWithWBDisc) {
            return [tryPrice, tryPriceWithDiscount, tryPriceWithSPP];
        }
    }
    return [0, 0, 0];
}

function calculatePromoPrices(price_with_discount, wb_discount, spp, discount, isOzon) {
    let price = Math.round((price_with_discount / (100 - discount)) * 100);
    for (let i = 50; i > 0; i--) {
        let try_price = price + i;
        let calc_price = (try_price * (100 - discount)) / 100;
        let try_price_with_discount = Math.floor(calc_price);
        let try_price_with_spp = Math.floor((calc_price * (100 - spp)) / 100);
        let try_price_with_wb_discount = Math.floor((try_price_with_spp * (100 - wb_discount)) / 100);
        if (try_price_with_discount == price_with_discount) {
            return [try_price, try_price_with_spp, try_price_with_wb_discount];
        }
    }

    return [0, 0, 0];
}

const calculateRow = (row) => {
    let load_price = 0;
    let load_discount = 0;
    let load_price_client = 0;
    if (!isNaN(Number(row.load_price)) || !isNaN(Number(row.load_discount))) {
        if (Number(row.load_price) == 0) {
            load_price = row.price;
        } else {
            load_price = Number(row.load_price);
        }
        if (Number(row.load_discount) == 0) {
            load_discount = row.discount;
        } else {
            load_discount = Number(row.load_discount);
        }
        load_price_client = Math.floor(
            (load_price * (100 - load_discount)) / 100
        );
    }
    let [new_price, new_price_with_discount, new_price_client] = calculateStartPrices(row.new_price_with_wb_discount, row.wb_discount, row.spp, row.discount, row.is_ozon);
    let [sale_price, sale_price_client, sale_price_with_wb_discount] = calculatePromoPrices(row.sale_price_with_discount, row.wb_discount, row.spp, row.discount, row.is_ozon);

    let sebes_itog_with_commission = Math.round((new_price_with_discount * row.sebes_commission_wb) / 100 + row.sebes_itog);
    console.log('new_price', new_price)
    row.new_price = new_price;
    row.new_price_with_discount = new_price_with_discount;
    row.new_price_client = new_price_client;
    row.new_profit = Math.round(new_price_with_discount - row.sebes_itog_with_commission);
    row.new_nacenka = Math.round((((new_price_with_discount * (100 - row.sebes_commission_wb)) / 100 - row.sebes_itog) / sebes_itog_with_commission) * 100, 2);
    row.new_marzha = new_price_with_discount != 0 ? Math.round(((new_price_with_discount - sebes_itog_with_commission) / new_price_with_discount) * 100, 2) : 0;
    row.new_price_with_wb_discount = Math.round(row.new_price_with_wb_discount);
    row.load_price = Math.round(row.load_price);
    row.load_discount = Math.round(row.load_discount);
    row.load_price_client = load_price_client;
    row.sale_price = sale_price;
    row.sale_price_with_discount = Math.round(row.sale_price_with_discount);
    row.sale_price_client = sale_price_client;
    row.sale_price_with_wb_discount = sale_price_with_wb_discount;
    row.sale_profit = Math.round(row.sale_price_with_discount - row.sebes_itog_with_commission);
    row.sale_nacenka = Math.round((((row.sale_price_with_discount * (100 - row.sebes_commission_wb)) / 100 - row.sebes_itog) / sebes_itog_with_commission) * 100, 2);
    row.sale_marzha = Number(row.sale_price_with_discount) != 0 ? Math.round(((row.sale_price_with_discount - sebes_itog_with_commission) / row.sale_price_with_discount) * 100, 2) : 0;
    return row
}


export default function TablePrices(args) {
    console.log('args.isOzon', args.isOzon)
    const [isHideColumns, setHideColumns] = useState(false);
    const [newPrice, setNewPrice] = useState();
    let defaultColumns = getColumns(args.filters, args.likeHideList, args.onChangeLikeHide, isHideColumns, args.cards[0]?.sales_info, args.isOzon);

    const handleSave = (row) => {
        const newData = [...args.cards];
        let index = 0
        let item = {}
        if (row.is_ozon) {
            console.log('isozon', row.article)
            index = newData.findIndex((item) => row.nm_id === item.key);
            item = newData[index];
        } else {
            index = newData.findIndex((item) => row.key === item.key);
            item = newData[index];
        }
        row = calculateRow(row)
        let new_item = {}
        if (row.is_ozon) {
            new_item = item
            for (let sku in item.by_sku) {
                console.log(sku, row.sku)
                if (sku == row.sku) {
                    console.log('find');
                    new_item.by_sku[sku] = row
                    break
                }
            }
        } else {
            new_item = row
        }
        newData.splice(index, 1, {...item, ...new_item,});
        args.setCards(newData);
    };

    const components = {body: {row: EditableRow, cell: EditableCell,},};

    function enhanceColumn(col, handleSave) {
        if (col.children) {
            col.children = col.children.map((childCol) => enhanceColumn(childCol, handleSave));
        }
        if (!col.editable) {
            return col;
        }
        return {...col, onCell: (record) => ({record, editable: col.editable, dataIndex: col.dataIndex, title: col.title, handleSave,}),};
    }

    const columns = defaultColumns.map((col) => enhanceColumn(col, handleSave));
    let DataSource = args.cards;
    if (args.cards) {
        DataSource = expandData(args.cards);
    }
    return (
        <>
            <Flex gap={'small'} align={'center'} style={{'padding-bottom': '10px'}}>
                <Switch checkedChildren='Скрыть' unCheckedChildren='Скрыть' checked={isHideColumns} onChange={() => setHideColumns(!isHideColumns)}/>
                <Button icon={<CheckOutlined/>} iconPosition='start' onClick={args.checkPrices}>Проверить цены</Button>
                <Button icon={<UploadOutlined/>} iconPosition='start' onClick={args.loadPrices}>Загрузить цены на WB</Button>
                <Button icon={<ReloadOutlined/>} iconPosition='start' onClick={args.updatePrices}>Обновить</Button>
            </Flex>

            <Table
                components={components}
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                scroll={{
                    y: 800,
                }}
                rowClassName={() => 'editable-row my-custom-row'}
                loading={args.loading}
                pagination={{
                    defaultPageSize: 30,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} из ${total} артикулов`,
                }}
            />
        </>
    );
}
