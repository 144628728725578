import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, message, Row, Select, Tooltip, Typography, Tabs, Space } from 'antd'
import { SelectorSolo } from '../../components/Selectors'
import { useAuth } from '../../hooks/useAuth'
import { options_hide, options_like } from '../../utils'
import { defaultOnChangeLikeHide, setLikeHideOnLoadData } from '../components/LikeHide'
import { apiAxios } from "../../utils/api_axios";
import { useSidebar } from '../../Context/SideBarContext'
import Sidebar from '../home/sideBar/SideBar'
import SideBarPrices from '../home/sideBar/SideBarPrices'
import PriceCurrentPrice from './components/PriceCurrentPrice'
import PriceNewPrice from './components/PriceNewPrice'
import PriceSebesAnalysis from './components/PriceSebesAnalysis'
import { ShopOutlined, DollarOutlined, QuestionCircleOutlined } from '@ant-design/icons'

const { TabPane } = Tabs

function PricingV2() {
    const { user } = useAuth()
    const [filters_data, setFiltersData] = useState([])
    const [cards, setCards] = useState([])
    const [selected_hide, setSelectedHide] = useState(1)
    const [selected_like, setSelectedLike] = useState(1)
    const [likeHideList, setLikeHideList] = useState({ like: [], hide: [] })
    const [isOzon, setIsOzon] = useState(true)
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('1')
    const [summaryStats, setSummaryStats] = useState({
        totalProducts: 0,
        avgMargin: 0,
        avgMarkup: 0,
        totalProfit: 0
    })
    const [expenses, setExpenses] = useState([])

    const { openSidebar } = useSidebar()

    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_id: null,
        wbacc_options: [],
        group: 1,
        group_options: [
            { label: 'По дням', value: 1 },
            { label: 'По неделям', value: 2 }
        ]
    })

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarPrices />)
    }, [openSidebar])

    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: user.wbacc_ids })
        let wbaccOptions = []

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({ label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id })
        }

        setSelectorFilter('wbacc_id', user.wbacc_ids[0])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList)
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedData = await await apiAxios.get('prices/get/', { wbacc_ids: [selectorFilter.wbacc_id], is_like: selected_like, is_hide: selected_hide })
            setFiltersData(fetchedData['filters'])
            setCards(fetchedData['cards'])
            console.log(fetchedData['cards'])
            // Вычисляем сводную статистику
            const stats = fetchedData['cards'].reduce((acc, card) => {
                acc.totalProducts++
                acc.avgMargin += card.new_marzha || 0
                acc.avgMarkup += card.new_nacenka || 0
                acc.totalProfit += card.new_profit || 0
                return acc
            }, { totalProducts: 0, avgMargin: 0, avgMarkup: 0, totalProfit: 0 })
            
            stats.avgMargin = stats.avgMargin / stats.totalProducts
            stats.avgMarkup = stats.avgMarkup / stats.totalProducts
            
            setSummaryStats(stats)
            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
            message.error('Ошибка при загрузке данных')
        }
    }

    const checkPrices = () => {
        setCards(
            cards.map((card) => {
                card.load_price = card.new_price
                let load_discount = 0
                if (Number(card.load_discount) === 0) {
                    load_discount = card.discount
                } else {
                    load_discount = Number(card.load_discount)
                }
                card.load_price_client = Math.floor((card.new_price * (100 - load_discount)) / 100)
                return card
            })
        )
        message.success('Цены проверены')
    }

    const loadPrices = async () => {
        try {
            const data = {
                cards: cards.map((card) => ({
                    nm_id: card.nm_id,
                    price: card.new_price_with_wb_discount ? card.new_price : 0,
                    discount: card.new_discount != card.discount ? card.new_discount : 0
                })),
                wbacc_id: cards[0].wbacc_id
            }

            await apiAxios.post('prices/load/', data)
            message.success('Цены успешно загружены!')
        } catch (err) {
            console.error('Error loading prices:', err)
            message.error('Не удалось загрузить цены')
        }
    }

    const updatePrices = async () => {
        try {
            setLoading(true)
            const data = { wbacc_id: cards[0].wbacc_id }
            await apiAxios.post('prices/update/', data)
            await fetchData()
            message.success('Цены обновлены')
        } catch (err) {
            console.error('Error updating prices:', err)
            message.error('Ошибка при обновлении цен')
        }
    }

    useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1()
        }

        if (selectorFilter.wbacc_id) {
            selectorFilter.wbacc_id > 0 ? setIsOzon(false) : setIsOzon(true)
            fetchData()
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_id, selected_hide, selected_like])

    const handleChangeLikeHide = (id) => {
        setLikeHideList(prev => {
            if (prev.includes(id)) {
                return prev.filter(item => item !== id)
            }
            return [...prev, id]
        })
    }

    return (
        <div className="pricing-container">
            {/* Header Section */}
            <Card className="mb-4">
                <Row gutter={[16, 16]} align="middle">
                    <Col xs={24} lg={12}>
                        <Space align="center">
                            <Typography.Title level={2}>
                                <DollarOutlined /> Ценообразование
                            </Typography.Title>
                            <Tooltip title="Информация">
                                <QuestionCircleOutlined
                                    onClick={handleOpenSidebar}
                                    style={{
                                        fontSize: '24px',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s',
                                    }}
                                    className="hover:rotate-180 active:scale-90"
                                />
                            </Tooltip>
                        </Space>
                    </Col>

                </Row>

                {/* Filters Section */}
                <Row gutter={[16, 16]} className="mt-4">
                    <Col xs={24} lg={6}>
                        <Typography.Title level={5}>
                            <ShopOutlined /> Магазины
                        </Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Выберите магазин"
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Col xs={12} lg={6}>
                        <Typography.Title level={5}>Избранное</Typography.Title>
                        <SelectorSolo
                            options={options_like}
                            selected={selected_like}
                            setSelected={setSelectedLike}
                        />
                    </Col>
                    <Col xs={12} lg={6}>
                        <Typography.Title level={5}>Скрытые товары</Typography.Title>
                        <SelectorSolo
                            options={options_hide}
                            selected={selected_hide}
                            setSelected={setSelectedHide}
                        />
                    </Col>
                </Row>
            </Card>

            {/* Main Content */}
            <Card>
                <Tabs activeKey={activeTab} onChange={setActiveTab}>
                    <TabPane tab="Текущая цена" key="1">
                        <PriceCurrentPrice
                            cards={cards}
                            setCards={setCards}
                            filters={filters_data}
                            likeHideList={likeHideList}
                            onChangeLikeHide={onChangeLikeHide}
                            loading={loading}
                            isOzon={isOzon}
                            updatePrices={updatePrices}
                            checkPrices={checkPrices}
                        />
                    </TabPane>
                    <TabPane tab="Новая цена" key="2">
                        <PriceNewPrice
                            cards={cards}
                            setCards={setCards}
                            filters={filters_data}
                            loading={loading}
                            isOzon={isOzon}
                            updatePrices={updatePrices}
                            loadPrices={loadPrices}
                            checkPrices={checkPrices}
                        />
                    </TabPane>
                    <TabPane tab="Анализ себестоимости" key="3">
                        <PriceSebesAnalysis
                            cards={cards}
                            filters={filters_data}
                            loading={loading}
                            expenses={expenses}
                        />
                    </TabPane>

                </Tabs>
            </Card>
            
            <Sidebar />
        </div>
    )
}

export default PricingV2
