import React, {useEffect, useState} from 'react'
import {getDashboard} from '../../core/api/home'
import {useAuth} from '../../core/hooks/useAuth'
import {GeneralGraph} from '../../components/smart/charts/general/generalGraph'
import {GeneralSmallCard} from '../../components/smart/charts/general/generalSmallCard'
import {IoCubeSharp} from 'react-icons/io5'
import {GoGraph} from 'react-icons/go'
import {MdOutlineSettingsBackupRestore} from 'react-icons/md'
import {GeneralLongCard} from '../../components/smart/charts/general/generalLongCard'
import {GeneralFin} from '../../components/smart/charts/general/generalFin'
import {GeneralProfit} from '../../components/smart/charts/general/generalProfit'
import {GeneralStorage, GeneralStorage2, GeneralWarehouse} from '../../components/smart/charts/general/generalStorage'
import {apiAxios} from '../../core/api/api_axios'
import {Card, Col, Row, Select, Typography} from 'antd'
import Title from 'antd/es/skeleton/Title'
import {NavigateButton} from '../../components/simple/buttons/Buttons'
import DatePickerRange from "../../components/simple/dateRange";
import dayjs from "dayjs";
import { IoMdClose } from "react-icons/io";

export const Dashboard = () => {
    const {user} = useAuth()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [inf, setInf] = useState()
    const [period, setPeriod] = useState([dayjs().add(-30, 'd'), dayjs().add(-1, 'd')]);
    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_id: null,
        wbacc_options: [],
    });
    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedData = await getDashboard(selectorFilter.wbacc_id, period)
            console.log('Fetched Data:', fetchedData)
            const {data: apiData} = fetchedData

            const formattedData2 = {...apiData}
            const formattedData = fetchedData?.data?.by_days.map((item) => ({
                name: item.date,
                orders: item.orders,
                buyouts: item.buyouts,
                ordersRUB: item.orders_sum,
                buyoutsRUB: item.buyouts_sum
            }))

            setData(formattedData)
            setInf(formattedData2)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids});
        let wbaccOptions = []
        for (let i in fetchedDataWbacc?.data) {
            wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id,})
        }
        // console.log(user.wbacc_ids)
        // console.log(wbaccOptions)
        setSelectorFilter('wbacc_id', user.wbacc_ids[0]);
        setSelectorFilter('wbacc_options', wbaccOptions);
    }

     useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1();
        }
        if (selectorFilter.wbacc_id) {
            fetchData();
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_id, period]);
    return (
        <>
            {loading ? (
                <p>Загрузка данных...</p>
            ) : user.wbacc_ids && inf ? (
                <div className='flex justify-center'>
                    <div className='max-w-[1920px]'><Row type="flex">
                    <Col lg={4} xs={24}>

                        <Card>
                            <Typography.Title level={5}>Период данных</Typography.Title>
                            <DatePickerRange selected={period} setSelected={setPeriod}/>
                            <Typography.Title level={5}>Магазины</Typography.Title>
                            <Select
                                maxTagCount={'responsive'}
                                style={{width: '100%'}}
                                placeholder="Выберите магазин"
                                value={selectorFilter.wbacc_id}
                                onChange={(value) => setSelectorFilter('wbacc_id', value)}
                                options={selectorFilter.wbacc_options}
                            />
                        </Card>

                    </Col>
                    <Col className='extra:max-w-[280px]' lg={4} xs={24}>
                        <GeneralSmallCard
                            Number={inf.orders}
                            byDayNumber={inf.orders_per_day}
                            title={'Заказы'}
                            Rubels={inf.orders_sum}
                            Format={'rubels'}
                            percent={inf.diff.orders}
                            FormatSmall={'sht'}
                            classNameDiv={'flex justify-center items-center bg-[#9dadff] absolute right-4 top-3 rounded-[10px] size-14'}
                            NameIcon={<IoCubeSharp className='text-[#5A76FB] text-4xl'/>}
                        />
                    </Col>
                            <Col className='extra:max-w-[280px]' lg={4} xs={24}>
                        <GeneralSmallCard
                            Number={inf.buyouts}
                            byDayNumber={inf.buyouts_per_day}
                            title={'Продажи'}
                            Rubels={inf.buyouts_sum}
                            Format={'rubels'}
                            percent={inf.diff.buyouts}
                            FormatSmall={'sht'}
                            classNameDiv={'flex justify-center items-center bg-[#faaff9ca] absolute right-4 top-3 rounded-[10px] size-14'}
                            NameIcon={<GoGraph className='text-[#DB6BD9] text-4xl'/>}
                        />
                    </Col>
                            <Col className='extra:max-w-[280px]' lg={4} xs={24}>
                        <GeneralSmallCard
                            Number={inf.returns}
                            percent={inf.diff.returns}
                            Rubels={'0'}
                            byDayNumber={inf.returns}
                            title={'Возвраты'}
                            classNameDiv={'flex justify-center items-center bg-[#FFE8DF] absolute right-4 top-3 rounded-[10px] size-14'}
                            NameIcon={<MdOutlineSettingsBackupRestore className='text-[#FF9973] rotate-45 text-4xl'/>}
                        />
                    </Col>
                    <Col className='extra:max-w-[280px]' lg={4} xs={24}>
                        <GeneralSmallCard
                            Number={inf?.cancels}
                            percent={inf?.diff.cancels}
                            Rubels={'0'}
                            byDayNumber={inf?.cancels}
                            title={'Отмены'}
                            classNameDiv={'flex justify-center items-center bg-[#fb9b9b] absolute right-4 top-3 rounded-[10px] size-14'}
                            NameIcon={<IoMdClose className='text-[#ff2b2b] text-4xl'/>}
                        />
                    </Col>
                    <div className='flex flex-col  xx:max-w-[460px] gap-3 w-full m-[7px] justify-between'>
                        <div className='flex gap-3 h-[80px]'>
                            <GeneralLongCard percent={inf.diff.cancels} Format={'sht'} title={'Отмены'} content={inf.cancels}/>
                        <GeneralLongCard percent={inf.diff.buyout_percent} title={'Процент выкупа'} Format={'percent'} content={inf.buyout_percent}/>
                        </div>
                        <div className='flex gap-3 h-[80px]'>
                        <GeneralLongCard percent={inf.diff.way_to} Format={'sht'} title={'В пути до клиента'} content={inf.way_to}/>
                            <GeneralLongCard percent={inf.diff.way_from} title={'В пути от клиента'} Format={'sht'} content={inf.way_from}/>
                        </div>
                    </div>
                        {/* <div className='w-full flex justify-center gap-2 xl: flex-wrap' lg={8} xs={24}>
                            
                                <GeneralLongCard percent={inf.diff.cancels} Format={'sht'} title={'Отмены'} content={inf.cancels}/>
                            
                            
                                <GeneralLongCard percent={inf.diff.buyout_percent} title={'Процент выкупа'} Format={'percent'} content={inf.buyout_percent}/>
                            
                            
                                <GeneralLongCard percent={inf.diff.way_to} Format={'sht'} title={'В пути до клиента'} content={inf.way_to}/>
                            
                            
                                <GeneralLongCard percent={inf.diff.way_from} title={'В пути от клиента'} Format={'sht'} content={inf.way_from}/>
                            
                    </div> */}
                    <Card className='w-full'>
                        <Col lg={24}>
                        <GeneralGraph data={data}/>
                    </Col>
                    </Card>
                    {/* <Col lg={24} className='p-[7px]'>
                        <Typography.Title level={1}>Реклама</Typography.Title>
                        <div className='flex justify-between gap-2 flex-wrap'>
                                <GeneralLongCard title={'ДРР'} Format={'percent'} content={inf?.drr} percent={inf?.diff.drr}/>
                                <GeneralLongCard title={'CTR'} Format={'percent'} content={inf.ctr_mid} percent={inf.diff.ctr_mid}/>
                                <GeneralLongCard title={'CPC'} Format={'percent'} content={inf.cpc_mid} percent={inf.diff.cpc_mid}/>
                                <GeneralLongCard title={'CPO'} Format={'percent'} content={inf.cpo_mid} percent={inf.diff.cpo_mid}/>
                                <GeneralLongCard title={'Заказы'} Format={'sht'} content={inf.orders} percent={inf.diff.orders}/>
                                <GeneralLongCard title={'Бюджет'} Format={'rubels'} content={inf.budget} percent={inf.diff.budget}/>
                                <GeneralLongCard title={'Клики'} Format={'views'} content={inf.clicks} percent={inf.diff?.clicks}/>
                                <GeneralLongCard title={'Просмотры'} Format={'viws'} content={inf.views} percent={inf.diff.views}/>
                        </div>
                    </Col> */}
                    <div className='flex flex-wrap gap-10 m-[7px] justify-between w-full'>
                            <GeneralStorage inf={inf}/>
                            <GeneralFin inf={inf} />
                    </div>
                        <GeneralWarehouse />
                </Row></div>
                </div>
            ) : <Card>
                <div className='flex flex-col gap-4'>
                    <h2 className='font-bold text-3xl'>У вас нету подключенных магазинов</h2> <NavigateButton title={'Подключить магазин'} link={'/profile?tab=stores'}/></div>
            </Card>
            }
        </>
    )
}
