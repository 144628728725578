import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { SelectorSolo } from '../../components/simple/selects';
import { useAuth } from '../../core/hooks/useAuth';
import dayjs from 'dayjs';
import { options_hide, options_like, serverUrl } from '../../core/constants';
import TablePosition from '../../components/smart/tables/utils/positions';
import { getPositions } from '../../core/api/utils/positions';
import DatePickerRange from '../../components/simple/dateRange';
import {defaultOnChangeLikeHide, setLikeHideOnLoadData} from "../analCards/utils";
import { ButtonExel } from '../../components/simple/buttons/Buttons';

export default function Position() {
    const { user, isCsrf } = useAuth();
    const [filters_data, setFiltersData] = useState([]);
    const [cards, setCards] = useState([]);
    const [storeNames, setStoreNames] = useState();
    const [regionNames, setRegionNames] = useState();
    const startDate =
        dayjs().date() == 1
            ? dayjs().subtract(1, 'month').startOf('month')
            : dayjs().startOf('month');
    const [period, setPeriod] = useState([startDate, dayjs()]);
    const [selectedWbaccs, setSelectedWbaccs] = useState([]);
    const [selected_hide, setSelectedHide] = useState(1);
    const [selected_like, setSelectedLike] = useState(1);
    const [likeHideList, setLikeHideList] = useState({ like: [], hide: [] });

    const [loading, setLoading] = useState(true);
    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await getPositions(
                selectedWbaccs,
                selected_like,
                selected_hide,
                period
            );
            setFiltersData(fetchedData['filters']);
            setCards(fetchedData['cards']);
            setStoreNames(fetchedData['store_names']);
            setRegionNames(fetchedData['region_names']);
            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : []);
    }, [user.wbacc_ids]);
    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData();
        }
    }, [selected_hide, selected_like, selectedWbaccs, period]);
    return (
        <>
            <Card>
                <Typography.Title level={1}>Позиции</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems:'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Период данных
                        </Typography.Title>
                        <DatePickerRange
                            selected={period}
                            setSelected={setPeriod}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Поставщики
                        </Typography.Title>
                        <SelectorSolo
                            options={filters_data?.wbaccs}
                            selected={selectedWbaccs}
                            setSelected={setSelectedWbaccs}
                        />
                    </Col>

                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Избранное</Typography.Title>
                        <SelectorSolo
                            options={options_like}
                            selected={selected_like}
                            setSelected={setSelectedLike}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Скрытые товары
                        </Typography.Title>
                        <SelectorSolo
                            options={options_hide}
                            selected={selected_hide}
                            setSelected={setSelectedHide}
                        />
                    </Col>
                    <ButtonExel data={cards} />
                </Row>
            </Card>
            <Card>
                <TablePosition
                    period={period}
                    cards={cards}
                    filters={filters_data}
                    likeHideList={likeHideList}
                    onChangeLikeHide={onChangeLikeHide}
                    loading={loading}
                    storeNames={storeNames}
                    regionNames={regionNames}
                />
            </Card>
        </>
    );
}
