import { apiAxios } from '../api_axios';

export const getSupplies = async(wbacc_id, is_like, is_hide) => {
    try {
        return await apiAxios.get('supplies/get/', {
            'wbacc_ids': [wbacc_id],
            'is_like': is_like,
            'is_hide': is_hide,
        })
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};


export const getSupply = async(wbacc_id, store_id, articles, need_days, days_calculate) => {
    try {
        return await apiAxios.get('supply/get/', {
            'wbacc_ids': [wbacc_id],
            'store_id': store_id,
            'articles': articles,
            'need_days': need_days,
            'days_calculate': days_calculate,
        })
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};