import { Button } from "antd";
import React from "react";

export const ProfileTabPartner = () => {
  return <div>
    <h2 className="font-bold text-3xl">Партнерская программа</h2>
    <div className="pt-5 pb-12">
        <div className="flex flex-row gap-7">
            <div className="flex flex-col pb-5 h-[150px] border-[1px] rounded-md max-w-[370px] w-full bg-[#F8F9FA] border-[#D9D9D9] gap-5">
                <h3 className="px-2 py-1 bg-[#F0F1F2] font-semibold">Ваш баланс</h3>
                <div className="flex px-2 flex-col gap-5">
                    <p className="text-gray-500 text-sm">На счету: <span className="text-black text-base font-bold">0 руб.</span></p>
                    <Button type="primary"  className="max-w-[100px]" color="green">Вывести</Button>
                </div>
            </div>
            <div className="flex flex-col pb-5 border-[1px] rounded-md h-[150px]  max-w-[370px] w-full bg-[#F8F9FA] border-[#D9D9D9] gap-5">
                <h3 className="px-2 py-1 bg-[#F0F1F2] font-semibold">Рефералы</h3>
                <div className="flex px-2 flex-col gap-5">
                    <p className="text-gray-500 text-sm">Сейчас у вас: <span className="text-black text-base font-bold">0 рефералов</span></p>
                        <p className="text-gray-500 text-sm">С покупками: <span className="text-black text-base font-bold">0 рефералов</span></p>
                </div>
            </div>
            <div className="flex flex-col pb-5 border-[1px] rounded-md w-full bg-[#F8F9FA] border-[#D9D9D9] gap-5">
                <h3 className="px-2 py-1 font-bold bg-[#F0F1F2]">Ссылка для приглашения</h3>
                <div className="flex gap-3 px-2 items-center">
                    <p>https://app.bb-data.pro/</p>
                    <div>icon</div>
                    <div>icon</div>
                    <div>icon</div>
                    <div>icon</div>
                </div>
                <div className="bg-[#E2E3E5] flex flex-col gap-2 mx-2 rounded-md px-2 py-3">
                    <p className="text-black font-semibold">Отправьте ссылка для прилашения зарегестрироваться.</p>
                    <span className="text-gray-500 text-sm">Пользователь, перейдя по вашей ссылке, автоматически становится вашим рефералом. В случае оплаты им тарифного плана вы получаете вознаграждение в соотвествии с правилами.</span>
                </div>
            </div>
        </div>
    </div>
    <span className="">Партнерская программа позволяет Вам зарабатывать, привлекая в сервис новых пользователей</span>
    <div className="flex flex-col gap-4 mt-5">
        <div className="flex flex-col gap-1">
            <h3 className="font-bold text-2xl">Бонус клиентам</h3>
            <p className="">При геристрации по вашей реферальной ссылке, пользователь которого Вы пригласили, получает приветсвенный бонус: <span className="font-bold">скидку 10 процентов на первую оплату</span></p>
        </div>
        <div className="flex flex-col gap-1">
            <h3 className="font-bold text-2xl">Комиссия</h3>
            <p className="">Вы получаете комиссию в размере <span className="font-bold">25%</span> при первой оплате и <span className="font-bold">10%</span> с каждой последующей от стоимости оплаченного тарифа привлеченных партнеров. Эти начисления будут сразу поступать на баланс в сервисе. Партнерские начисления можно вывести на личный счет или использовать для оплаты услуг сервиса. Минимальный баланс для вывода <span className="font-bold">3000 рублей.</span></p>
        </div>
        <div className="flex flex-col gap-1">
            <h3 className="font-bold text-2xl">Правила</h3>
            <ul style={{listStyleType:'number'}} className="pl-8">
                <li >Участникам партнерской программы не разрешается: 
                    <ul style={{listStyleType:'circle'}} className="pl-7 py-1">
                        <li>производить принудительную переадресацию партнеров на сайт сервиса через рекламную ссылку или баннер.</li>
                        <li>отправлять рекламную рассылку (спам) с указанием рекламной информации о сервисе.</li>
                        <li>создавать рекламные материалы и/или распростронять/размещать рекламную информацию о сервисе с нарушением действующего законодательства РФ.</li>
                        <li>регестрировать аккаунты для личного использования через свою партнерскую программу.</li>
                    </ul>
                </li>
                <li >Пользователи, которые уже зарегистрированы в сервисе, повторно зарегестрироваться по партнерской ссылке не могут.</li>
                <li >Пригласивший пользователь не может быть изменен, в том числе если его аккаунт удален или заблокирован.</li>
                <li >Парнерские начисления не переносятся на другой аккаунт.</li>
            </ul>
        </div>
    </div>
  </div>;
};

