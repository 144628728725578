import {formatCurrency, formatDays, formatPercent, formatQuantity} from "../../../../core/utils/formats";
import {Table} from "antd";
import React, {useState} from "react";


const getColumns = (filters_data, likeHideList, onChangeLikeHide, isHideColumns) => {
    const isMobile = window.innerWidth <= 769;
    return [

        {title: 'Группировка', dataIndex: 'group', width: 70, ellipsis: true},
        //{title: 'Карточка', dataIndex: 'imt_id', width: 80, filters: filters_data['imt_ids'], filterSearch: true, onFilter: (value, record) => record.imt_id == value || record.is_fix,},
        {title: 'Категория', dataIndex: 'category', key: 'category', width: 70, ellipsis: true, fixed: isMobile ? '': 'left', filters: filters_data?.category, filterSearch: true, onFilter: (value, record) => record.category == value || record.is_fix,},
        //{title: 'Модель', dataIndex: 'material', width: 70, filters: filters_data?.material, fixed: isMobile ? '': 'left', filterSearch: true, onFilter: (value, record) => record.material?.indexOf(value) === 0 || record.is_fix,},
        //{title: 'Цвет', dataIndex: 'color', width: 70, filters: filters_data?.color, filterSearch: true, onFilter: (value, record) => record.color?.indexOf(value) === 0},
        {title: 'ABC', dataIndex: 'abc', width: 50, filters: filters_data?.abc, filterSearch: true, onFilter: (value, record) => record.abc?.indexOf(value) === 0,},
        {title: 'Сезон', dataIndex: 'sezon', width: 60, filters: filters_data?.sezon, filterSearch: true, onFilter: (value, record) => record.sezon?.indexOf(value) === 0,},
        {title: 'Статус', dataIndex: 'status', width: 60, filters: filters_data?.status, filterSearch: true, onFilter: (value, record) => record.status?.indexOf(value) === 0,},
        {title: 'Деньги в товаре', dataIndex: 'ost_sum', width: 100, sorter: (a, b) => a.ost_sum - b.ost_sum, render: (text) => formatCurrency(text), className: 'blue-color',},

        // {
        //     title: 'План', className: 'yellow-color',
        //     children: [
        //         {title: 'Шт', dataIndex: 'plan_orders', width: 50, className: 'yellow-color', render: (text) => formatQuantity(text),},
        //         {title: 'Шт в день', dataIndex: 'plan_orders_per_day', width: 50, className: 'yellow-color', render: (text) => formatQuantity(text),},
        //     ]
        // },
        {
            title: 'Показатели', className: 'yellow-color',
            children: [
                {title: 'Заказы в день', dataIndex: 'orders_per_day', width: 60, className: 'yellow-color', render: (text) => formatQuantity(text),},
                {title: 'Выкупы в день', dataIndex: 'buyouts_per_day', width: 50, className: 'yellow-color', render: (text) => formatQuantity(text),},
                {title: 'Выкуп', dataIndex: 'buyout_percent', width: 50, className: 'yellow-color', render: (text) => formatPercent(text)},
                {title: 'Оборачиваемость', dataIndex: 'oborot_orders', width: 60, className: 'yellow-color', render: (text) => formatDays(text),},
                {title: 'Запас дней', dataIndex: 'oborot_buyouts', width: 60, className: 'yellow-color', render: (text) => formatDays(text),},
                {title: 'Обор. Себес', dataIndex: 'oborot_sebes', width: 60, className: 'yellow-color', render: (text) => formatDays(text),},
            ]
        },
        {
            title: 'Цена', className: 'green-color',
            children: [
                {title: 'Цена', dataIndex: 'price_with_discount', width: 50, className: 'green-color', render: (text) => formatCurrency(text)},
                // {title: 'Скидка', dataIndex: 'discount', width: 30, className: 'green-color', render: (text) => formatPercent(text)},
                // {title: 'Цена со скидкой', dataIndex: 'price_with_discount', width: 50, className: 'green-color', render: (text) => formatCurrency(text)},
                // {title: 'Спп', dataIndex: 'spp', width: 45, className: 'green-color', render: (text) => formatPercent(text)},
                // {title: 'Цена с спп', dataIndex: 'price_client', width: 50, className: 'green-color', render: (text) => formatCurrency(text)},
                // {title: 'Цена с кошельком', dataIndex: 'price_with_wb_discount', width: 50, className: 'green-color', render: (text) => formatCurrency(text)},
                {title: 'Наценка', dataIndex: 'nacenka_unit', width: 50, className: 'green-color', render: (text) => formatPercent(text)},
                {title: 'Маржа', dataIndex: 'marzha_unit', width: 50, className: 'green-color', render: (text) => formatPercent(text)},
                {title: 'Прибыль', dataIndex: 'profit_unit', width: 50, className: 'green-color', render: (text) => formatCurrency(text)},
            ]
        },
        {
            title: 'Себестоимость',
            className: 'red-color',
            children: [
                {title: 'Себес', dataIndex: 'sebes', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                // {title: 'Комиссия %', dataIndex: 'commission', width: 50, className: 'red-color', render: (text) => formatPercent(text),},
                // {title: 'Хранение', dataIndex: 'store_cost_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                {title: 'Логистика', dataIndex: 'delivery_cost_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                {title: 'Приемка', dataIndex: 'supply_cost_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                // {title: 'Маркетинг', dataIndex: 'budget_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                // {title: 'Налог', dataIndex: 'nalog_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                // {title: 'Другие расходы', dataIndex: 'other_services_cost_unit', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                // {title: 'Себ-ть ИТОГО', dataIndex: 'sebes_itog', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
                // {title: 'Расходы с комиссией', dataIndex: 'sebes_itog_with_commission', width: 50, className: 'red-color', render: (text) => formatCurrency(text),},
            ],
        },

        {
            title: 'Реклама', className: 'pink-color',
            children: [
                {title: 'Бюджет', dataIndex: 'budget', width: 60, className: 'pink-color', render: (text) => formatCurrency(text)},
                // {title: 'Просмотры', dataIndex: 'views', width: 40, className: 'pink-color'},
                // {title: 'Клики', dataIndex: 'clicks', width: 40, className: 'pink-color'},
                // {title: 'Корзина', dataIndex: 'add_to_cart', width: 40, className: 'pink-color'},
                // {title: 'Заказы', dataIndex: 'adv_orders', width: 40, className: 'pink-color'},
                {title: 'CTR', dataIndex: 'ctr', width: 45, className: 'pink-color', render: (text) => formatPercent(text)},
                {title: 'CPC', dataIndex: 'cpc', width: 45, className: 'pink-color', render: (text) => formatCurrency(text)},
                {title: 'ДРР', dataIndex: 'drr', width: 45, className: 'pink-color', render: (text) => formatPercent(text)},


            ]
        },


        {
            title: 'Остатки МС', className: 'green-color',
            children: [
                {title: 'Москва', dataIndex: 'ms_ost_msk', width: 50, className: 'green-color', render: (text) => formatQuantity(text),},
                {title: 'Бишкек', dataIndex: 'ms_ost_bishkek', width: 50, className: 'green-color', render: (text) => formatQuantity(text),},
                {title: 'Транзит', dataIndex: 'ms_transfer', width: 70, className: 'green-color', render: (text) => formatQuantity(text),},
                {title: 'Шьется', dataIndex: 'ms_production', width: 70, className: 'green-color', render: (text) => formatQuantity(text),},
            ]
        },
        {
            title: 'Остатки FBO', className: 'pink-color',
            children: [
                {title: 'Остатки', dataIndex: 'ost', width: 55, className: 'pink-color', render: (text) => formatQuantity(text), ellipsis:true},
                {title: 'В пути до клиента', dataIndex: 'way_to', width: 50, className: 'pink-color', render: (text) => formatQuantity(text), ellipsis:true},
                {title: 'В пути от клиента', dataIndex: 'way_from', width: 50, className: 'pink-color', render: (text) => formatQuantity(text), ellipsis:true},
                {title: 'Остатки итого', dataIndex: 'full', width: 55, className: 'pink-color', render: (text) => formatQuantity(text), ellipsis:true},
            ]
        },
    ]
}


export default function TableStatGeneralCategory(args) {
    const [isHideColumns, setHideColumns] = useState(false)
    let columns = getColumns(args.filters, args.likeHideList, args.onChangeLikeHide, isHideColumns);

    return <>
        <Table
            columns={columns}
            dataSource={args.data}
            bordered
            size={'small'}
            scroll={{ x: 400 }}
            rowClassName={(record, index) => `my-custom-row ${record.is_split ? 'border_top' : ''}`}
            loading={args.loading}
            pagination={false}
        />
    </>
}