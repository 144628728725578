import React, {useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Dropdown, Input, Menu, Row, Select, Space, Tag, Tooltip, Typography} from 'antd'
import TableEditCards from './editCards'
import { useSidebar } from '../../../Context/SideBarContext'
import SideBarEditCards from '../../home/sideBar/SideBarEditCards'
import Sidebar from '../../home/sideBar/SideBar'
import {apiAxios} from "../../../utils/api_axios";
import {useFilteredCards, useFilterLogic} from "../../components/filterUtils";
import {FilterOutlined, ReloadOutlined} from "@ant-design/icons";

export default function ProductsSebes({selectorFilter}) {
    const [cards, setCards] = useState([])
    const [filters_data, setFiltersData] = useState({});
    const [loading, setLoading] = useState(true)
    const [activeFilters, setActiveFilters] = useState([]);
    const [filterSearchTexts, setFilterSearchTexts] = useState({
        article: '',
        material: '',
        category: '',
        status: '',
        sezon: '',
        brand: '',
        color: ''
    });
    const {openSidebar} = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarEditCards/>)
    }, [openSidebar])

    const onEditCard = (card) => {
        apiAxios.post('edit-cards/update/', {'card': card})
    }
    const savePositions = (nm_id, positions) => {
        apiAxios.post("edit-cards/update/positions/", {'nm_id': nm_id, 'positions': positions});
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('edit-cards/get/', {
                wbacc_ids: [selectorFilter.wbacc_id],
            });
            setCards(response.cards);
            setFiltersData(response.filters || {});
        } catch (error) {
            console.error('Error fetching supply data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectorFilter.wbacc_id) {
            fetchData();
        }
    }, [selectorFilter.wbacc_id]);
    const handleAddFilter = (type, value) => {
        setActiveFilters(prev => {
            const filterExists = prev.some(
                filter => filter.type === type && filter.value.value === value.value
            );
            if (filterExists) return prev;
            return [...prev, {type, value}];
        });
    };

    const handleRemoveFilter = (index) => {
        setActiveFilters(prev => prev.filter((_, i) => i !== index));
    };

    const menuItems = useFilterLogic(
        filters_data,
        filterSearchTexts,
        setFilterSearchTexts,
        handleAddFilter
    );

    const filterMenu = <Menu items={menuItems}/>;
    const [quickFilters, setQuickFilters] = useState({
        showNoSebes: false,
        showNoModel: false,
        showNoMaterial: false,
        showNoColor: false,
        showNoSezon: false,
        showNoStatus: false,
    });

    const quickFilterButtons = [
        {key: 'showNoSebes', label: 'Не указана себестоимость', filter: item => item.sebes === 0},
        {key: 'showNoModel', label: 'Не указана модель', filter: item => ['', '-', ' '].includes(item.model)},
        {key: 'showNoMaterial', label: 'Не указан материал', filter: item => ['', '-', ' '].includes(item.material)},
        {key: 'showNoColor', label: 'Не указан цвет', filter: item => ['', '-', ' '].includes(item.color)},
        {key: 'showNoSezon', label: 'Не указан сезон', filter: item => ['', '-', ' '].includes(item.sezon)},
        {key: 'showNoStatus', label: 'Не указан статус', filter: item => ['', '-', ' '].includes(item.status)},

    ];

    const handleQuickFilter = (filterKey) => {
        if (quickFilters[filterKey]) {
            setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
                ...acc,
                [key]: false
            }), {}));
        } else {
            setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
                ...acc,
                [key]: key === filterKey
            }), {}));
        }
    };

    const applyQuickFilters = (data) => {
        const activeFilter = Object.entries(quickFilters).find(([_, value]) => value);
        if (!activeFilter) return data;

        const filterConfig = quickFilterButtons.find(btn => btn.key === activeFilter[0]);
        if (!filterConfig) return data;
        const filteredData = data.filter(filterConfig.filter);
        return filteredData;
    };
    let filteredData = useFilteredCards(cards, activeFilters);
    filteredData = applyQuickFilters(filteredData);
    const [searchText, setSearchText] = useState('');
    filteredData = filteredData.filter(record =>
        record.article?.toLowerCase().includes(searchText.toLowerCase()) || record.nm_id?.toString().toLowerCase().includes(searchText.toLowerCase())
    );

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };
    return (
        <>

            <Sidebar/>
            <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16, marginTop: 16}}>
                <Space wrap>
                    <Input
                        placeholder="Поиск по артикулу"
                        value={searchText}
                        onChange={handleSearch}
                    />
                    <Dropdown overlay={filterMenu} trigger={['click']}>
                        <Button icon={<FilterOutlined/>}>
                            Добавить фильтр
                        </Button>
                    </Dropdown>
                    <Button
                        icon={<ReloadOutlined/>}
                        onClick={fetchData}
                    >
                        Обновить
                    </Button>


                    {activeFilters.map((filter, index) => (
                        <Tag
                            key={index}
                            closable
                            onClose={() => handleRemoveFilter(index)}
                        >
                            {`${filter.type}: ${filter.value.label}`}
                        </Tag>
                    ))}
                </Space>
                <Space wrap>
                    {quickFilterButtons.map(button => (
                        <Button
                            key={button.key}
                            type={quickFilters[button.key] ? 'primary' : 'default'}
                            onClick={() => handleQuickFilter(button.key)}
                        >
                            {button.label}
                        </Button>
                    ))}
                </Space>
            </Space>
            <TableEditCards
                cards={filteredData}
                filters={filters_data}
                loading={loading}
                setCards={setCards}
                onEditCard={onEditCard}
                savePositions={savePositions}
            />

        </>
    )
}
