import {formatCurrency, formatDays, formatPercent, formatQuantity} from "../../../../core/utils/formats";
import {EyeOutlined, HeartOutlined} from "@ant-design/icons";
import {Checkbox, Form, Input, Select, Switch, Table} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";

const {TextArea} = Input;

const getColumns = (filters_data, likeHideList, onChangeLikeHide, isHideColumns, savePositions, isAdmin) => {
    const isMobile = window.innerWidth <= 769;
    const save = (e) => {
        console.log('Change:', e.target.value, e.target.id);
        savePositions(e.target.id, e.target.value);
    };
    return [
        {title: <HeartOutlined/>, width: 21, className: 'width21', render: (text, record) => (<Checkbox checked={likeHideList['like'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'like')}/>), hidden: isHideColumns,},
        {title: <EyeOutlined/>, width: 21, className: 'width21', render: (text, record) => (<Checkbox checked={likeHideList['hide'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'hide')}/>), hidden: isHideColumns,},
        {title: '', dataIndex: 'img', width: 20, className: 'width21', fixed: isMobile ? '': 'left', render: (text) => <img src={text} style={{'height': '20px'}}></img>,},
        {title: 'Артикул продавца', dataIndex: 'article', ellipsis: true, width: 100, render: (text) => <a href={`product/${text}`}>{text}</a>,},
        {title: 'Артикул WB', dataIndex: 'nm_id', width: 100, ellipsis: true, fixed: isMobile ? '': 'left', hidden: isAdmin, },
        {title: 'Карточка', dataIndex: 'imt_id', width: 80, ellipsis: true, hidden: isAdmin, filters: filters_data['imt_ids'], filterSearch: true, onFilter: (value, record) => record.imt_id == value || record.is_fix,},
        {title: 'Категория', dataIndex: 'category', key: 'category', ellipsis: true, width: 100, fixed: isMobile ? '': 'left', filters: filters_data?.category, filterSearch: true, onFilter: (value, record) => record.category == value || record.is_fix,},
        {title: 'Модель', dataIndex: 'material', width: 100, ellipsis: true, editable: true, filters: filters_data?.material, fixed: isMobile ? '': 'left', filterSearch: true, onFilter: (value, record) => record.material?.indexOf(value) === 0 || record.is_fix,},
        {title: 'Цвет', dataIndex: 'color', width: 100, ellipsis: true, editable: true, filters: filters_data?.color, filterSearch: true, onFilter: (value, record) => record.color?.indexOf(value) === 0},
        {title: 'ABC', dataIndex: 'abc', width: 100, ellipsis: true, filters: filters_data?.abc, filterSearch: true, onFilter: (value, record) => record.abc?.indexOf(value) === 0,},
        {title: 'Сезон', dataIndex: 'sezon', width: 100, ellipsis: true, editable: true, filters: filters_data?.sezon, filterSearch: true, onFilter: (value, record) => record.sezon?.indexOf(value) === 0,},
        {title: 'Статус', dataIndex: 'status', width: 100, ellipsis: true, editable: true, filters: filters_data?.status, filterSearch: true, onFilter: (value, record) => record.status?.indexOf(value) === 0,},
        {title: 'Себестоимость', dataIndex: 'sebes', width: 100, ellipsis: true, editable: !isAdmin,},
        {
            title: 'План', className: 'yellow-color', hidden: isAdmin,
            children: [
                {title: 'Шт', dataIndex: 'plan_orders', width: 100, ellipsis: true, editable: true, className: 'yellow-color', render: (text) => formatQuantity(text),},
                {title: 'Шт в день', dataIndex: 'plan_orders_day', width: 100, ellipsis: true, className: 'yellow-color', render: (text) => formatQuantity(text),},
                {title: 'Рублей', dataIndex: 'plan_orders_sum', width: 100, ellipsis: true, editable: true, className: 'yellow-color', render: (text) => formatCurrency(text),},
                {title: 'Рублей в день', dataIndex: 'plan_orders_sum_day', width: 100, ellipsis: true, className: 'yellow-color', render: (text) => formatCurrency(text),},
            ]
        },
        {
            title: 'Фразы для отслеживания позиций', dataIndex: 'positions', width: 200, hidden: isAdmin, render: (text, record, index) => {
                let options = []
                let selected = []
                if (record?.params?.positions) {
                    record.params.positions.forEach((query) => {
                        options.push({label: query, value: query, nm_id: record.nm_id});
                        selected.push(query)
                    })

                }
                return <TextArea onPressEnter={save} onBlur={save} placeholder="Введите фразы через запятую" defaultValue={selected} id={record.nm_id}/>
            }
        },

    ]
}


const EditableContext = React.createContext(null);
const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({title, editable, children, dataIndex, record, handleSave, ...restProps}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const validationRules = {
        plan_orders: [
            {
                transform: (value) => Number(value),
                type: 'number',
                message: `Нужно ввести число`,
            },
        ],
        plan_orders_sum: [
            {
                transform: (value) => Number(value),
                type: 'number',
                message: `Нужно ввести число`,
            },
        ],
        sebes: [
            {
                transform: (value) => Number(value),
                type: 'number',
                message: `Нужно ввести число`,
            },
        ]
    };
    const specificRules = validationRules[dataIndex] || [];

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={specificRules}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div
                className='editable-cell-value-wrap'
                style={{
                    paddingInlineEnd: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
export default function TableEditCards(args) {
    const [isHideColumns, setHideColumns] = useState(false)
    let defaultColumns = getColumns(args.filters, args.likeHideList, args.onChangeLikeHide, isHideColumns, args.savePositions, args.isAdmin);

    let DataSource = args.cards

    const handleSave = (row) => {
        const newData = [...args.cards];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];


        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        args.onEditCard(row);
        args.setCards(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    function enhanceColumn(col, handleSave) {
        if (col.children) {
            col.children = col.children.map((childCol) =>
                enhanceColumn(childCol, handleSave)
            );
        }
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    }

    const columns = defaultColumns.map((col) => enhanceColumn(col, handleSave));


    return <>
        <Table
            components={components}
            columns={columns}
            dataSource={DataSource}
            bordered
            size={'small'}
            scroll={{
                y: 800,
            }}
            rowClassName={(record, index) => `editable-row `}
            loading={args.loading}
            pagination={{'defaultPageSize': 30, 'showTotal': (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`}}

        />
    </>
}