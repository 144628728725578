import {createContext, useState} from "react";

export const AuthContext = createContext({
    isAuth: false,
    setIsAuth: null,
    isCsrf: null,
    setIsCsrf: null,
    user: null,
    setUser: null,

})

export const AuthProvider = ({children}) => {

    const [isAuth, setIsAuth] = useState(localStorage.getItem('isAuth'))
    const [isCsrf, setIsCsrf] = useState(null)
    const [user, setUser] = useState({});

    return (
        <AuthContext.Provider
            value={{
                isAuth,
                setIsAuth,
                isCsrf,
                setIsCsrf,
                user,
                setUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}