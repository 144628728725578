import React, { useEffect, useState } from 'react';
import { Card, Typography, Select } from 'antd';
import { useAuth } from '../../core/hooks/useAuth';
import dayjs from 'dayjs';
import chroma from 'chroma-js';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import ActiveUser from '../../components/smart/charts/HeatMap/ActiveUser';
import { apiAxios } from '../../core/api/api_axios';

const { Text } = Typography;
const { Option } = Select;

const HeatMap = () => {
    const { user } = useAuth();
    const [data, setData] = useState([]);
    const [anal, setAnal] = useState([]);
    const [selectedWbaccs, setSelectedWbaccs] = useState([]);
    const startDate = dayjs().date() === 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month');
    const [period, setPeriod] = useState([startDate, dayjs()]);
    const [loading, setLoading] = useState(true);
    const [statDay, setStatDay] = useState([]);
    const [statHour, setStatHour] = useState([]);
    const [graphType, setGraphType] = useState('hour'); // 'hour' или 'day'

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await apiAxios.post('sales/heatmap/get', {
                wbacc_id: selectedWbaccs,
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD')
            });
            console.log('fetchedData', response);

            if (response.success) {
                setData(response.heatmap || []);
                setAnal(response.anal || []);
                setStatDay(response.stat_day || []);
                setStatHour(response.stat_hour || []);
            } else {
                console.error('Ошибка от сервера:', response.error);
            }
        } catch (error) {
            console.error('Ошибка при загрузке данных тепловой карты:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : []);
    }, [user.wbacc_ids]);

    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData();
        }
    }, [selectedWbaccs, period]);

    const getColor = (percentage) => {
        const colorScale = chroma.scale(['#fff7e0', '#f9713c']).domain([0, 100]);
        return colorScale(percentage).hex();
    };

    const hourAverages = Array(24)
        .fill(0)
        .map((_, hour) => {
            // Получаем массив значений процента для текущего часа по всем дням
            const hourData = data
                .map((dayData) => {
                    const hourInfo = dayData.hours.find((h) => h.hour === hour);
                    return hourInfo ? hourInfo.percentage_hour : null;
                })
                .filter((value) => value !== null); // Исключаем null значения

            // Рассчитываем среднее значение, если у нас есть данные для текущего часа
            const avg = hourData.length ? hourData.reduce((acc, val) => acc + val, 0) / hourData.length : 0;
            return avg.toFixed(1);
        });

    const dayPercentages = data.map((dayData) => dayData.percentage_day.toFixed(1));

    // Конфигурация данных для графика по часам
    const hourActivityData = statHour.map((count, hour) => ({
        label: `${hour}`, // Изменено на просто номер часа
        value: count
    }));

    // Конфигурация данных для графика по дням недели
    const dayLabels = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
    const dayActivityData = statDay.map((count, index) => ({
        label: dayLabels[index],
        value: count
    }));

    return (
        <Card loading={loading}>
            <ActiveUser data={anal} />
            <Text className='font-bold text-2xl' strong>
                В какое время чаще всего совершают покупки <span className='text-[#f9713c]'>на маркетплейсках</span>
            </Text>

            {/* Тепловая карта */}
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '40px repeat(24, 1fr) 60px',
                    gridTemplateRows: 'auto repeat(7, 40px) 40px', // Добавляем дополнительный ряд для меток часов
                    gap: '2px',
                    marginTop: '16px',
                    alignItems: 'center'
                }}
            >
                <div />
                {hourAverages.map((avg, hourIndex) => (
                    <div key={hourIndex} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {avg}%
                    </div>
                ))}
                <div />
                {data.map((dayData, dayIndex) => (
                    <React.Fragment key={dayIndex}>
                        <Text style={{ gridRow: dayIndex + 2, gridColumn: '1', fontSize: '0.9rem', textAlign: 'right', marginRight: '5px' }}>
                            {dayLabels[dayData.day]}
                        </Text>
                        {dayData.hours.map((hourData, hourIndex) => (
                            <div
                                key={hourIndex}
                                style={{
                                    backgroundColor: getColor(hourData.percentage_hour),
                                    gridRow: dayIndex + 2,
                                    gridColumn: hourIndex + 2,
                                    height: '100%',
                                    borderRadius: '4px',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: hourData.count > 0 ? '#000' : 'transparent', // Скрываем текст, если нет заказов
                                    fontSize: '0.7rem',
                                    fontWeight: 'bold'
                                }}
                                title={`Час: ${hourData.hour}, Заказы: ${hourData.count}, Процент: ${hourData.percentage_hour}%`}
                            >
                                {hourData.count > 0 && hourData.count}
                            </div>
                        ))}

                        {/* Day Percentage */}
                        <Text style={{ gridRow: dayIndex + 2, gridColumn: 26, textAlign: 'center', fontWeight: 'bold' }}>
                            {dayPercentages[dayIndex]}%
                        </Text>
                    </React.Fragment>
                ))}

                {/* Нижний ряд: метки часов */}
                <div /> {/* Пустая ячейка в левом нижнем углу */}
                {Array.from({ length: 24 }, (_, hour) => (
                    <div key={hour} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        {hour}
                    </div>
                ))}
                <div /> {/* Пустая ячейка в правом нижнем углу */}
            </div>

            {/* Активность покупателей по заказам */}
            <div style={{ marginTop: '32px' }}>
                {/* Заголовок графика и селектор на одном уровне */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text className='font-bold text-2xl' strong>
                        Активность покупателей по <span className='text-[#a065ff]'>заказам</span>
                    </Text>
                    <Select
                        defaultValue="hour"
                        style={{ width: 200 }}
                        onChange={(value) => setGraphType(value)}
                    >
                        <Option value="hour">По часам</Option>
                        <Option value="day">По дням недели</Option>
                    </Select>
                </div>
                {/* Подзаголовок серым текстом под названием графика */}
                <Text className='text-dark opacity-60 text-sm'>
                    Посчитали среднюю активность ваших покупателей по заказам, учитывая их часовой пояс
                </Text>

                <ResponsiveContainer width="100%" height={300}>
                    {graphType === 'hour' ? (
                        <BarChart
                            data={hourActivityData}
                            margin={{ top: 20, right: 40, left: 0, bottom: 0 }}
                        >
                            <CartesianGrid stroke="#e0e0e0" vertical={false} />
                            <XAxis dataKey="label" axisLine={false} tickLine={false} />
                            <YAxis
                                allowDecimals={false}
                                orientation="right"
                                axisLine={false}
                                tickLine={false}
                                tick={{ fontSize: 12 }}
                                domain={[0, 'auto']}
                            />
                            {/* "чел." над Y */}
                            <text
                                x="100%"
                                y={20}
                                dy={-5}
                                dx={-50}
                                textAnchor="end"
                                style={{ fontSize: '12px', fill: '#666' }}
                            >
                                чел.
                            </text>
                            <Tooltip />
                            <Bar
                                dataKey="value"
                                fill="#a065ff"
                                name="Количество заказов"
                                radius={[5, 5, 0, 0]}
                                barSize={20}
                            />
                        </BarChart>
                    ) : (
                        <BarChart
                            data={dayActivityData}
                            margin={{ top: 20, right: 40, left: 0, bottom: 0 }}
                        >
                            <CartesianGrid stroke="#e0e0e0" vertical={false} />
                            <XAxis dataKey="label" axisLine={false} tickLine={false} />
                            <YAxis
                                allowDecimals={false}
                                orientation="right"
                                axisLine={false}
                                tickLine={false}
                                tick={{ fontSize: 12 }}
                                domain={[0, 'auto']}
                            />
                            {/* "чел." над Y */}
                            <text
                                x="100%"
                                y={20}
                                dy={-5}
                                dx={-50}
                                textAnchor="end"
                                style={{ fontSize: '12px', fill: '#666' }}
                            >
                                чел.
                            </text>
                            <Tooltip />
                            <Bar
                                dataKey="value"
                                fill="#a065ff"
                                name="Количество заказов"
                                radius={[10, 10, 0, 0]}
                                barSize={20}
                            />
                        </BarChart>
                    )}
                </ResponsiveContainer>
            </div>
        </Card>
    );
};

export default HeatMap;
