import Navbar from './pages/home/navbar';
import './App.css';
import React, {useEffect} from 'react';
import {Layout} from 'antd';
import {useAuth} from '../src/hooks/useAuth';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {NoMatchTest} from './pages/test';
import PlanFact from './pages/rnp/planFact';
import SalesReport from './pages/rnp/SalesReport';
import Prices from './pages/products/prices';
import {SignInPage} from './pages/auth/SignIn';
import {SignUpPage} from './pages/auth/SignUp';
import OrdersYear from './pages/sales/OrdersYear';
import Position from './pages/products/Positions';
import Marketing from './pages/marketing/marketing';
import {Profile} from './pages/user/profile';
import Adverts from "./pages/marketing/adverts";
import Advert from "./pages/marketing/advert";
import Details from './pages/marketing/marketingDetails';
import {Catalog} from './pages/useless/catalog';
import StatGeneral from "./pages/main/generalStat";
import GeneralStatDay from './pages/main/generalStatDay';
import {Terms} from './pages/user/Terms';
import TrafficAcc from './pages/marketing/trafficAcc';
import {Traffic} from './pages/marketing/traffic';
import {TrafficTrack} from './pages/marketing/trafficTrack';
import {apiAxios} from "../src/utils/api_axios";

import PnL from "./pages/finances/pnl";
import AnalPeriod from "./pages/useless/analPeriod";
import OrdersMain from './pages/sales/OrdersMain';
import FinDetail from "./pages/finances/finDetail";
import {Dashboard} from "./pages/home/dashboard";
import SalesPlan from './pages/rnp/SalesPlan';
import PricingV2 from "./pages/products/pricing_v2";
import Oborot from "./pages/rnp/Oborot";
import PlanFactV3 from "./pages/rnp/PlanFactV3";
import MyProducts from "./pages/products/MyProducts";

export default function App() {
    const {isAuth, setIsAuth, user, setUser} = useAuth();
    const fetchData = async () => {
        const res = await apiAxios.get('user/session/');

        if (res.isAuthenticated) {
            setIsAuth(true);
            setUser(res.user);
        } else {
            setIsAuth(false);
        }

    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Layout>
            {isAuth ? <Navbar/> : <> </>}
            <Layout.Content>
                <Routes>
                    <Route path='/' element={<RequireAuth><Dashboard/></RequireAuth>}/>
                    <Route path='/plan-fact' element={<RequireAuth><PlanFact/></RequireAuth>}/>
                    <Route path='/plan-fact-v2' element={<RequireAuth><PlanFactV3/></RequireAuth>}/>
                    <Route path='/sales-report' element={<RequireAuth><SalesReport/></RequireAuth>}/>
                    <Route path='/pnl' element={<RequireAuth><PnL/></RequireAuth>}/>
                    <Route path='/pnl-detail' element={<RequireAuth><FinDetail/></RequireAuth>}/>
                    <Route path='/prices' element={<RequireAuth><Prices/></RequireAuth>}/>
                    <Route path='/prices_v2' element={<RequireAuth><PricingV2/></RequireAuth>}/>
                    <Route path='/orders-year' element={<RequireAuth><OrdersYear/></RequireAuth>}/>
                    <Route path='/login' element={<SignInPage/>}/>
                    <Route path='/signup' element={<SignUpPage/>}/>
                    <Route path='/positions' element={<RequireAuth><Position/></RequireAuth>}></Route>
                    <Route path='/analytic-period' element={<RequireAuth><AnalPeriod/></RequireAuth>}></Route>
                    <Route path='/marketing' element={<RequireAuth><Marketing/></RequireAuth>}></Route>
                    <Route path="/profile" element={<RequireAuth><Profile/></RequireAuth>}></Route>
                    <Route path="/marketing-details" element={<RequireAuth><Details/></RequireAuth>}></Route>
                    <Route path='/adverts' element={<RequireAuth><Adverts/></RequireAuth>}></Route>
                    <Route path="/advert/:wbacc_id/:id" element={<RequireAuth><Advert/></RequireAuth>}></Route>
                    <Route path="/catalog" element={<RequireAuth><Catalog/></RequireAuth>}></Route>
                    <Route path="/stat-general" element={<RequireAuth><StatGeneral/></RequireAuth>}></Route>
                    <Route path="/stat-general-day" element={<RequireAuth><GeneralStatDay/></RequireAuth>}></Route>
                    <Route path="/traffic" element={<RequireAuth><Traffic/></RequireAuth>}/>
                    <Route path="/traffic/:wbacc_id" element={<RequireAuth><TrafficAcc/></RequireAuth>}/>
                    <Route path="/traffic/:wbacc_id/:track_id" element={<RequireAuth><TrafficTrack/></RequireAuth>}/>
                    <Route path="/orders-list" element={<RequireAuth><OrdersMain/></RequireAuth>}/>
                    <Route path="/oborot" element={<RequireAuth><Oborot/></RequireAuth>}/>

                    <Route path="/plan-sales" element={<RequireAuth><SalesPlan/></RequireAuth>}/>
                    <Route path="/orders-sales" element={<RequireAuth><OrdersMain/></RequireAuth>}/>

                     <Route path="/my-product-v2" element={<RequireAuth><MyProducts/></RequireAuth>}/>

                    <Route path="/terms" element={<Terms/>}/>
                    <Route path='*' element={<NoMatchTest/>}/>
                </Routes>
            </Layout.Content>
        </Layout>
    );
}

function RequireAuth({children}) {
    const {isAuth} = useAuth();
    let location = useLocation();
    if (!isAuth) {
        return <Navigate to='/login' state={{from: location}} replace/>;
    }

    return children;
}
