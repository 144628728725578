import React from 'react'
import { formatCurrency, formatPercent } from '../../../../core/utils/formats'

export const GeneralProfit = ({ inf }) => {
    const profit = [
        { title: 'Цена со скидкой', procent: inf.price_with_discount, format: 'rub' },
        { title: 'Наценка', procent: inf.nacenka_unit },
        { title: 'Маржа', procent: inf.marzha_unit },
        { title: 'Прибыль', procent: inf.profit_unit }
    ]

    return (
        <div className='bg-white flex flex-col h-fit max-w-[900px] w-full gap-4 rounded-2xl p-5'>
            <h2 className='text-xl md:text-3xl font-bold text-black'>Цена</h2>
            <div className='flex flex-col gap-2'>
                {profit.map((item, index) => (
                    <div key={index} className={`${index !== 0 && 'border-t-[1px] border-gray-300'} flex pt-2 justify-between items-center`}>
                        <p className='text-xs md:text-xl  font-medium'>{item.title}</p>
                        <div className='flex items-center gap-2'>
                            <span className='text-sm md:text-2xl font-bold'>{item.format === 'rub' ? formatCurrency(item.procent) : formatPercent(item.procent)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
