import React, { useState } from 'react'
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, defs, linearGradient, stop } from 'recharts'

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className='custom-tooltip' style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                <p>{label}</p>
                {payload[0] && (
                    <p className='text-xs md:text-base' style={{ color: '#E66ED4' }}>
                        ■ Заказы, шт: {payload[0].value}
                    </p>
                )}
                {payload[1] && (
                    <p className='text-xs md:text-base' style={{ color: '#427EFE' }}>
                        ■ Продажи, шт: {payload[1].value}
                    </p>
                )}
                {payload[2] && (
                    <p className='text-xs md:text-base' style={{ color: '#E66ED4' }}>
                        ● Заказы, руб: {payload[2].value.toLocaleString()}
                    </p>
                )}
                {payload[3] && (
                    <p className='text-xs md:text-base' style={{ color: '#427EFE' }}>
                        ● Продажи, руб: {payload[3].value.toLocaleString()}
                    </p>
                )}
            </div>
        )
    }

    return null
}

export const GeneralGraph = ({ data }) => {
    const [visibleItems, setVisibleItems] = useState({
        orders: true,
        buyouts: true,
        ordersRUB: true,
        buyoutsRUB: true
    })

    const toggleVisibility = (key) => {
        setVisibleItems({ ...visibleItems, [key]: !visibleItems[key] })
    }
    const checkboxes = [
        { id: 'checkBox1', label: 'Заказы, шт', key: 'orders', accentColor: '#E66ED4' },
        { id: 'checkBox2', label: 'Продажи, шт', key: 'buyouts', accentColor: '#427EFE' },
        { id: 'checkBox3', label: 'Заказы, руб', key: 'ordersRUB', accentColor: '#E66ED4' },
        { id: 'checkBox4', label: 'Продажи, руб', key: 'buyoutsRUB', accentColor: '#427EFE' }
    ]

    return (
        <>
            <div className='flex justify-end flex-wrap gap-4 pr-2'>
                {checkboxes.map(({ id, label, key, accentColor }) => (
                    <label key={id} className='flex items-center text-xs gap-1'>
                        <input id={id} type='checkbox' style={{ accentColor }} checked={visibleItems[key]} onChange={() => toggleVisibility(key)} />
                        {label}
                    </label>
                ))}
            </div>
            <ResponsiveContainer width='100%' height={400}>
                <ComposedChart data={data}>
                    <defs>
                        <linearGradient id='colorOrders' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='0%' stopColor='#E66ED4' stopOpacity={1} />
                            <stop offset='50%' stopColor='#D268DC' stopOpacity={1} />
                            <stop offset='100%' stopColor='#C565E3' stopOpacity={0.3} />
                        </linearGradient>
                        <linearGradient id='colorSales' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='0%' stopColor='#427EFE' stopOpacity={1} />
                            <stop offset='50%' stopColor='#6F6FF9' stopOpacity={1} />
                            <stop offset='100%' stopColor='#9263F4' stopOpacity={0.5} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid stroke='#f5f5f5' />

                    <XAxis dataKey='name' />
                    <YAxis yAxisId='left' orientation='left' />
                    <YAxis yAxisId='right' orientation='right' />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />

                    {visibleItems.orders && <Bar yAxisId='left' dataKey='orders' barSize={20} fill='url(#colorOrders)' name='Заказы, шт' />}
                    {visibleItems.buyouts && <Bar yAxisId='left' dataKey='buyouts' barSize={20} fill='url(#colorSales)' name='Продажи, шт' />}

                    {visibleItems.ordersRUB && (
                        <Line yAxisId='right' type='monotone' dataKey='ordersRUB' stroke='url(#colorOrders)' strokeWidth={2} dot={{ r: 4, fill: '#E66ED4' }} name='Заказы, руб' />
                    )}
                    {visibleItems.buyoutsRUB && (
                        <Line yAxisId='right' type='monotone' dataKey='buyoutsRUB' stroke='url(#colorSales)' strokeWidth={2} dot={{ r: 4, fill: '#427EFE' }} name='Продажи, руб' />
                    )}
                </ComposedChart>
            </ResponsiveContainer>
        </>
    )
}
