import axios from 'axios';
import Cookies from 'universal-cookie';
import { serverUrl } from "../utils/index";

axios.defaults.xsrfCookieName = 'csrftoken'; // Имя куки, где хранится CSRF-токен
axios.defaults.xsrfHeaderName = 'X-CSRFToken'; // Имя заголовка, в который будет помещен токен

class ApiAxiosClass {
    constructor() {
        this.cookies = new Cookies(); // Создаем экземпляр Cookies
        this.csrftoken = this.cookies.get('xcsrftoken'); // Проверяем наличие токена в куках
    }

    async get_csrf() {
        //console.log('Получение CSRF-токена...');
        const res = await axios.get(serverUrl + 'user/csrf/', { withCredentials: true });
        this.csrftoken = res.headers.get('X-CSRFToken'); // Получаем токен из заголовков

        // Сохраняем CSRF-токен в куках на 1 день
        this.cookies.set('xcsrftoken', this.csrftoken, { path: '/', maxAge: 86400*30 }); // 86400 секунд = 1 день
        //console.log('CSRF-токен получен и сохранен:', this.csrftoken);
    }

    async post(url, data) {
        if (!this.csrftoken || this.csrftoken == 'undefined') {
            await this.get_csrf(); // Получаем токен, если его нет
        }
        try {
            const res = await axios.post(serverUrl + url, data, {withCredentials: true, headers: {"Content-Type": "application/json", "X-CSRFToken": this.csrftoken,},});
            return res.data;
        } catch (error) {
            if (error.response && error.response.status === 403) {
                await this.get_csrf();
                const res = await axios.post(serverUrl + url, data, {withCredentials: true, headers: {"Content-Type": "application/json", "X-CSRFToken": this.csrftoken,},});
                return res.data;
            }
        }

    }
    async get(url, res1) {
        const res = await axios.get(serverUrl + url, { withCredentials: true, headers: { "Content-Type": "application/json" }, params: res1 });
        return res.data;
    }

}


export const apiAxios = new ApiAxiosClass();