import React from 'react';
import { DatePicker } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/ru'; // Импортируем русскую локализацию для dayjs
import ruRU from 'antd/lib/locale/ru_RU'; // Импортируем русскую локализацию для Ant Design
import { ConfigProvider } from 'antd';

const { RangePicker } = DatePicker;

dayjs.locale('ru');

const disabled30DaysDate = (current, { from }) => {
    if (from) {
        return Math.abs(current.diff(from, 'days')) >= 31;
    }
    return false;
};

const rangePresets = [
    {
        label: 'Последние 7 дней',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Последние 14 дней',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Последние 30 дней',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'Текущий месяц',
        value: [dayjs().startOf('month'), dayjs()],
    },
];

const DatePickerRange = (props) => {
    const { selected, setSelected } = props;

    return (
        <ConfigProvider locale={ruRU}> {/* Устанавливаем русскую локализацию для Ant Design */}
            <RangePicker
                suffixIcon={<CalendarOutlined />}
                presets={rangePresets}
                defaultValue={selected}
                minDate={dayjs('2024-06-01', 'YYYY-MM-DD')}
                maxDate={dayjs()}
                allowClear={false}
                disabledDate={disabled30DaysDate}
                onChange={(value) => setSelected(value)}
                placeholder={['Начальная дата', 'Конечная дата']} // Плейсхолдеры на русском
            />
        </ConfigProvider>
    );
};

export default DatePickerRange;
