import {useAuth} from'../../hooks/useAuth'
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {defaultOnChangeLikeHide} from "../components/LikeHide";
import TableStatGeneral from "./components/generalStat";
import {GeneralPieBlocks} from "./components/generalPieBlocks";
import TableStatGeneralCategory from "./components/generalStatCategory";
import {Card, Typography} from 'antd';
import TableStatGeneralOst from "./components/generalStatOst";
import { apiAxios } from"../../utils/api_axios";

const {Title} = Typography;

export default function StatGeneral() {
    const {isCsrf} = useAuth();
    const [filters_data, setFiltersData] = useState([]);
    const [cards, setCards] = useState([]);
    const [summedStat, setSummedStat] = useState([]);
    const [categoryStat, setCategoryStat] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [sortedColumns, setSortedColumns] = useState([]);
    const [date, setDate] = useState(dayjs());
    const [likeHideList, setLikeHideList] = useState({like: [], hide: []});
    const [loading, setLoading] = useState(true);

    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await apiAxios.get('general/stat/get/', { date: date })
            console.log('fetchedData', fetchedData);
            setCards(fetchedData['cards']);
            setFiltersData(fetchedData['filters']);
            setSummedStat(fetchedData['summed_stat']);
            setSortedColumns(fetchedData['sorted_columns']);
            setCategoryStat(fetchedData['category_stat']);
            setTableData(fetchedData['summed_stat']['table']);  
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [date]);

    return (
        <>
            <Card >
                <Typography.Title level={1}>Общая аналитика</Typography.Title>
                <GeneralPieBlocks
                    data={summedStat?.miniblocks}
                />
            </Card>
            <Card>
                <Title style={{marginTop: 0}}>Сгруппированная статистика</Title>
                <TableStatGeneralCategory
                    data={categoryStat}
                    loading={loading}
                />
            </Card>
            <Card>
                <Title style={{ marginTop: 0 }}>Остатки по категориям</Title>
                <TableStatGeneralOst
                    data={tableData}
                    loading={loading}
                />
            </Card>
            <Card>
                <Title style={{marginTop: 0}}>Статистика по артикулам</Title>
                <TableStatGeneral
                    cards={cards}
                    filters={filters_data}
                    likeHideList={likeHideList}
                    onChangeLikeHide={onChangeLikeHide}
                    loading={loading}
                />
            </Card>
        </>
    );
}

