import { Card, Checkbox, Col, ConfigProvider, Flex, Row, Select, Tabs, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { Area, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from 'recharts'
import Title from 'antd/es/typography/Title'

const { Text } = Typography

const CheckboxWithTheme = ({ themeColor, checked, onChange, label }) => (
    <ConfigProvider theme={{ token: { colorPrimary: themeColor } }}>
        <Checkbox checked={checked} onChange={onChange}>
            {label}
        </Checkbox>
    </ConfigProvider>
)

const ActiveUser = ({ data }) => {
    const [activeLines, setActiveLines] = useState({
        open_card: true,
        add_to_cart: true,
        orders: true,
        buyouts: true
    })

    const handleCheckboxChange = (key) => {
        setActiveLines((prev) => ({
            ...prev,
            [key]: !prev[key]
        }))
    }
    return (
        <>
            <Title level={2}>Активность покупателей</Title>
            <Flex className='flex-col'>
                <Text className='text-dark opacity-60 text-sm'>Посчитали активность уникальных покупателей на каждом этапе воронки продаж.</Text>
                <Text className='text-dark opacity-60 text-sm'>Также учли тех, кто сделал больше одного заказа или выкупа за выбранный период. Такие заказы и выкупы являются повторными</Text>
            </Flex>
            <Flex className='mt-5 gap-1'>
                <CheckboxWithTheme themeColor='#FF69B4' checked={activeLines.open_card} onChange={() => handleCheckboxChange('open_card')} label='Перешли в карточку' />
                <CheckboxWithTheme themeColor='#8A2BE2' checked={activeLines.add_to_cart} onChange={() => handleCheckboxChange('add_to_cart')} label='Добавили в корзину' />
                <CheckboxWithTheme themeColor='#00BFFF' checked={activeLines.orders} onChange={() => handleCheckboxChange('orders')} label='Заказали' />
                <CheckboxWithTheme themeColor='#32CD32' checked={activeLines.buyouts} onChange={() => handleCheckboxChange('buyouts')} label='Выкупили' />
            </Flex>
            <ResponsiveContainer width='100%' height={400}>
                <ComposedChart data={data} margin={{ top: 20, right: 30, bottom: 20, left: 30 }}>
                    <CartesianGrid stroke='#f0f0f0' />
                    <XAxis dataKey='date' />
                    <YAxis yAxisId='left' orientation='left' />
                    <YAxis yAxisId='right' orientation='right' />
                    <Tooltip
                        content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <Card className='custom-tooltip'>
                                        <p>{`${label}`}</p>
                                        {payload.map((entry, index) => (
                                            <div key={`item-${index}`} className='flex items-center w-[300px] justify-between'>
                                                <div className='flex items-center gap-2'>
                                                    <div style={{ background: entry.color }} className='h-[6px] w-7'></div>
                                                    <span className='font-bold '>{entry.name}:</span>
                                                </div>
                                                <span className='font-bold'>{entry.value} </span>
                                            </div>
                                        ))}
                                    </Card>
                                )
                            }
                            return null
                        }}
                    />
                    <Legend />
                    {activeLines.open_card && <Area type='monotone' yAxisId='left' dataKey='open_card' stroke='#FF69B4' fill='url(#colorOpenCard)' name='Перешли в карточку' />}
                    {activeLines.add_to_cart && <Area type='monotone' yAxisId='right' dataKey='add_to_cart' stroke='#8A2BE2' fill='url(#colorAddToCart)' name='Добавили в корзину' />}
                    {activeLines.orders && <Area type='monotone' yAxisId='right' dataKey='orders' stroke='#00BFFF' fill='url(#colorOrders)' name='Заказали' />}
                    {activeLines.buyouts && <Area type='monotone' yAxisId='right' dataKey='buyouts' stroke='#32CD32' fill='url(#colorBuyouts)' name='Выкупили' />}
                    <defs>
                        <linearGradient id='colorOpenCard' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor='#FF69B4' stopOpacity={0.8} />
                            <stop offset='95%' stopColor='#FF69B4' stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id='colorAddToCart' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor='#8A2BE2' stopOpacity={0.8} />
                            <stop offset='95%' stopColor='#8A2BE2' stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id='colorOrders' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor='#00BFFF' stopOpacity={0.8} />
                            <stop offset='95%' stopColor='#00BFFF' stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id='colorBuyouts' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor='#32CD32' stopOpacity={0.8} />
                            <stop offset='95%' stopColor='#32CD32' stopOpacity={0} />
                        </linearGradient>
                    </defs>
                </ComposedChart>
            </ResponsiveContainer>
        </>
    )
}

export default ActiveUser
