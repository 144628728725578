import React from 'react'
import { useSidebar } from '../../../Context/SideBarContext'
const Sidebar = () => {
    const { isSidebarVisible, sidebarContent, closeSidebar } = useSidebar()

    return (
        <>
            {isSidebarVisible && (
                <div
                    onClick={closeSidebar}
                    className='sidebar-overlay'
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 999,
                        transition: 'opacity 1s ease',
                        opacity: isSidebarVisible ? 1 : 0
                    }}
                />
            )}

            <div
                className={`sidebar ${isSidebarVisible ? 'open' : ''} overflow-scroll`}
                style={{
                    position: 'fixed',
                    right: isSidebarVisible ? 0 : '-600px',
                    top: 0,
                    width: '600px',
                    height: '100vh',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '20px',
                    boxShadow: '-3px 0 5px rgba(0, 0, 0, 0.2)',
                    transition: 'right 1s ease'
                }}
            >
                {/* <IoMdClose onClick={closeSidebar} className='text-xl mb-2  hover:rotate-90 active:scale-90 transition-all duration-300 cursor-pointer' /> */}
                {sidebarContent}
            </div>
        </>
    )
}

export default Sidebar
