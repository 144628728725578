import React, { useState } from 'react'
import { Menu } from 'antd'
import { BarChartOutlined, CalculatorOutlined, EyeOutlined, HomeOutlined, InboxOutlined, MailOutlined, ProductOutlined, SettingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useAuth } from '../../core/hooks/useAuth'

const getItems = (is_admin) => {
    let items = [
        {
            label: 'Главная',
            key: 'main',
            icon: <BarChartOutlined />,
            children: [
                { label: <Link to='/'>Дашборд</Link>, key: 'main:1' },
                { label: <Link to='/'>Еженедельный</Link>, key: 'main:2' },
                { label: <Link to='/'>Детализация</Link>, key: 'main:3' }
            ]
        },
        {
            label: 'Продажи',
            key: 'sales',
            icon: <BarChartOutlined />,
            children: [
                { label: <Link to='/plan-fact'>План Факт</Link>, key: 'sales:1' },
                { label: <Link to='/oborot'>Оборачиваемость</Link>, key: 'sales:2' },
                { label: <Link to='/prices'>Ценообразование</Link>, key: 'sales:3' },
                { label: <Link to='/'>План</Link>, key: 'sales:4' },
                { label: <Link to='/heatmap'>Тепловая карта</Link>, key: 'sales:5' },
                { label: <Link to='/orders-list'>Лента заказов</Link>, key: 'sales:6' }
            ]
        },
        {
            label: 'Остатки',
            key: 'ost',
            icon: <BarChartOutlined />,
            children: [
                { label: <Link to='/'>Остатки</Link>, key: 'ost:1' },
                { label: <Link to='/'>Логистика</Link>, key: 'ost:2' },
                { label: <Link to='/'>Локализация</Link>, key: 'ost:3' },
                { label: <Link to='/'>Хранение</Link>, key: 'ost:4' }
            ]
        },
        {
            label: 'Аналитика',
            key: 'anal',
            icon: <BarChartOutlined />,
            children: [
                { label: <Link to='/analytic'>По товарам</Link>, key: 'anal:1' },
                { label: <Link to='/analytic-period'>За период</Link>, key: 'anal:2' },
                { label: <Link to='/orders-year'>Продажи год</Link>, key: 'anal:3' }
            ]
        },
        {
            label: 'Финансы',
            key: 'finance',
            icon: <CalculatorOutlined />,
            children: [
                { label: <Link to='/pnl'>Прибыли и убытки</Link>, key: 'finance:1' },
                { label: <Link to='/pnl-detail'>Прибыли и убытки детализация</Link>, key: 'finance:2' },
                { label: <Link to='/'>ДДС</Link>, key: 'finance:3' },
                { label: <Link to='/sales-report'>Отчет по продажам</Link>, key: 'finance:4' }
            ]
        },
        {
            label: 'Товары',
            key: 'products',
            icon: <InboxOutlined />,
            children: [
                { label: <Link to='/edit-cards'>Все товары</Link>, key: 'products:1' },
                { label: <Link to='/'>Юнит экономика</Link>, key: 'products:2' },
                { label: <Link to='/'>ABC</Link>, key: 'products:3' },
                { label: <Link to='/'>Отзывы и вопросы</Link>, key: 'products:4' },
                { label: <Link to='/positions'>Позиции</Link>, key: 'products:5' },
                { label: <Link to='/catalog'>Справочник</Link>, key: 'products:6' }
            ]
        },
        {
            label: 'Поставки',
            key: 'supply',
            icon: <InboxOutlined />,
            children: [
                { label: <Link to='/supplies'>Планирование</Link>, key: 'supply:1' },
                { label: <Link to='/supply'>Рассчет поставки</Link>, key: 'supply:2' },
                { label: <Link to='/'>Добавить поставку</Link>, key: 'supply:3' }
            ]
        },
        {
            label: 'Маркетинг',
            key: 'marketing',
            icon: <EyeOutlined />,
            children: [
                { label: <Link to='/marketing'>Дашборд</Link>, key: 'marketing:1' },
                { label: <Link to='/marketing-details'>Детализация</Link>, key: 'marketing:2' },
                { label: <Link to='/adverts'>Рекламные кампании</Link>, key: 'marketing:3' },
                { label: <Link to='/'>Сравнение периодов</Link>, key: 'marketing:4' },
                { label: <Link to='/traffic'>Внешний трафик</Link>, key: 'marketing:5' }
            ]
        }
    ]
    if (is_admin) {
        items.unshift({
            label: 'Общая',
            key: 'our',
            icon: <HomeOutlined />,
            children: [
                { label: <Link to='/stat-general'>Аналитика</Link>, key: 'our:1' },
                { label: <Link to='/stat-general-day'>Аналитика за период</Link>, key: 'our:2' }
            ]
        })
    }
    return items
}

const LeftMenu = ({ mode, showDrawer }) => {
    const [current, setCurrent] = useState('mail')
    const { user } = useAuth()
    return (
        <div className='menuContainer'>
            <Menu
                mode={mode}
                onClick={(e) => {
                    setCurrent(e.key)
                    if (mode == 'inline') {
                        showDrawer()
                    }
                }}
                selectedKeys={[current]}
                items={getItems(user?.is_admin)}
            />
        </div>
    )
}

export default LeftMenu
