import React, {useState} from 'react'
import {Menu} from 'antd'
import {BarChartOutlined, CalculatorOutlined, EyeOutlined, HomeOutlined, InboxOutlined, MailOutlined, ProductOutlined, SettingOutlined} from '@ant-design/icons'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../hooks/useAuth'

const getItems = (is_admin) => {
    let items = [
        {
            label: 'Общая',
            key: 'main',
            icon: <HomeOutlined/>,
            children: [
                {label: <Link to='/stat-general'>Аналитика</Link>, key: 'main:1'},
                {label: <Link to='/stat-general-day'>Аналитика за период</Link>, key: 'main:2'}
            ]
        },

        {
            label: 'РНП',
            key: 'rnp',
            icon: <BarChartOutlined/>,
            children: [
                {label: <Link to='/plan-sales'>План</Link>, key: 'rnp:1'},
                {label: <Link to='/plan-fact'>План Факт</Link>, key: 'rnp:2'},
                {label: <Link to='/plan-fact-v2'>План Факт V2</Link>, key: 'rnp:3'},
                {label: <Link to='/oborot'>Оборачиваемость</Link>, key: 'rnp:4'},
                {label: <Link to='/sales-report'>Отчет по продажам</Link>, key: 'rnp:5'}
            ]
        },
        {
            label: 'Продажи',
            key: 'sales',
            icon: <BarChartOutlined/>,
            children: [
                {label: <Link to='/orders-sales'>Заказы и продажи</Link>, key: 'sales:1'},
                {label: <Link to='/orders-year'>Продажи год</Link>, key: 'sales:2'}
            ]
        },
        {
            label: 'Финансы',
            key: 'finance',
            icon: <CalculatorOutlined/>,
            children: [
                {label: <Link to='/pnl'>Прибыли и убытки</Link>, key: 'finance:1'},
                {label: <Link to='/pnl-detail'>Прибыли и убытки детализация</Link>, key: 'finance:2'},
                //{ label: <Link to='/'>ДДС</Link>, key: 'finance:3' },
            ]
        },
        {
            label: 'Товары',
            key: 'products',
            icon: <InboxOutlined/>,
            children: [
                {label: <Link to='/my-product-v2'>Товары</Link>, key: 'products:1'},
                {label: <Link to='/prices'>Ценообразование</Link>, key: 'products:2'},
                {label: <Link to='/prices_v2'>Ценообразование V2</Link>, key: 'products:3'},
                {label: <Link to='/positions'>Позиции</Link>, key: 'products:4'},
                // { label: <Link to='/catalog'>Справочник</Link>, key: 'products:6' }
            ]
        },
        {
            label: 'Маркетинг',
            key: 'marketing',
            icon: <EyeOutlined/>,
            children: [
                {label: <Link to='/marketing'>Дашборд</Link>, key: 'marketing:1'},
                {label: <Link to='/marketing-details'>Детализация</Link>, key: 'marketing:2'},
                {label: <Link to='/adverts'>Рекламные кампании</Link>, key: 'marketing:3'},
                //{ label: <Link to='/'>Сравнение периодов</Link>, key: 'marketing:4' },
                {label: <Link to='/traffic'>Внешний трафик</Link>, key: 'marketing:5'}
            ]
        }
    ]

    return items
}

const LeftMenu = ({mode, showDrawer}) => {
    const [current, setCurrent] = useState('mail')
    const {user} = useAuth()
    console.log('user', user);
    return (
        <div className='menuContainer'>
            <Menu
                mode={mode}
                onClick={(e) => {
                    setCurrent(e.key)
                    if (mode == 'inline') {
                        showDrawer()
                    }
                }}
                selectedKeys={[current]}
                items={getItems(user?.is_admin)}
            />
        </div>
    )
}

export default LeftMenu
