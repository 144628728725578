import Navbar from './containers/navbar';
import './App.css';
import React, {useEffect} from 'react';
import {Layout} from 'antd';
import {useAuth} from './core/hooks/useAuth';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {NoMatchTest} from './pages/test';
import PlanFact from './pages/analCards/planFact';
import SalesReport from './pages/finances/salesReport';
import Prices from './pages/analCards/prices';
import {SignInPage} from './pages/auth/SignIn';
import {SignUpPage} from './pages/auth/SignUp';
import Supplies from './pages/analStock/supplies';
import OrdersYear from './pages/analCards/ordersYear';
import Position from './pages/utils/positions';
import Anal from './pages/analCards/anal';
import EditCards from './pages/settings/editCards';
import Marketing from './pages/marketing/marketing';
import {Profile} from './pages/user/profile';
import Adverts from "./pages/marketing/adverts";
import Advert from "./pages/marketing/advert";
import Details from './pages/marketing/marketingDetails';
import Supply from "./pages/analStock/supply";
import {Catalog} from './pages/settings/catalog';
import StatGeneral from "./pages/general/generalStat";
import GeneralStatDay from './pages/general/generalStatDay';
import {Terms} from './pages/user/Terms';
import Product from './pages/product/Product';
import TrafficAcc from './pages/marketing/trafficAcc';
import {Traffic} from './pages/marketing/traffic';
import {TrafficTrack} from './pages/marketing/trafficTrack';
import {apiAxios} from "./core/api/api_axios";
import PnL from "./pages/finances/pnl";
import AnalPeriod from "./pages/analCards/analPeriod";
import OrdersMain from './pages/orders/OrdersMain';
import FinDetail from "./pages/finances/finDetail";
import {Dashboard} from "./pages/home/dashboard";
import Oborot from './pages/orders/Oborot';
import SalesPlan from './pages/finances/SalesPlan';
import HeatMap from './pages/orders/HeatMap';

export default function App() {
    const {isAuth, setIsAuth, user, setUser} = useAuth();
    const fetchData = async () => {
        const res = await apiAxios.get('user/session/');
        if (res.isAuthenticated) {
            setIsAuth(true);
            setUser(res.user);
        } else {
            setIsAuth(false);
        }

    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Layout>
            {isAuth ? <Navbar/> : <> </>}
            <Layout.Content>
                <Routes>
                    <Route path='/' element={<RequireAuth><Dashboard/></RequireAuth>}/>
                    <Route path='/plan-fact' element={<RequireAuth><PlanFact/></RequireAuth>}/>
                    <Route path='/sales-report' element={<RequireAuth><SalesReport/></RequireAuth>}/>
                    <Route path='/pnl' element={<RequireAuth><PnL/></RequireAuth>}/>
                    <Route path='/pnl-detail' element={<RequireAuth><FinDetail/></RequireAuth>}/>
                    <Route path='/prices' element={<RequireAuth><Prices/></RequireAuth>}/>
                    <Route path='/supplies' element={<RequireAuth><Supplies/></RequireAuth>}/>
                    <Route path='/supply' element={<RequireAuth><Supply/></RequireAuth>}/>
                    <Route path='/orders-year' element={<RequireAuth><OrdersYear/></RequireAuth>}/>
                    <Route path='/login' element={<SignInPage/>}/>
                    <Route path='/signup' element={<SignUpPage/>}/>
                    <Route path='/positions' element={<RequireAuth><Position/></RequireAuth>}></Route>
                    <Route path='/analytic-period' element={<RequireAuth><AnalPeriod/></RequireAuth>}></Route>
                    <Route path='/analytic' element={<RequireAuth><Anal/></RequireAuth>}></Route>
                    <Route path='/edit-cards' element={<RequireAuth><EditCards/></RequireAuth>}></Route>
                    <Route path='/marketing' element={<RequireAuth><Marketing/></RequireAuth>}></Route>
                    <Route path="/profile" element={<RequireAuth><Profile/></RequireAuth>}></Route>
                    <Route path="/marketing-details" element={<RequireAuth><Details/></RequireAuth>}></Route>
                    <Route path='/adverts' element={<RequireAuth><Adverts/></RequireAuth>}></Route>
                    <Route path="/advert/:wbacc_id/:id" element={<RequireAuth><Advert/></RequireAuth>}></Route>
                    <Route path="/catalog" element={<RequireAuth><Catalog/></RequireAuth>}></Route>
                    <Route path="/stat-general" element={<RequireAuth><StatGeneral/></RequireAuth>}></Route>
                    <Route path="/stat-general-day" element={<RequireAuth><GeneralStatDay/></RequireAuth>}></Route>
                    <Route path="/product" element={<RequireAuth><Product/></RequireAuth>}></Route>
                    <Route path="/traffic" element={<RequireAuth><Traffic/></RequireAuth>}/>
                    <Route path="/traffic/:wbacc_id" element={<RequireAuth><TrafficAcc/></RequireAuth>}/>
                    <Route path="/traffic/:wbacc_id/:track_id" element={<RequireAuth><TrafficTrack/></RequireAuth>}/>
                    <Route path="/orders-list" element={<RequireAuth><OrdersMain/></RequireAuth>}/>
                    <Route path="/oborot" element={<RequireAuth><Oborot/></RequireAuth>}/>
                    <Route path="/plan-sales" element={<RequireAuth><SalesPlan/></RequireAuth>}/>
                    <Route path="/heatmap" element={<RequireAuth><HeatMap/></RequireAuth>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path='*' element={<NoMatchTest/>}/>
                </Routes>
            </Layout.Content>
        </Layout>
    );
}

function RequireAuth({children}) {
    const {isAuth} = useAuth();
    let location = useLocation();
    if (!isAuth) {
        return <Navigate to='/login' state={{from: location}} replace/>;
    }

    return children;
}
