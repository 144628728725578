import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Typography, Tag, Tooltip, Select, Input } from 'antd';
import { DownloadOutlined, ReloadOutlined, UnorderedListOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import OrdersCharts from './OrdersCharts';

const { Text } = Typography;

const OrdersTable = ({ data, loading, onRefresh, chartData }) => {
    const [filteredData, setFilteredData] = useState(data);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [searchArticle, setSearchArticle] = useState('');

    // Extract unique regions and warehouses
    const regions = [...new Set(data?.filter(item => item.region).map(item => item.region))];
    const warehouses = [...new Set(data?.filter(item => item.warehouse_name).map(item => item.warehouse_name))];

    useEffect(() => {
        let result = data || [];

        if (selectedRegion) {
            result = result.filter(item => item.region === selectedRegion);
        }

        if (selectedWarehouse) {
            result = result.filter(item => item.warehouse_name === selectedWarehouse);
        }

        if (selectedStatus) {
            result = result.filter(item => {
                switch (selectedStatus) {
                    case 'delivering':
                        return item.sale_date === 'None' && !item.is_cancel;
                    case 'delivered':
                        return item.sale_date !== 'None' && !item.is_return;
                    case 'cancelled':
                        return item.is_cancel;
                    case 'returned':
                        return item.is_return;
                    default:
                        return true;
                }
            });
        }

        if (searchArticle) {
            result = result.filter(item => 
                item.nm_id?.toString().includes(searchArticle)
            );
        }

        setFilteredData(result);
    }, [data, selectedRegion, selectedWarehouse, selectedStatus, searchArticle]);

    const columns = [
        {
            title: 'Дата заказа',
            dataIndex: 'order_date_time',
            key: 'order_date_time',
            width: 150,
            render: (text) => dayjs(text).format('DD.MM.YY HH:mm'),
            sorter: (a, b) => dayjs(a.order_date_time).unix() - dayjs(b.order_date_time).unix(),
        },
        {
            title: 'ID заказа (rid)',
            dataIndex: 'rid',
            key: 'rid',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Артикул WB',
            dataIndex: 'nm_id',
            key: 'nm_id',
            width: 120,
        },
        {
            title: 'Артикул продавца',
            dataIndex: 'article',
            key: 'article',
            width: 120,
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (name) => (
                <Tooltip placement="topLeft" title={name}>
                    {name}
                </Tooltip>
            ),
        },
        {
            title: 'Баркод',
            dataIndex: 'barcode',
            key: 'barcode',
            width: 130,
        },
        {
            title: 'Размер',
            dataIndex: 'size',
            key: 'size',
            width: 100,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (_, record) => {
                const getStatusTag = () => {
                    switch (true) {
                        case record.sale_date === 'None' && !record.is_cancel:
                            return <Tag color="blue">Доставляется</Tag>;
                        case record.sale_date !== 'None' && !record.is_return:
                            return <Tag color="green">Доставлен</Tag>;
                        case record.is_cancel:
                            return <Tag color="red">Отменен</Tag>;
                        case record.is_return:
                            return <Tag color="purple">Возврат</Tag>;
                        default:
                            return '';
                    }
                };
                return getStatusTag();
            },
           
            
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            width: 100,
            render: (price) => price ? `${price.toLocaleString()} ₽` : '-',
        },
        {   
            title: 'Склад',
            dataIndex: 'warehouse_name',
            key: 'warehouse_name',
            width: 120,
        },
        {
            title: 'Регион',
            dataIndex: 'region',
            key: 'region',
            width: 150,
        },
    ];

    const handleRefresh = () => {
        setSelectedRegion(null);
        setSelectedWarehouse(null);
        setSelectedStatus(null);
        setSearchArticle('');
        onRefresh?.();
    };

    return (
        <div>
            <OrdersCharts chartData={chartData} />
            <Space style={{ marginBottom: 16, marginTop: 16 }} size="middle">
                <Space>
                    <Select
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Фильтр по региону"
                        onChange={setSelectedRegion}
                        value={selectedRegion}
                        options={regions.map(region => ({ label: region, value: region }))}
                    />
                    <Select
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Фильтр по складу"
                        onChange={setSelectedWarehouse}
                        value={selectedWarehouse}
                        options={warehouses.map(warehouse => ({ label: warehouse, value: warehouse }))}
                    />
                    <Select
                        allowClear
                        style={{ width: 200 }}
                        placeholder="Фильтр по статусу"
                        onChange={setSelectedStatus}
                        value={selectedStatus}
                        options={[
                            { label: 'Доставляется', value: 'delivering' },
                            { label: 'Доставлен', value: 'delivered' },
                            { label: 'Отменен', value: 'cancelled' },
                            { label: 'Возврат', value: 'returned' }
                        ]}
                    />
                    <Input.Search
                        placeholder="Поиск по артикулу WB"
                        style={{ width: 200 }}
                        value={searchArticle}
                        onChange={(e) => setSearchArticle(e.target.value)}
                        allowClear
                    />
                </Space>
                <Button icon={<DownloadOutlined />}>СКАЧАТЬ</Button>
                <Button 
                    icon={<ReloadOutlined />} 
                    onClick={handleRefresh}
                    loading={loading}
                >
                    ОБНОВИТЬ
                </Button>
            </Space>
            <Table
                columns={columns}
                dataSource={filteredData}
                loading={loading}
                scroll={{ x: 'max-content' }}
                size="small"
                pagination={{
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} заказов`,
                }}
                rowKey="rid"
            />
        </div>
    );
};

export default OrdersTable;
