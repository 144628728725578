import React, {useCallback, useEffect, useState} from 'react'
import {Card, Col, Row, Select, Tooltip, Typography} from 'antd'
import {SelectorMulti, SelectorSolo} from '../../components/Selectors'
import TablePlanFact from './components/planFact'
import {useAuth} from '../../hooks/useAuth'
import DatePickerRange from '../../components/dateRange'
import dayjs from 'dayjs'
import {options_hide, options_like} from '../../utils'
import {defaultOnChangeLikeHide, setLikeHideOnLoadData} from '../components/LikeHide'
import {determineNormalDistributionBounds} from '../../components/Gradient'
import {ButtonExel} from '../../components/Buttons'
import { useSidebar } from '../../Context/SideBarContext'
import Sidebar from '../home/sideBar/SideBar'
import SideBarPlanFact from '../home/sideBar/SideBarPlanFact'
import {CiCircleQuestion} from 'react-icons/ci'
import {apiAxios} from "../../utils/api_axios";

function PlanFact() {
    const {isCsrf} = useAuth()
    const [filters_data, setFiltersData] = useState([])
    const [cards, setCards] = useState([])
    const [sum_stat, setSumStat] = useState([])
    const [mini_blocks, setMiniBlocks] = useState([])
    const [selectedWbaccs, setSelectedWbaccs] = useState([])
    const {openSidebar} = useSidebar()

    const {user} = useAuth()
    const handleOpenSidebar = useCallback(() => {
        console.log('Button clicked')
        openSidebar(<SideBarPlanFact/>)
    }, [openSidebar])

    const [showedDays, setShowedDays] = useState([dayjs().add(-1, 'd').format('DD.MM')])
    const [selected_hide, setSelectedHide] = useState(1)
    const [selected_like, setSelectedLike] = useState(1)
    const startDate = dayjs().date() == 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month')
    const [period, setPeriod] = useState([startDate, dayjs().add(-1, 'd')])
    const [likeHideList, setLikeHideList] = useState({like: [], hide: []})
    const [loading, setLoading] = useState(true)
    const [isOzon, setIsOzon] = useState(true)
    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }
    const [gradientData, setGradientData1] = useState({})
    const setGradientData = (name, value) => {
        setGradientData1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }
    const filter_keys = ['ost', 'way_to', 'way_from', 'full', 'ms_ost', 'orders_sum', 'orders_per_day', 'orders', 'oborot_orders', 'marzha']

    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedData = await apiAxios.get('plan-fact/get/', {
                'wbacc_ids': [selectedWbaccs],
                'is_like': 1,
                'is_hide': 2,
                'st': period[0].format('YYYY-MM-DD'),
                'en': period[1].format('YYYY-MM-DD'),
            });
            setFiltersData(fetchedData['filters'])

            setCards(fetchedData['cards'])
            setSumStat(fetchedData['sum_stat'])
            setMiniBlocks(fetchedData['mini_blocks'])
            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            setLoading(false)
            filter_keys.forEach((key) => {
                const allValues = fetchedData['cards'].map((item) => item[key])

                // Определяем границы на основе нормального распределения
                let bounds = determineNormalDistributionBounds(allValues)
                setGradientData(key, bounds)
            })
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    //console.log('filters_data', filters_data);
    // useEffect(() => {
    //     getCSRF(setIsCsrf);
    //     fetchUser(setSelectedWbaccs);
    //
    // }, []);

    useEffect(() => {
        setIsOzon(true)
        fetchData()
        // selectedWbaccs.map((item) => {
        //     if (item > 0) {
        //         setIsOzon(false)
        //     }
        // })
    }, [selected_hide, selected_like, selectedWbaccs, period])

    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : []);
    }, [user.wbacc_ids]);

    return (
        <>
            <Card>
                <Typography.Title level={1}>План факт</Typography.Title>
                <Row type='flex' style={{gap: 10, alignItems: 'end'}}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod}/>
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Поставщики</Typography.Title>
                        <Select
                            mode='multiple'
                            maxTagCount={'responsive'}
                            allowClear
                            style={{width: '100%'}}
                            placeholder='Выберите магазин'
                            value={selectedWbaccs}
                            onChange={(value) => setSelectedWbaccs(value)}
                            options={filters_data.wbaccs}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Избранное</Typography.Title>
                        <SelectorSolo options={options_like} selected={selected_like} setSelected={setSelectedLike}/>
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Скрытые товары</Typography.Title>
                        <SelectorSolo options={options_hide} selected={selected_hide} setSelected={setSelectedHide}/>
                    </Col>
                    <ButtonExel data={cards}/>
                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold'/>
                    </Tooltip>
                    <Sidebar/>
                </Row>
            </Card>
            <Card>
                <TablePlanFact
                    cards={cards}
                    filters={filters_data}
                    showedDays={showedDays}
                    setShowedDays={setShowedDays}
                    likeHideList={likeHideList}
                    onChangeLikeHide={onChangeLikeHide}
                    loading={loading}
                    sum_stat={sum_stat}
                    isOzon={isOzon}
                    gradientData={gradientData}
                />
            </Card>
        </>
    )
}

export default PlanFact
