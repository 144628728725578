import {useAuth} from "../../core/hooks/useAuth";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {defaultOnChangeLikeHide} from "../analCards/utils";
import {getStatGeneral} from "../../core/api/general/general_stat";
import TableStatGeneral from "../../components/smart/tables/general/generalStat";
import {GeneralPieBlocks} from "../../components/smart/charts/general/generalPieBlocks";
import TableStatGeneralCategory from "../../components/smart/tables/general/generalStatCategory";
import {Card, Typography} from 'antd';
import TableStatGeneralOst from "../../components/smart/tables/general/generalStatOst";
import {Navigate} from "react-router-dom";

const {Title} = Typography;

export default function StatGeneral() {
    const {user, isCsrf, setIsCsrf} = useAuth();
    const [filters_data, setFiltersData] = useState([]);
    const [cards, setCards] = useState([]);
    const [summedStat, setSummedStat] = useState([]);
    const [categoryStat, setCategoryStat] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [sortedColumns, setSortedColumns] = useState([]);
    const [date, setDate] = useState(dayjs());
    const [likeHideList, setLikeHideList] = useState({like: [], hide: []});
    const [loading, setLoading] = useState(true);

    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await getStatGeneral(
                date
            );
            console.log('fetchedData', fetchedData);
            setCards(fetchedData['cards']);
            setFiltersData(fetchedData['filters']);
            setSummedStat(fetchedData['summed_stat']);
            setSortedColumns(fetchedData['sorted_columns']);
            setCategoryStat(fetchedData['category_stat']);
            setTableData(fetchedData['summed_stat']['table']);  
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // useEffect(() => {
    //     getCSRF(setIsCsrf);
    //     fetchUser(setSelectedWbaccs);
    // }, []);

    useEffect(() => {
        fetchData();
    }, [date]);

    return (
        <>
            <Card >
                <Typography.Title level={1}>Общая аналитика</Typography.Title>
                <GeneralPieBlocks
                    data={summedStat?.miniblocks}
                />
            </Card>
            <Card>
                <Title style={{marginTop: 0}}>Сгруппированная статистика</Title>
                <TableStatGeneralCategory
                    data={categoryStat}
                    loading={loading}
                />
            </Card>
            <Card>
                <Title style={{ marginTop: 0 }}>Остатки по категориям</Title>
                <TableStatGeneralOst
                    data={tableData}
                    loading={loading}
                />
            </Card>
            <Card>
                <Title style={{marginTop: 0}}>Статистика по артикулам</Title>
                <TableStatGeneral
                    cards={cards}
                    filters={filters_data}
                    likeHideList={likeHideList}
                    onChangeLikeHide={onChangeLikeHide}
                    loading={loading}
                />
            </Card>
        </>
    );
}

