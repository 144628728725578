import { Table, Button } from "antd";
import React, { useState } from "react";

export default function TableStatGeneralOst({ data, loading }) {
    const transformedData = Object.values(data).map(item => ({
        wbacc_name: item.wbacc_name,
        ...item.abc,
        ...item.category,
        ...item.color,
        ...item.material,
        ...item.sezon,
        ...item.status
    }));

    const firstItem = data && Object.values(data)[0]; 

    const abcFields = firstItem?.abc || {}; 
    const categoryFields = firstItem?.category || {};
    const colorFields = firstItem?.color || {};
    const materialFields = firstItem?.material || {};
    const sezonFields = firstItem?.sezon || {};
    const statusFields = firstItem?.status || {};

    const [expandedColumns, setExpandedColumns] = useState({
        category: false,
        material: false,
        color: false
    });

    const toggleExpand = (column) => {
        setExpandedColumns(prev => ({ ...prev, [column]: !prev[column] }));
    };

    const generateColumns = (fields, className, width, type) => {
        const keys = Object.keys(fields);
        const displayCount = expandedColumns[type] ? keys.length : Math.min(keys.length, 10);
        
        return keys.slice(0, displayCount).map(key => ({
            title: key,
            dataIndex: key,
            render: (text) => (text ? text : '-'),
            width: width,
            className
        }));
    };

    const columns = [
        { title: 'WBACC Name', dataIndex: 'wbacc_name', key: 'wbacc_name', fixed:'left', width:100 },
        {
            title: (
                <span style={{display:'flex', alignItems:'center'}}>
                    <Button 
                        size="small" 
                        className="buttonOstatki"
                        onClick={() => toggleExpand('category')} 
                        style={{marginLeft:'10px', padding: '0 5px' }}
                    >
                        {expandedColumns.category ? '-' : '+'}
                    </Button>
                    <p style={{width:'100%', display:'flex', justifyContent:'center'}}>Категория</p>
                </span>
            ),
            className: 'blue-color',
            children: generateColumns(categoryFields, 'blue-color', 65, 'category')
        },
        { title: 'Сезон', className: 'blue2-color', children: generateColumns(sezonFields, 'blue2-color', 80) },
        { title: 'Статус', className: 'pink-color', children: generateColumns(statusFields, 'pink-color', 50) },
        { title: 'ABC', className: 'green-color', children: generateColumns(abcFields, 'green-color', 70) },
        {
            title: (
                <span style={{display:'flex', alignItems:'center'}}>
                    <Button 
                        size="small" 
                        className="buttonOstatki"
                        onClick={() => toggleExpand('material')} 
                        style={{marginLeft:'10px', padding: '0 5px' }}
                    >
                        {expandedColumns.material ? '-' : '+'}
                    </Button>
                    <p style={{width:'100%', display:'flex', justifyContent:'center'}}>Материал</p>
                </span>
            ),
            className: 'red-color',
            children: generateColumns(materialFields, 'red-color', 90, 'material')
        },
        {
            title: (
                <span style={{display:'flex', alignItems:'center'}}>
                    <Button 
                        size="small" 
                        className="buttonOstatki"
                        onClick={() => toggleExpand('color')} 
                        style={{marginLeft:'10px', padding: '0 5px' }}
                    >
                        {expandedColumns.color ? '-' : '+'}
                    </Button>
                    <p style={{width:'100%', display:'flex', justifyContent:'center'}}>Цвет</p>
                </span>
            ),
            className: 'yellow-color',
            children: generateColumns(colorFields, 'yellow-color', 65, 'color')
        },
    ];

    return (
        <Table
            dataSource={transformedData}
            columns={columns}
            size={'small'}
            bordered
            scroll={{ x: 400 }}
            loading={loading}
            rowClassName={(record) => `my-custom-row ${record.is_ms ? 'border_top' : ''}`}
            rowKey="wbacc_name"
            pagination={{'defaultPageSize': 30, 'showTotal': (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`}}
        />
    );
}