import React from "react";
import {Menu} from "antd";
import { InfoCircleOutlined, SettingOutlined, NotificationOutlined} from "@ant-design/icons";
import axios from "axios";
import {serverUrl} from "../../../utils";
import {useAuth} from '../../../hooks/useAuth'
import {Link, useNavigate} from "react-router-dom";

const items = [
    {
        icon: <NotificationOutlined/>, key: 'notice',
        children: [
            {label: <Link to="/app-front/public">Уведомления</Link>, key: 'notice:1',},

        ]
    },
    {
        icon:<InfoCircleOutlined/>, key: 'info',
        children: [
            {label: <Link to="/terms">Оферта</Link>, key: 'info:1',},
            {label: <Link to="/terms">Пользовательское соглашение</Link>, key: 'info:2',},
            {label: <Link to="/terms">Новости и обновления</Link>, key: 'info:3',},

        ]
    },
    {
        icon: <SettingOutlined/>, key: 'settings2',
        children: [
            {label: <Link to="/profile">Профиль</Link>, key: 'settings2:1'},
            // {label: <Link to="/profile">Магазины</Link>, key: 'settings2:2'},
            // {label: <Link to="/profile">Сотрудники</Link>, key: 'settings2:3'},
            // {label: <Link to="/profile">Подписка</Link>, key: 'settings2:4'},
            // {label: <Link to="/profile">Партнерская программа</Link>, key: 'settings2:5'},
            { type: 'divider' },
            {label: 'Выйти', key: 'log-out'},
        ]
    },

]

const RightMenu = ({mode, showDrawer}) => {
    const {isAuth, setIsAuth} = useAuth()
    const navigate = useNavigate();
    const logout = () => {
        console.log('loguout')
        axios.get(serverUrl + "user/logout/", {withCredentials: true})
            .then((res) => {
                setIsAuth(false);
                localStorage.setItem('isAuth', false);
                navigate('/login')
            })
            .catch(err => console.error(err));
    }
    const onClick = (e) => {
        if (mode == 'inline') {
            showDrawer();
        }
        if (e.key == 'log-out') {
            logout();
        }
        console.log('click ', e);
    };
    return (
        <div className="menuContainer2">
            <Menu mode={mode} onClick={onClick} items={items}>
                {/*<Menu.SubMenu*/}
                {/*    title={*/}
                {/*        <>*/}
                {/*            <Avatar icon={<UserOutlined/>}/>*/}
                {/*            <span className="username">John Doe</span>*/}
                {/*        </>*/}
                {/*    }*/}
                {/*>*/}
                {/*    <Menu.Item key="profile">*/}

                {/*        <UserOutlined/><Link to="/profile"> Профиль</Link>*/}
                {/*    </Menu.Item>*/}
                {/*    <Menu.Item key="log-out">*/}
                {/*        <LogoutOutlined/> Выйти*/}
                {/*    </Menu.Item>*/}
                {/*</Menu.SubMenu>*/}

            </Menu>
        </div>
    );
};

export default RightMenu;