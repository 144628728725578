import { Checkbox, Table } from 'antd';
import React from 'react';
import { formatCurrency, formatPercent, formatQuantity } from '../../../../core/utils/formats';

const metrics = [
    { key: 'quantity', label: 'Остатки' },
    { key: 'orders', label: 'Заказов' },
    { key: 'orders_sum', label: 'Сумма заказов' },
    { key: 'marzha_unit', label: 'Маржинальность' },
    { key: 'oborot_buyouts', label: 'Оборачиваемость' },
    { key: 'price', label: 'Цена' },
    { key: 'buyout_percent', label: 'Выкуп' },
    { key: 'budget', label: 'Бюджет РК' },
    { key: 'drr', label: 'ДРР' },
    { key: 'views', label: 'Просмотров' },
    { key: 'clicks', label: 'Кликов' },
    { key: 'CTR', label: 'CTR' },
    { key: 'CPC', label: 'CPC' },
    { key: 'drr', label: 'DRR' },
    { key: 'rating', label: 'Рейтинг' },
];

const getColumns = (filters_data, period, dateColumns, groupType) => {
    const columns = [
        {
            title: 'Поставщик',
            dataIndex: 'wbacc_name',
            fixed: 'left',
            width: 85,
            ellipsis: true,
        },
        {
            title: 'Показатели',
            dataIndex: 'metrics',
            fixed: 'left',
            render: () => [
                <div style={{ height: '301px' }}>
                    {metrics.map((item, key) => (
                        <div
                            style={{ borderBottom: '0.5px solid gray' }}
                            key={item.key}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>,
            ],
            width: 125,
        },
    ];
    console.log('dateColumns', dateColumns)
    dateColumns.forEach((date) => {

    columns.push({
        title: date,  
        dataIndex: groupType===1 ? 'by_days' : 'by_weeks',
        render: (by_days, record) => {
            const positionData = by_days?.find(day => day.date === date) || {};
            
            return (
                <div style={{ height: '301px' }}>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='quantity'>
                        {formatQuantity(positionData.quantity) || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='orders'>
                        {formatQuantity(positionData.orders) || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='orders_sum'>
                        {formatCurrency(positionData.orders_sum) || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='marzha_unit'>
                        {formatPercent(positionData.marzha_unit) || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='oborot_buyouts'>
                        {positionData.oborot_buyouts || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='price'>
                        {formatCurrency(positionData.price_client) || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='buyout_percent'>
                        {formatPercent(positionData.buyout_percent) || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='budget'>
                        {positionData.budget || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='drr'>
                        {positionData.drr || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='views'>
                        {positionData.views || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='clicks'>
                        {positionData.clicks || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='ctr'>
                        {positionData.ctr || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='cpc'>
                        {positionData.cpc || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='drr'>
                        {positionData.drr || '-'}
                    </div>
                    <div style={{ borderBottom: '0.5px solid gray' }} key='rating'>
                        {positionData.rating || '-'}
                    </div>
                </div>
            );
        },
        width: 150,
    });
});

    return columns;
};

const expandData = (cards) => {
    return cards.map((card) => {
        card['children'] = [];
        return {
            ...card,
        };
    });
};

export default function TableStatDay({period,cards, groupType, filters, loading,  dateColumns, }) {

    let columns = getColumns(
        filters,
        period,
        dateColumns,
        groupType
    );

    let DataSource = cards;

    if (cards) {
        DataSource = expandData(cards);
    }
     console.log('DataSource', DataSource)
    return (
        <>
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                scroll={{
                    y: 800,
                }}
                dateColumns={dateColumns}
                className='DaysTable'
                rowClassName={(record, index) => `my-custom-row`}
                loading={loading}
                pagination={false}
            />
        </>
    );
}
