import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useAuth } from '../../hooks/useAuth'
import { GeneralGraph } from './components/generalGraph'
import { GeneralFin } from './components/generalFin'
import { GeneralStorage } from './components/generalStorage'
import { Card, Col, Row, Select, Typography } from 'antd'
import dayjs from 'dayjs'
import DatePickerRange from '../../components/dateRange'
import { apiAxios } from "../../utils/api_axios";
import { FaBox, FaChartLine, FaUndo, FaBan } from 'react-icons/fa'
import { Skeleton } from 'antd'

export const Dashboard = () => {
    const { user } = useAuth()
    const [loading, setLoading] = useState(true)
    const [dashboardData, setDashboardData] = useState(false)

    const [period, setPeriod] = useState([dayjs().add(-30, 'd').startOf('day'), dayjs().add(-1, 'd').endOf('day')])
    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_id: null,
        wbacc_options: [],
    });

    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids});
        let wbaccOptions = [];

        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id});
        }

        setSelectorFilter('wbacc_id', user.wbacc_ids[0]);
        setSelectorFilter('wbacc_options', wbaccOptions);
    };

    const fetchData = async () => {
        setLoading(true)
        const response = await apiAxios.get('general/dashboard/get/', {wbacc_ids: [selectorFilter.wbacc_id], st: period[0].format('YYYY-MM-DD'), en: period[1].format('YYYY-MM-DD')});
        console.log('fetchedData', response)
        setDashboardData(response.data)
        setLoading(false)
    }
     useEffect(() => {
        if (!selectorFilter.wbacc_id && user.wbacc_ids) {
            fetchData1();
        }
        if (selectorFilter.wbacc_id) {
            fetchData();
        }
    }, [user.wbacc_ids, selectorFilter.wbacc_id, period]);


    return (
        <div className="p-4 md:p-6 min-h-screen">
            <Row gutter={[16, 16]} className="min-h-full">
                <Col xs={24} md={6} lg={4} className="">
                    <Card bordered={false} className="shadow-sm h-full m-0">
                        {loading ? (
                            <div className='flex flex-col gap-2'>
                                <Skeleton active paragraph={{ rows: 2 }} />
                                <Skeleton.Input active size={'default'} />
                            </div>
                        ) : (
                            <div className="flex flex-col h-full">
                                <div className="mb-3">
                                    <Typography.Title level={5} className="!mb-4">Период данных</Typography.Title>
                                    <DatePickerRange
                                        selected={period}
                                        setSelected={(dates) => {
                                            if (dates) {
                                                setPeriod([dates[0].startOf('day'), dates[1].endOf('day')])
                                            } else {
                                                setPeriod([dayjs().add(-30, 'd').startOf('day'), dayjs().add(-1, 'd').endOf('day')])
                                            }
                                        }}
                                    />
                                </div>

                                <div>
                                    <Typography.Title level={5} className="!mb-4">Магазины</Typography.Title>
                                    <Select
                                        maxTagCount={'responsive'}
                                        style={{ width: '100%' }}
                                        placeholder='Выберите магазин'
                                        value={selectorFilter.wbacc_id}
                                        onChange={(value) => setSelectorFilter('wbacc_id', value)}
                                        options={selectorFilter.wbacc_options}
                                    />
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>

                <Col xs={24} md={18} lg={20}>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
                        <Card bordered={false} className="shadow-sm hover:shadow-md transition-shadow m-0">
                            <div className="flex items-center justify-between">
                                <div>
                                    <Typography.Text className="text-gray-600">Отмены</Typography.Text>
                                    <div className="flex items-baseline gap-2">
                                        <Typography.Title level={4} className="!mb-0">{dashboardData?.cancels || 0} шт</Typography.Title>
                                        <Typography.Text type={dashboardData?.diff?.cancels >= 0 ? "success" : "danger"} className="text-sm">
                                            {dashboardData?.diff?.cancels >= 0 ? "+" : ""}{dashboardData?.diff?.cancels || 0}%
                                        </Typography.Text>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Card bordered={false} className="shadow-sm hover:shadow-md transition-shadow m-0">
                            <div className="flex items-center justify-between">
                                <div>
                                    <Typography.Text className="text-gray-600">Процент выкупа</Typography.Text>
                                    <div className="flex items-baseline gap-2">
                                        <Typography.Title level={4} className="!mb-0">{dashboardData?.buyout_percent || 0}%</Typography.Title>
                                        <Typography.Text type={dashboardData?.diff?.buyout_percent >= 0 ? "success" : "danger"} className="text-sm">
                                            {dashboardData?.diff?.buyout_percent >= 0 ? "+" : ""}{dashboardData?.diff?.buyout_percent || 0}%
                                        </Typography.Text>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Card bordered={false} className="shadow-sm hover:shadow-md transition-shadow m-0">
                            <div className="flex items-center justify-between">
                                <div>
                                    <Typography.Text className="text-gray-600">В пути до клиента</Typography.Text>
                                    <div className="flex items-baseline gap-2">
                                        <Typography.Title level={4} className="!mb-0">{dashboardData?.way_to || 0} шт</Typography.Title>
                                        <Typography.Text type={dashboardData?.diff?.way_to >= 0 ? "success" : "danger"} className="text-sm">
                                            {dashboardData?.diff?.way_to >= 0 ? "+" : ""}{dashboardData?.diff?.way_to || 0}%
                                        </Typography.Text>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Card bordered={false} className="shadow-sm hover:shadow-md transition-shadow m-0">
                            <div className="flex items-center justify-between">
                                <div>
                                    <Typography.Text className="text-gray-600">В пути от клиента</Typography.Text>
                                    <div className="flex items-baseline gap-2">
                                        <Typography.Title level={4} className="!mb-0">{dashboardData?.way_from || 0} шт</Typography.Title>
                                        <Typography.Text type={dashboardData?.diff?.way_from >= 0 ? "success" : "danger"} className="text-sm">
                                            {dashboardData?.diff?.way_from >= 0 ? "+" : ""}{dashboardData?.diff?.way_from || 0}%
                                        </Typography.Text>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-4'>
                        <Card bordered={false} className="shadow-sm hover:shadow-md transition-shadow m-0">
                            <div className="flex items-center justify-between mb-4">
                                <span className="text-gray-600 text-sm">Заказы</span>
                                <div className="p-2 bg-blue-100 rounded-lg">
                                    <FaBox className="text-blue-600 text-xl" />
                                </div>
                            </div>
                            {loading ? (
                                <Skeleton active paragraph={{ rows: 1 }} />
                            ) : (
                                <div className="space-y-2">
                                    <div className="flex items-baseline justify-between">
                                        <span className="text-2xl font-semibold">{dashboardData?.orders_sum || '0 ₽'}</span>
                                        <span className={`text-sm ${dashboardData?.orders_percent >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                            {dashboardData?.orders_percent >= 0 ? '+' : ''}{dashboardData?.orders_percent || '0'}%
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between text-sm text-gray-500">
                                        <span>В день ~ {dashboardData?.orders_per_day || '0'} шт</span>
                                        <span>{dashboardData?.orders || '0'} шт</span>
                                    </div>
                                </div>
                            )}
                        </Card>
                        <Card bordered={false} className="shadow-sm hover:shadow-md transition-shadow m-0">
                            <div className="flex items-center justify-between mb-4">
                                <span className="text-gray-600 text-sm">Продажи</span>
                                <div className="p-2 bg-purple-100 rounded-lg">
                                    <FaChartLine className="text-purple-600 text-xl" />
                                </div>
                            </div>
                            {loading ? (
                                <Skeleton active paragraph={{ rows: 1 }} />
                            ) : (
                                <div className="space-y-2">
                                    <div className="flex items-baseline justify-between">
                                        <span className="text-2xl font-semibold">{dashboardData?.buyouts_sum || '0 ₽'}</span>
                                        <span className={`text-sm ${dashboardData?.buyouts_percent >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                            {dashboardData?.buyouts_percent >= 0 ? '+' : ''}{dashboardData?.buyouts_percent || '0'}%
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between text-sm text-gray-500">
                                        <span>В день ~ {dashboardData?.buyouts_per_day || '0'} шт</span>
                                        <span>{dashboardData?.buyouts || '0'} шт</span>
                                    </div>
                                </div>
                            )}
                        </Card>
                        <Card bordered={false} className="shadow-sm hover:shadow-md transition-shadow m-0">
                            <div className="flex items-center justify-between mb-4">
                                <span className="text-gray-600 text-sm">Возвраты</span>
                                <div className="p-2 bg-orange-100 rounded-lg">
                                    <FaUndo className="text-orange-600 text-xl" />
                                </div>
                            </div>
                            {loading ? (
                                <Skeleton active paragraph={{ rows: 1 }} />
                            ) : (
                                <div className="space-y-2">
                                    <div className="flex items-baseline justify-between">
                                        <span className="text-2xl font-semibold">{dashboardData?.returns || '0'}</span>
                                        <span className={`text-sm ${dashboardData?.returns_percent >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                            {dashboardData?.returns_percent >= 0 ? '+' : ''}{dashboardData?.returns_percent || '0'}%
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between text-sm text-gray-500">
                                        <span>В день ~ {dashboardData?.returns_per_day || '0'} шт</span>
                                        <span>{dashboardData?.returns || '0'} шт</span>
                                    </div>
                                </div>
                            )}
                        </Card>
                        <Card bordered={false} className="shadow-sm hover:shadow-md transition-shadow m-0">
                            <div className="flex items-center justify-between mb-4">
                                <span className="text-gray-600 text-sm">Отмены</span>
                                <div className="p-2 bg-red-100 rounded-lg">
                                    <FaBan className="text-red-600 text-xl" />
                                </div>
                            </div>
                            {loading ? (
                                <Skeleton active paragraph={{ rows: 1 }} />
                            ) : (
                                <div className="space-y-2">
                                    <div className="flex items-baseline justify-between">
                                        <span className="text-2xl font-semibold">{dashboardData?.cancels || '0'}</span>
                                        <span className={`text-sm ${dashboardData?.cancels_percent >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                            {dashboardData?.cancels_percent >= 0 ? '+' : ''}{dashboardData?.cancels_percent || '0'}%
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between text-sm text-gray-500">
                                        <span>В день ~ {dashboardData?.cancels_per_day || '0'} шт</span>
                                        <span>{dashboardData?.cancels || '0'} шт</span>
                                    </div>
                                </div>
                            )}
                        </Card>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col span={24}>
                    <Card bordered={false} className="shadow-sm m-0">
                        {loading ? (
                            <Skeleton active paragraph={{ rows: 6 }} />
                        ) : (
                            <GeneralGraph data={dashboardData?.by_days} />
                        )}
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="mt-4">
                <Col xs={24} lg={12}>
                    <Card bordered={false} className="shadow-sm m-0">
                        {loading ? (
                            <Skeleton active paragraph={{ rows: 4 }} />
                        ) : (
                            <GeneralStorage inf={dashboardData} />
                        )}
                    </Card>
                </Col>
                <Col xs={24} lg={12}>
                    <Card bordered={false} className="shadow-sm m-0">
                        {loading ? (
                            <Skeleton active paragraph={{ rows: 4 }} />
                        ) : (
                            <GeneralFin inf={dashboardData} />
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}