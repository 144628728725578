// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sale_report_row0 {
    background-color: #c9daf8 !important;
}

.sale_report_row1 {
    background-color: #c9daf8 !important;
}

.sale_report_row2 {
    background-color: #cfe2f3 !important;
}

.sale_report_row3 {
    background-color: #c9daf8 !important;
}

.sale_report_row4 {
    background-color: #c9daf8 !important;
}

.sale_report_row5 {
    background-color: #fff2cc !important;
}

.sale_report_row6 {
    background-color: #fff2cc !important;
}

.sale_report_row7 {
    background-color: #fff2cc !important;
}

.sale_report_row8 {
    background-color: #fff2cc !important;
}

.sale_report_row9 {
    background-color: #cfe2f3 !important;
}

.sale_report_row10 {
    background-color: #cfe2f3 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/smart/tables/finances/styles.css"],"names":[],"mappings":";AACA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":["\r\n.sale_report_row0 {\r\n    background-color: #c9daf8 !important;\r\n}\r\n\r\n.sale_report_row1 {\r\n    background-color: #c9daf8 !important;\r\n}\r\n\r\n.sale_report_row2 {\r\n    background-color: #cfe2f3 !important;\r\n}\r\n\r\n.sale_report_row3 {\r\n    background-color: #c9daf8 !important;\r\n}\r\n\r\n.sale_report_row4 {\r\n    background-color: #c9daf8 !important;\r\n}\r\n\r\n.sale_report_row5 {\r\n    background-color: #fff2cc !important;\r\n}\r\n\r\n.sale_report_row6 {\r\n    background-color: #fff2cc !important;\r\n}\r\n\r\n.sale_report_row7 {\r\n    background-color: #fff2cc !important;\r\n}\r\n\r\n.sale_report_row8 {\r\n    background-color: #fff2cc !important;\r\n}\r\n\r\n.sale_report_row9 {\r\n    background-color: #cfe2f3 !important;\r\n}\r\n\r\n.sale_report_row10 {\r\n    background-color: #cfe2f3 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
