//export const serverUrl = 'http://localhost:8000/api/'
export const serverUrl = '/api/'

export const options_like = [
    { label: 'Все товары', value: 1 },
    { label: 'Избранные', value: 2 },
    { label: 'Не избранные', value: 3 }
]

export const options_hide = [
    { label: 'Не скрытые', value: 1 },
    { label: 'Все товары', value: 2 },
    { label: 'Скрытые', value: 3 }
]

export const monthsRU = { 0: 'Январь', 1: 'Февраль', 2: 'Март', 3: 'Апрель', 4: 'Май', 5: 'Июнь', 6: 'Июль', 7: 'Август', 8: 'Сентябрь', 9: 'Октябрь', 10: 'Ноябрь', 11: 'Декабрь' }
