import React from 'react'
import {Button } from 'antd'
import { formatCurrency, formatPercent } from '../../core/utils/formats';


const Product = () => {

    const inf = [{
        title:'Артикул продавца',
        content:'060130',
    },{
        title:'Артикул маркетплейса',
        content:'248784770',
    },{
        title:'Магазин',
        content:'ворок (Wildberries)',
    },{
        title:'Бренд',
        content:'TM LIMITED',
    },{
        title:'Себестоимость',
        cost:0.00,
    },{
        title:'Цена продажи',
        cost:1925.00,
    },];

    const days =[
        {
            date:'03.10.2024',
            sells:0,
            buyouts:3,
            ost:174
        },
        {
            date:'03.10.2024',
            sells:0,
            buyouts:3,
            ost:174
        },
        {
            date:'03.10.2024',
            sells:0,
            buyouts:3,
            ost:174
        },
        {
            date:'03.10.2024',
            sells:0,
            buyouts:3,
            ost:174
        },
        {
            date:'03.10.2024',
            sells:0,
            buyouts:3,
            ost:174
        },
        {
            date:'03.10.2024',
            sells:0,
            buyouts:3,
            ost:174
        },
    ]
    const move = [{
        title:'Заказы, шт',
        content:50,
    },{
        title:'Заказы (самовыкупы), шт',
        content:0,
    },{
        title:'Продажи, шт',
        content:13,
    },{
        title:'Продажи (самовыкупы), шт',
        content:0,
    },{
        title:'Отмены, шт',
        content:17,
    },{
        title:'Отмены (самовыкупы), шт',
        content:0,
    },{
        title:'Возвраты, шт',
        content:2,
    },{
        title:'Возвраты (самовыкупы), шт',
        content:0,
    },{
        title:'Процент выкупа',
        content:40.63,
        format:'%'
    },{
        title:'Процент выкупа (без самовыкупов)',
        content:40.63,
        format:'%'
    },{
        title:'Среднее заказов в день, шт',
        content:1.7,
    },{
        title:'Товар в пути, дней ср.',
        content:2.2,
    },{
        title:'Оборачиваемость, дней',
        content:258,
    },];

    const border = 'border-gray-300 border-[1px]'

    return <div className='p-5'>
        <div className='flex flex-col gap-2'>
            <h2 className='font-bold text-3xl text-black'>Toвap «060130«</h2>
            <Button className='max-w-[200px]' type='primary'>К списку товаров</Button>
        </div>
        <div className='bg-white w-full rounded-2xl'>
            <div className='border-[1px] w-fit border-gray-300 flex'>
                <div>PHOTO</div>
                <div className='border-l-[1px] border-gray-300'>
                    {inf.map((item,index) => <div className={`${index !== 0 && 'border-t-[1px] border-gray-300'} flex items-center justify-between`} key={index}>
                        <p className='w-[350px] px-1'>{item.title}</p>
                        <span className={`border-l-[1px] border-gray-300 w-[250px] py-1 px-1`}>{item?.content || formatCurrency(item?.cost)}</span>
                    </div>)}
                </div>
            </div>
            Движение товара
            <div className='border-[1px] w-fit flex-col border-gray-300 flex'>
                    {move.map((item,index) => <div className={`${index !== 0 && 'border-t-[1px] border-gray-300'} flex items-center justify-between`} key={index}>
                        <p className='w-[350px] px-1'>{item.title}</p>
                        <span className={`border-l-[1px] border-gray-300 w-[250px] py-1 px-1`}>{item.format === '%' ? formatPercent(item?.content) : item?.content}</span>
                    </div>)}
            </div>
            Заказы по дням
            <div className={`${border} flex flex-col max-w-[500px]`}>
                <div className='flex'>
                    <p className='w-[300px]'>День</p>
                    <p className='w-[150px] border-l-[1px] border-gray-300 text-center'>Заказы</p>
                    <p className='w-[150px] border-l-[1px] border-gray-300 text-center'>Продажи</p>
                    <p className='w-[150px] border-l-[1px] border-gray-300 text-center'>Остатки</p>
                </div>
                <div>
                    {days.map((item,index) => <div key={index} className={`${index !== 0 ? 'border-t-[1px] border-gray-300' : 'border-t-4 border-black'} flex`}>
                    <span className='w-[300px]'>{item?.date}</span><span className='w-[150px] border-l-[1px] border-gray-300 text-center'>{item?.buyouts}</span><span className='w-[150px] border-l-[1px] border-gray-300 text-center'>{item?.sells}</span><span className='w-[150px] border-l-[1px] border-gray-300 text-center'>{item?.ost}</span>
                    </div>)}
                </div>
            </div>
        </div>
    </div>
}

export default Product
