import { apiAxios } from '../api_axios';

export const getStatWeeks = async(period) => {
    try {
        return await apiAxios.get('general/stat_by_week/get/', {
            st: period[0].format('YYYY-MM-DD'),
            en: period[1].format('YYYY-MM-DD'),
        })
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return [];
    }
};