import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

const { Text } = Typography

const SideBarPositions = () => {
    return (
        <div>
            <Title level={2}>Описание страницы "Позиции"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                Страница <span className='text-black'>"Позиции"</span> на вашем сайте предназначена для отображения подробной информации по каждой товарной позиции. На ней собраны данные, позволяющие
                анализировать и управлять каждым товаром в отдельности, отслеживать ключевые показатели и динамику.
            </Text>
        </div>
    )
}

export default SideBarPositions
