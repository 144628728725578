import { Card, Col, Row, Select, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ButtonExel } from "../../components/simple/buttons/Buttons";
import { Space, Table } from 'antd';
import '../../App.css'
import { formatCurrency, formatPercent } from "../../core/utils/formats";
import { GetSales } from "../../core/api/finances/sales";
import { useAuth } from "../../core/hooks/useAuth";
import dayjs from "dayjs";
import { SelectorSolo } from "../../components/simple/selects";
import DatePickerRange from "../../components/simple/dateRange";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { apiAxios } from "../../core/api/api_axios";

const TAB_NAMES = ['Заказы[504]', 'Выкупы[123]', 'Отказы[49]', 'Возвраты[185]'];

const columns = [
  {
    title: 'ДАТА',
    dataIndex: 'order_date',
    key: 'order_date',
    width:70,
  },
  {
    title: 'АРТИКУЛ WB',
    dataIndex: 'nm_id',
    key: 'nm_id',
    width:100,
  },
  {
    title: 'БАРКОД',
    dataIndex: 'barcode',
    key: 'barcode',
        width:120,
  },
  {
    title: 'ЦЕНА ЗАКАЗА/ВЫКУПА',
    dataIndex: 'price',
    key: 'price',
    render: (text) => formatCurrency(text),
    sorter:(a,b) => a.price - b.price,
    width:150,
  },
  {
    title: 'СО СКЛАДА',
    dataIndex: 'warehouse_name',
    key: 'warehouse_name',
        width:70,
  },
  {
    title: 'СТРАНА',
    dataIndex: 'country',
    key: 'country',
        width:70,
  },
  {
    title: 'РЕГИОН',
    dataIndex: 'region',
    key: 'region',
        width:150,
  },
  {
    title: 'СТАТУС',
    dataIndex: 'status',
    key: 'status',
    width:50
  },
];

const columnsCancels = [
{
    title: 'ДАТА',
    dataIndex: 'order_date',
    key: 'order_date',
    width:70,
  },
  {
    title: 'АРТИКУЛ WB',
    dataIndex: 'nm_id',
    key: 'nm_id',
    width:100,
  },
  {
    title: 'БАРКОД',
    dataIndex: 'barcode',
    key: 'barcode',
        width:120,
  },
  {
    title: 'ЦЕНА ЗАКАЗА/ВЫКУПА',
    dataIndex: 'price',
    key: 'price',
    render: (text) => formatCurrency(text),
    sorter:(a,b) => a.price - b.price,
    width:120,
  },
  {
    title: 'СО СКЛАДА',
    dataIndex: 'warehouse_name',
    key: 'warehouse_name',
        width:120,
  },
  {
    title: 'СТРАНА',
    dataIndex: 'country',
    key: 'country',
        width:70,
  },
  {
    title: 'РЕГИОН',
    dataIndex: 'region',
    key: 'region',
        width:150,
  },
  {
    title: 'Отказано',
    dataIndex: 'is_cancel',
    key: 'is_cancel',
    render: (isCancel) => (
      isCancel ? 
        <CheckCircleOutlined style={{ color: 'green' }} /> : 
        <CloseCircleOutlined style={{ color: 'red' }} />
    ),
    width:50
  },
];

const columnsSales = [
  {
    title: 'ДАТА ЗАКАЗА',
    dataIndex: 'order_date',
    key: 'order_date',
    width:100
  },
  {
    title: 'ДАТА ВЫКУПА',
    dataIndex: 'sale_date',
    key: 'sale_date',
    width:100
  },
    {
    title: 'АЙДИ',
    dataIndex: 'sale_id',
    key: 'sale_id',
    width:100
  },
  {
    title: 'АРТИКУЛ WB',
    dataIndex: 'nm_id',
    key: 'nm_id',
    width:100
  },
  {
    title: 'БАРКОД',
    dataIndex: 'barcode',
    key: 'barcode',
        width:120,
  },
  {
    title: 'ЦЕНА ЗАКАЗА/ВЫКУПА',
    dataIndex: 'price',
    key: 'price',
    render: (text) => formatCurrency(text),
    sorter:(a,b) => a.price - b.price,
    width:100,
  },
  {
    title: 'СО СКЛАДА',
    dataIndex: 'warehouse_name',
    key: 'warehouse_name',
        width:130,
  },
  {
    title: 'СТРАНА',
    dataIndex: 'country',
    key: 'country',
        width:70,
  },
  {
    title: 'РЕГИОН',
    dataIndex: 'region',
    key: 'region',
        width:150,
  },
];

const columnsReturns = [
  {
    title: 'ДАТА',
    dataIndex: 'order_date',
    key: 'order_date',
    width:70,
  },
  {
    title: 'АРТИКУЛ WB',
    dataIndex: 'nm_id',
    key: 'nm_id',
    width:100,
  },
  {
    title: 'БАРКОД',
    dataIndex: 'barcode',
    key: 'barcode',
        width:120,
  },
  {
    title: 'ЦЕНА ЗАКАЗА/ВЫКУПА',
    dataIndex: 'price',
    key: 'price',
    render: (text) => formatCurrency(text),
    sorter:(a,b) => a.price - b.price,
    width:150,
  },
  {
    title: 'СО СКЛАДА',
    dataIndex: 'warehouse_name',
    key: 'warehouse_name',
        width:70,
  },
  {
    title: 'СТРАНА',
    dataIndex: 'country',
    key: 'country',
        width:70,
  },
  {
    title: 'РЕГИОН',
    dataIndex: 'region',
    key: 'region',
        width:150,
  },
  {
    title: 'Возвращено',
    dataIndex: 'is_return',
    key: 'is_return',
    render: (isCancel) => (
      isCancel ? 
        <CheckCircleOutlined style={{ color: 'green' }} /> : 
        <CloseCircleOutlined style={{ color: 'red' }} />
    ),
    width:50
  },
];


const OrdersMain = () => {
    const [activeTab, setActiveTab] = useState(TAB_NAMES[0]);
    const { user } = useAuth();
    const [data, setData] = useState([]);
    const [filters_data, setFiltersData] = useState([]);
    
    const startDate =
        dayjs().date() === 1
            ? dayjs().subtract(1, 'month').startOf('month')
            : dayjs().startOf('month');
    
    const [period, setPeriod] = useState([startDate, dayjs()]);
    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_id: 0,
        wbacc_options: [],
        group: 1,
    });

    const [loading, setLoading] = useState(true);
    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', {wbacc_ids: user.wbacc_ids});
        let wbaccOptions = []
        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id,})
        }
        setSelectorFilter('wbacc_id', user.wbacc_ids[0]);
        setSelectorFilter('wbacc_options', wbaccOptions);
    }
    const filtered_sales_data = data.filter(row => row.sale_id);
    const filtered_returns_data = data.filter(row => row.is_return);
    const filtered_cancels_data = data.filter(row => row.is_cancel);
    const orders = filtered_sales_data.length;
    const dynamicTabNames = [
        `Заказы[${data.length}]`,
        `Выкупы[${orders}]`,
        `Отказы[${data.filter(row => row.is_cancel).length}]`,
        `Возвраты[${data.filter(row => row.is_return).length}]`,
    ];

    const renderTabContent = () => {
        switch (activeTab) {
            case dynamicTabNames[0]:
                return <Table columns={columns} className="Orders-table" dataSource={data} loading={loading} />;
            case dynamicTabNames[1]:
                return <Table columns={columnsSales} className="Orders-table" dataSource={filtered_sales_data} loading={loading} />;
            case dynamicTabNames[2]:
                return <Table columns={columnsCancels} className="Orders-table" dataSource={filtered_cancels_data} loading={loading} />;
            case dynamicTabNames[3]:
                return <Table columns={columnsReturns} className="Orders-table" dataSource={filtered_returns_data} loading={loading} />;
            default:
                return null;
        }
    };

    const fetchData = async () => {
        setLoading(true);
        const fetchedData = await GetSales(selectorFilter.wbacc_id, period);
        setData(fetchedData?.data.orders); 
        console.log(fetchedData?.data, 'data')
        setFiltersData(fetchedData?.data['filters']);
        setLoading(false);
    };

useEffect(() => {
        if (!selectorFilter.wbacc_ids && user.wbacc_ids) {
            fetchData1();
        }
    }, [user.wbacc_ids]);
    useEffect(() => {

        if (selectorFilter.wbacc_id) {
            fetchData();
        }
    }, [selectorFilter.wbacc_id, selectorFilter.group, selectorFilter.like, selectorFilter.hide, period]);


    return (
        <Card>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                    <h2 className="font-bold text-2xl">Лента заказов в реальном времени</h2>
                    <span className="text-gray-500 text-xl">Дата последнего сбора отчета: {dayjs().format('DD MMMM YYYY')}г. Обновление данных происходит раз в час.</span>
                </div>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Период данных
                        </Typography.Title>
                        <DatePickerRange
                            selected={period}
                            setSelected={setPeriod}
                        />
                    </Col>
                        <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Магазины
                        </Typography.Title>
                        <Select
                            allowClear
                            style={{width: '100%'}}
                            placeholder="Выберите магазин"
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <ButtonExel data={data} />
                </Row>
                <Tabs activeKey={activeTab} onChange={setActiveTab}>
                    {dynamicTabNames.map((name) => (
                        <Tabs.TabPane tab={name} key={name}>
                            {renderTabContent()}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
        </Card>
    );
};

export default OrdersMain;
