import React from 'react'
import {formatCurrency, formatDays, formatPercent, formatQuantity} from '../../../../core/utils/formats'
import {Card, Col, Row, Table, Typography} from "antd";
import '../../../../App.css'
export const GeneralStorage = ({inf}) => {
    const profit = [
        {title: 'Остатки', procent: inf.ost, format: 'sht'},
        {title: 'В пути до клиента', procent: inf.way_to, format: 'sht'},
        {title: 'В пути от клиента', procent: inf.way_from, format: 'sht'},
        {title: 'Остатки итого', procent: inf.full, format: 'sht'},
        {title: 'Продажи 14 дней средние', procent: inf.orders_per_day},
        {title: 'Оборачиваемость', procent: inf.oborot_orders, format: 'day'},
        {title: 'Себестоимость', procent: inf.sebes_buyouts, format: 'rub'},
        {title: 'Розница', procent: inf?.test, format: 'day'},
        {title: 'Запас дней', procent: inf.oborot_buyouts, format: 'day'}
    ]

    return (
        <div className='bg-white flex flex-col h-fit max-w-[900px] flex-1 w-full gap-4 rounded-2xl p-5'>
            <h2 className='text-3xl font-bold text-black'>Остатки FBO</h2>
            <div className='flex flex-col gap-2'>
                {profit.map((item, index) => (
                    <div key={index} className={`${index !== 0 && 'border-t-[1px] border-gray-300'} flex pt-2 justify-between items-center`}>
                        <p className='text-xs md:text-xl font-medium'>{item.title}</p>
                        <div className='flex items-center gap-2'>
                            <span className='text-sm md:text-2xl font-bold'>
                                {item.format === 'sht' ? formatQuantity(item.procent) : item.format === 'day' ? formatDays(item.procent) : item.format === 'rub' ? formatCurrency(item.procent) : item.procent}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const GeneralWarehouse = ({inf}) => {
    const dataSource = [
  { key: '1', category: 'FBO', cost: '737 373,00 ₽', margin: '1 837 373,00 ₽', stock: '315 шт' },
  { key: '2', category: 'FBS', cost: '15 737 373,00 ₽', margin: '513 899,55 ₽', stock: '124 шт' },
  { key: '3', category: 'Едет к клиенту', cost: '0', margin: '0', stock: '0' },
];

const columns = [
  { title: 'Где товар', dataIndex: 'category', key: 'category' },
  { title: 'Себестоимость', dataIndex: 'cost', key: 'cost', align: 'right' },
    { title: 'Розница', dataIndex: 'margin', key: 'margin', align: 'right' },
  { title: 'Остатки', dataIndex: 'stock', key: 'stock', align: 'right' },
];

    return (
        <div className='bg-white flex flex-col mb-10 h-fit  w-full gap-4 rounded-2xl p-5'>
            <h2 className='text-3xl font-bold text-black'>Склад</h2>
            <Table
    dataSource={dataSource}
    columns={columns}
    pagination={false}
    bordered={false}
    className="House-table"
  />
        </div>
    )
}

export const GeneralStorage2 = ({inf}) => {
    const profit = [
        {title: 'Остатки', procent: inf.ost, format: 'sht'},
        {title: 'Остатки итого', procent: inf.full, format: 'sht'},
        {title: 'В пути до клиента', procent: inf.way_to, format: 'sht'},
        {title: 'В пути от клиента', procent: inf.way_from, format: 'sht'},
        {title: 'Продажи 14 дней средние', procent: inf.orders_per_day},
        {title: 'Оборачиваемость', procent: inf.oborot_orders, format: 'day'},
        {title: 'Запас дней', procent: inf.oborot_buyouts, format: 'day'}
    ]

    return (
        <div className='p-[7px]'>
            <Typography.Title level={1}>Остатки FBO</Typography.Title>
            <div className='flex gap-2 flex-wrap xx:justify-between'>
                {profit.map((item, index) => (

                        <Card key={index} className={`CardSmall CardLong extra:max-w-[265px] w-full`}>
                            <p className=' text-xs md:text-base font-medium'>{item.title}</p>
                            <div className='flex items-center gap-2'>
                            <span className='text-sm md:text-2xl font-bold'>
                                {item.format === 'sht' ? formatQuantity(item.procent) : item.format === 'day' ? formatDays(item.procent) : item.procent}
                            </span>
                            </div>
                        </Card>

                ))}
            </div>
        </div>
    )
}
