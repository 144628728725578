import { Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

const { Text } = Typography

const SideBarGeneral = () => {
    const sections = [
        {
            label: 'Фильтры',
            description: ['В верхней части страницы пользователю доступны фильтры для выбора периода данных и поставщиков.', 'Также есть кнопка "Экспорт в Excel" для выгрузки данных в таблицу Excel.']
        },
        {
            label: 'Таблица аналитики',
            description: [
                'Таблица содержит детализированную информацию по дням, включая следующие колонки:',
                'Поставщик – имя поставщика.',
                'Дата – дата, за которую представлены данные.',
                'Остатки – информация о текущих остатках товаров.',
                'Финансы – данные о сумме заказов, выкупов, возвратов и других финансовых показателях.',
                'Показатели – количество заказов, выкупов, отмен и возвратов.',
                'Реклама – информация о бюджете, показах и кликах по рекламным кампаниям.'
            ]
        },
        {
            label: 'Структура данных',
            description: [
                'Таблица организована с использованием вложенных строк для отображения детализированных данных по каждому поставщику и дате.',
                'Каждая строка может быть раскрыта для просмотра дополнительной информации.'
            ]
        },
        {
            label: 'Интерактивные элементы',
            description: ['Пользователь может взаимодействовать с таблицей, например, скрывать или показывать определенные колонки для удобства анализа.']
        }
    ]

    return (
        <div>
            <Title level={2}>Описание страницы "Аналитика по дням"</Title>
            <Text className='text-base text-gray-600 font-semibold'>
                На странице <span className='text-black'>"Аналитика по дням"</span> представлена таблица с детализированной информацией о показателях за каждый день.
            </Text>

            <Title level={3}>Основные элементы страницы:</Title>

            <div className='flex flex-col gap-1 mb-10' style={{ listStyle: 'inside' }}>
                {sections.map((section, index) => (
                    <li key={index} className='text-base text-black'>
                        <span className='font-bold'>{section.label}</span>:
                        <ul>
                            {section.description.map((desc, subIndex) => (
                                <li key={subIndex} className='text-base text-gray-600'>
                                    {desc}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </div>

            <Text className='font-bold text-xs'>
                Эта страница позволяет пользователям глубоко анализировать данные по дням, что упрощает контроль за показателями и помогает принимать более обоснованные решения.
            </Text>
        </div>
    )
}

export default SideBarGeneral
