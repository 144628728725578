// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.abc-analysis-table {
    font-size: 13px;
}

.abc-analysis-table .ant-table-thead > tr > th {
    background-color: #f5f5f5;
    padding: 8px;
    font-weight: 600;
}

.abc-analysis-table .ant-table-tbody > tr > td {
    padding: 8px;
}

.abc-analysis-table .ant-table-tbody > tr:hover > td {
    background-color: #f0f7ff;
}

.abc-analysis-table .ant-typography {
    margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/sales/components/AbcTable.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".abc-analysis-table {\r\n    font-size: 13px;\r\n}\r\n\r\n.abc-analysis-table .ant-table-thead > tr > th {\r\n    background-color: #f5f5f5;\r\n    padding: 8px;\r\n    font-weight: 600;\r\n}\r\n\r\n.abc-analysis-table .ant-table-tbody > tr > td {\r\n    padding: 8px;\r\n}\r\n\r\n.abc-analysis-table .ant-table-tbody > tr:hover > td {\r\n    background-color: #f0f7ff;\r\n}\r\n\r\n.abc-analysis-table .ant-typography {\r\n    margin-bottom: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
