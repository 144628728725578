import { apiAxios } from "../api_axios";

export const GetSales = async(wbacc_ids, period) => {
    try {
        const data = {
            'wbacc_id': wbacc_ids,
            'st': period[0].format('YYYY-MM-DD'),
            'en': period[1].format('YYYY-MM-DD'),
        }
        const res = apiAxios.post("sales/orders-list/get/", data)
        return res
    } catch (err) {
        return [];
    }
};