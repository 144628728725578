
function calculateStartPrices(priceWithWBDisc, wBDisc, spp, discount, isOzon) {
    priceWithWBDisc = Number(priceWithWBDisc)
    wBDisc = Number(wBDisc)
    spp = Number(spp)
    discount = Number(discount)

    let price = (((((priceWithWBDisc / (100 - wBDisc)) * 100) / (100 - spp)) * 100) / (100 - discount)) * 100
    price = Math.round(price)
    for (let i = 50; i > 0; i--) {
        let tryPrice = price + i
        let calcPrice = tryPrice * ((100 - discount) / 100)

        let tryPriceWithDiscount = isOzon ? Math.round(calcPrice) : Math.floor(calcPrice)
        let tryPriceWithSPP = isOzon ? Math.round(calcPrice * ((100 - spp) / 100)) : Math.floor(calcPrice * ((100 - spp) / 100))
        let tryPriceWithWBDiscount = isOzon ? Math.round(tryPriceWithSPP * ((100 - wBDisc) / 100)) : Math.floor(tryPriceWithSPP * ((100 - wBDisc) / 100)) // 100%
        if (tryPriceWithWBDiscount === priceWithWBDisc) {
            return [tryPrice, tryPriceWithDiscount, tryPriceWithSPP]
        }
    }
    return [0, 0, 0]
}

function calculatePromoPrices(price_with_discount, wb_discount, spp, discount, isOzon) {
    let price = Math.round((price_with_discount / (100 - discount)) * 100)
    for (let i = 50; i > 0; i--) {
        let try_price = price + i
        let calc_price = (try_price * (100 - discount)) / 100
        let try_price_with_discount = Math.floor(calc_price)
        let try_price_with_spp = Math.floor((calc_price * (100 - spp)) / 100)
        let try_price_with_wb_discount = Math.floor((try_price_with_spp * (100 - wb_discount)) / 100)
        if (try_price_with_discount == price_with_discount) {
            return [try_price, try_price_with_spp, try_price_with_wb_discount]
        }
    }

    return [0, 0, 0]
}

export const calculateRow = (row) => {
    let load_price = 0
    let load_discount = 0
    let load_price_client = 0
    if (!isNaN(Number(row.load_price)) || !isNaN(Number(row.load_discount))) {
        if (Number(row.load_price) == 0) {
            load_price = row.price
        } else {
            load_price = Number(row.load_price)
        }
        if (Number(row.load_discount) == 0) {
            load_discount = row.discount
        } else {
            load_discount = Number(row.load_discount)
        }
        load_price_client = Math.floor((load_price * (100 - load_discount)) / 100)
    }
    let [new_price, new_price_with_discount, new_price_client] = calculateStartPrices(row.new_price_with_wb_discount, row.wb_discount, row.spp, row.new_discount, row.is_ozon)
    let [sale_price, sale_price_client, sale_price_with_wb_discount] = calculatePromoPrices(row.sale_price_with_discount, row.wb_discount, row.spp, row.new_discount, row.is_ozon)

    let sebes_itog_with_commission = Math.round((new_price_with_discount * row.sebes_commission_wb) / 100 + row.sebes_itog)
    console.log('new_price', new_price)
    row.new_price = new_price
    row.new_price_with_discount = new_price_with_discount
    row.new_price_client = new_price_client
    row.new_profit = Math.round(new_price_with_discount - row.sebes_itog_with_commission)
    row.new_nacenka = Math.round((((new_price_with_discount * (100 - row.sebes_commission_wb)) / 100 - row.sebes_itog) / sebes_itog_with_commission) * 100, 2)
    row.new_marzha = new_price_with_discount != 0 ? Math.round(((new_price_with_discount - sebes_itog_with_commission) / new_price_with_discount) * 100, 2) : 0
    row.new_price_with_wb_discount = Math.round(row.new_price_with_wb_discount)
    row.load_price = Math.round(row.load_price)
    row.load_discount = Math.round(row.load_discount)
    row.load_price_client = load_price_client
    row.sale_price = sale_price
    row.sale_price_with_discount = Math.round(row.sale_price_with_discount)
    row.sale_price_client = sale_price_client
    row.sale_price_with_wb_discount = sale_price_with_wb_discount
    row.sale_profit = Math.round(row.sale_price_with_discount - row.sebes_itog_with_commission)
    row.sale_nacenka = Math.round((((row.sale_price_with_discount * (100 - row.sebes_commission_wb)) / 100 - row.sebes_itog) / sebes_itog_with_commission) * 100, 2)
    row.sale_marzha = Number(row.sale_price_with_discount) != 0 ? Math.round(((row.sale_price_with_discount - sebes_itog_with_commission) / row.sale_price_with_discount) * 100, 2) : 0
    return row
}