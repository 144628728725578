import React, {useState,  useRef} from 'react'
import {Table, Typography, Space, Button, Dropdown, Tag, Menu, Input} from 'antd'
import {FilterOutlined, CloseOutlined, ReloadOutlined, DownloadOutlined} from '@ant-design/icons'
import {formatCurrency, formatDays, formatPercent, formatQuantity} from '../../../utils/formats';
import './PriceTable.css'
import {useFilterLogic, useFilteredCards} from '../../components/filterUtils'
import * as XLSX from "xlsx";

const {Text} = Typography

const FilterSearch = ({placeholder, onChange}) => (
    <div onClick={e => e.stopPropagation()} style={{padding: '8px'}}>
        <Input.Search
            placeholder={placeholder}
            onChange={onChange}
        />
    </div>
)

const PriceCurrentPrice = ({
                        cards,
                        setCards,
                        filters,
                        loading,
                        isOzon
                    }) => {
    const isMobile = window.innerWidth <= 769
    const updateTimeoutRef = useRef(null);
    const [activeFiltersState, setActiveFilters] = useState([])
    const [filterSearchTexts, setFilterSearchTexts] = useState({
        model: '',
        category: '',
        status: '',
        sezon: '',
        brand: '',
        color: ''
    })

    const handleAddFilter = (type, value) => {
        setActiveFilters(prev => {
            const filterExists = prev.some(
                filter => filter.type === type && filter.value.value === value.value
            )
            if (filterExists) return prev
            return [...prev, {type, value}]
        })
    }

    const handleRemoveFilter = (index) => {
        setActiveFilters(prev => prev.filter((_, i) => i !== index))
    }

    const menuItems = useFilterLogic(
        filters,
        filterSearchTexts,
        setFilterSearchTexts,
        handleAddFilter
    )

    const filteredCards = useFilteredCards(cards, activeFiltersState)

    const filterMenu = <Menu items={menuItems}/>
    const downloadExcel = (data) => {
        // Подготавливаем данные для Excel

        const excelData = data.map(card => ({
            'Артикул': card.article,
            'Артикул WB': card.nm_id,
            'Название': card.name,
            'Карточка': card.imt_id,
            'Бренд': card.brand,
            'Категория': card.category,
            'Модель': card.model,
            'Материал': card.material,
            'Статус': card.status,
            'Сезон': card.material,
            'Цвет': card.color,

            'Остатки Всего': card.full,
            'Остатки FBO': card.ost,
            'В пути от': card.way_from || 0,
            'В пути до': card.way_to || 0,

            'Цена': card.price,
            'Скидка': card.discount,
            'Цена со скидкой': card.price_with_discount,
            'Спп': card.spp,
            'Цена с спп': card.price_client,
            'Кошелек': card.wb_discount,
            'Цена с кошельком': card.price_with_wb_discount,

            'Себестоимость': card.sebes,
            'Себес с расходами': card.sebes_itog,
            'Прибыль': card.profit,
            'Наценка': card.nacenka,
            'Маржа': card.marzha,

            'Выкуп': card.buyout_percent,
            'Продажи в день': card.speed14,
            'Оборачиваемость': card.oborot,
            'ABC': card.abc,
        }));

        // Создаем книгу Excel
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Настраиваем ширину столбцов
        const maxWidth = 20;
        const colWidths = {};
        Object.keys(excelData[0] || {}).forEach(key => {
            colWidths[key] = Math.min(
                maxWidth,
                Math.max(
                    key.length,
                    ...excelData.map(row => String(row[key] || '').length)
                )
            );
        });
        ws['!cols'] = Object.values(colWidths).map(width => ({wch: width}));

        // Добавляем лист в книгу
        XLSX.utils.book_append_sheet(wb, ws, "Статистика");

        // Скачиваем файл
        XLSX.writeFile(wb, `Статистика_${new Date().toLocaleDateString()}.xlsx`);
    };

    return (
        <div className="price-table-container">
            <div className="table-header">
                <Space size={8} wrap>
                    <Dropdown
                        overlay={filterMenu}
                        trigger={['click']}
                        overlayClassName="filter-dropdown"
                    >
                        <Button icon={<FilterOutlined/>}>
                            Добавить фильтр
                        </Button>
                    </Dropdown>
                    <Button
                        icon={<ReloadOutlined/>}
                    >
                        Обновить
                    </Button>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined/>}
                        style={{backgroundColor: '#4CAF50', borderColor: '#4CAF50'}}
                        onClick={() => downloadExcel(filteredCards)}
                    >
                        Экспорт в Excel
                    </Button>
                    {activeFiltersState.map((filter, index) => (
                        <Tag
                            key={index}
                            closable
                            onClose={() => handleRemoveFilter(index)}
                            closeIcon={<CloseOutlined/>}
                        >
                            {`${filter.type}: ${filter.value.label}`}
                        </Tag>
                    ))}
                </Space>
            </div>
            <Table
                dataSource={filteredCards}
                columns={[
                    // Товар
                    {
                        title: 'Товар',
                        key: 'product',
                        width: 300,
                        render: (_, record) => (
                            <Space direction="vertical" size={0}>
                                <Space align="start" size={12}>
                                    <img src={record.img} style={{height: '60px', width: '60px', objectFit: 'contain'}} alt="Product"/>
                                    <Space direction="vertical" size={0} className="cost-info-cell">
                                        <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                                        <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                                        <Text><strong>Название:</strong> {record.name}</Text>
                                        <Text><strong>Карточка:</strong> {record.imt_id}</Text>

                                    </Space>
                                </Space>
                            </Space>
                        )
                    },
                    // Информация о товаре
                    {
                        title: 'Информация о товаре',
                        fixed: isMobile ? '' : 'left',
                        width: 250,
                        render: (_, record) => (
                            <Space size={24} className="cost-info-cell" style={{width: '100%',}}>
                                <Space direction="vertical" size={0}>
                                    <Text><strong>Модель:</strong> {record.material}</Text>
                                    <Text><strong>Бренд:</strong> {record.brand}</Text>
                                    <Text><strong>Категория:</strong> {record.category}</Text>
                                </Space>
                                <Space direction="vertical" size={0}>
                                    <Text><strong>Статус:</strong> {record.status}</Text>
                                    <Text><strong>Сезон:</strong> {record.sezon}</Text>
                                    <Text><strong>Цвет:</strong> {record.color}</Text>
                                </Space>
                            </Space>
                        )
                    },
                    // Текущая цена
                    {
                        title: 'Текущая цена',
                        key: 'current_price',
                        width: 200,
                        render: (_, record) => (
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Цена:</strong> {formatCurrency(record.price)}</Text>
                                <Text style={{whiteSpace: 'nowrap'}}><strong>Со скидкой:</strong> {formatCurrency(record.price_with_discount)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>(скидка {formatPercent(record.discount)})</span></Text>
                                <Text style={{whiteSpace: 'nowrap'}}><strong>С СПП:</strong> {formatCurrency(record.price_client)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>(скидка {formatPercent(record.spp)})</span></Text>
                                <Text style={{whiteSpace: 'nowrap'}}><strong>С ВБ кошельком:</strong> {formatCurrency(record.price_with_wb_discount)} <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>(скидка {formatPercent(record.wb_discount)})</span></Text>
                            </Space>
                        )
                    },
                    // Себестоимость и прибыль
                    {
                        title: 'Себестоимость и прибыль',
                        width: 180,
                        render: (_, record) => (
                            <Space direction="vertical" size={0} className="cost-info-cell">
                                <Text><strong>Себестоимость:</strong> {formatCurrency(record.sebes)}</Text>
                                <Text><strong>Себес с расходами:</strong> {formatCurrency(record.sebes_itog)}</Text>
                                <Text type={record.profit > 0 ? 'success' : 'danger'}><strong>Прибыль:</strong> {formatCurrency(record.profit)}</Text>
                                <Text type={record.nacenka > 0 ? 'success' : 'danger'}><strong>Наценка:</strong> {formatPercent(record.nacenka)}</Text>
                                <Text type={record.marzha > 0 ? 'success' : 'danger'}><strong>Маржа:</strong> {formatPercent(record.marzha)}</Text>
                            </Space>
                        )
                    },
                    // Складские остатки
                    {
                        title: 'Остатки',
                        width: 150,
                        render: (_, record) => (
                            <Space direction="vertical" size={0} className="stock-info-cell">
                                <Text><strong>Всего:</strong> {formatQuantity(record.ost + (record.way_to || 0))}</Text>
                                <Text><strong>FBO:</strong> {formatQuantity(record.ost)}</Text>
                                <Text><strong>В пути от:</strong> {formatQuantity(record.way_from || 0)}</Text>
                                <Text><strong>В пути до:</strong> {formatQuantity(record.way_to || 0)}</Text>
                            </Space>
                        )
                    },
                    // Аналитика
                    {
                        title: 'Аналитика',
                        width: 120,
                        render: (_, record) => (
                            <Space direction="vertical" size={0} className="analytics-info-cell">
                                <Text><strong>Выкуп:</strong> {formatPercent(record.buyout_percent)}</Text>
                                <Text><strong>Продажи в день:</strong> {formatQuantity(record.speed14)}</Text>
                                <Text><strong>Оборачиваемость:</strong> {formatDays(record.oborot)}</Text>
                                <Text><strong>ABC:</strong> {record.abc}</Text>
                            </Space>
                        )
                    },
                ]}
                loading={loading}
                pagination={{
                    defaultPageSize: 30,
                    showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`
                }}
                size={isMobile ? 'small' : 'middle'}
            />
        </div>
    )
}


export default PriceCurrentPrice
