import { apiAxios } from '../api_axios';

export const getPositions = async(wbacc_id, is_like, is_hide, period) => {
    return await apiAxios.get('positions/get/', {
        wbacc_ids: [wbacc_id],
        is_like: is_like,
        is_hide: is_hide,
        st: period[0].format('YYYY-MM-DD'),
        en: period[1].format('YYYY-MM-DD'),
    })
};