import React, { useState} from 'react'
import {Button, Checkbox, Table, Typography} from 'antd'
import {PlusOutlined, MinusOutlined, HeartOutlined, EyeOutlined} from '@ant-design/icons'
import {formatCurrency, formatDays, formatPercent, formatQuantity} from '../../../utils/formats';
import {createFilterFunction, createFilterText, getCellStyle} from '../../../components/Gradient'

const {Text} = Typography

const getColumns = (filters_data, likeHideList, onChangeLikeHide, isHideColumns, isOzon, gradientData) => {
    console.log('gradientData', gradientData)

    // const getCellStyle = (value) => {
    //     if (value < 10) {
    //         return {background: 'linear-gradient(to right, #ffcccc, #ff9999)'}; // Красный градиент
    //     } else if (value >= 10 && value < 100) {
    //         return {background: 'linear-gradient(to right, #ffffcc, #ffff99)'}; // Желтый градиент
    //     } else {
    //         return {background: 'linear-gradient(to right, #ccffcc, #99ff99)'}; // Зеленый градиент
    //     }
    // };

    const isMobile = window.innerWidth <= 769
    return [
        {title: <HeartOutlined/>, width: 21, render: (text, record) => <Checkbox checked={likeHideList['like'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'like')}/>, hidden: isHideColumns},
        {title: <EyeOutlined/>, width: 21, render: (text, record) => <Checkbox checked={likeHideList['hide'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'hide')}/>, hidden: isHideColumns},
        {title: 'Поставщик', dataIndex: 'wbacc_name', width: 100, hidden: isHideColumns},
        {title: '', dataIndex: 'img', width: 20, fixed: isMobile ? '' : 'left', render: (text) => <img src={text} style={{height: '20px'}}></img>},
        {title: 'Артикул', dataIndex: 'article', width: 100, fixed: isMobile ? '' : 'left', render: (text) => <a href={`product/${text}`}>{text}</a>, hidden: isHideColumns,
            filters: filters_data?.article, filterSearch: true, onFilter: (value, record) => record.article == value || record.is_fix},
        {title: 'Артикул МП', dataIndex: 'nm_id', width: 100, fixed: isMobile ? '' : 'left', hidden: isOzon},
        {title: 'Карточка', dataIndex: 'imt_id', width: 100, filters: filters_data['imt_ids'], filterSearch: true, onFilter: (value, record) => record.imt_id == value || record.is_fix, hidden: isOzon},
        {title: 'Категория', dataIndex: 'category', key: 'category', width: 100, ellipsis: true, fixed: isMobile ? '' : 'left', filters: filters_data['category'], filterSearch: true, onFilter: (value, record) => record.category == value || record.is_fix},
        {title: 'Модель', dataIndex: 'material', width: 100, ellipsis: true, filters: filters_data['material'], fixed: isMobile ? '' : 'left', filterSearch: true, onFilter: (value, record) => record.material == value || record.is_fix},

        {
            title: 'Остатки FBO', dataIndex: 'ost', width: 70, fixed: isMobile ? '' : 'left', render: (text) => <div style={getCellStyle(gradientData, 'ost', text)}>{formatQuantity(text)}</div>,
            filters: createFilterText(gradientData, 'ost'), onFilter: createFilterFunction(gradientData, 'ost')
        },
        {
            title: 'В пути от', dataIndex: 'way_to', width: 70, hidden: isHideColumns || isOzon, render: (text) => <div style={getCellStyle(gradientData, 'way_to', text)}>{formatQuantity(text)}</div>,
            filters: createFilterText(gradientData, 'way_to'), onFilter: createFilterFunction(gradientData, 'way_to')
        },
        {
            title: 'В пути до', dataIndex: 'way_from', width: 70, hidden: isHideColumns || isOzon, render: (text) => <div style={getCellStyle(gradientData, 'way_from', text)}>{formatQuantity(text)}</div>,
            filters: createFilterText(gradientData, 'way_from'), onFilter: createFilterFunction(gradientData, 'way_from')
        },
        {
            title: 'Итого', dataIndex: 'full', width: 70, hidden: isHideColumns || isOzon, render: (text) => <div style={getCellStyle(gradientData, 'full', text)}>{formatQuantity(text)}</div>,
            filters: createFilterText(gradientData, 'full'), onFilter: createFilterFunction(gradientData, 'full')
        },
        {title: 'Ост. МС', dataIndex: 'ms_ost', width: 70, hidden: isHideColumns, render: (text) => <div style={getCellStyle(gradientData, 'ms_ost', text)}>{formatQuantity(text)}</div>},
        {title: 'Транзит', dataIndex: 'ms_transfer', width: 70, hidden: isHideColumns, render: (text) => formatQuantity(text)},
        {title: 'Шьется', dataIndex: 'ms_production', width: 70, hidden: isHideColumns, render: (text) => formatQuantity(text)},

        {title: 'Цвет', dataIndex: 'color', width: 80, ellipsis: true,  filters: filters_data['color'], filterSearch: true, onFilter: (value, record) => record.color == value, hidden: isHideColumns},
        {title: 'Рейтинг', dataIndex: 'rating', width: 55, hidden: isHideColumns},
        {title: 'Деньги в товаре', dataIndex: 'ost_sum', width: 100,  sorter: (a, b) => a.ost_sum - b.ost_sum, hidden: isHideColumns, render: (text) => formatCurrency(text)},
        {title: 'Цена', dataIndex: 'price_with_discount', width: 70, hidden: isHideColumns, render: (text) => formatCurrency(text)},
        {title: 'ABC', dataIndex: 'abc', width: 50, filters: filters_data['abc'], filterSearch: true, onFilter: (value, record) => record.abc?.indexOf(value) === 0, hidden: isHideColumns},
        {title: 'Сезон', dataIndex: 'sezon', width: 70, filters: filters_data['sezon'], filterSearch: true, onFilter: (value, record) => record.sezon?.indexOf(value) === 0, hidden: isHideColumns},
        {title: 'Статус', dataIndex: 'status', width: 60, filters: filters_data?.status, filterSearch: true, onFilter: (value, record) => record.status == value, hidden: isHideColumns},
        {title: 'Заказы факт руб', dataIndex: 'orders_sum', width: 100, render: (text) => <div style={getCellStyle(gradientData, 'orders_sum', text)}>{formatCurrency(text)}</div>},
        {
            title: 'План',
            hidden: isHideColumns,
            children: [
                {title: 'Шт', dataIndex: 'plan_orders', key: 'plan_orders', width: 50, hidden: isHideColumns, render: (text) => formatQuantity(text)},
                {title: 'Руб', dataIndex: 'plan_orders_sum', key: 'plan_orders_sum', width: 50, hidden: isHideColumns, render: (text) => formatCurrency(text)},
                {title: 'Шт в день', dataIndex: 'plan_orders_day', key: 'plan_orders_day', width: 50, hidden: isHideColumns, render: (text) => formatQuantity(text)},
                {title: 'Руб в день', dataIndex: 'plan_orders_sum_day', key: 'plan_orders_sum_day', width: 50, hidden: isHideColumns, render: (text) => formatCurrency(text)}
            ]
        },
        {
            title: 'Цена',
            hidden: isHideColumns,
            children: [
                {title: 'Цена WB', dataIndex: 'price_client', width: 70, hidden: isHideColumns, render: (text) => formatCurrency(text)},
                {
                    title: 'Маржа',
                    dataIndex: 'marzha',
                    width: 70,
                    ellipsis: true,
                    hidden: isHideColumns,
                    render: (text) => <div style={getCellStyle(gradientData, 'marzha', text)}>{formatPercent(text)}</div>,
                    filters: createFilterText(gradientData, 'marzha'),
                    onFilter: createFilterFunction(gradientData, 'marzha')
                },
                {
                    title: 'Обор.',
                    dataIndex: 'oborot_orders',
                    width: 70,
                    ellipsis: true,
                    hidden: isHideColumns,
                    render: (text) => <div style={getCellStyle(gradientData, 'oborot_orders', text, true)}>{formatDays(text)}</div>,
                    filters: createFilterText(gradientData, 'oborot_orders'),
                    onFilter: createFilterFunction(gradientData, 'oborot_orders')
                }
            ]
        },
        {
            title: 'Расчет',
            hidden: isHideColumns,
            children: [
                {
                    title: 'накоп',
                    dataIndex: 'orders',
                    width: 50,
                    ellipsis: true,
                    hidden: isHideColumns,
                    render: (text) => <div style={getCellStyle(gradientData, 'orders', text)}>{formatQuantity(text)}</div>
                },
                {
                    title: 'сред в день',
                    dataIndex: 'orders_per_day',
                    width: 80,
                    ellipsis: true,
                    hidden: isHideColumns,
                    render: (text) => <div style={getCellStyle(gradientData, 'orders_per_day', text)}>{formatQuantity(text)}</div>,
                    filters: createFilterText(gradientData, 'orders_per_day'),
                    onFilter: createFilterFunction(gradientData, 'orders_per_day')
                }
            ]
        }
    ]
}

function getDayColumns(dayStat, showedDays, setShowedDays, gradientData) {
    let resultColumns = []
    let statNames = {
        Рублей: (text) => formatCurrency(text),
        Выполнение: (text) => formatPercent(text),
        МРК: (text) => formatCurrency(text),
        ДРР: (text) => formatPercent(text),
        СТР: (text) => formatPercent(text),
        'Обор.': (text) => formatDays(text),
        Выкуп: (text) => formatPercent(text),
        Цена: (text) => formatCurrency(text)
    }
    let newColumn = {}
    let i = 1
    let col_index = 0
    let child = 0

    dayStat.forEach((day, indexDay, array) => {
        newColumn = {
            title: <ExpandButton title={day['date_show']} showedDays={showedDays} setShowedDays={setShowedDays}/>,
            children: [],
            className: 'border_left'
        }
        i = 1
        col_index = `col${indexDay}_${i++}`
        child = {
            title: 'Штук',
            dataIndex: col_index,
            width: 60,
            ellipsis: true,
            className: 'border_left',
            render: (text) => <div style={getCellStyle(gradientData, 'orders_per_day', text)}>{formatQuantity(text)}</div>
        }
        newColumn.children.push(child)

        newColumn.children.push({title: 'Рублей', dataIndex: `col${indexDay}_${i++}`, width: 80, hidden: !showedDays.includes(day['date_show']), render: (text) => formatCurrency(text)});
        newColumn.children.push({title: 'Выполнение', dataIndex: `col${indexDay}_${i++}`, width: 50, hidden: !showedDays.includes(day['date_show']), render: (text) => formatPercent(text)});
        newColumn.children.push({title: 'МРК', dataIndex: `col${indexDay}_${i++}`, width: 80, hidden: !showedDays.includes(day['date_show']), render: (text) => formatCurrency(text)});
        newColumn.children.push({title: 'ДРР', dataIndex: `col${indexDay}_${i++}`, width: 50, hidden: !showedDays.includes(day['date_show']), render: (text) => formatPercent(text)});
        newColumn.children.push({title: 'СТР', dataIndex: `col${indexDay}_${i++}`, width: 50, hidden: !showedDays.includes(day['date_show']), render: (text) => formatPercent(text)});
        newColumn.children.push({title: 'Обор.', dataIndex: `col${indexDay}_${i++}`, width: 65, hidden: !showedDays.includes(day['date_show']), render: (text) => formatDays(text)});
        newColumn.children.push({title: 'Выкуп', dataIndex: `col${indexDay}_${i++}`, width: 50, hidden: !showedDays.includes(day['date_show']), render: (text) => formatPercent(text)});
        newColumn.children.push({title: 'Цена', dataIndex: `col${indexDay}_${i++}`, width: 50, hidden: !showedDays.includes(day['date_show']), render: (text) => formatCurrency(text)});
        // for (let name in statNames) {
        //     col_index = `col${indexDay}_${i++}`
        //     child = {
        //         title: name,
        //         dataIndex: col_index,
        //         width: 80,
        //         hidden: !showedDays.includes(day['date_show']),
        //         render: statNames[name]
        //     }
        //     newColumn.children.push(child)
        // }
        resultColumns.push(newColumn)
    })
    resultColumns.at(-1).fixed = 'right'
    resultColumns.at(-1).children.forEach((item) => (item.fixed = 'right'))
    return resultColumns
}

const expandDaysStat = (cards) => {
    return cards.map((card) => {
        card['days_stat'].forEach((day, index) => {
            let col_index = 'col' + index
            card[col_index + '_1'] = day['orders']
            card[col_index + '_2'] = day['orders_sum']
            card[col_index + '_3'] = day['otkl']
            card[col_index + '_4'] = day['budget']
            card[col_index + '_5'] = day['drr']
            card[col_index + '_6'] = day['ctr']
            card[col_index + '_7'] = day['oborot_orders']
            card[col_index + '_8'] = day['buyout_percent']
            card[col_index + '_9'] = day['price_with_discount']
            //delete card['days_stat']
        })
        return card
    })
}

const ExpandButton = ({title, showedDays, setShowedDays}) => {
    return (
        <div style={{display: 'flex', 'align-items': 'center'}}>
            <Button size={'small'} onClick={() => toggleDay(title, showedDays, setShowedDays)}>
                {showedDays.includes(title) ? <MinusOutlined/> : <PlusOutlined/>}
            </Button>
            {title}
        </div>
    )
}

const toggleDay = (day, showedDays, setShowedDays) => {
    const index = showedDays.indexOf(day)
    if (index === -1) {
        // Если элемента нет в массиве, добавляем его.
        setShowedDays([...showedDays, day])
    } else {
        // Если элемент уже есть, создаем новый массив без этого элемента.
        setShowedDays(showedDays.filter((_day) => _day !== day))
    }
}

export default function TablePlanFact(args) {
    let fixedRow = {}
    const [isHideColumns, setHideColumns] = useState(false)
    if (args.sum_stat.fact) {
        fixedRow = {
            is_fix: true,
            ost: args.sum_stat.fact.ost.quantity,
            way_to: args.sum_stat.fact.ost.way_to,
            way_from: args.sum_stat.fact.ost.way_from,
            full: args.sum_stat.fact.ost.full,
            ost_sum: args.sum_stat.fact.ost.sum,
            orders_sum: args.sum_stat.fact.orders_sum,
            plan_orders: args.sum_stat.plan.orders,
            plan_orders_sum: args.sum_stat.plan.orders_sum,
            plan_orders_day: args.sum_stat.plan.orders_day,
            plan_orders_sum_day: args.sum_stat.plan.orders_sum_day
        }
        const card = [{days_stat: args.sum_stat.days}]
        const expandedDataDaysStat = expandDaysStat(card)
        fixedRow = {...fixedRow, ...expandedDataDaysStat[0]}
    }

    const expandedData = expandDaysStat(args.cards)
    const modifiedDataSource = [fixedRow, ...expandedData]
    let resultColumns = getColumns(args.filters, args.likeHideList, args.onChangeLikeHide, isHideColumns, args.isOzon, args.gradientData)

    if (args.cards.length > 0) {
        let newColumns = getDayColumns(args.cards[0]['days_stat'], args.showedDays, args.setShowedDays, args.gradientData)
        resultColumns = [...resultColumns, ...newColumns]
    }

    return (
        <>
            {/* <Switch checkedChildren="Скрыть"
                unCheckedChildren="Скрыть"
                checked={isHideColumns}
                onChange={() => setHideColumns(!isHideColumns)}/> */}
            <Table
                columns={resultColumns}
                dataSource={modifiedDataSource}
                bordered
                size={'small'}
                className='adverts-table2'
                scroll={{
                    y: 800
                }}
                rowClassName={(record, index) => 'my-custom-row'}
                loading={args.loading}
                pagination={{defaultPageSize: 50, showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} артикулов`}}
                summary={(pageData) => {
                    let Ost = 0
                    let WayFrom = 0
                    let WayTo = 0
                    let MsOst = 0
                    let MsTransfer = 0
                    let MsProduction = 0
                    let OstSum = 0
                    let OrdersSum = 0
                    let Orders = 0
                    let OrdersPerDay = 0
                    console.log('pageData', pageData)
                    pageData.forEach((item) => {
                        if (!item.is_fix) {
                            Ost += item.ost
                            WayFrom += item.way_from
                            WayTo += item.way_to
                            MsOst += item.ms_ost
                            MsTransfer += item.ms_transfer
                            MsProduction += item.ms_production
                            OstSum += item.ost_sum
                            OrdersSum += item.orders_sum
                            Orders += item.orders
                            OrdersPerDay += item.orders_per_day
                        }
                    })
                    OrdersPerDay = Math.round(OrdersPerDay)
                    OstSum = Math.round(OstSum)
                    OrdersSum = Math.round(OrdersSum)
                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>Итого</Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>{Ost}</Table.Summary.Cell>
                                <Table.Summary.Cell>{WayFrom}</Table.Summary.Cell>
                                <Table.Summary.Cell>{WayTo}</Table.Summary.Cell>
                                <Table.Summary.Cell>{MsOst}</Table.Summary.Cell>
                                <Table.Summary.Cell>{MsTransfer}</Table.Summary.Cell>
                                <Table.Summary.Cell>{MsProduction}</Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>{OstSum}</Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>{OrdersSum}</Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>{Orders}</Table.Summary.Cell>
                                <Table.Summary.Cell>{OrdersPerDay}</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    )
                }}
            />
        </>
    )
}
