import {Link, Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../core/hooks/useAuth";
import {Pie} from '@ant-design/plots';

export function LayoutTest() {
    return (
        <div>
            {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
            <nav>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li>
                        <Link to="/nothing-here">Nothing Here</Link>
                    </li>
                </ul>
            </nav>

            <hr/>

            {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
            <Outlet/>
        </div>
    );
}

export function AboutTest() {
    return (
        <div>
            <h2>About</h2>
        </div>
    );
}

export function DashboardTest() {
    return (
        <div>
            <h2>Dashboard</h2>
        </div>
    );
}

export function NoMatchTest() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link href="/">Go to the home page</Link>
            </p>
            {/* <DemoPie/> */}
        </div>
    );
}


const PlotMaps = {};
const DemoPie = () => {
    //const [data, setData] = useState({});

    const data = {
        "pie1": [
            {"area": "Болотчиева", "bill": 4588, "sale": 7924453},
            {"area": "Бегменов", "bill": 4205, "sale": 6715442},
            {"area": "Интрейд", "bill": 2598, "sale": 4074576},
            {"area": "Озон Интрейд", "bill": 2148, "sale": 3614068},
            {"area": "Озон Ворокова", "bill": 1763, "sale": 2879904},
        ],
        "pie2": [
            {"area": "Болотчиева", "value": 28477},
            {"area": "Бегменов", "value": 7700},
            {"area": "Интрейд", "value": 5526},
            {"area": "Озон Интрейд", "value": 9014},
            {"area": "Озон Ворокова", "value": 14907},
        ],
        "pie3": [
            {"area": "Болотчиева", "value": 10},
            {"area": "Бегменов", "value": 20},
            {"area": "Интрейд", "value": 30},
            {"area": "Озон Интрейд", "value": 40},
            {"area": "Озон Ворокова", "value": 50},
        ]
    }

    // useEffect(() => {
    //     asyncFetch();
    // }, []);
    //
    // const asyncFetch = () => {
    //     fetch('https://gw.alipayobjects.com/os/antfincdn/fKTgtjKdaN/association-pie.json')
    //         .then((response) => response.json())
    //         .then((json) => setData(json))
    //         .catch((error) => {
    //             console.log('fetch data failed', error);
    //         });
    // };
    // if (!Object.keys(data).length) {
    //     return null;
    // }
    console.log(data);
    const showTooltip = (evt, pie) => {
        Object.keys(PlotMaps).forEach((plot) => {
            if (plot !== pie) {
                PlotMaps[plot].chart.emit('tooltip:show', {
                    data: {data: {area: evt.data.data.area}},
                });
                PlotMaps[plot].chart.emit('element:highlight', {
                    data: {data: {area: evt.data.data.area}},
                });
            }
        });
    };

    const hideTooltip = (evt, pie) => {
        Object.keys(PlotMaps).forEach((plot) => {
            if (plot !== pie) {
                PlotMaps[plot].chart.emit('tooltip:hide', {
                    data: {data: {area: evt.data.data.area}},
                });
                PlotMaps[plot].chart.emit('element:unhighlight', {
                    data: {data: {area: evt.data.data.area}},
                });
            }
        });
    };

    const LeftConfig = {
        angleField: 'bill',
        colorField: 'area',
        data: data.pie1,
        label: {
            text: 'bill',
        },
        //legend: false,
        innerRadius: 0.6,
        tooltip: {
            title: 'area',
        },
        interaction: {
            elementHighlight: true,
        },
        state: {
            inactive: {opacity: 0.5},
        },
        annotations: [
            {
                type: 'text',
                style: {
                    text: 'AntV\nCharts',
                    x: '50%',
                    y: '50%',
                    textAlign: 'center',
                    fontSize: 40,
                    fontStyle: 'bold',
                },
            },
        ],
        scale: {
            color: {
                palette: 'cool',
            },
        },
    };
    const RightConfig = {
        angleField: 'value',
        colorField: 'area',
        data: data.pie2,
        label: {
            text: 'value',
        },
        legend: false,
        tooltip: {
            title: 'area',
        },
        interaction: {
            elementHighlight: true,
        },
        state: {
            inactive: {opacity: 0.5},
        },
        scale: {
            color: {
                palette: 'accent',
            },
        },
    };
    const MidConfig = {
        angleField: 'value',
        colorField: 'area',
        data: data.pie3,
        label: {
            text: 'value',
        },
        legend: false,
        tooltip: {
            title: 'area',
        },
        interaction: {
            elementHighlight: true,
        },
        state: {
            inactive: {opacity: 0.5},
        },
    };
    return (
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <div style={{width: '300px', height: '300px'}}>
                <Pie

                    {...LeftConfig}
                    onReady={(plot) => {
                        PlotMaps.leftPie = plot;
                        plot.chart.on('interval:pointerover', (evt) => {
                            showTooltip(evt, 'leftPie');
                        });
                        plot.chart.on('interval:pointerout', (evt) => {
                            hideTooltip(evt, 'leftPie');
                        });
                    }}
                />
            </div>
            <Pie
                style={{width: '50%'}}
                {...MidConfig}
                onReady={(plot) => {
                    PlotMaps.midPie = plot;
                    plot.chart.on('interval:pointerover', (evt) => {
                        showTooltip(evt, 'midPie');
                    });
                    plot.chart.on('interval:pointerout', (evt) => {
                        hideTooltip(evt, 'midPie');
                    });
                }}
            />
            <Pie
                style={{width: '50%'}}
                {...RightConfig}
                onReady={(plot) => {
                    PlotMaps.rightPie = plot;
                    plot.chart.on('interval:pointerover', (evt) => {
                        showTooltip(evt, 'rightPie');
                    });
                    plot.chart.on('interval:pointerout', (evt) => {
                        hideTooltip(evt, 'rightPie');
                    });
                }}
            />
        </div>
    );
};
const DemoPie2 = () => {
    const [pies, setPies] = useState([
        {
            id: 1,
            data: [
                {area: 'Appliances', value: 27},
                {area: 'Electronics', value: 25},
                {area: 'Clothing', value: 18},
            ],
        },
        {
            id: 2,
            data: [
                {area: 'Shoes', value: 15},
                {area: 'Food', value: 10},
                {area: 'Cosmetics', value: 5},
            ],
        },
    ]);

    const addPie = () => {
        const newPie = {
            id: pies.length + 1,
            data: [
                {area: 'New Category 1', value: Math.floor(Math.random() * 100)},
                {area: 'New Category 2', value: Math.floor(Math.random() * 100)},
            ],
        };
        setPies([...pies, newPie]);
    };

    const showTooltip = (evt, pieId) => {
        pies.forEach((pie) => {
            if (pie.id !== pieId) {
                // Emit tooltip events for other pies
                // This part is omitted for simplicity
            }
        });
    };

    const hideTooltip = (evt, pieId) => {
        pies.forEach((pie) => {
            if (pie.id !== pieId) {
                // Emit tooltip hide events for other pies
                // This part is omitted for simplicity
            }
        });
    };

    return (
        <div>
            <button onClick={addPie}>Add Pie Chart</button>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                {pies.map((pie) => (
                    <Pie
                        key={pie.id}
                        style={{width: '45%'}}
                        angleField="value"
                        colorField="area"
                        data={pie.data}
                        label={{
                            content: ({area, value}) => `${area}: ${value}`,
                        }}
                        tooltip={{
                            title: 'Area',
                        }}
                        onReady={(plot) => {
                            plot.chart.on('interval:pointerover', (evt) => showTooltip(evt, pie.id));
                            plot.chart.on('interval:pointerout', (evt) => hideTooltip(evt, pie.id));
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

