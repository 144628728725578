import React, {useEffect, useState} from 'react';
import {Button, Card, Typography, Tag, Flex, Modal, Input, InputNumber, message} from 'antd';
import {formatCurrency} from '../../../utils/formats';
import {SelectorSolo} from "../../../components/Selectors";
import DatePickerRange from "../../../components/dateRange";
import { apiAxios } from "../../../utils/api_axios";
import { serverUrl } from '../../../utils';

const {Title} = Typography;

export const AdvertCard = (data) => {
    const advert = data.advert ? data.advert : {}

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addBalance, setAddBalance] = useState(1000)
    const [selectedBalanceMethod, setSelectedBalanceMethod] = useState(1)
    const [balance, setBalance] = useState(0)
    const [cpm, setCpm] = useState(100)
    const [balanceWBAcc, setBalanceWBAcc] = useState({balance: 0, net: 0})
    const [advertStatus, setAdvertStatus] = useState(advert.status)
    const [isHide, setIsHide] = useState(true)

    const balanceOptions = [{value: 1, label: `Баланс: ${formatCurrency(balanceWBAcc.net)}`}, {value: 0, label: `Счет: ${formatCurrency(balanceWBAcc.balance)}`}]
    useEffect(() => {
        setAdvertStatus(advert.status)
        setIsHide([1, 7, 8].includes(advert.status));
        if (advert.id) {
            apiAxios.get('advert/get/balance/', {
                advert_id: advert.advert_id,
                wbacc_id: advert.wbacc_id,
            }).then((response) => {
                console.log('getAdvertBalance', response.data);
                setBalance(response.data.balance);
            });
            apiAxios.get('adverts/get/balance-wbacc/', {
                wbacc_id: advert.wbacc_id,
            }).then((response) => {
                console.log('getAdvertBalanceWBAcc', response.data);
                setBalanceWBAcc(response.data.data);
            });
        }
        if (advert.advert_type == 8) {
            setCpm(advert.params.cpm);
        }
        if (advert.advert_type == 9) {
            console.log(advert.params[0].searchCPM)
            setCpm(advert.params[0].searchCPM);
        }
    }, [advert.status]);

    const handleOk = () => {
        setIsModalOpen(false);
        console.log('addBalance', addBalance);
        const data = {
            'wbacc_id': advert.wbacc_id,
            'advert_id':advert.id,
            'value': addBalance,
            'method': selectedBalanceMethod,
        }
        apiAxios.post("advert/edit/add-balance/", data)
            .then((response) => {
                setBalance(response.data.balance);
                response.data.balance != 0 ?
                    message.open({ type: 'success', content: 'Баланс пополнен!', style: { marginTop: '5vh', }, }) :
                    message.open({ type: 'error', content: 'Не удалось пополнить баланс!', style: { marginTop: '5vh', }, });
            })
        setAddBalance(1000);
    };

    const handleConfirmCpm = (e) => {
        console.log('handleConfirmCpm', e.target.value);
        const param = advert.advert_type == 8 ? advert.params.subject.id : advert.params[0].subject.id
        const data = {
            'wbacc_id':advert.wbacc_id,
            'advert_id': advert.advert_id,
            'advert_type': advert.advert_type,
            'cpm': e.target.value,
            'param': param,
            'instrument': 6
        }
        apiAxios.post(serverUrl + "advert/edit/set-cpm/", data)
            .then((response) => {
                response.data.success != 0 ?
                    message.open({ type: 'success', content: 'Ставка изменена!', style: { marginTop: '5vh', }, }) :
                    message.open({ type: 'error', content: 'Не удалось изменить ставку!', style: { marginTop: '5vh', }, });
            })
    }
    const handleStart = () => {
        if (advertStatus == 11) {
            const data = {
                'wbacc_id': advert.wbacc_id,
                'advert_id': advert.advert_id,
                'active': 'start',
            }
            apiAxios.post("advert/edit/set-active/", data)
                .then((response) => {
                    if (response.data.success) {
                        message.open({ type: 'success', content: 'Статус кампании изменен!', style: { marginTop: '5vh', }, });
                        const statuses = {
                            start: 9,
                            pause: 11,
                            stop: 7,
                        }
                        setAdvertStatus(statuses['active']);
                    } else {
                        message.open({ type: 'error', content: 'Не удалось изменить статус кампании!', style: { marginTop: '5vh', }, });
                    }
    
    
                })
        } else {
            const data = {
                'wbacc_id': advert.wbacc_id,
                'advert_id': advert.advert_id,
                'active': 'pause',
            }
            apiAxios.post("advert/edit/set-active/", data)
                .then((response) => {
                    if (response.data.success) {
                        message.open({ type: 'success', content: 'Статус кампании изменен!', style: { marginTop: '5vh', }, });
                        const statuses = {
                            start: 9,
                            pause: 11,
                            stop: 7,
                        }
                        setAdvertStatus(statuses['active']);
                    } else {
                        message.open({ type: 'error', content: 'Не удалось изменить статус кампании!', style: { marginTop: '5vh', }, });
                    }
    
    
                })
        }
    }
    const handleStop = () => {
        const data = {
            'wbacc_id': advert.wbacc_id,
            'advert_id': advert.advert_id,
            'active': 'stop',
        }
        apiAxios.post("advert/edit/set-active/", data)
            .then((response) => {
                if (response.data.success) {
                    message.open({ type: 'success', content: 'Статус кампании изменен!', style: { marginTop: '5vh', }, });
                    const statuses = {
                        start: 9,
                        pause: 11,
                        stop: 7,
                    }
                    setAdvertStatus(statuses['active']);
                } else {
                    message.open({ type: 'error', content: 'Не удалось изменить статус кампании!', style: { marginTop: '5vh', }, });
                }


            })
    }

    const statusColor = {
        1: 'red',
        4: 'green',
        7: 'black',
        8: 'black',
        9: 'green',
        11: 'blue',
    }
    const statusName = {
        1: 'В процессе удаления',
        4: 'Готова к запуску',
        7: 'Завершена',
        8: 'Отказался',
        9: 'Активна',
        11: 'Пауза',
    }
    return (
        <Card>
            <Flex gap="middle">
                <span>{advert.type_name}</span>
                <span>ID {advert.id}</span>
                <span>Создана {advert.create_time}</span>
                <Tag color={statusColor[advertStatus]}>{statusName[advertStatus]}</Tag>
            </Flex>
            <Title level={4} style={{marginTop: '0'}}>{advert.name} </Title>
            {(!isHide) && (
                <Flex gap="middle">
                    <Button type="default" onClick={handleStart}>{(advertStatus == 11) ? 'Запустить' : 'Приостановить'} </Button>
                    <Button danger type="default" onClick={handleStop}>Завершить</Button>
                </Flex>
            )}
            <Flex style={{marginTop: '10px'}}>
                <div>
                    <Title level={5} style={{marginTop: '0'}}>Категория товаров</Title>
                    <div style={{marginTop: '12px'}}>{advert.subject_name}</div>
                </div>
                <hr style={{marginRight: '15px', marginLeft: '15px'}}/>
                <div>
                    <Title level={5} style={{marginTop: '0'}}>Товаров в кампании</Title>
                    <div style={{marginTop: '12px'}}>{advert.quantity_nm_ids}</div>
                </div>
                {(!isHide) && (<>
                    <hr style={{marginRight: '15px', marginLeft: '15px'}}/>
                    <div>
                        <Title level={5} style={{marginTop: '0'}}>Бюджет</Title>
                        {(!isHide) ? (
                            <div style={{marginTop: '12px'}}><a onClick={() => setIsModalOpen(true)} style={{padding: '0'}}>{formatCurrency(balance)}</a></div>
                        ) : (
                            <div style={{marginTop: '12px'}}>{formatCurrency(0)}</div>
                        )}
                    </div>
                    <hr style={{marginRight: '15px', marginLeft: '15px'}}/>
                    <div>
                        <Title level={5} style={{marginTop: '0'}}>Ставка CPM, ₽</Title>
                        <InputNumber min={100} value={cpm} onChange={(value) => setCpm(value)} onBlur={handleConfirmCpm} onPressEnter={handleConfirmCpm}/>
                    </div>
                </>)}

            </Flex>
            <Typography.Title level={5}>
                Период данных статистики
            </Typography.Title>
            <DatePickerRange
                selected={data.period}
                setSelected={data.setPeriod}
            />
            <Modal title="Пополнение бюджета кампании" open={isModalOpen}
                   centered
                   onCancel={() => setIsModalOpen(false)}
                   footer={[
                       <Button key="submit" type="primary" onClick={handleOk}>
                           Пополнить
                       </Button>,
                       <Button key="back" onClick={() => setIsModalOpen(false)}>
                           Отменить
                       </Button>,
                   ]}>
                <span>Сумма пополнения (₽)</span>
                <Input placeholder="Сумма пополнения" value={addBalance} onChange={(e) => setAddBalance(e.target.value)}/>
                <SelectorSolo options={balanceOptions} selected={selectedBalanceMethod} setSelected={setSelectedBalanceMethod}/>
            </Modal>


        </Card>
    )
        ;
};
