import React, {useState} from "react";
import {Button, Divider, Flex, Input, message, Modal, Select, Table, Tag, Typography} from "antd";
import {SelectorMulti} from "../../components/Selectors";
import { apiAxios } from"../../utils/api_axios";

const {Title, Text, Link, Paragraph} = Typography
export const TabStaff = (user, isModalOpen, setIsModalOpen, tabStaffState, setTabStaffState) => {

    const actions = (email, allowed_wbacc_ids, is_owner) => {
        return (
            <>
                <Flex wrap gap="small">
                    {is_owner ? <Button color="primary" variant="outlined" onClick={() => {
                        console.log('choice', email, allowed_wbacc_ids);
                        setIsModalOpen('staff_edit', true);
                        setTabStaffState('email', email);
                        setTabStaffState('wbaccs', user.wbaccs?.map((item) => {return {label: item.name, value: item.id}}));
                        setTabStaffState('allowed_wbaccs', allowed_wbacc_ids)
                    }}>Права доступа</Button> : ''}
                    {is_owner ? <Button danger onClick={() => {
                        setIsModalOpen('staff_remove', true);
                        setTabStaffState('email', email);
                    }}>Удалить</Button> : ''}
                </Flex>
            </>
        )
    }
    const columns = [
        {title: 'Имя', dataIndex: 'first_name', width: 100,},
        {title: 'Email', dataIndex: 'email', width: 100,},
        {title: 'Телефон', dataIndex: 'phone', width: 100,},
        {title: 'Статус', dataIndex: 'status', width: 100,},
        {
            title: 'Доступные аккаунты', dataIndex: 'allowed_wbaccs', width: 100, render: (wbaccs) => (
                <>
                    {wbaccs?.map((account) => (
                        <Tag key={account.id} color={account.id > 0 ? "purple" : 'blue'}>
                            {account.name}
                        </Tag>
                    ))}
                </>
            ),
        },
        {title: 'Управление', dataIndex: 'settings', width: 100, render: (text, record) => actions(record.email, record.allowed_wbacc_ids, !user.is_staff)},
    ]

    return (
        <>
        <Flex gap={20}>
            {!user.is_staff ? <Button onClick={() => setIsModalOpen('staff', true)} color="primary" variant="outlined">Добавить сотрудника</Button> : null}
            {user.id === 1 ? <Button onClick={() => setIsModalOpen('staff_manager', true)} color="primary" variant="outlined">Добавить менеджера</Button> : null}
        </Flex>
            <Divider/>
            <Table
                className="adverts-table2"
                columns={columns}
                dataSource={user.staff}
                bordered
                pagination={false}
            />
            <ModalAddStaff wbaccs={user.wbaccs} isModalOpen={isModalOpen.staff} setIsModalOpen={setIsModalOpen}/>
            <ModalEditStaff wbaccs={user.wbaccs} tabStaffState={tabStaffState} setTabStaffState={setTabStaffState} isModalOpen={isModalOpen.staff_edit} setIsModalOpen={setIsModalOpen}/>
            <ModalRemoveStaff tabStaffState={tabStaffState} setTabStaffState={setTabStaffState} isModalOpen={isModalOpen.staff_remove} setIsModalOpen={setIsModalOpen}/>
            <ModalAddManager all_wbaccs={user?.all_wbaccs} isModalOpen={isModalOpen.staff_manager} setIsModalOpen={setIsModalOpen} />
        </>
    )
}

const ModalAddStaff = ({wbaccs, isModalOpen, setIsModalOpen}) => {
    const [email, setEmail] = useState('');
    const [allowedWbaccs, setAllowedWbaccs] = useState([]);
    const handleOk = async () => {
        setIsModalOpen('staff', false);
        await apiAxios.post('user/staff/update/', {'email': email, 'allowed_wbacc_ids': allowedWbaccs, 'wbacc_ids': wbaccs.map(item => item.id)})
        setEmail('');
        setAllowedWbaccs([]);
        message.open({
            type: 'success',
            content: 'Сотрудник добавлен!',
            style: {
                marginTop: '5vh',
            },
        });
    };
    return (
        <Modal title="Пригласить сотрудника" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('staff', false)}
               footer={[
                   <Button key="submit" type="primary" onClick={handleOk}>
                       Пригласить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('staff', false)}>
                       Отменить
                   </Button>,
               ]}>
            <Paragraph className="small text-muted">
                Ваш сотрудник получит письмо с уведомлением и ссылкой для подтверждения аккаунта
            </Paragraph>

            <Title level={5} style={{marginTop: 0}}>Email</Title>
            <Input label={"Field B"} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            <Title level={5} style={{marginTop: 0}}>Доступные аккаунты для сотрудника</Title>
            <SelectorMulti options={wbaccs ? wbaccs.map(item => ({label: item.name, value: item.id})) : []} selected={allowedWbaccs} setSelected={setAllowedWbaccs}/>

            <p></p>
            <Paragraph className="small text-muted">
                Предоставляя персональные данные третьего лица для добавления сотрудника, я заверяю о наличии согласия
                такого лица на передачу вам персональных данных, а также последующую обработку вами персональных данных
            </Paragraph>

        </Modal>
    )
}


const ModalEditStaff = ({wbaccs, tabStaffState, setTabStaffState, isModalOpen, setIsModalOpen}) => {
    const handleOk = async () => {
        setIsModalOpen('staff_edit', false);
        await apiAxios.post('user/staff/update/', {'email': tabStaffState.email, 'allowed_wbacc_ids': tabStaffState.allowed_wbaccs, 'wbacc_ids': wbaccs.map(item => item.id)})
        setTabStaffState('allowed_wbaccs', []);
        message.open({
            type: 'success',
            content: 'Настройки изменены!',
            style: {
                marginTop: '5vh',
            },
        });
    };
    return (
        <Modal title="Изменить права доступа сотрудника" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('staff_edit', false)}
               footer={[
                   <Button key="submit" type="primary" onClick={handleOk}>
                       Сохранить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('staff_edit', false)}>
                       Отменить
                   </Button>,
               ]}>
            <Paragraph className="small text-muted">
                Выберите права доступа для сотрудника: {tabStaffState.email}
            </Paragraph>
            <Title level={5} style={{marginTop: 0}}>Доступные аккаунты для сотрудника</Title>

            <Select
                mode="multiple"
                maxTagCount={'responsive'}
                allowClear
                style={{width: '100%'}}
                placeholder="Введите артикул"
                value={tabStaffState.allowed_wbaccs}
                defaultValue={tabStaffState.allowed_wbaccs}
                onChange={(value) => setTabStaffState('allowed_wbaccs', value)}
                options={wbaccs?.map(item => ({label: item.name, value: item.id}))}
            />
        </Modal>
    )
}

const ModalRemoveStaff = ({tabStaffState, setTabStaffState, isModalOpen, setIsModalOpen}) => {
    const handleOk = async () => {
        setIsModalOpen('staff_remove', false);
        await apiAxios.post('user/staff/remove/', {'email': tabStaffState.email})
        setTabStaffState('allowed_wbaccs', []);
        setTabStaffState('email', '');
        message.open({
            type: 'success',
            content: 'Сотрудник удален!',
            style: {
                marginTop: '5vh',
            },
        });
    };
    return (
        <Modal title="Удалить сотрудника" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('staff_remove', false)}
               footer={[
                   <Button key="submit" danger onClick={handleOk}>
                       Удалить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('staff_remove', false)}>
                       Отменить
                   </Button>,
               ]}>
            <Paragraph className="small text-muted">
                Удалить сотрудника {tabStaffState.email}?
            </Paragraph>
        </Modal>
    )
}

const ModalAddManager = ({all_wbaccs, setIsModalOpen, isModalOpen}) => {
    const [email, setEmail] = useState('');
    const [allowedWbaccs, setAllowedWbaccs] = useState([]);
    const handleOk = async () => {
        setIsModalOpen('staff_manager', false);
        await apiAxios.post('user/staff/update/', {'email': email, 'allowed_wbacc_ids': allowedWbaccs, 'wbacc_ids': all_wbaccs.map(item => item.id)})
        setEmail('');
        setAllowedWbaccs([]);
        message.open({
            type: 'success',
            content: 'Сотрудник добавлен!',
            style: {
                marginTop: '5vh',
            },
        });
    };
    return (
        <Modal title="Добавить менеджера" open={isModalOpen}
               centered
               onCancel={() => setIsModalOpen('staff_manager', false)}
               footer={[
                   <Button key="submit" type="primary" onClick={handleOk}>
                       Пригласить
                   </Button>,
                   <Button key="back" onClick={() => setIsModalOpen('staff_manager', false)}>
                       Отменить
                   </Button>,
               ]}>
            <Title level={5} style={{marginTop: 0}}>Email</Title>
            <Input label={"Field B"} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            <Title level={5} style={{marginTop: 0}}>Доступные аккаунты для сотрудника</Title>
            <SelectorMulti options={all_wbaccs ? all_wbaccs.map(item => ({label: item.name, value: item.id})) : []} selected={allowedWbaccs} setSelected={setAllowedWbaccs}/>
        </Modal>
    )
}

