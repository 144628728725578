import React, { useEffect, useState, useCallback } from 'react'
import { Card, Col, Row, Select, Tooltip, Typography } from 'antd'
import { useAuth } from '../../hooks/useAuth'
import dayjs from 'dayjs'
import DatePickerRange from '../../components/dateRange'
import { apiAxios } from "../../utils/api_axios";
import { options_hide, options_like } from '../../utils'
import { defaultOnChangeLikeHide, setLikeHideOnLoadData } from '../components/LikeHide'
import TableAnalPeriod from './components/analPeriod'
import { ButtonExel } from '../../components/Buttons'
import { useSidebar } from '../../Context/SideBarContext'
import { CiCircleQuestion } from 'react-icons/ci'
import Sidebar from '../home/sideBar/SideBar'
import SideBarAnalPeriod from '../home/sideBar/SideBarAnalPeriod'

export default function AnalPeriod() {
    const { user } = useAuth()
    const [data, setData] = useState([])
    const [filters_data, setFiltersData] = useState([])
    const [likeHideList, setLikeHideList] = useState({ like: [], hide: [] })
    const [selectorFilter, setSelectorFilter1] = useState({
        wbacc_id: 0,
        wbacc_options: [],
        group: 1,
        group_options: [
            { label: 'По дням', value: 1 },
            { label: 'По неделям', value: 2 }
        ],
        like: 1,
        like_options: options_like,
        hide: 1,
        hide_options: options_hide
    })
    const [period, setPeriod] = useState([dayjs().add(-30, 'd'), dayjs().add(-1, 'd')])

    const { openSidebar } = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        console.log('Button clicked')
        openSidebar(<SideBarAnalPeriod />)
    }, [openSidebar])

    const [loading, setLoading] = useState(true)
    const setSelectorFilter = (name, value) => {
        setSelectorFilter1((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const fetchData1 = async () => {
        const fetchedDataWbacc = await apiAxios.post('user/wbacc/get/', { wbacc_ids: user.wbacc_ids })
        let wbaccOptions = []
        for (let i in fetchedDataWbacc.data) {
            wbaccOptions.push({ label: fetchedDataWbacc.data[i].name, value: fetchedDataWbacc.data[i].id })
        }
        setSelectorFilter('wbacc_id', user.wbacc_ids[0])
        setSelectorFilter('wbacc_options', wbaccOptions)
    }

    const fetchData = async () => {
        setLoading(true)
        const fetchedData = await apiAxios.post('anal-period/get/', { wbacc_ids: [selectorFilter.wbacc_id], is_like: selectorFilter.like, is_hide: selectorFilter.hide, st: period[0].format('YYYY-MM-DD'), en: period[1].format('YYYY-MM-DD'), is_week: selectorFilter.group === 2 })  
        setFiltersData(fetchedData['filters'])
        setData(fetchedData['cards'])

        setLikeHideOnLoadData(fetchedData, setLikeHideList)
        setLoading(false)
    }
    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList)
    }
    useEffect(() => {
        if (!selectorFilter.wbacc_ids && user.wbacc_ids) {
            fetchData1()
        }
    }, [user.wbacc_ids])
    useEffect(() => {
        if (selectorFilter.wbacc_id) {
            fetchData()
        }
    }, [selectorFilter.wbacc_id, selectorFilter.group, selectorFilter.like, selectorFilter.hide, period])

    return (
        <>
            <Card>
                <Typography.Title level={1}>Аналитика за период</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Магазины</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='Выберите магазин'
                            value={selectorFilter.wbacc_id}
                            onChange={(value) => setSelectorFilter('wbacc_id', value)}
                            options={selectorFilter.wbacc_options}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Группировка</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            value={selectorFilter.group}
                            onChange={(value) => {
                                setSelectorFilter('group', value)
                            }}
                            options={selectorFilter.group_options}
                        />
                    </Col>

                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Избранное</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            value={selectorFilter.like}
                            onChange={(value) => {
                                setSelectorFilter('like', value)
                            }}
                            options={selectorFilter.like_options}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Скрытые товары</Typography.Title>
                        <Select
                            style={{ width: '100%' }}
                            value={selectorFilter.hide}
                            onChange={(value) => {
                                setSelectorFilter('hide', value)
                            }}
                            options={selectorFilter.hide_options}
                        />
                    </Col>
                    <ButtonExel data={data} />
                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                    </Tooltip>
                </Row>
            </Card>
            <Sidebar />
            <Card>
                <TableAnalPeriod period={period} cards={data} filters={filters_data} likeHideList={likeHideList} onChangeLikeHide={onChangeLikeHide} loading={loading} />
            </Card>
        </>
    )
}
