// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DaysTable .ant-table-row-expand-icon {
    display: none;
}

.DaysTable .ant-table-cell{
  padding:0px !important; 
}

.my-custom-row td {

    height: 100%;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/smart/tables/analCards/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;IAEI,YAAY;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".DaysTable .ant-table-row-expand-icon {\r\n    display: none;\r\n}\r\n\r\n.DaysTable .ant-table-cell{\r\n  padding:0px !important; \r\n}\r\n\r\n.my-custom-row td {\r\n\r\n    height: 100%;\r\n}\r\n\r\n.editable-cell {\r\n  position: relative;\r\n}\r\n\r\n.editable-cell-value-wrap {\r\n  padding: 5px 12px;\r\n  cursor: pointer;\r\n}\r\n\r\n.editable-row:hover .editable-cell-value-wrap {\r\n  padding: 4px 11px;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 2px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
