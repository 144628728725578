import {apiAxios} from '../api_axios';

export const getPlanFact = async (wbacc_ids, is_like, is_hide, period) => {
    const res = await apiAxios.get('plan-fact/get/', {
            'wbacc_ids': wbacc_ids,
            'is_like': is_like,
            'is_hide': is_hide,
            'st': period[0].format('YYYY-MM-DD'),
            'en': period[1].format('YYYY-MM-DD'),
        });
    return res
};

export const updateLikeHide = (article, typ) => {
    const response = apiAxios.post('plan-fact/update/like-hide/', {'article': article, 'typ': typ});
    return response
};