import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {Card, Col, Row, Typography} from 'antd';
import {SelectorSolo} from '../../components/simple/selects';
import {useAuth} from '../../core/hooks/useAuth';
import dayjs from 'dayjs';
import {options_hide, options_like} from '../../core/constants';
import DatePickerRange from '../../components/simple/dateRange';
import {getStatDays} from '../../core/api/general/general_stat';
import TableStatDay from '../../components/smart/tables/general/generalStatDays';
import {getStatWeeks} from '../../core/api/general/general_stat_week';
import TableStatDay2 from "../../components/smart/tables/general/generalStatDays2";
import { ButtonExel } from '../../components/simple/buttons/Buttons';

export default function GeneralStatDay() {
    const {user, isCsrf} = useAuth();
    const [filters_data, setFiltersData] = useState([]);
    const [cards, setCards] = useState([]);
    const [cards1, setCards1] = useState([]);

    const [period, setPeriod] = useState([dayjs().add(-30, 'd'), dayjs().add(-1, 'd')]);
    const [groupType, setGroupType] = useState(1);
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const fetchedData = await getStatDays(
                period
            );
            setFiltersData(fetchedData['filters']);
            setCards(fetchedData['data']);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [period]);

    const fetchData1 = useCallback(async () => {
        try {
            setLoading(true);
            const fetchedData = await getStatWeeks(
                period
            );
            setFiltersData(fetchedData['filters']);
            setCards1(fetchedData['data']);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [period]);


    useEffect(() => {
        fetchData();
        fetchData1();

    }, [period]);


    const groupOptions = [{label: 'По дням', value: 1}, {label: 'По неделям', value: 2}]
    return (
        <>
            <Card>
                <Typography.Title level={1}>Аналитика по дням</Typography.Title>
                <Row type='flex' style={{gap: 10, alignItems:'end'}}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Период данных
                        </Typography.Title>
                        <DatePickerRange
                            selected={period}
                            setSelected={setPeriod}
                        />
                    </Col>

                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Группировка
                        </Typography.Title>
                        <SelectorSolo
                            options={groupOptions}
                            selected={groupType}
                            setSelected={setGroupType}
                        />
                    </Col>
                    <ButtonExel data={groupType === 1 ? cards : cards1} />
                </Row>
            </Card>
            <Card>
                <TableStatDay2
                    cards={groupType === 1 ? cards : cards1}
                    groupType={groupType}
                    loading={loading}
                />
            </Card>
        </>
    );
}
