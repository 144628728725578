export function formatQuantity(number) {
    if (number) {
        const formattedNumber = Number(number).toLocaleString('ru-RU')
        return `${formattedNumber} шт`
    }
    return number
}

export function formatCurrency(number) {
    if (number) {
        const roundedNumber = Math.round(Number(number));
        const formattedNumber = roundedNumber.toLocaleString('ru-RU');
        return `${formattedNumber} ₽`;
    }
    return number;
}
export function formatCurrency2(number) {
    if (number !== undefined && number !== null) {
        const roundedNumber = number.toFixed(2);
        const formattedNumber = roundedNumber.toLocaleString('ru-RU');
        return `${formattedNumber} ₽`;
    }
    return number;
}

export function formatCurrency1000(number) {
    if (number !== undefined && number !== null) {
        // Округляем до ближайшей тысячи
        const roundedNumber = Math.round(number / 1000);

        // Форматируем число с разделителями
        const formattedNumber = roundedNumber.toLocaleString('ru-RU');

        return `${formattedNumber} т.р.`;
    }
    return number;
}
export function formatViews(number) {
    if (number) {
        const formattedNumber = Number(number).toLocaleString('ru-RU')
        return `${formattedNumber} раз`
    }
    return number
}

export function formatPercent2(number) {
    if (number !== undefined && number !== null) {
        const num = Number(number);
        if (isNaN(num) || !Number.isFinite(num)) return '0%';
        return `${num.toFixed(0)}%`;
    }
    return '0%';
}

export function formatPercent(number) {
    if (number !== undefined && number !== null) {
        const num = Number(number);
        if (isNaN(num)) return '0%';
        return `${num.toFixed(2)}%`;
    }
    return '0%';
}

export function formatDays(number) {
    if (number) {
        return `${number} дн`
    }
    return number
}

export function formatDays2(number) {
    if (number) {
        return `${number.toFixed(0)} дн`
    }
    return number
}

export function formatNumber(number) {
    if (number) {
        const formattedNumber = Number(number).toLocaleString('ru-RU')
        return `${formattedNumber}`
    }
    return number
}

export function formatDate(dateStr) {
    if (!dateStr) {
        return ''
    }
    const date = new Date(dateStr);
    return date.toLocaleDateString('ru-RU');
}

export function formatAdvertType(number) {
    const types = {
        8: 'Автоматическая',
        9: 'Аукцион'
    }
    return types[number]
}

export function formatAdvertStatus(number) {
    const types = {
        4: 'Готова к запуску',
        7: 'Завершена',
        8: 'Отменена',
        9: 'Активна',
        11: 'Пауза'
    }
    return types[number]
}
