import React, { useState, useEffect } from 'react';
import { Table, Col, Typography, Button, Space, Input, Dropdown, Menu, Tag, Select } from 'antd';
import { formatNumber, formatPercent, formatQuantity} from '../../../utils/formats';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import './AbcTable.css';
import { useFilteredCards, useFilterLogic } from '../../components/filterUtils';

const { Text } = Typography;


export default function AbcTable({ data, loading, group,filters_data,selectorFilter,setSelectorFilter }) {
    const [sortedData, setSortedData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [activeFilters, setActiveFilters] = useState([]);
    const [quickFilters, setQuickFilters] = useState({
        showNegativeMargin: false,
        showNoSales: false,
        showLongTurnover: false,
        showLowBuyout: false
    });
    const handleAddFilter = (type, value) => {
        setActiveFilters(prev => {
            const filterExists = prev.some(
                filter => filter.type === type && filter.value.value === value.value
            );
            if (filterExists) return prev;
            return [...prev, {type, value}];
        });
    };
    const [filterSearchTexts, setFilterSearchTexts] = useState({
        article: '',
        material: '',
        category: '',
        status: '',
        sezon: '',
        brand: '',
        color: ''
    });
    const quickFilterButtons = [
        {
            key: 'showNegativeMargin',
            label: 'Отрицательная маржа',
            filter: item => item.margin_percent < 0
        },
        {
            key: 'showNoSales',
            label: 'Нет заказов',
            filter: item => item.orders_per_day < 0.2 && item.ost_quantity > 10
        },
        {
            key: 'showLongTurnover',
            label: 'Низкие отмены',
            filter: item => item.cancels < 15 // больше 60 дней
        },
        {
            key: 'showLowBuyout',
            label: 'Низкий выкуп',
            filter: item => item.buyout_percent < 30 && item.buyout_percent > 0 // меньше 50%
        }
    ];

    const handleQuickFilter = (filterKey) => {
        // Сбрасываем все фильтры, если текущий фильтр уже активен
        if (quickFilters[filterKey]) {
            setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
                ...acc,
                [key]: false
            }), {}));
        } else {
            // Включаем только выбранный фильтр
            setQuickFilters(prev => Object.keys(prev).reduce((acc, key) => ({
                ...acc,
                [key]: key === filterKey
            }), {}));
        }
    };

    const applyQuickFilters = (data) => {
        const activeFilter = Object.entries(quickFilters).find(([_, value]) => value);
        if (!activeFilter) return data;

        const filterConfig = quickFilterButtons.find(btn => btn.key === activeFilter[0]);
        return data.filter(filterConfig.filter);
    };

    const filteredData = applyQuickFilters(useFilteredCards(data, activeFilters));

    useEffect(() => {
        if (data) {
            setSortedData(data);
        }
    }, [data]);


    const handleSearch = (value) => {
        setSearchText(value);
        if (!value.trim()) {
            setSortedData(data);
            return;
        }

        const filteredData = data.filter(item => 
            item.article?.toString().toLowerCase().includes(value.toLowerCase()) ||
            item.nm_id?.toString().toLowerCase().includes(value.toLowerCase())
        );
        setSortedData(filteredData);
    };



    const columns = [
        group !== 'nm_id' ? {   
            title: group,
            dataIndex: 'group_key',
            key: 'group_key',
            width: 100,
        } :  {
            title: 'Товар',
            dataIndex: 'article',
            key: 'article',
            width: 300,
            render: (_, record) => (
                <Space direction="vertical" size={0}>
                    <Space align="start" size={12}>
                        <img src={record.img} style={{height: '60px', width: '60px', objectFit: 'contain'}} alt="Product"/>
                        <Space direction="vertical" size={0} className="cost-info-cell">
                            <Text><strong>Артикул:</strong> <a href={`product/${record.article}`}>{record.article}</a></Text>
                            <Text><strong>Артикул WB:</strong> {record.nm_id}</Text>
                            <Text><strong>Название:</strong> {record.name}</Text>
                            <Text><strong>Карточка:</strong> {record.imt_id}</Text>
                        </Space>
                    </Space>
                </Space>
            )
        },
       
        {
            title: 'Информация о товаре',
            dataIndex: 'info',
            key: 'info',
            width: 300,
            render: (_, record) => (
                <Space size={24} className="cost-info-cell">
                <div className='w-[200px]'>
                    <Text><strong>Модель:</strong> {record.material}</Text>
                    <Text><strong>Бренд:</strong> {record.brand}</Text>
                    <Text><strong>Категория:</strong> {record.category}</Text>
                </div>
                <div>
                    <Text><strong>Статус:</strong> {record.status}</Text>
                    <Text><strong>Сезон:</strong> {record.sezon}</Text>
                    <Text><strong>Цвет:</strong> {record.color}</Text>
                </div>
            </Space>
            )
        },
        {
            title: 'Продажи',
            dataIndex: 'sells',
            key: 'sells',
            width: 300,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Заказы:</strong> {formatQuantity(record.orders)}</Text>
                        <Text><strong>Выкупы:</strong> {formatQuantity(record.buyouts)}</Text>
                        <Text><strong>Отмены:</strong> {formatQuantity(record.cancels || 0)}</Text>
                        <Text><strong>Возвраты:</strong> {formatQuantity(record.returns || 0)}</Text>
                </Space>

            ),  
        },
        {
            title: 'ABC',
            dataIndex: 'finances',
            key: 'finances',
            width: 300,
            render: (_, record) => (
               <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>ABC:</strong> {record.abc}</Text>
                        <Text><strong>Процент выручки:</strong> {formatPercent(record.abc_percent_buyouts_sum)}</Text>
                        <Text><strong>Процент маржи:</strong> {formatPercent(record.abc_percent_profit)}</Text>
                </Space>
            ),
        },

        {
            title: 'Показатели',
            dataIndex: 'efficiency',
            key: 'efficiency',
            width: 300,
            render: (_, record) => (
                <Space direction="vertical" size={0} className="cost-info-cell">
                        <Text><strong>Маржинальность:</strong> {formatPercent(record.marzha)}</Text>
                    <Text><strong>Рентабельность:</strong> {formatPercent(record.ren)}</Text>
                        <Text><strong>DRR:</strong> {formatPercent(record.drr)}</Text>
                        <Text><strong>ROI:</strong> {formatNumber(record.roi)}</Text>
                </Space>
            ),
        },
    ];
    const handleRemoveFilter = (index) => {
        setActiveFilters(prev => prev.filter((_, i) => i !== index));
    };

    const menuItems = useFilterLogic(
        filters_data,
        filterSearchTexts,
        setFilterSearchTexts,
        handleAddFilter
    );

    const filterMenu = <Menu items={menuItems}/>;

    return (
        <>
         <Space direction="vertical" size={16} style={{width: '100%', marginBottom: 16}}>
                    <Space wrap className='items-end'>
                    <Col>
                    <Typography.Title level={5}>Группировка</Typography.Title>
                        <Select
                            style={{ width: '200px' }}
                            value={selectorFilter.group}
                            onChange={(value) => {
                                console.log('value', value)
                                setSelectorFilter('group', value)
                            }}
                            options={selectorFilter.group_options}
                        /></Col>
                        <Dropdown overlay={filterMenu} trigger={['click']}>
                            <Button icon={<FilterOutlined/>}>
                                Добавить фильтр
                            </Button>
                        </Dropdown>
                        {/* <Button
                            icon={<ReloadOutlined/>}
                            onClick={fetchOborotData}
                        >
                            Обновить
                        </Button> */}
                        {activeFilters.map((filter, index) => (
                            <Tag
                                key={index}
                                closable
                                onClose={() => handleRemoveFilter(index)}
                            >
                                {`${filter.type}: ${filter.value.label}`}
                            </Tag>
                        ))} 
                    </Space>
                </Space>
            <Space style={{ marginBottom: 16 }} size="middle">
                <Input
                    placeholder="Поиск по артикулу"
                    prefix={<SearchOutlined />}
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchText}
                    style={{ width: 200 }}
                    allowClear
                />
                <Space wrap>
                        {quickFilterButtons.map(button => (
                            <Button
                                key={button.key}
                                type={quickFilters[button.key] ? 'primary' : 'default'}
                                onClick={() => handleQuickFilter(button.key)}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Space>
            </Space>
            <Table
                dataSource={filteredData}
                columns={columns}
                loading={loading}
                scroll={{ x: 'max-content' }}
                bordered
                size="small"
                pagination={{
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} товаров`
                }}
                rowKey="nm_id"
                className="abc-analysis-table"
            />
        </>
    );
}
