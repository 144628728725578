import { EyeOutlined, HeartOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Table, Button } from 'antd';
import React, { useState } from 'react';
import { formatCurrency } from '../../../utils/formats';

const getColumns = (likeHideList, onChangeLikeHide) => {

    const columns =  [
        {
            title: '', width: 30,
            render: (text, record) =>
                record.isMainRow ? (
                    <Button
                    className='buttonCatalog'
                        type="link"
                        onClick={record.onExpand}
                    >
                        {record.expanded ? <MinusOutlined /> : <PlusOutlined />}
                    </Button>
                ) : null,
        },
        {
            title: <HeartOutlined />,
            width: 21,
            render: (text, record) => (
                record.isMainRow ? (
                    <Checkbox
                    style={{ display: 'flex', justifyContent: 'center' }}
                    checked={likeHideList['like'].includes(record.article)}
                    onChange={(e) =>
                        onChangeLikeHide(record, e.target.checked, 'like')
                    }
                />
                ) : null
            ),
        },
        {
            title: <EyeOutlined />,
            width: 21,
            render: (text, record) => (
                    record.isMainRow ? (
                            <Checkbox
                    style={{ display: 'flex', justifyContent: 'center' }}
                    checked={likeHideList['hide'].includes(record.article)}
                    onChange={(e) =>
                        onChangeLikeHide(record, e.target.checked, 'hide')
                    }
                />
                    ) : null
            ),
        },
        { title: 'WBACC Name', dataIndex: 'wbacc_name', width:90 },
        { title: 'Артикул', dataIndex: 'article', width:90 },
        { title: 'Название', dataIndex: 'name', ellipsis:true,width:190 },
        { title: 'Размеры', dataIndex: 'sizes', ellipsis:true,width:150 },
        { title: 'barcodes', dataIndex: 'barcodes', ellipsis:true,width:170 },
        { title: 'Сезон', dataIndex: 'sezon', ellipsis:true,render:(text) => (text ? text : '-'),width:80 },
        { title: 'Цвет', dataIndex: 'color', ellipsis:true, render:(text) => (text ? text : '-'),width:80 },
        { title: 'Цена клиента', dataIndex: 'price_client', render: (text) => formatCurrency(text ? text : '-'), width:80, ellipsis:true},
        {
            title: 'Size Value',
            dataIndex: 'size_value',
            className:'size_value_catalog',
            render: (text, record) => {
                const wbEntries = record.wb || [];
                const ozonEntries = record.ozon || [];
                const wbSizeValue = wbEntries.length > 0 ? wbEntries[0].size_value : null;
                const ozonSizeValue = ozonEntries.length > 0 ? ozonEntries[0].size_value : null;
                const isDifferent = wbSizeValue !== ozonSizeValue && wbSizeValue && ozonSizeValue;
                return (
                    <div style={{
                        backgroundColor: isDifferent ? '#fe4c4cc3' : 'transparent',
                        color: isDifferent ? 'white' : 'inherit',
                        padding: '14px 0px',
                        height:'100%',
                    }}>
                        {text ? text : '-'}
                    </div>
                );
            },
            width:90
        },
        { title: 'Рейтинг', dataIndex: 'rating', render: (text) => (text ? text : '-'),width:80 },
        { title: 'NM ID', dataIndex: 'nm_id', render: (text) => (text ? text : '-'), width:80},
        { title: 'IMT ID', dataIndex: 'imt_id', render: (text) => (text ? text : '-'), width:80},
        { title: 'skus', dataIndex: 'skus', render: (text) => (text ? text : '-'), ellipsis:true ,width:120},
        { title: 'SPP', dataIndex: 'spp', render: (text) => (text ? text : '-'), ellipsis:true,width:80 },
        { title: 'status', dataIndex: 'status', render: (text) => (text ? text : '-'), width:80 },
    ];

    return columns;
};

export default function CatalogTable({
    likeHideList,
    onChangeLikeHide,
    loading,
    cards,
}) {
    const [expandedRows, setExpandedRows] = useState([]);

    const handleExpandRow = (record) => {
        const newExpandedRows = expandedRows.includes(record.article)
            ? expandedRows.filter(id => id !== record.article)
            : [...expandedRows, record.article];

        setExpandedRows(newExpandedRows);
    };

    const columns = getColumns(likeHideList, onChangeLikeHide);

    const expandedData = (record) => {
    const wbData = record.wb || [];
    const ozonData = record.ozon || [];

    const combinedData = [
        ...wbData.map((wbEntry) => ({
            key: `${record.article}-wb-${wbEntry.nm_id}`, wbacc_name: wbEntry.wbacc_name, article: `${wbEntry.article}`,
            name: wbEntry.name || '-',  sizes: wbEntry.sizes ? wbEntry.sizes.join(', ') : '-', price_client: wbEntry.price_client || '-',
            nm_id: wbEntry.nm_id || '-', barcodes: wbEntry.barcodes || '-', imt_id: wbEntry.imt_id || '-', size_value: wbEntry.size_value || '-',
            rating: wbEntry.rating || '-',  isMainRow: false,  platform: 'WB', spp:wbEntry.spp
        })),
        ...ozonData.map((ozonEntry) => ({
            key: `${record.article}-ozon-${ozonEntry.article}`, wbacc_name: ozonEntry.wbacc_name,
            article: `${ozonEntry.article}`, name: ozonEntry.name || '-', barcodes: ozonEntry.barcodes || '-', 
            sizes: ozonEntry.sizes ? ozonEntry.sizes.join(', ') : '-', price_client: ozonEntry.price_client || '-',
            nm_id: ozonEntry.wbacc_id || '-',  imt_id: '-', size_value: ozonEntry.size_value || '-',
            rating: ozonEntry.rating || '-', isMainRow: false, platform: 'Ozon', skus:ozonEntry.skus, spp:ozonEntry.spp
        })),
    ];

    return combinedData;
};


    const DataSource = cards.reduce((acc, card) => {
        acc.push({
            ...card,
            onExpand: () => handleExpandRow(card),
            expanded: expandedRows.includes(card.article), isMainRow: true, wbacc_name: "Мой склад", sizes: card.sizes.join(', ') || '-', barcodes: card.barcodes.join(', ') || '-'
        });

        if (expandedRows.includes(card.article)) {
            acc = [...acc, ...expandedData(card)];
        }
        
        return acc;
    }, []);

    return (
        <>
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size="small"
                scroll={{ y: 800 }}
                className="DaysTable"
                rowKey="key"
                loading={loading}
                pagination={{
                    defaultPageSize: 30,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} из ${total} артикулов`,
                }}
                rowClassName={(record) => {
                    if (record.platform === 'WB') {
                        return 'my-custom-row row-wb';
                    } else if (record.platform === 'Ozon') {
                        return 'my-custom-row row-ozon';
                    }
                    return 'my-custom-row';
                }}
            />
        </>
    );
}