import { Card, Col, Row, Tooltip, Typography } from 'antd'
import { CardDiff } from './components/cardDiff'
import { SelectorMulti } from '../../components/Selectors'
import React, { useCallback, useEffect, useState } from 'react'
import { MarketingChart } from './components/marketingChart'
import DatePickerRange from '../../components/dateRange'
import dayjs from 'dayjs'
import TableMarketingMain from './components/marketing'
import { formatCurrency, formatNumber, formatPercent, formatQuantity } from '../../utils/formats';
import { ButtonExel } from '../../components/Buttons'
import { useSidebar } from '../../Context/SideBarContext'
import Sidebar from '../home/sideBar/SideBar'
import { CiCircleQuestion } from 'react-icons/ci'
import SideBarMarketing from '../home/sideBar/SideBarMarketing'
import { apiAxios } from "../../utils/api_axios";
import { useAuth } from '../../hooks/useAuth'

export default function Marketing() {
    const [filters_data, setFiltersData] = useState([])
    const [data, setData] = useState([])
    const [dataChart, setDataChart] = useState([])
    const [selectedWbaccs, setSelectedWbaccs] = useState([])
    const [selected_hide, setSelectedHide] = useState(1)
    const [selected_like, setSelectedLike] = useState(1)
    const startDate = dayjs().date() == 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month')
    const [period, setPeriod] = useState([startDate, dayjs()])
    const [loading, setLoading] = useState(true)
    const [miniblocks, setMiniblocks] = useState([])

    const {user} = useAuth();
    const { openSidebar } = useSidebar()

    const handleOpenSidebar = useCallback(() => {
        openSidebar(<SideBarMarketing />)
    }, [openSidebar])

    const items = {
        budget: 'Расходы',
        views: 'Показы',
        clicks: 'Клики',
        add_to_cart: 'Корзина',
        orders: 'Заказы',
        orders_sum: 'Сумма заказов',
        cpc: 'CPC',
        ctr: 'CTR',
        cpo: 'CPO',
        drr: 'ДРР',
        romi: 'ROMI',
        quantity_adverts: 'Рекламных кампаний',
        quantity_nm_ids: 'Товаров в рекламе'
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const fetchedData =  await apiAxios.get('marketing/get/', { 'wbacc_ids': selectedWbaccs, 'is_like': selected_like, 'is_hide': selected_hide, 'st': period[0].format('YYYY-MM-DD'), 'en': period[1].format('YYYY-MM-DD') })
            setFiltersData(fetchedData['filters'])
            setData(fetchedData['data'])
            setLoading(false)
            let data_ = []
            fetchedData['data'].forEach((stat) => {
                for (let key in items) {
                    data_.push({ date: stat.date, value: stat[key], category: items[key] })
                }
            })
            data_.sort((a, b) => new Date(a.date) - new Date(b.date))
            setDataChart(data_)
            const stat = fetchedData['sum_stat']
            setMiniblocks([
                [
                    { title: 'Расходы', value: formatCurrency(stat.budget), diff: formatCurrency(stat.diff_budget), is_up: stat.diff_budget > 0, is_green: stat.diff_budget < 0 },
                    { title: 'ROMI', value: stat.romi, diff: stat.diff_romi, is_up: stat.diff_romi > 0, is_green: stat.diff_romi > 0 },
                    { title: 'ДРР', value: formatPercent(stat.drr), diff: formatPercent(stat.diff_drr), is_up: stat.diff_drr > 0, is_green: stat.diff_drr < 0 }
                ],
                [
                    { title: 'Показы', value: formatNumber(stat.views), diff: formatNumber(stat.diff_views), is_up: stat.diff_views > 0, is_green: stat.diff_views > 0 },
                    { title: 'Рекламных кампаний', value: stat.quantity_adverts, diff: stat.diff_quantity_adverts, is_up: stat.diff_quantity_adverts > 0, is_green: stat.diff_quantity_adverts > 0 },
                    { title: 'Товаров в рекламе', value: stat.quantity_nm_ids, diff: stat.diff_quantity_nm_ids, is_up: stat.diff_quantity_nm_ids > 0, is_green: stat.diff_quantity_nm_ids > 0 }
                ],
                [
                    { title: 'Клики', value: formatNumber(stat.clicks), diff: formatNumber(stat.diff_clicks), is_up: stat.diff_clicks > 0, is_green: stat.diff_clicks > 0 },
                    { title: 'CPC', value: formatCurrency(stat.cpc), diff: formatCurrency(stat.diff_cpc), is_up: stat.diff_cpc > 0, is_green: stat.diff_cpc < 0 },
                    { title: 'CTR', value: formatPercent(stat.ctr), diff: formatPercent(stat.diff_ctr), is_up: stat.diff_ctr > 0, is_green: stat.diff_ctr > 0 }
                ],
                [
                    { title: 'Корзина', value: formatNumber(stat.add_to_cart), diff: formatNumber(stat.diff_add_to_cart), is_up: stat.diff_add_to_cart > 0, is_green: stat.diff_add_to_cart > 0 },
                    {
                        title: 'Стоимость корзины',
                        value: formatCurrency(stat.add_to_cart_cost),
                        diff: formatCurrency(stat.diff_add_to_cart_cost),
                        is_up: stat.diff_add_to_cart_cost > 0,
                        is_green: stat.diff_add_to_cart_cost < 0
                    },
                    {
                        title: 'Добавление в корзину',
                        value: formatPercent(stat.add_to_cart_percent),
                        diff: formatPercent(stat.diff_add_to_cart_percent),
                        is_up: stat.diff_add_to_cart_percent > 0,
                        is_green: stat.diff_add_to_cart_percent > 0
                    }
                ],
                [
                    { title: 'Заказы', value: formatQuantity(stat.orders), diff: formatQuantity(stat.diff_orders), is_up: stat.diff_orders > 0, is_green: stat.diff_orders > 0 },
                    { title: 'Стоимость заказа', value: formatCurrency(stat.cpo), diff: formatCurrency(stat.diff_cpo), is_up: stat.diff_cpo > 0, is_green: stat.diff_cpo < 0 },
                    { title: 'Выручка', value: formatCurrency(stat.orders_sum), diff: formatCurrency(stat.diff_orders_sum), is_up: stat.diff_orders_sum > 0, is_green: stat.diff_orders_sum > 0 }
                ]
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : []);
    }, [user.wbacc_ids]);

    useEffect(() => {
        fetchData()
    }, [selected_hide, selected_like, selectedWbaccs, period])

    return (
        <>
            <Card>
                <Typography.Title level={1}>Общий отчет</Typography.Title>
                <Row type='flex' style={{ gap: 10, alignItems: 'end' }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Поставщики</Typography.Title>
                        <SelectorMulti options={filters_data?.wbaccs} selected={selectedWbaccs} setSelected={setSelectedWbaccs} />
                    </Col>
                    <ButtonExel data={data} />
                    <Tooltip title='Информация по таблице'>
                        <CiCircleQuestion onClick={handleOpenSidebar} className='text-[32px] hover:rotate-180 transition-all duration-300 active:scale-90 cursor-pointer font-bold' />
                    </Tooltip>
                </Row>
            </Card>

            <Row>
                {miniblocks.map((item) => {
                    return (
                        <Col xs={24} sm={12} md={7} lg={4}>
                            <CardDiff items={item} />
                        </Col>
                    )
                })}
            </Row>

            <Row>
                <Col xs={24}>
                    <Card>
                        <MarketingChart dataChart={dataChart} />
                    </Card>
                </Col>
            </Row>
            <Sidebar />
            <Card>
                <TableMarketingMain data={data} filters={filters_data} loading={loading} />
            </Card>
        </>
    )
}
